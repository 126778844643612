import {
  AlertTitle,
  Button,
  Chip,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { APIServices } from "../../helpers/APIServices";
import {
  BootstrapDialogTitle,
  checkMinMaxNG,
  checkMinMaxOK,
  searchSubcategoriesName,
} from "./EditPaper";
import { IconCheckNG } from "./PaperV2";
import PhotoIcon from "@mui/icons-material/Photo";
import { BootstrapDialog } from "./FormAddImageAnswer";
import config from "../../config";
import ConfirmModal from "../../Components/ConfirmModal";
import { UserContext } from "../../Layout";
import SignatureBox from "../../Components/SignatureBox";
import { useSnackbarStore } from "../../helpers/state";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import Dialog from "@mui/material/Dialog";
import Close from "@mui/icons-material/Close";
import Rotate90DegreesCw from "@mui/icons-material/Rotate90DegreesCw";

// import { XLSX } from "xlsx";
import SimpleBackdrop from "../../Components/Backdrop";
import { parseStatus } from "../../helpers/common";

const ShowImages = ({ imageURL, indexSC }) => {
  const [imageFullScreen, setImageFullScreen] = useState({
    open: false,
    imageURL: "",
  });
  const [rotateDeg, setRotateDeg] = useState(0);

  const rotateImage = () => {
    if (rotateDeg === 270) {
      setRotateDeg(0);
      return;
    }
    setRotateDeg((prev) => prev + 90);
  };

  return (
    <>
      <Dialog open={imageFullScreen.open} fullScreen>
        <div className={`flex justify-end gap-4 fixed top-4 right-6 z-10`}>
          <div
            className={`w-10 h-10 flex justify-center items-center rounded-full bg-white hover:bg-gray-100 cursor-pointer`}
            onClick={rotateImage}>
            <Rotate90DegreesCw />
          </div>
          <div
            className={`w-10 h-10 flex justify-center items-center rounded-full bg-white hover:bg-gray-100 cursor-pointer`}
            onClick={() => {
              setImageFullScreen((prev) => ({
                ...prev,
                open: false,
              }));
            }}>
            <Close />
          </div>
        </div>
        <img
          src={`${imageFullScreen.imageURL}`}
          alt=''
          className={`z-0`}
          style={{
            transform: `rotate(${rotateDeg}deg)`,
            // transformOrigin: `top`,
          }}
        />
      </Dialog>
      {indexSC !== undefined
        ? imageURL[indexSC].map(
            (imageURLLoop, index) =>
              typeof imageURLLoop === "string" && (
                <div
                  key={index}
                  className={`flex justify-evenly items-center ${
                    index === 0 ? "mx-auto" : index % 2 !== 0 && "mx-auto"
                  } relative basis-1/3 `}>
                  <div
                    className='relative cursor-pointer'
                    onClick={() => {
                      setImageFullScreen((prev) => ({
                        ...prev,
                        open: true,
                        imageURL: `${config.server}${imageURLLoop}`,
                      }));
                    }}>
                    <div className='w-[200px] overflow-hidden '>
                      <img src={`${config.server}${imageURLLoop}`} alt='' />
                    </div>
                  </div>
                </div>
              )
          )
        : imageURL.map(
            (imageURLLoop, index) =>
              typeof imageURLLoop === "string" && (
                <div
                  key={index}
                  className={`flex justify-evenly items-center ${
                    index === 0 ? "mx-auto" : index % 2 !== 0 && "mx-auto"
                  } relative basis-1/3 `}>
                  <div
                    className='relative cursor-pointer'
                    onClick={() => {
                      setImageFullScreen((prev) => ({
                        ...prev,
                        open: true,
                        imageURL: `${config.server}${imageURLLoop}`,
                      }));
                    }}>
                    <div className='w-[200px] overflow-hidden '>
                      <img src={`${config.server}${imageURLLoop}`} alt='' />
                    </div>
                  </div>
                </div>
              )
          )}
    </>
  );
};

const ViewPhoto = ({ open, setOpen, selectedQuestion, setPaper, paper }) => {
  return (
    <div>
      <BootstrapDialog onClose={() => setOpen(false)} open={open}>
        <BootstrapDialogTitle onClose={() => setOpen(false)}>
          รูปภาพ
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component='form'
            sx={{
              "& .MuiTextField-root": {
                mt: 2,
              },
            }}
            noValidate
            autoComplete='off'>
            <div className='flex flex-col'>
              <div className='mt-4 flex gap-12 flex-wrap'>
                {selectedQuestion.questionData.imageURL ? (
                  <ShowImages
                    imageURL={selectedQuestion.questionData.imageURL}
                    indexSC={selectedQuestion.indexSC}
                  />
                ) : (
                  <div>ไม่มีข้อมูลรูปภาพ</div>
                )}
              </div>
            </div>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

const SubCategories = ({
  subCategories,
  subCatPerLoop,
  subCatIndex,
  setOpenViewPhoto,
  setSelectedQuestion,
}) => {
  const subData = searchSubcategoriesName(subCategories, subCatPerLoop.subId);
  return (
    subCatPerLoop.questions.length !== 0 && (
      <div
        key={`paper${subCatPerLoop.subId}`}
        className={`bg-white drop-shadow p-4 rounded-md`}>
        <div className='flex flex-row pb-4'>
          <div className='flex items-center rounded-full text-xl'>
            <Chip label={subData.categoryName} variant='outlined' />
          </div>
          <div className='flex items-center text-xl font-semibold ml-4'>
            {subData.subCategoriesName}
          </div>
        </div>
        <hr />
        <div>
          {/* {console.log("subCatPerLoop 148", subCatPerLoop.category)} */}
          {subCatPerLoop.questions.map((question, index) => (
            <QuestionContent
              key={`question${index}`}
              question={question}
              questionsPerSub={subData.questions}
              questionIndex={index}
              subCatIndex={subCatIndex}
              setOpenViewPhoto={setOpenViewPhoto}
              setSelectedQuestion={setSelectedQuestion}
              category={subCatPerLoop.category}
            />
          ))}
        </div>
      </div>
    )
  );
};

const QuestionContent = ({
  question,
  questionsPerSub,
  questionIndex,
  subCatIndex,
  setOpenViewPhoto,
  setSelectedQuestion,
  category,
}) => {
  const resultQuestion = questionsPerSub.find(
    (questionPerLoop) => questionPerLoop.id === question.questionId
  );
  const questionName = resultQuestion.attributes.question || "";
  const min = resultQuestion.attributes.spec_min || null;
  const max = resultQuestion.attributes.spec_max || null;
  const sc_status = resultQuestion.attributes.sc_status;
  return (
    <div>
      <div className='text-lg mt-4 flex flex-row items-center'>
        {questionName}
        {min !== null && max !== null && (
          <div className={`flex gap-2 ml-4`}>
            <span>{parseFloat(min).toFixed(2)}</span>
            <span>~</span>
            <span>{parseFloat(max).toFixed(2)}</span>
          </div>
        )}
        {sc_status && (
          <div className='ml-2'>
            <Chip label='SC' color='primary' variant='outlined' />
          </div>
        )}
      </div>
      <div className={`mt-4`}>
        {Array(3)
          .fill(0)
          .map((d, index) => {
            return (
              <div key={`form${index}`} className='flex mt-4'>
                <FormQuestion
                  questionMaster={resultQuestion.attributes}
                  questionData={question}
                  indexSC={index}
                  questionIndex={questionIndex}
                  subCatIndex={subCatIndex}
                  setOpenViewPhoto={setOpenViewPhoto}
                  setSelectedQuestion={setSelectedQuestion}
                  category={category}
                />
              </div>
            );
          })}
      </div>
    </div>
  );
};

const checkDisableInput = (image) => {
  if (image === "") {
    return true;
  }
};

const checkNG = (paper, subCategoryIndex, questionIndex, index) => {
  if (paper) {
    const currentAnswer = paper.answer[index];
    if (currentAnswer === "NG") {
      return true;
    }
  }
};

const FormQuestion = ({
  questionMaster,
  questionData,
  indexSC,
  questionIndex,
  subCatIndex,
  setOpenViewPhoto,
  setSelectedQuestion,
  category,
}) => {
  return (
    <div className='flex flex-row items-center'>
      <FormControl>
        {questionMaster.spec_min !== null &&
        questionMaster.spec_max !== null ? (
          <RadioGroup row>
            <FormControlLabel
              value={`OK`}
              control={
                <Radio
                  disabled
                  checked={checkMinMaxOK(
                    questionMaster.spec_min,
                    questionMaster.spec_max,
                    questionData.actual[indexSC].toString()
                      ? questionData.actual[indexSC]
                      : ""
                  )}
                />
              }
              label='OK'
            />
            <FormControlLabel
              value={`NG`}
              control={
                <Radio
                  disabled
                  checked={checkMinMaxNG(
                    questionMaster.spec_min,
                    questionMaster.spec_max,
                    questionData.actual[indexSC].toString()
                      ? questionData.actual[indexSC]
                      : ""
                  )}
                />
              }
              label='NG'
            />
          </RadioGroup>
        ) : (
          <RadioGroup row>
            <FormControlLabel
              value={`OK`}
              checked={questionData.answer[indexSC] === "OK"}
              control={<Radio disabled />}
              label='OK'
            />
            <FormControlLabel
              value={`NG`}
              checked={questionData.answer[indexSC] === "NG"}
              control={<Radio disabled />}
              label='NG'
            />
          </RadioGroup>
        )}
      </FormControl>
      <Box
        component={"form"}
        sx={{
          "& .MuiTextField-root": {
            m: 1,
            width: "25ch",
          },
        }}
        noValidate
        autoComplete='off'>
        {((questionMaster.spec_min !== null &&
          questionMaster.spec_max !== null) ||
          category === "Dimension") && (
          <TextField
            label='Actual'
            type='number'
            size='small'
            defaultValue={
              questionData.actual && questionData.actual[indexSC].toString()
                ? questionData.actual[indexSC]
                : ""
            }
            disabled
            // InputProps={{
            //   readOnly: true,
            // }}
          />
        )}
        <TextField
          label='Comment'
          type='text'
          size='small'
          defaultValue={
            questionData.comment ? questionData.comment[indexSC] : ""
          }
          disabled
        />
      </Box>
      <div className='flex gap-4 items-center'>
        <Fab
          onClick={() => {
            setOpenViewPhoto(true);
            setSelectedQuestion({
              questionData,
              subCatIndex,
              questionIndex,
              questionMaster,
              indexSC,
            });
          }}
          disabled={checkDisableInput(
            indexSC !== undefined &&
              questionData.imageURL &&
              questionData.imageURL[indexSC].length > 0
              ? `${questionData.imageURL[indexSC].length}`
              : ""
          )}
          color='primary'
          variant='contained'
          component='label'>
          {indexSC !== undefined &&
          questionData.imageURL &&
          questionData.imageURL[indexSC].length > 0 ? (
            `${questionData.imageURL[indexSC].length + " รูป"}`
          ) : (
            <PhotoIcon />
          )}
        </Fab>
        <IconCheckNG
          show={questionData ? questionData.answer[indexSC] !== "" : false}
          isNG={checkNG(questionData, subCatIndex, questionIndex, indexSC)}
        />
      </div>
    </div>
  );
};

const ConfirmButton = ({ onClick, className, role, status }) => {
  let disable = false;
  if (!status) {
    disable = true;
  }
  if (role === "checker" || (role === "super_admin" && status === "1")) {
    if (status === "2" || status === "3" || status === "4" || status === "5") {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant='contained'
          size='large'
          onClick={onClick}>
          ผ่านการตรวจสอบ
        </Button>
      </div>
    );
  } else if (
    role === "approver" ||
    (role === "super_admin" && status !== "1")
  ) {
    if (status === "3" || status === "4" || status === "5" || status === "1") {
      disable = true;
    }
    if (!status) {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant='contained'
          size='large'
          onClick={onClick}>
          อนุมัติ
        </Button>
      </div>
    );
  }
  return <div></div>;
};

const RejectButton = ({ onClick, className, role, status }) => {
  let disable = false;
  if (!status) {
    disable = true;
  }
  if (role === "checker" || (role === "super_admin" && status === "1")) {
    if (status === "2" || status === "3" || status === "4" || status === "5") {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant='outlined'
          size='large'
          color='error'
          onClick={onClick}>
          ไม่ผ่านการตรวจสอบ
        </Button>
      </div>
    );
  } else if (
    role === "approver" ||
    (role === "super_admin" && status !== "1")
  ) {
    let disable = false;
    if (status === "3" || status === "4" || status === "5" || status === "1") {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant='outlined'
          size='large'
          color='error'
          onClick={onClick}>
          ไม่อนุมัติ
        </Button>
      </div>
    );
  }
  return <div></div>;
};

const ViewPaperV2 = () => {
  const param = useParams();
  const [isOpenPartPhoto, setIsOpenPartPhoto] = useState(false);
  const [paper, setPaper] = useState();
  const [openViewPhoto, setOpenViewPhoto] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    message: "",
  });
  const [loading, setLoading] = useState(true);
  const [remark, setRemark] = useState(null);

  const [openReject, setOpenReject] = useState({
    open: false,
    message: "",
  });
  const user = useContext(UserContext);
  const snackBarState = useSnackbarStore();
  const navigation = useNavigate();

  console.log("rerender!");

  const confirm = async (paper) => {
    if (user.signature) {
      setLoading(true);
      const signature = user.signature[user.signature.length - 1];
      // const signature = user.signature[user.signature.findLastIndex((e) => e)];

      if (
        user.qc_role === "checker" ||
        (paper.status === "1" && user.qc_role === "super_admin")
      ) {
        let useRemark = [];
        if (remark) {
          const newRemark = {
            remark: remark,
            userFirstName: user.name,
            userLastName: user.lastname,
            role: user.qc_role,
            userSignature: signature.url,
            dateTime: new Date().toISOString(),
            status: "2",
          };
          if (paper.remark === null) {
            useRemark.push(newRemark);
          } else {
            paper.remark.forEach((remarkLoop) => {
              const oldRemark = {
                remark: remarkLoop.remark,
                userFirstName: remarkLoop.userFirstName,
                userLastName: remarkLoop.userLastName,
                role: remarkLoop.role,
                userSignature: remarkLoop.userSignature,
                dateTime: new Date().toISOString(),
                status: "2",
              };
              useRemark.push(oldRemark);
            });
            useRemark.push(newRemark);
          }
        }
        await APIServices.updateCheckerInPaper(
          param.idPaper,
          signature.url,
          "2",
          user.id,
          useRemark.length === 0 ? null : useRemark
        );
      } else if (
        user.qc_role === "approver" ||
        user.qc_role === "super_admin"
      ) {
        let useRemark = [];
        if (remark) {
          const newRemark = {
            remark: remark,
            userFirstName: user.name,
            userLastName: user.lastname,
            role: user.qc_role,
            userSignature: signature.url,
            dateTime: new Date().toISOString(),
            status: "4",
          };
          if (paper.remark === null) {
            useRemark.push(newRemark);
          } else {
            console.log("remark 841", paper.remark);
            paper.remark.forEach((remarkLoop) => {
              const oldRemark = {
                remark: remarkLoop.remark,
                userFirstName: remarkLoop.userFirstName,
                userLastName: remarkLoop.userLastName,
                role: remarkLoop.role,
                userSignature: remarkLoop.userSignature,
                dateTime: new Date().toISOString(),
                status: "4",
              };
              useRemark.push(oldRemark);
            });
            useRemark.push(newRemark);
          }
        }
        await APIServices.updateApproverInPaper(
          param.idPaper,
          signature.url,
          "4",
          user.id,
          useRemark.length === 0 ? null : useRemark
        );
      }
      snackBarState.onOpen({
        msg: "สำเร็จ",
        type: "success",
      });
      backward(2000);
      setLoading(false);
    } else {
      alert("you don't exist signature please insert signature");
    }
  };

  const reject = async (paper) => {
    // if (result) {
    if (user.signature) {
      setLoading(true);

      const signature = user.signature[user.signature.length - 1];
      // const signature = user.signature[user.signature.findLastIndex((e) => e)];

      if (
        user.qc_role === "checker" ||
        (paper.status === "1" && user.qc_role === "super_admin")
      ) {
        let useRemark = [];
        if (remark) {
          const newRemark = {
            remark: remark,
            userFirstName: user.name,
            userLastName: user.lastname,
            role: user.qc_role,
            userSignature: signature.url,
            dateTime: new Date().toISOString(),
            status: "3",
          };
          if (paper.remark === null) {
            useRemark.push(newRemark);
          } else {
            paper.remark.forEach((remarkLoop) => {
              const oldRemark = {
                remark: remarkLoop.remark,
                userFirstName: remarkLoop.userFirstName,
                userLastName: remarkLoop.userLastName,
                role: remarkLoop.role,
                userSignature: remarkLoop.userSignature,
                dateTime: new Date().toISOString(),
                status: "3",
              };
              useRemark.push(oldRemark);
            });
            useRemark.push(newRemark);
          }
        }
        await APIServices.updateCheckerInPaper(
          param.idPaper,
          signature.url,
          "3",
          user.id,
          useRemark.length === 0 ? null : useRemark
        );
      } else if (
        user.qc_role === "approver" ||
        user.qc_role === "super_admin"
      ) {
        let useRemark = [];
        if (remark) {
          const newRemark = {
            remark: remark,
            userFirstName: user.name,
            userLastName: user.lastname,
            role: user.qc_role,
            userSignature: signature.url,
            dateTime: new Date().toISOString(),
            status: "5",
          };
          if (paper.remark === null) {
            useRemark.push(newRemark);
          } else {
            paper.remark.forEach((remarkLoop) => {
              const oldRemark = {
                remark: remarkLoop.remark,
                userFirstName: remarkLoop.userFirstName,
                userLastName: remarkLoop.userLastName,
                role: remarkLoop.role,
                userSignature: remarkLoop.userSignature,
                dateTime: new Date().toISOString(),
                status: "5",
              };
              useRemark.push(oldRemark);
            });
            useRemark.push(newRemark);
          }
        }
        await APIServices.updateApproverInPaper(
          param.idPaper,
          signature.url,
          "5",
          user.id,
          useRemark.length === 0 ? null : useRemark
        );
      }
      snackBarState.onOpen({
        msg: "สำเร็จ",
        type: "success",
      });
      backward(2000);
      setLoading(false);
    } else {
      alert("you don't exist signature please insert signature");
    }
    // }
  };

  const backward = (delay) => {
    setTimeout(() => {
      navigation(-1);
    }, delay);
  };

  const handleOnClickConfirm = () => {
    let message = "ผ่านการตรวจสอบ";
    if (user.qc_role === "approver" || user.qc_role === "super_admin") {
      message = "ผ่านการอนุมัติ";
    }
    setOpenConfirm({ open: true, message: message });
  };

  const handleOnClickReject = () => {
    let message = "ไม่ผ่านการตรวจสอบ";
    if (user.qc_role === "approver" || user.qc_role === "super_admin") {
      message = "ไม่ผ่านการอนุมัติ";
    }
    setOpenReject({ open: true, message: message });
  };

  useEffect(() => {
    const getPaperByID = async () => {
      console.log("618");
      setLoading(true);
      const response = await APIServices.findOnePaperForEditPaper(
        param.idPaper
      );
      // const getQuestion = await APIServices.getQuestion();
      // const resQuestion = getQuestion.data.data;
      // const realQuestion = resQuestion.map((qt) => {
      //   return { id: qt.id, ...qt.attributes };
      // });
      const result = response.data.data;
      // let dataExport;
      if (result) {
        const paper = result.attributes;
        const getAllSubCat =
          await APIServices.getSubCategoriesForViewPaperByPartId(
            paper.partName.data.id
          );
        const dataSubCat = [];
        paper.answer.sub_categories.forEach((subCat) => {
          const findSub = getAllSubCat.data.data.find(
            (sub) => sub.id === subCat.subId
          );
          let dataQuestion = [];
          subCat.questions.forEach((question) => {
            const findRealQuestion = findSub.attributes.questions.data.find(
              (qt) => qt.id === question.questionId
            );
            if (findRealQuestion.attributes.status === true) {
              dataQuestion.push(question);
            }
          });
          dataSubCat.push({
            category: subCat.category,
            questions: dataQuestion,
            revise_time_subCat: subCat.revise_time_subCat,
            subId: subCat.subId,
            sub_category: subCat.sub_category,
          });
        });
        paper.answer.sub_categories = dataSubCat;
        setPaper(paper);
      }
      // setDataForExport(dataExport);
      console.log("762");
      setLoading(false);
      // getDataForExport()
    };
    getPaperByID();
  }, [param.idPaper]);

  return (
    <>
      {isOpenPartPhoto && (
        <BootstrapDialog
          open={isOpenPartPhoto}
          onClose={() => setIsOpenPartPhoto(false)}>
          <img src={`${config.server}${paper.answer.partImage}`} alt='' />
        </BootstrapDialog>
      )}
      <SimpleBackdrop open={loading} setOpen={setLoading} />
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackBarState.open}
        autoHideDuration={6000}
        onClose={snackBarState.onClose}>
        <Alert severity={snackBarState.type}>{snackBarState.message}</Alert>
      </Snackbar>
      {paper && (
        <div className={`mb-2`}>
          <Typography variant='h4'>
            {paper.partName.data.attributes.part_name}
          </Typography>
          <Typography variant='h6'>
            {paper.partName.data.attributes.part_code}
          </Typography>
        </div>
      )}
      <div className='flex flex-row justify-between'>
        <div className='mb-4 flex flex-row items-center'>
          <Stack direction='row' spacing={1}>
            {/* <Chip label={`ครั้งที่: ${dataFromLocation.state.index}`} /> */}
            <Chip
              label={`วันที่: ${
                paper && dayjs(paper.create_datetime).format("DD-MM-YYYY")
              }`}
            />
            <Chip label={`ช่วงเวลา: ${paper && paper.shift}`} />
            {paper && paper.shiftAB && (
              <Chip label={`กลุ่ม: ${paper && paper.shiftAB}`} />
            )}
            {paper && paper.process_status && (
              <Chip label={`${paper && paper.process_status}`} />
            )}
          </Stack>
        </div>
        {/* <ExportFileXLSX paperData={paper} paperID={paperID} /> */}
        <div className=''>
          {/* <Button variant="contained" onClick={() => handleExportFile()}>
            Export Inspection Sheet
          </Button> */}
        </div>
      </div>
      {paper &&
        paper.remark &&
        paper.remark.map((remark, remarkIndex) => {
          // statusRevise
          return (
            <div key={remarkIndex} className='mb-2'>
              {remark.status &&
                (remark.status === "2" || remark.status === "4" ? (
                  <Alert severity='success'>
                    <AlertTitle>{parseStatus(remark.status)}</AlertTitle>
                    <AlertTitle>{remark.remark}</AlertTitle>
                    {remark.userFirstName + " " + remark.userLastName} —{" "}
                    <strong>{remark.role}</strong>
                  </Alert>
                ) : (
                  <Alert severity='error'>
                    <AlertTitle>{parseStatus(remark.status)}</AlertTitle>
                    <AlertTitle>{remark.remark}</AlertTitle>
                    {remark.userFirstName + " " + remark.userLastName} —{" "}
                    <strong>{remark.role}</strong>
                  </Alert>
                ))}
            </div>
          );
        })}
      <div className={`flex flex-col lg:flex-row relative gap-4 mt-4`}>
        <div className={` lg:relative`}>
          <div
            className={`flex justify-center items-center relative lg:sticky top-0 rounded-md drop-shadow-md bg-white`}>
            {paper && paper.answer.partImage !== "" ? (
              <div
                className={`mt-4 w-full flex justify-center cursor-pointer`}
                onClick={() => setIsOpenPartPhoto(true)}>
                <img
                  src={paper.answer.partImage}
                  //config.server +
                  className=' object-contain h-[300px]'
                  alt=''
                />
              </div>
            ) : (
              <div
                style={{ width: "12rem", height: "12rem" }}
                className='bg-gray-100 flex items-center justify-center rounded-lg m-2'>
                <NoPhotographyIcon sx={{ fontSize: 80 }} />
              </div>
            )}
          </div>
        </div>
        <div
          className={`h-96 lg:h-full overflow-auto lg:overflow-visible lg:relative rounded-md shadow-md`}>
          {paper ? (
            paper.answer.sub_categories.map((subCatData, subCatIndex) => (
              <SubCategories
                key={`subCat${subCatIndex}`}
                subCategories={
                  paper.partName.data.attributes.sub_categories.data
                }
                subCatPerLoop={subCatData}
                subCatIndex={subCatIndex}
                setOpenViewPhoto={setOpenViewPhoto}
                setSelectedQuestion={setSelectedQuestion}
              />
            ))
          ) : (
            <div>ไม่มีข้อมูล</div>
          )}
        </div>
      </div>
      <div className='mt-4 mb-2'>
        {paper && (
          <React.Fragment>
            {paper.inspector_id && (
              <div className='flex justify-end'>
                <div className={`text-gray-500`}>Inspector :</div>
                <SignatureBox
                  className={`ml-4`}
                  user={paper.inspector_id.data.attributes}
                  signatureURL={
                    paper.signature_inspector
                      ? paper.signature_inspector
                      : undefined
                  }
                />
              </div>
            )}
            {paper.signature_checker && (
              <div className='flex justify-end mt-4'>
                <div className={`text-gray-500`}>Checker :</div>

                <SignatureBox
                  className={`ml-4`}
                  user={paper.checker_id.data.attributes}
                  signatureURL={paper.signature_checker}
                />
              </div>
            )}
            {paper.signature_approver && (
              <div className='flex justify-end mt-4'>
                <div className={`text-gray-500`}>Approver :</div>
                <SignatureBox
                  className={`ml-4`}
                  user={paper.approver_id.data.attributes}
                  signatureURL={paper.signature_approver}
                />
              </div>
            )}
            <div className={`flex justify-end mt-4`}>
              <RejectButton
                role={user.qc_role}
                status={paper.status}
                className={`ml-4`}
                onClick={() => handleOnClickReject()}
              />
              <ConfirmButton
                role={user.qc_role}
                status={paper.status}
                className={`ml-4`}
                onClick={() => handleOnClickConfirm()}
              />
            </div>
          </React.Fragment>
        )}
        {openViewPhoto && (
          <ViewPhoto
            open={openViewPhoto}
            setOpen={setOpenViewPhoto}
            selectedQuestion={selectedQuestion}
            setPaper={setPaper}
            paper={paper}
          />
        )}
        {openConfirm.open && (
          <ConfirmModal
            open={openConfirm.open}
            setOpen={setOpenConfirm}
            action={confirm}
            paper={paper}
            confirmText={"ตกลง"}
            cancelText={"ยกเลิก"}
            header={"ยืนยันการตรวจสอบ"}
            message={
              <span>
                คุณต้องการยืนยันว่าเอกสาร
                <span className='text-green-600 underline underline-offset-1'>
                  {openConfirm.message}
                </span>
              </span>
            }
            setRemark={setRemark}
            remark={remark}
          />
        )}
        {openReject.open && (
          <ConfirmModal
            open={openReject.open}
            setOpen={setOpenReject}
            action={reject}
            paper={paper}
            confirmText={"ตกลง"}
            cancelText={"ยกเลิก"}
            header={"ยืนยันการตรวจสอบ"}
            message={
              <span>
                คุณต้องการยืนยันว่าเอกสาร
                <span className='text-red-600 underline underline-offset-1'>
                  {openReject.message}
                </span>
              </span>
            }
            setRemark={setRemark}
            remark={remark}
          />
        )}
      </div>
    </>
  );
};

export default ViewPaperV2;
