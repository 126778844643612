import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

export default function TableReportNormalQTWithActual({ headers, data }) {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) =>
              header.col ? (
                <TableCell
                  key={`cell${index}`}
                  align="center"
                  colSpan={header.col.length}
                >
                  <div className="">{header.name}</div>
                  <div className="flex justify-between mt-4">
                    {header.col.map((col, index) => (
                      <div key={`col${index}`} className="">
                        {col.name}
                      </div>
                    ))}
                  </div>
                </TableCell>
              ) : (
                <TableCell key={`cell${index}`} align="center" colSpan={0}>
                  {header.name}
                </TableCell>
              )
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((dataLoop, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" align="center" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="center">{dataLoop.createAt}</TableCell>
              <TableCell align="center">{dataLoop.shift}</TableCell>
              {dataLoop.data.actual ? (
                dataLoop.data.actual.map((actual, index) => (
                  <TableCell key={`cell${index}`} align="center">
                    {console.log("dataLoop",dataLoop)}
                    {actual.toString() || "-"}
                  </TableCell>
                ))
              ) : (
                <>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                </>
              )}
              <TableCell align="center">{dataLoop.average}</TableCell>
              <TableCell align="center">{dataLoop.inspector}</TableCell>
              <TableCell align="center">{dataLoop.checker}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableReportNormalQTWithActual.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
};
