import React, { useContext, useState } from "react";
import DateTime from "../../Components/DateTime";
import dayjs from "dayjs";
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import { UserContext } from "../../Layout";
import { Search } from "@mui/icons-material";
import { APIServices } from "../../helpers/APIServices";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { parseStatus, parseFullNameFromUser } from "../../helpers/common";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeletePaperModal from "../../Components/DeletePaperModal";
import SimpleBackdrop from "../../Components/Backdrop";
import _ from "lodash";
import FileDownload from "@mui/icons-material/FileDownload";
import { export_exceljs } from "../../Components/ExportInspectionSheet";
// import { exportData } from "../Answer/EditPaperV3";
import { exportData } from "../../helpers/common";

const getStartDateFilter = () => {
  const value = localStorage.getItem("filterQCASP")
  return value ? JSON.parse(value)?.startDate : undefined
}
const setStartDateFilter = (startDate) => {
  const oldValue = localStorage.getItem("filterQCASP") || "{}"
  const newValue = {
    ...JSON.parse(oldValue),
    startDate,
  }
  localStorage.setItem("filterQCASP", JSON.stringify(newValue))
}

const getEndDateFilter = () => {
  const value = localStorage.getItem("filterQCASP")
  return value ? JSON.parse(value)?.endDate : undefined
}
const setEndDateFilter = (endDate) => {
  const oldValue = localStorage.getItem("filterQCASP") || "{}"
  const newValue = {
    ...JSON.parse(oldValue),
    endDate: endDate,
  }
  localStorage.setItem("filterQCASP", JSON.stringify(newValue))
}

const CustomDateHistory = ({ partData, setSuccess }) => {
  const [startDate, setStartDate] = useState({
    value: getStartDateFilter() ? getStartDateFilter() : `${dayjs().subtract(1, "d").format("YYYY-MM-DD")} 00:00:00`,
    error: false,
  });
  const [endDate, setEndDate] = useState({
    value: getEndDateFilter() ? getEndDateFilter() : `${dayjs().format("YYYY-MM-DD")} 23:59:59`,
    error: false,
  });
  const [papers, setPapers] = useState([]);
  const [loading, setLoading] = useState(false);

  const user = useContext(UserContext);
  const role = user.qc_role;
  const { id } = useParams();
  const [openDelete, setOpenDelete] = useState({
    open: false,
    message: "",
  });
  const [data, setData] = useState();
  const [apiSuccess, setapiSuccess] = useState(true);
  const [checkedPaperId, setCheckedPaperId] = useState({})

  const handleOnChangeStartDate = (date, time) => {
    if (!date || !time) {
      setStartDate((prev) => ({
        ...prev,
        value: undefined,
      }));
      return;
    }
    const startDate = dayjs(`${date} ${time}`).format("YYYY-MM-DD HH:mm:ss");
    setStartDate((prev) => ({
      ...prev,
      value: startDate,
      error: false,
    }));
    setStartDateFilter(startDate)
  };

  const handleOnChangeEndDate = (date, time) => {
    if (!date || !time) {
      setEndDate((prev) => ({
        ...prev,
        value: undefined,
      }));
      return;
    }
    const endDate = dayjs(`${date} ${time}`).format("YYYY-MM-DD HH:mm:ss");
    setEndDate((prev) => ({
      ...prev,
      value: endDate,
      error: false,
    }));
    setEndDateFilter(endDate)
  };

  const getPaper = async () => {
    setLoading(true);
    try {
      const startDateParsed = dayjs(startDate.value).toISOString();
      const endDateParsed = dayjs(endDate.value).toISOString();
      const response = await APIServices.getPaper(
        startDateParsed,
        endDateParsed,
        id,
        user
      );
      setPapers(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const submitSearch = async () => {
    getPaper();
  };

  const deletePaper = async (result, row) => {
    // console.log("result",result, row);
    // return;

    if (result) {
      setLoading(true);
      await APIServices.updateDeletePaper(true, row.id);
      // partData.mutate();
      setTimeout(() => {
        setLoading(false);
        // setSuccess(true);
        setapiSuccess(true);
      }, 1000);
    }
  };

  const handleOnClickDelete = (row) => {
    setData(row);
    let message = "ลบข้อมูลใช่หรือไม่";
    setOpenDelete({ open: true, message: message });
  };
  
  const Action = ({ row, index }) => {
    if (role === "super_admin") {
      return (
        <>
          <Link
            key={"view_paper_v2"}
            to={`view_paper_v2/${row.id}`}
            state={{ index: index + 1 }}
          >
            <IconButton color="primary">
              <VisibilityIcon />
            </IconButton>
          </Link>
          <Link
            key={"edit_paper_v3"}
            to={`edit_paper_v3/${row.id}`}
            state={{ index: index + 1 }}
          >
            <IconButton color="primary">
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton color="error" onClick={() => handleOnClickDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </>
      );
    }
    if (role === "inspector") {
      // if (row.attributes.status === "1") {
      return (
        <>
          <Link
            key={"edit_paper_v3"}
            to={`edit_paper_v3/${row.id}`}
            state={{ index: index + 1 }}
          >
            <IconButton color="primary">
              <EditIcon />
            </IconButton>
          </Link>
          <IconButton color="error" onClick={() => handleOnClickDelete(row)}>
            <DeleteIcon />
          </IconButton>
        </>
      );
      // }
    } else if (
      role === "checker" ||
      role === "approver" ||
      // role === "super_admin" ||
      role === "admin"
    ) {
      return (
        <>
          <Link
            key={"view_paper_v2"}
            to={`view_paper_v2/${row.id}`}
            state={{ index: index + 1 }}
          >
            <IconButton color="primary">
              <VisibilityIcon />
            </IconButton>
          </Link>
        </>
      );
    } else {
      return <div>Action</div>;
    }
  };

  useEffect(() => {
    if (apiSuccess) {
      // getData();
      setapiSuccess(false);
      getPaper();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiSuccess]);

  //  useEffect(() => {
  //   getPaper();
  // }, []);

  const handleCheckedPaper = (checked, paperId) => {
    if(checked){
      setCheckedPaperId(prev => ({...prev, [`${paperId}`]: true}))
    }else{
      const cloneCheckedPaperId = _.cloneDeep(checkedPaperId)
      delete cloneCheckedPaperId[`${paperId}`]
      setCheckedPaperId(cloneCheckedPaperId)
    }
  }

  const clearAllCheckedPaperId = (e, checked) => {
    if(checked){
      let paperId = {}
      papers.forEach(paper => {
        paperId[`${paper.id}`] = true
      })
      setCheckedPaperId(paperId)
    }else{
      setCheckedPaperId({})
    }
  }

  const downloadReport = async () => {
    console.log("boom !");
    console.log("checkedPaperId list", checkedPaperId);
    const checkedArray = Object.keys(checkedPaperId).map((key) => key)
    console.log("checkedArray", checkedArray);
    if(checkedArray.length === 0){
      return
    }
    const response = await APIServices.getManyPaper(checkedArray)
    console.log("response ", response.data);
    const result = response.data.data
    console.log("result", result[0]);
    for (let i = 0; i < result.length; i++) {
      const data = result[i];
      const dataForExport = exportData(data)
      console.log("dataForExport 262:", dataForExport)
      await export_exceljs(dataForExport)
    }
    // await export_exceljs(result[0])
    // console.log("end report");
  }

  return (
    <div className="">
      <SimpleBackdrop open={loading} setOpen={setLoading} />

      <TableContainer component={Paper}>
        <div className={`p-4`}>
          <div className={`h-full flex items-end pb-1 text-xl`}>
            ประวัติการตรวจสอบย้อนหลัง
          </div>
          <div className={`mt-2 flex justify-between items-end`}>
            <div className={`flex items-end gap-4`}>
              <DateTime
                value={startDate.value}
                // autoFocus={startDate.error}
                label={`Start date`}
                onChange={handleOnChangeStartDate}
              />
              <DateTime
                value={endDate.value}
                // autoFocus={startDate.error}
                label={`End date`}
                onChange={handleOnChangeEndDate}
              />
              <Button
                variant="contained"
                size={`small`}
                onClick={() => {
                  submitSearch();
                }}
              >
                <Search />
              </Button>
            </div>
            <div className={``}>
              <Button
                variant="outlined"
                endIcon={<FileDownload />}
                onClick={downloadReport}
                disabled={Object.keys(checkedPaperId).length === 0}
              >
                Download
              </Button>
            </div>
          </div>
        </div>
        <Table sx={{ minWidth: 550 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {/* <TableCell>Dessert (100g serving)</TableCell> */}
              <TableCell align="center">
                <div
                  // className={`bg-blue-400 p-2 cursor-pointer`}
                  // onClick={() => {
                  //   console.log("element 291: ", myRef.current.checked);
                  //   if (myRef.current) {
                  //     myRef.current.checked = !myRef.current.checked;
                  //   }
                  //   // myRef.current.checked = true
                  // }}
                >
                  {/* click ! */}
                </div>
                <Checkbox
                  onChange={clearAllCheckedPaperId}
                />
              </TableCell>
              {role === "inspector" && (
                <TableCell align="center">ครั้งที่</TableCell>
              )}
              {/* <TableCell align="center">คำถาม</TableCell> */}
              <TableCell align="center">วันและเวลา</TableCell>
              <TableCell align="center">ช่วงเวลา</TableCell>
              <TableCell align="center">Status</TableCell>
              {(role === "checker" ||
                role === "approver" ||
                role === "super_admin" ||
                role === "admin") && (
                <TableCell align="center">Inspector</TableCell>
              )}
              {/* {role === "approver" && ( */}
              <TableCell align="center">Checker</TableCell>
              <TableCell align="center">Approver</TableCell>
              <TableCell align="center">Action</TableCell>
              {/* )} */}
            </TableRow>
          </TableHead>
          <TableBody>
            {papers.map((row, index) => {
                return (
                  <TableRow
                    hover
                    key={`tableRow${index}`}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    // onClick={() => gotoViewPaper(row.id, index)}
                    className={`cursor-pointer`}
                  >
                    <TableCell align="center">
                      <Checkbox
                        onChange={(e, checked) => handleCheckedPaper(checked, row.id)}
                        checked={checkedPaperId[`${row.id}`] ? true : false}
                      />
                    </TableCell>
                    {/* <TableCell align="center">{row.id}</TableCell> */}
                    {role === "inspector" && (
                      <TableCell component="th" scope="row" align="center">
                        {index + 1}
                      </TableCell>
                    )}
                    <TableCell component="th" scope="row" align="center">
                      {row.attributes.create_datetime &&
                        dayjs(row.attributes.create_datetime).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                    </TableCell>
                    <TableCell align="center">{row.attributes.shift}</TableCell>
                    <TableCell align="center">
                      <div className={`flex justify-center`}>
                        {parseStatus(row.attributes.status)}
                      </div>
                    </TableCell>
                    {(role === "checker" ||
                      role === "approver" ||
                      role === "super_admin" ||
                      role === "admin") && (
                      <TableCell align="center">
                        {parseFullNameFromUser(
                          row.attributes.inspector_id.data.attributes
                        )}
                      </TableCell>
                    )}
                    {/* {role === "approver" && ( */}
                    <>
                      <TableCell align="center">
                        {row.attributes.checker_id.data
                          ? parseFullNameFromUser(
                              row.attributes.checker_id.data.attributes
                            )
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        {row.attributes.approver_id.data
                          ? parseFullNameFromUser(
                              row.attributes.approver_id.data.attributes
                            )
                          : "-"}
                      </TableCell>
                      <TableCell align="center">
                        <Action row={row} index={index} />
                      </TableCell>
                    </>
                    {/* )} */}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <DeletePaperModal
        open={openDelete.open}
        setOpen={setOpenDelete}
        action={deletePaper}
        confirmText={"ใช่"}
        cancelText={"ยกเลิก"}
        header={"ยืนยันการลบข้อมูล"}
        message={
          <span>
            คุณต้องการ
            <span>{openDelete.message}</span>
          </span>
        }
        data={data}
      />
    </div>
  );
};

export default CustomDateHistory;
