import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const PaperView = () => {
    const params = useParams()
    const navigate = useNavigate()
    // http://localhost:3000/layout/paper/view/555/3376
    useEffect(() => {
        navigate(`/layout/paper/history/${params.partId}/view_paper_v2/${params.paperId}`)
    }, [])
  return (
    <div>
      <Backdrop
        open={true}
        sx={{
          color: '#fff'
        }}
      >
        <CircularProgress color='inherit' />
      </Backdrop>
    </div>
  )
}

export default PaperView