import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmCreatePaperModal({
  open,
  setOpen,
  action,
  header,
  message,
  confirmText,
  cancelText,
}) {
  const handleClose = () => {
    setOpen((prev) => ({ ...prev, open: false }));
  };

  const handleOnClick = (result) => {
    setOpen((prev) => ({ ...prev, open: false }));
    action(result);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{header || "Header"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || "Content"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOnClick(false)}>
          {cancelText || "Disagree"}
        </Button>
        <Button
          variant="contained"
          onClick={() => handleOnClick(true)}
          autoFocus
        >
          {confirmText || "Agree"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
