// eslint-disable-next-line import/no-anonymous-default-export
export default {
  ess: "https://ess.aapico.com",
  // server: "http://agrappl03.aapico.com:1339",
  server: "https://qc-backend-yrl362wd5a-de.a.run.app",
  // server:
  //   window.location.origin.split(":")[0] +
  //   ":" +
  //   window.location.origin.split(":")[1] +
  //   ":1337",
  version: "v4.0.10",
  // server: 'http://192.168.2.37:1337'
  // orgServer: "http://agrflow01.aapico.com:1337",
  orgServer: "https://apigw.aapico.com/agrappl03",
  status_revise: {
    ADD: '1',
    EDIT: '2',
    DELETE: '3',
  }
};
