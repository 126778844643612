import React, { useState, useEffect, useContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APIServices } from "../helpers/APIServices";
import config from "../config";
import { UserContext } from "../Layout";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import { useSnackbarStore, useSuccessStore } from "../helpers/state";
import SignatureBox from "../Components/SignatureBox";
import ConfirmModal from "../Components/ConfirmModal";
import { AlertTitle, Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { parseStatus, statusRevise } from "../helpers/common";

const ConfirmButton = ({ onClick, className, role, status }) => {
  let disable = false;
  if (!status) {
    disable = true;
  }
  if (role === "checker_revise" || (role === "super_admin" && status === "1")) {
    if (status === "2" || status === "3" || status === "4" || status === "5") {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant="contained"
          size="large"
          onClick={onClick}
        >
          ผ่านการตรวจสอบ
        </Button>
      </div>
    );
  } else if (
    role === "approver_revise" ||
    (role === "super_admin" && status !== "1")
  ) {
    if (status === "3" || status === "4" || status === "5" || status === "1") {
      disable = true;
    }
    if (!status) {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant="contained"
          size="large"
          onClick={onClick}
        >
          อนุมัติ
        </Button>
      </div>
    );
  }
  return <div></div>;
};

const RejectButton = ({ onClick, className, role, status }) => {
  let disable = false;
  if (!status) {
    disable = true;
  }
  if (role === "checker_revise" || (role === "super_admin" && status === "1")) {
    if (status === "2" || status === "3" || status === "4" || status === "5") {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant="outlined"
          size="large"
          color="error"
          onClick={onClick}
        >
          ไม่ผ่านการตรวจสอบ
        </Button>
      </div>
    );
  } else if (
    role === "approver_revise" ||
    (role === "super_admin" && status !== "1")
  ) {
    let disable = false;
    if (status === "3" || status === "4" || status === "5" || status === "1") {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant="outlined"
          size="large"
          color="error"
          onClick={onClick}
        >
          ไม่อนุมัติ
        </Button>
      </div>
    );
  }
  return <div></div>;
};

const Revise = () => {
  const param = useParams();
  const user = useContext(UserContext);
  const [revise, setRevise] = useState();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    message: "",
  });
  const [openReject, setOpenReject] = useState({
    open: false,
    message: "",
  });
  const snackBarState = useSnackbarStore();
  const navigation = useNavigate();
  const [remark, setRemark] = useState(null);

  const findOneReVise = async () => {
    const findRevise = await APIServices.findOneRevise(param.id);
    const resRevise = findRevise.data.data;

    setRevise({
      id: resRevise.id,
      ...resRevise.attributes,
    });
  };

  const confirm = async (revise) => {
    if (user.signature) {
      const signature = user.signature[user.signature.findLastIndex((e) => e)];
      if (
        user.qc_role === "checker_revise" ||
        (revise.revise_status === "1" && user.qc_role === "super_admin")
      ) {
        let useRemark = [];
        if (remark) {
          const newRemark = {
            remark: remark,
            userFirstName: user.name,
            userLastName: user.lastname,
            role: user.qc_role,
            userSignature: signature.url,
            dateTime: new Date().toISOString(),
            status: "2",
          };
          // if (revise.remark.length === 0 || revise.remark === null) {
          if (revise.remark === null || revise.remark.length === 0) {
            useRemark.push(newRemark);
          } else {
            revise.remark.forEach((remarkLoop) => {
              const oldRemark = {
                remark: remarkLoop.remark,
                userFirstName: remarkLoop.userFirstName,
                userLastName: remarkLoop.userLastName,
                role: remarkLoop.role,
                userSignature: remarkLoop.userSignature,
                dateTime: new Date().toISOString(),
                status: "2",
              };
              useRemark.push(oldRemark);
            });
            useRemark.push(newRemark);
          }
        }
        const response = await APIServices.updateCheckerForRevise(
          param.id,
          signature.url,
          "2",
          user.id,
          useRemark.length === 0 ? null : useRemark
        );
      } else if (
        user.qc_role === "approver_revise" ||
        user.qc_role === "super_admin"
      ) {
        let useRemark = [];
        if (remark) {
          const newRemark = {
            remark: remark,
            userFirstName: user.name,
            userLastName: user.lastname,
            role: user.qc_role,
            userSignature: signature.url,
            dateTime: new Date().toISOString(),
            status: "4",
          };
          if (revise.remark.length === 0 || revise.remark === null) {
            useRemark.push(newRemark);
          } else {
            revise.remark.forEach((remarkLoop) => {
              const oldRemark = {
                remark: remarkLoop.remark,
                userFirstName: remarkLoop.userFirstName,
                userLastName: remarkLoop.userLastName,
                role: remarkLoop.role,
                userSignature: remarkLoop.userSignature,
                dateTime: new Date().toISOString(),
                status: "4",
              };
              useRemark.push(oldRemark);
            });
            useRemark.push(newRemark);
          }
        }
        const response = await APIServices.updateApproverForRevise(
          param.id,
          signature.url,
          "4",
          user.id,
          useRemark.length === 0 ? null : useRemark
        );
        const updateRevise = response.data.data;
        if (updateRevise) {
          if (updateRevise.attributes.revise_status === "4") {
            const response = await APIServices.updatePartStatus(
              revise.part.data.id,
              true
            );
          }
        }
      }
      snackBarState.onOpen({
        msg: "สำเร็จ",
        type: "success",
      });
      backward(2000);
    } else {
      alert("you don't exist signature please insert signature");
    }
  };
  const reject = async (revise) => {
    // if (result) {
    if (user.signature) {
      const signature = user.signature[user.signature.findLastIndex((e) => e)];
      if (
        user.qc_role === "checker_revise" ||
        (revise.revise_status === "1" && user.qc_role === "super_admin")
      ) {
        let useRemark = [];
        if (remark) {
          const newRemark = {
            remark: remark,
            userFirstName: user.name,
            userLastName: user.lastname,
            role: user.qc_role,
            userSignature: signature.url,
            dateTime: new Date().toISOString(),
            status: "3",
          };
          if (revise.remark.length === 0 || revise.remark === null) {
            useRemark.push(newRemark);
          } else {
            revise.remark.forEach((remarkLoop) => {
              const oldRemark = {
                remark: remarkLoop.remark,
                userFirstName: remarkLoop.userFirstName,
                userLastName: remarkLoop.userLastName,
                role: remarkLoop.role,
                userSignature: remarkLoop.userSignature,
                dateTime: new Date().toISOString(),
                status: "3",
              };
              useRemark.push(oldRemark);
            });
            useRemark.push(newRemark);
          }
        }
        const response = await APIServices.updateCheckerForRevise(
          param.id,
          signature.url,
          "3",
          user.id,
          useRemark.length === 0 ? null : useRemark
        );
      } else if (
        user.qc_role === "approver_revise" ||
        user.qc_role === "super_admin"
      ) {
        let useRemark = [];
        if (remark) {
          const newRemark = {
            remark: remark,
            userFirstName: user.name,
            userLastName: user.lastname,
            role: user.qc_role,
            userSignature: signature.url,
            dateTime: new Date().toISOString(),
            status: "5",
          };
          if (revise.remark === null) {
            useRemark.push(newRemark);
          } else {
            revise.remark.forEach((remarkLoop) => {
              const oldRemark = {
                remark: remarkLoop.remark,
                userFirstName: remarkLoop.userFirstName,
                userLastName: remarkLoop.userLastName,
                role: remarkLoop.role,
                userSignature: remarkLoop.userSignature,
                dateTime: new Date().toISOString(),
                status: "5",
              };
              useRemark.push(oldRemark);
            });
            useRemark.push(newRemark);
          }
        }
        const response = await APIServices.updateApproverForRevise(
          param.id,
          signature.url,
          "5",
          user.id,
          useRemark.length === 0 ? null : useRemark
        );
      }
      snackBarState.onOpen({
        msg: "สำเร็จ",
        type: "success",
      });
      backward(2000);
    } else {
      alert("you don't exist signature please insert signature");
    }
    // }
  };

  const backward = (delay) => {
    setTimeout(() => {
      navigation(-1);
    }, delay);
  };

  const handleOnClickConfirm = () => {
    let message = "ผ่านการตรวจสอบ";
    // TODO: change role to revise_status - sawanon:recomend - 20230602
    const revise_status = revise.revise_status;
    if (user.qc_role === "approver_revise" || user.qc_role === "super_admin") {
      message = "ผ่านการอนุมัติ";
    }
    setOpenConfirm({ open: true, message: message });
  };

  const handleOnClickReject = () => {
    let message = "ไม่ผ่านการตรวจสอบ";
    if (user.qc_role === "approver_revise" || user.qc_role === "super_admin") {
      message = "ไม่ผ่านการอนุมัติ";
    }
    setOpenReject({ open: true, message: message });
  };

  useEffect(() => {
    findOneReVise();
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackBarState.open}
        autoHideDuration={6000}
        onClose={snackBarState.onClose}
      >
        <Alert severity={snackBarState.type}>{snackBarState.message}</Alert>
      </Snackbar>
      {revise &&
        revise.remark &&
        revise.remark.map((remark, remarkIndex) => {
          // statusRevise
          return (
            <div key={remarkIndex} className="mb-2">
              {remark.status &&
                (remark.status === "2" || remark.status === "4" ? (
                  <Alert severity="success">
                    <AlertTitle>{parseStatus(remark.status)}</AlertTitle>
                    <AlertTitle>{remark.remark}</AlertTitle>
                    {remark.userFirstName + " " + remark.userLastName} —{" "}
                    <strong>{remark.role}</strong>
                  </Alert>
                ) : (
                  <Alert severity="error">
                    <AlertTitle>{parseStatus(remark.status)}</AlertTitle>
                    <AlertTitle>{remark.remark}</AlertTitle>
                    {remark.userFirstName + " " + remark.userLastName} —{" "}
                    <strong>{remark.role}</strong>
                  </Alert>
                ))}
            </div>
          );
        })}
      <div>
        {revise && (
          <Accordion expanded>
            <AccordionSummary
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <div>
                <Typography className={`select-text`} sx={{ flexShrink: 0 }} variant="h5">
                  <span className={`text-gray-400 text-base`}>Part name:</span> {revise.part.data.attributes.part_name}
                </Typography>
                <Typography className={`select-text`} sx={{ flexShrink: 0 }} variant="h6">
                  <span className={`text-gray-400 text-base`}>Part code:</span> {revise.part.data.attributes.part_code}
                </Typography>
              </div>
            </AccordionSummary>
            <AccordionDetails className={`space-y-2`}>
              {revise.part.data.attributes.image.data && (
                <div className="flex justify-center drop-shadow bg-white rounded">
                  <div className="overflow-hidden w-[250px] h-[250px] rounded-md flex justify-center mb-4">
                    <img
                      src={`${config.server}${revise.part.data.attributes.image.data.attributes.url}`}
                      alt=""
                    />
                  </div>
                </div>
              )}

              {revise.part.data.attributes.sub_categories.data.length !== 0 ? (
                revise.part.data.attributes.sub_categories.data.map(
                  (subCat, index) => {
                    return (
                      <div key={index}>
                        <Accordion>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                          >
                            <Typography sx={{ width: "25%", flexShrink: 0 }}>
                              {
                                subCat.attributes.category.data.attributes
                                  .category
                              }
                            </Typography>
                            <Typography id={`${subCat.id}`} sx={{ color: "text.secondary" }}>
                              {subCat.attributes.sub_categories}
                              {statusRevise(subCat.attributes.status_revise)}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails className={`space-y-2`}>
                            {subCat.attributes.questions.data !== 0 ? (
                              subCat.attributes.questions.data.map(
                                (question, index) => {
                                  return (
                                    <div key={index}>
                                      <Accordion expanded>
                                        <AccordionSummary
                                          // expandIcon={<ExpandMoreIcon />}
                                          aria-controls="panel2a-content"
                                          id="panel2a-header"
                                        >
                                          <Typography sx={{ flexShrink: 0 }}>
                                            Point Check:{" "}
                                            {question.attributes.question}
                                            {statusRevise(
                                              question.attributes.status_revise
                                            )}
                                          </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                          {question.attributes.sc_status ===
                                            true && (
                                            <Typography>
                                              SC : <CheckIcon />
                                            </Typography>
                                          )}
                                          {question.attributes.spec_min && (
                                            <Typography>
                                              Spec min :
                                              {question.attributes.spec_min}{" "}
                                            </Typography>
                                          )}
                                          {question.attributes.spec_max && (
                                            <Typography>
                                              Spec max :
                                              {question.attributes.spec_max}{" "}
                                            </Typography>
                                          )}
                                          {question.attributes.sc_status ===
                                            true &&
                                            question.attributes.UCL_Xbar && (
                                              <Typography>
                                                UCL Xbar :
                                                {question.attributes.UCL_Xbar}
                                              </Typography>
                                            )}
                                          {question.attributes.sc_status ===
                                            true &&
                                            question.attributes.CL_Xbar && (
                                              <Typography>
                                                CL Xbar :
                                                {question.attributes.CL_Xbar}
                                              </Typography>
                                            )}
                                          {question.attributes.sc_status ===
                                            true &&
                                            question.attributes.LCL_Xbar && (
                                              <Typography>
                                                LCL Xbar :
                                                {question.attributes.LCL_Xbar}
                                              </Typography>
                                            )}
                                          {question.attributes.sc_status ===
                                            true &&
                                            question.attributes.UCL_Rchart && (
                                              <Typography>
                                                UCL Rchart :
                                                {question.attributes.UCL_Rchart}
                                              </Typography>
                                            )}
                                          {question.attributes.sc_status ===
                                            true &&
                                            question.attributes.CL_Rchart && (
                                              <Typography>
                                                CL Rchart :
                                                {question.attributes.CL_Rchart}
                                              </Typography>
                                            )}
                                          {question.attributes.sc_status ===
                                            true &&
                                            question.attributes.LCL_Rchart && (
                                              <Typography>
                                                LCL Rchart :
                                                {question.attributes.LCL_Rchart}
                                              </Typography>
                                            )}
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  );
                                }
                              )
                            ) : (
                              <Typography>----- ไม่มีข้อมูล -----</Typography>
                            )}
                          </AccordionDetails>
                        </Accordion>
                      </div>
                    );
                  }
                )
              ) : (
                <Typography>----- ไม่มีข้อมูล -----</Typography>
              )}
            </AccordionDetails>
          </Accordion>
        )}

        <div className="mt-4">
          {revise && (
            <React.Fragment>
              {revise.release_id && (
                <div className="flex justify-end">
                  <div className={`text-gray-500`}>Release :</div>
                  <SignatureBox
                    className={`ml-4`}
                    user={revise.release_id.data.attributes}
                    signatureURL={
                      revise.release_signature
                        ? revise.release_signature
                        : undefined
                    }
                  />
                </div>
              )}
              {revise.checker_signature && (
                <div className="flex justify-end mt-4">
                  <div className={`text-gray-500`}>Checker :</div>

                  <SignatureBox
                    className={`ml-4`}
                    user={revise.checker_id.data.attributes}
                    signatureURL={revise.checker_signature}
                  />
                </div>
              )}
              {revise.approver_signature && (
                <div className="flex justify-end mt-4">
                  <div className={`text-gray-500`}>Approver :</div>
                  <SignatureBox
                    className={`ml-4`}
                    user={revise.approver_id.data.attributes}
                    signatureURL={revise.approver_signature}
                  />
                </div>
              )}
              <div className={`flex justify-end mt-4`}>
                <RejectButton
                  role={user.qc_role}
                  status={revise.revise_status}
                  className={`ml-4`}
                  onClick={() => handleOnClickReject()}
                />
                <ConfirmButton
                  role={user.qc_role}
                  status={revise.revise_status}
                  className={`ml-4`}
                  onClick={() => handleOnClickConfirm()}
                />
              </div>
            </React.Fragment>
          )}
          {openConfirm.open && (
            <ConfirmModal
              open={openConfirm.open}
              setOpen={setOpenConfirm}
              action={confirm}
              paper={revise}
              confirmText={"ตกลง"}
              cancelText={"ยกเลิก"}
              header={"ยืนยันการตรวจสอบ"}
              message={
                <span>
                  คุณต้องการยืนยันว่าเอกสาร
                  <span className="text-green-600 underline underline-offset-1">
                    {openConfirm.message}
                  </span>
                </span>
              }
              setRemark={setRemark}
              remark={remark}
            />
          )}
          {openReject.open && (
            <ConfirmModal
              open={openReject.open}
              setOpen={setOpenReject}
              action={reject}
              paper={revise}
              confirmText={"ตกลง"}
              cancelText={"ยกเลิก"}
              header={"ยืนยันการตรวจสอบ"}
              message={
                <span>
                  คุณต้องการยืนยันว่าเอกสาร
                  <span className="text-red-600 underline underline-offset-1">
                    {openReject.message}
                  </span>
                </span>
              }
              setRemark={setRemark}
              remark={remark}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Revise;
