import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export default function LineChartForNormalQT({
  arrData,
  chartName,
  dataSetName,
}) {
  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: chartName ? chartName : "Chart.js Line Chart",
      },
    },
  };

  const data = {
    labels: arrData.map((data, index) => index + 1),
    datasets: [
      {
        label: dataSetName || "dataset 1",
        data: arrData.map((data) => data),
        borderColor: "#1976D2",
        backgroundColor: "#1976D2",
      },
    ],
  };
  return (
    <div className="xl:h-60 xl:w-full">
      <Line options={options} data={data} />
    </div>
  );
}
