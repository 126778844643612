import React, { useEffect, useState, useContext } from "react";
import { APIServices } from "../../helpers/APIServices";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import Fab from "@mui/material/Fab";
import FormAddQuestion, { searchPartID } from "./FormAddQuestion";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useLocation, useParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import CheckIcon from "@mui/icons-material/Check";
import FormUpdateQuestion from "./FormUpdateQuestion";
import { UserContext } from "../../Layout";
import { Chip } from "@mui/material";
import SimpleBackdrop from "../../Components/Backdrop";
import { sortData } from "../../helpers/common";
// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function AddQuestion() {
  const [open, setOpen] = useState(false);
  const [question, setQuestion] = useState([]);
  // const [create, setCreateCategories] = useState([]);
  const user = useContext(UserContext);
  const [success, setSuccess] = useState(true);
  const param = useParams();
  const [search, setSearch] = useState("");
  const [searchSwitch, setSearchSwitch] = useState(false);

  const [editQuestion, setEditQuestion] = useState({
    open: false,
    data: null,
  });
  // const [part, setPart] = useState();
  const [subCat, setSubCat] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [openFilter, setOpenFiter] = useState(false);

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleSearchSwitch = (value) => {
    setSearchSwitch(value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const getQuestion = async () => {
    const response = await APIServices.getQuestion(param.id);
    const question = response.data.data;
    const newQuestion = question.map((questionPerRound) => {
      return {
        id: questionPerRound.id,
        ...questionPerRound.attributes,
      };
    });

    // _.sortBy(newQuestion, [function(o) { return o.question; }]);
    setQuestion(newQuestion);
    setIsLoading(false);
  };
  const findSubCat = async () => {
    const response = await APIServices.findOneSubCat(param.id);
    const subCat = response.data.data;
    const newSubCat = {
      id: subCat.id,
      ...subCat.attributes,
    };
    setSubCat(newSubCat);
  };

  // const findOnePart = async () => {
  //   const findPart = await APIServices.findOnePartForUseName(partId);
  //   const partData = findPart.data.data;
  //   const newPart = {
  //     id: partData.id,
  //     ...partData.attributes,
  //   };
  //   setPart(newPart);
  // };

  const checkSearch = (emptyCheck) => {
    // if (search === "" || emptyCheck.question.includes(search)) {
    //   console.log(105);
    //   return true;
    // }
    // if (searchSwitch === undefined) {
    //   return true;
    // }
    if (searchSwitch === false || emptyCheck.sc_status) {
      if (
        search === "" ||
        emptyCheck.question.toUpperCase().includes(search.toUpperCase())
      ) {
        return true;
      }
      // else {
      //   if (search === "" || emptyCheck.question.includes(search)) {
      //     return true;
      //   }
      // }

      return false;
    }
  };

  const checkRole = () => {
    if (
      user.qc_role === "super_admin" ||
      user.qc_role === "admin" ||
      user.qc_role === "release"
    ) {
      return true;
    }
  };

  const handleOpenFilter = () => {
    setOpenFiter(false);
  };

  const handleOffFilter = () => {
    setOpenFiter(true);
  };

  useEffect(() => {
    // findOnePart();
    findSubCat();
  }, []);

  useEffect(() => {
    // run true of false
    if (success) {
      // only true
      getQuestion();
      setSuccess(false);
    }
  }, [success]);

  return (
    <>
      <SimpleBackdrop open={isLoading} setOpen={setIsLoading} />
      {subCat && (
        <div className={`flex flex-col justify-center items-start mb-2`}>
          <div
            className={`flex mb-2 flex-col lg:flex lg:flex-row items-baseline`}
          >
            {/* <Chip label={"Part Name: "} color="primary" size="small" /> */}
            <div className={`ml-2 font-bold text-xl`}>
              {subCat.part.data.attributes.part_name}{" "}
            </div>
            <div className={`ml-2 `}>
              ({subCat.part.data.attributes.part_code})
            </div>
          </div>
          {/* <div className={`flex mb-2 flex-row items-center`}>
            <Chip label={"Part Code: "} color="primary" size="small" />
            <div className={`ml-2 font-bold`}>{subCat.part.data.attributes.part_code}</div>
          </div> */}
          <div className={`flex mb-2 flex-row items-center`}>
            <Chip
              label={"Category: "}
              color="primary"
              size="small"
              variant="outlined"
            />
            <div className={`ml-2 font-medium`}>
              {subCat.category.data.attributes.category}
            </div>
          </div>
          <div className={`flex mb-2 flex-row items-center`}>
            <Chip
              label={"Sub category: "}
              color="primary"
              size="small"
              variant="outlined"
            />
            <div className={`ml-2 font-medium`}>{subCat.sub_categories}</div>
          </div>
        </div>
      )}
      <div className="mb-4 flex ">
        {/* <div className="flex flex-row items-center justify-center mr-2">
          {openFilter ? (
            <FilterAltIcon onClick={() => handleOpenFilter()} />
          ) : (
            <FilterAltOffIcon onClick={() => handleOffFilter()} />
          )}
        </div> */}
        <div className="flex flex-row items-center justify-center">
          <span>SC</span>
          <Switch
            onChange={(e) => {
              handleSearchSwitch(e.target.checked);
            }}
          />
        </div>
        <TextField
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          placeholder="Search by question"
          className="flex-1"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        {checkRole() && (
          <Button
            sx={{
              mx: "8px",
            }}
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              handleClickOpen();
            }}
          >
            เพิ่มข้อมูลคำถาม
          </Button>
        )}
      </div>
      <div className="flex flex-col justify-center items-center ">
        {question.length !== 0 ? (
          <div className="bg-white p-4 mb-4 flex w-full flex-row rounded-md shadow-md">
            {/* <div className="basis-1/4 flex items-center rounded-full ">รูป</div> */}
            <div className="basis-1/2  flex items-center">คำถาม</div>
            <div className="basis-1/4  flex items-center justify-center">
              Spec Min
            </div>
            <div className="basis-1/4  flex items-center justify-center">
              Spec Max
            </div>
            <div className="basis-1/4  flex items-center justify-center">
              สถานะ SC
            </div>
          </div>
        ) : (
          <div className="bg-white p-4 mb-4 flex justify-center w-full rounded-md shadow-md">
            <div>ไม่มีข้อมูล</div>
          </div>
        )}

        {(openFilter ? sortData(question, "question") : question)
          .filter(
            (emptyCheck) =>
              emptyCheck !== "" &&
              emptyCheck.status === true &&
              checkSearch(emptyCheck)
          )
          .map((data, index) => (
            <div
              key={index}
              className="bg-white p-4 mb-4 flex w-full flex-row rounded-md shadow-md cursor-pointer hover:bg-neutral-100"
              onClick={() => {
                setEditQuestion({
                  open: true,
                  data: {
                    ...data,
                    spec_min: data.spec_min === null ? "" : data.spec_min,
                    spec_max: data.spec_max === null ? "" : data.spec_max,
                  },
                });
              }}
            >
              {/* <div className="basis-1/4 flex items-center rounded-full ">
                {data.image.data !== null ? (
                  <img
                    src={`${config.server}${data.image.data.attributes.url}`}
                    className="w-24 h-24"
                  />
                ) : (
                  <div
                    style={{ width: "6rem", height: "6rem" }}
                    className="bg-gray-100 flex items-center justify-center rounded-lg"
                  >
                    <NoPhotographyIcon />
                  </div>
                )}
              </div> */}
              <div className="basis-1/2  flex items-center ">
                {data.question}
              </div>
              <div className="basis-1/4  flex items-center justify-center">
                {data.spec_min !== null ? data.spec_min : "-"}
              </div>
              <div className="basis-1/4  flex items-center justify-center">
                {data.spec_max !== null ? data.spec_max : "-"}
              </div>
              <div className="basis-1/4  flex items-center justify-center">
                {data.sc_status ? <CheckIcon /> : "-"}
              </div>
            </div>
          ))}
        {checkRole() && (
          <div>
            <Fab variant="extended" color="primary" onClick={handleClickOpen}>
              <AddIcon />
              <span>เพิ่มข้อมูลคำถาม</span>
            </Fab>
          </div>
        )}
        {open &&
          <FormAddQuestion
            subCat={parseInt(param.id)}
            open={open}
            setOpen={setOpen}
            setSuccess={setSuccess}
            subCategory={subCat ? subCat.sub_categories : null}
            category={subCat ? subCat.category.data.attributes.category : null}
            partName={subCat ? subCat.part.data.attributes.part_name: null}
            partCode={subCat ? subCat.part.data.attributes.part_code: null}
          />
        }
        {editQuestion.open && (
          <FormUpdateQuestion
            state={[editQuestion, setEditQuestion]}
            setSuccess={setSuccess}
            subCategory={subCat ? subCat.sub_categories:null}
            category={subCat ? subCat.category.data.attributes.category :null}
            partName={subCat ? subCat.part.data.attributes.part_name: null}
            partCode={subCat ? subCat.part.data.attributes.part_code: null}
          />
        )}
      </div>
    </>
  );
}

export default AddQuestion;
