import {
  AssignmentInd,
  Edit,
  ImageNotSupported,
  ModeEdit,
  PersonAddAlt1,
  Search,
  VerifiedUser,
  Visibility,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import useSWR, { useSWRConfig } from "swr";

import AddUser from "./AddUser";
import EditUser from "./EditUser";
import React from "react";
import { checkRule } from "./module";
import config from "../../config";
import { useUser } from "../../helpers/APIServices";
import dayjs from "dayjs";

function User() {
  const { data, error, isLoading, mutate } = useUser();
  const [search, setSearch] = React.useState("");
  const [editUserState, setEditUser] = React.useState({
    open: false,
    user: null,
  });
  const [addUser, setAddUser] = React.useState({
    isOpen: false,
  });

  const handleUpdate = (updateUser) => {
    mutate();
  };

  const handleSearch = (value) => {
    setSearch(value);
  };

  const checkSearch = (userCheckRole) => {
    if (
      search === "" ||
      userCheckRole.username.toLowerCase().includes(search.toLowerCase()) ||
      userCheckRole.name.toLowerCase().includes(search.toLowerCase()) ||
      userCheckRole.lastname.toLowerCase().includes(search.toLowerCase())
    ) {
      return true;
    }

    return false;
  };

  const sortSignature = (signature) => {
    let sortSig = signature.sort((a, b) => a.id - b.id);
  };

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  return (
    <>
      <div className='mb-2 flex'>
        <TextField
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          placeholder='Search by Username,name or lastname'
          className='flex-1'
          variant='outlined'
          size='small'
          InputProps={{
            startAdornment: (
              <InputAdornment position='start'>
                <Search />
              </InputAdornment>
            ),
          }}></TextField>
        {/* <div> */}
        <Button
          sx={{
            mx: "8px",
          }}
          startIcon={<PersonAddAlt1 />}
          variant='outlined'
          onClick={() => {
            setAddUser({
              open: true,
            });
          }}>
          เพิ่มผู้ใช้งาน
        </Button>
        {/* </div> */}
      </div>
      <div className=' h-(screen-18) '>
        <div className='flex flex-wrap'>
          {data.data
            .filter((userCheckRole) =>
              // userCheckRole.blocked !== true && checkSearch(userCheckRole)
              // userCheckRole.qc_role !== "admin" && checkSearch(userCheckRole)
              checkSearch(userCheckRole)
            )
            .map((user) => (
              <div
                key={user.id}
                style={{ flex: "0 1 19%" }}
                className='  border-2 mr-2 rounded-lg shadow-md mb-4 flex'>
                <Card
                  // className="flex-1"
                  //  sx={{ width: "23vw", flex : '1 0 21%' }}
                  sx={{ width: 345 }}>
                  {user.profile_image !== null ? (
                    <Avatar
                      className='mx-auto mt-2'
                      sx={{ width: "9rem", height: "9rem" }}
                      src={`${user.profile_image.url}`}
                      // config.server +
                      alt={user.username + "_signature"}
                    />
                  ) : (
                    <div className='flex justify-center mt-2 '>
                      <ImageNotSupported
                        sx={{ width: "9rem", height: "9rem" }}
                      />
                    </div>
                  )}
                  <CardContent>
                    <Typography
                      className=' text-center'
                      gutterBottom
                      variant='h5'
                      component='div'>
                      {user.name} {user.lastname}
                    </Typography>
                    <div className='flex justify-between mb-2'>
                      <Typography variant='span' color='text.secondary'>
                        UserName
                      </Typography>
                      <Typography variant='span' color='text.secondary'>
                        {user.username}
                      </Typography>
                    </div>
                    <div className='flex justify-between mb-2'>
                      <Typography variant='span' color='text.secondary'>
                        Email
                      </Typography>
                      <Typography variant='span' color='text.secondary'>
                        {user.email}
                      </Typography>
                    </div>

                    <div className='flex justify-between mb-2'>
                      <Typography variant='span' color='text.secondary'>
                        Role
                      </Typography>
                      <Typography variant='span' color='text.secondary'>
                        <Chip
                          icon={checkRule(user)}
                          label={user.qc_role}
                          variant='outlined'
                        />
                      </Typography>
                    </div>
                    <div className='flex justify-between mb-2'>
                      <Typography variant='span' color='text.secondary'>
                        Status
                      </Typography>
                      <Typography variant='span' color='text.secondary'>
                        <Chip
                          label={!user.blocked ? "Enabled" : "Blocked"}
                          color={!user.blocked ? "success" : "error"}
                        />
                      </Typography>
                    </div>
                    <div className='flex justify-between mb-2'>
                      <Typography
                        className=' self-center'
                        variant='span'
                        color='text.secondary'>
                        Signature{" "}
                      </Typography>
                      <Typography variant='span' color='text.secondary'>
                        {" "}
                        <Signature user={user} />
                        {/* {user.signature !== null ? (
                          <img
                            draggable="false"
                            className="w-14 h-14 mx-auto mt-2"
                            src={`${
                              config.server +
                              // sortSignature(user.signature)
                              user.signature[user.signature.length - 1].url
                            }`}
                            alt={user.username + "_signature"}
                          />
                        ) : (
                          <div className="flex justify-center mt-2 ">
                            <ImageNotSupported
                              sx={{ width: "3.5rem", height: "3.5rem" }}
                            />
                          </div>
                        )} */}
                      </Typography>
                    </div>
                  </CardContent>
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                    }}>
                    <Button
                      sx={{
                        mx: "8px",
                      }}
                      // size="small"
                      startIcon={<ModeEdit />}
                      variant='outlined'
                      onClick={() => {
                        setEditUser({
                          open: true,
                          user: user,
                        });
                      }}>
                      แก้ไขข้อมูล
                    </Button>
                  </CardActions>
                </Card>
              </div>
            ))}
        </div>
      </div>
      <AddUser state={[addUser, setAddUser]} handleUpdate={handleUpdate} />
      {editUserState.open && (
        <EditUser
          state={[editUserState, setEditUser]}
          handleUpdate={handleUpdate}
        />
      )}
    </>
  );
}

export default User;

const Signature = ({ user }) => {
  if (user.signature !== null) {
    user.signature.sort((a, b) =>
      dayjs(a.updatedAt).isBefore(dayjs(b.updatedAt))
        ? -1
        : dayjs(a.updatedAt).isAfter(dayjs(b.updatedAt))
        ? 1
        : 0
    );
  }
  return (
    <div
      className={`overflow-hidden border border-gray-200 rounded flex items-center`}>
      {user.signature !== null ? (
        <img
          draggable='false'
          className='w-14 mx-auto'
          src={`${
            // config.server +
            // sortSignature(user.signature)
            user.signature[user.signature.length - 1].url
          }`}
          alt={user.username + "_signature"}
        />
      ) : (
        <div className='flex justify-center mt-2 '>
          <ImageNotSupported sx={{ width: "3.5rem", height: "3.5rem" }} />
        </div>
      )}
    </div>
  );
};
