let user = localStorage.getItem("user");

const Storages = {
  // getStorage: (name) => {
  //   const v = document.Storage.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  //   return v ? v[2] : null;
  // },

  getStorage: (name) => {
    // const v = document.Storage.match("(^|;) ?" + name + "=([^;]*)(;|$)");
    // return v ? v[2] : null;
    return localStorage && localStorage.getItem(name);
  },

  // setStorage: (name, value, days) => {
  //   var d = new Date();
  //   d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);
  //   document.Storage = name + "=" + value + ";path=/;expires=" + d.toGMTString();
  // },

  setStorage: (name, value, days) => {
    localStorage.setItem(name, value);
  },

  // deleteStorage: (name) => {
  //   Storages.setStorage(name, "", -1);
  // },

  deleteStorage: (name) => {
    localStorage.removeItem(name);
  },

  getHeader: () => {
    const token = Storages.getStorage("token");
    return {
      Authorization: "Bearer " + token,
    };
  },
  getAvatar: () => {
    const avatar = Storages.getStorage("avatar");
    return avatar;
  },
  getUserID: () => {
    const user = JSON.parse(Storages.getStorage("user"));
    return user.empID;
  },
  getUsername: () => {
    const user = JSON.parse(Storages.getStorage("user"));
    return user.username;
  },
  getJwt: () => {
    const jwt = Storages.getStorage("token");
    return jwt;
  },
  getStoreID: () => {
    const user = JSON.parse(Storages.getStorage("user"));
    return user.storeID;
  },
  getEmpKey: () => {
    const user = JSON.parse(Storages.getStorage("user"));
    return user.empKey;
  },
  getCompany: () => {
    const user = JSON.parse(Storages.getStorage("user"));
    return user.company;
  },
  getGroupCode: () => {
    const user = JSON.parse(Storages.getStorage("user"));
    return user.groupCode;
  },
  getUser: () => {
    const user = JSON.parse(Storages.getStorage("user"));
    return user;
  },
};

export default Storages;
