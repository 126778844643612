import React, { useEffect, useState } from "react";
import { Input } from "@mui/material";
import dayjs from "dayjs";
const DateTime = ({ label, onChange, autoFocus, value }) => {
  const [date, setDate] = useState(dayjs(value).format("YYYY-MM-DD"));
  const [time, setTime] = useState(dayjs(value).format("HH:mm"));

  const handleChangeDate = (e) => {
    setDate(e.target.value);
  };
  const handleChangeTime = (e) => {
    setTime(e.target.value);
  };

  useEffect(() => {
    if (date !== undefined && time !== undefined) {
      onChange(date, time);
    }
  }, [date, time]);
  return (
    <div>
      <span className="text-sm text-gray-600">
        {label ? label : `DateTime`}
      </span>
      <div className="flex">
        <Input value={date} onChange={handleChangeDate} type="date" />
        <span className="text-lg ml-2">:</span>
        <Input
          className="ml-2"
          value={time}
          onChange={handleChangeTime}
          onBlur={handleChangeTime}
          type="time"
        />
      </div>
    </div>
  );
};

export default DateTime;
