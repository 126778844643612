import Quiz from '@mui/icons-material/Quiz'
import Person from '@mui/icons-material/Person'
import Description from '@mui/icons-material/Description'
import LineAxis from '@mui/icons-material/LineAxis';
import ManageHistory from '@mui/icons-material/ManageHistory';
import Settings from '@mui/icons-material/Settings';
import Article from '@mui/icons-material/Article';

const menuItem = [
  {
    groupName: "Part management",
    visible: true,
    menu: [
      {
        path: "part",
        text: "Part",
        icon: <Quiz />,
        role: [
          "admin",
          "super_admin",
          "release",
          "approver_revise",
          "checker_revise",
        ],
      },
      {
        path: "revise",
        text: "Revise",
        role: [
          "admin",
          "super_admin",
          "release",
          "approver_revise",
          "checker_revise",
        ],
        icon: <ManageHistory />
      },
    ]
  },
  {
    groupName: "Inspection",
    visible: true,
    menu: [
      {
        path: "paper",
        text: "Paper",
        role: ["inspector", "checker", "approver", "super_admin"],
        icon: <Description />
      },
    ]
  },
  {
    groupName: "Monitor",
    visible: true,
    menu: [
      {
        path: "dashboard",
        text: "Dashboard",
        role: ["admin", "inspector", "checker", "approver", "super_admin"],
        icon: <LineAxis />
      },
      {
        path: "report",
        text: "Report",
        icon: <Article />,
        role: ["admin", "super_admin", "checker", "approver", "super_admin"],
      },
    ]
  },
  {
    groupName: "Settings",
    visible: true,
    menu: [
      {
        path: "user",
        text: "User",
        role: ["admin", "super_admin"],
        icon: <Person />,
      },
      {
        path: "setting",
        text: "Setting",
        role: ["admin", "super_admin"],
        icon: <Settings />
      },
    ]
  },
  {
    groupName: "other",
    visible: false,
    menu: [
      {
        path: "profile",
        text: "Profile",
        role: [],
      },
    ]
  }
];

export default menuItem;
