import _ from "lodash";
import config from "../config";

const dayjs = require("dayjs");

const getToday = () => {
  return dayjs().format("YYYY-MM-DD HH:mm:ss");
  // return dayjs().toISOString();
};

const getTomorrow = () => {
  return dayjs().add(1, "day").format("YYYY-MM-DD HH:mm:ss");
};

const getYesterday = () => {
  return dayjs().subtract(1, "day").format("YYYY-MM-DD HH:mm:ss");
  // return dayjs().subtract(1, "day").toISOString();
};

const getBeforeSevenDay = () => {
  return dayjs().subtract(7, "day").format("YYYY-MM-DD HH:mm:ss");
};

const getLastMonth = () => {
  return dayjs().subtract(1, "month").format("YYYY-MM-DD HH:mm:ss");
};

const compareFn = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

const parseFullNameFromUser = (user) => {
  return `${user.name} ${user.lastname}`;
};

const parseStatus = (status) => {
  let statusText;
  if (status === "1") {
    statusText = (
      <div
        className={`rounded-lg max-w-max border border-blue-500 px-2 py-1 text-xs text-blue-500`}
      >
        รอการดำเนินการตรวจสอบ
      </div>
    );
  } else if (status === "2") {
    statusText = (
      <span
        className={`rounded-lg w-max border border-green-500 px-2 py-1 text-xs text-green-500`}
      >
        ผ่านการตรวจสอบ
      </span>
    );
  } else if (status === "3") {
    statusText = (
      <span
        className={`rounded-lg w-max border border-red-500 px-2 py-1 text-xs text-red-500`}
      >
        ไม่ผ่านการตรวจสอบ
      </span>
    );
  } else if (status === "4") {
    statusText = (
      <span
        className={`rounded-lg w-max bg-green-600 px-2 py-1 text-xs text-white`}
      >
        ผ่านการอนุมัติ
      </span>
    );
  } else if (status === "5") {
    statusText = (
      <span
        className={`rounded-lg w-max bg-red-500 px-2 py-1 text-xs text-white`}
      >
        ไม่ผ่านการอนุมัติ
      </span>
    );
  }
  //  else if (status === "6") {
  //   statusText = (
  //     <span
  //       className={`rounded-lg max-w-max border border-orange-500 px-2 py-1 text-xs text-orange-500`}
  //     >
  //       รอการแก้ไข
  //     </span>
  //   );
  // } else if (status === "7") {
  //   statusText = (
  //     <span
  //       className={`rounded-lg max-w-max border border-blue-500 px-2 py-1 text-xs text-blue-500`}
  //     >
  //       แก้ไขสำเร็จ
  //     </span>
  //   );
  // } 
  else {
    statusText = "Invalid status";
  }
  return statusText;
};

const parseStatusRevise = (status) => {
  let statusText;
  if (status === "1") {
    statusText = (
      <div
        className={`rounded-lg max-w-max border border-blue-500 px-2 py-1 text-xs text-blue-500`}
      >
        รอการดำเนินการตรวจสอบ
      </div>
    );
  } else if (status === "2") {
    statusText = (
      <span
        className={`rounded-lg w-max bg-green-600 px-2 py-1 text-xs text-white`}
      >
        ผ่านการตรวจสอบจาก Checker
      </span>
    );
  } else if (status === "3") {
    statusText = (
      <span
        className={`rounded-lg w-max bg-red-500 px-2 py-1 text-xs text-white`}
      >
        ไม่ผ่านการตรวจสอบจาก
      </span>
    );
  } else if (status === "4") {
    statusText = (
      <span
        className={`rounded-lg w-max bg-green-600 px-2 py-1 text-xs text-white`}
      >
        ผ่านการตรวจสอบจาก
      </span>
    );
  } else if (status === "5") {
    statusText = (
      <span
        className={`rounded-lg w-max bg-red-500 px-2 py-1 text-xs text-white`}
      >
        ไม่ผ่านการตรวจสอบจาก
      </span>
    );
  } else {
    statusText = "Invalid status";
  }
  return statusText;
};

const statusRevise = (status) => {
  let statusText;
  if (status === "0") {
    statusText = "";
  } else if (status === "1") {
    statusText = (
      <span
        className={`ml-2 rounded-lg max-w-max border border-green-600 px-2 py-1 text-xs text-green-600`}
      >
        เพิ่ม
      </span>
    );
  } else if (status === "2") {
    statusText = (
      <span
        className={`ml-2 rounded-lg max-w-max border border-yellow-500 px-2 py-1 text-xs text-yellow-500`}
      >
        แก้ไข
      </span>
    );
  } else if (status === "3") {
    statusText = (
      <span
        className={`ml-2 rounded-lg max-w-max border border-red-500 px-2 py-1 text-xs text-red-500`}
      >
        ลบ
      </span>
    );
  } else {
    statusText = "";
  }
  return statusText;
};

const sortData = (data, field) => {
  return data
  const cloneData = _.cloneDeep(data);
  cloneData.sort(function (a, b) {
    let numA = parseInt(a[field].match(/\d+/));
    let numB = parseInt(b[field].match(/\d+/));
    if (!isNaN(numA) && !isNaN(numB)) {
      if (numA !== numB) {
        return numA - numB;
      }
    }
    return a[field].localeCompare(b[field]);
  });
  return cloneData;
};

const statusPartSuccess = (status) => {
  let statusText;
  if (status === true) {
    statusText = (
      <div
        className={`rounded-lg max-w-max border border-blue-500 px-2 py-1 text-xs text-blue-500`}
      >
        เพิ่มข้อูล part สำเร็จ
      </div>
    );
  } else if (status === false) {
    statusText = (
      <span
        className={`rounded-lg w-max border border-green-500 px-2 py-1 text-xs text-green-500`}
      >
        เพิ่มข้อูล part ไม่สำเร็จ
      </span>
    );
  } else {
    statusText = "Invalid status";
  }
  return statusText;
};

const scrollToRef = (ref) => {
  if (ref) {
    setTimeout(() => {
      ref.scrollIntoView({ behavior: 'auto' });
    }, 100);
  }
}

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

const sortSignature = (signature) => {
  return signature.sort((a, b) => dayjs(a.createdAt).isAfter(dayjs(b.createdAt), 'minute') ? -1 : dayjs(a.createdAt).isBefore(dayjs(b.createdAt), 'minute') ? 1 : 0)
}

const exportData = (paper) => {
  let dataExport;
  if (paper) {
    const shift = paper.shiftAB ? paper.shiftAB : paper.shift;
    const partData = paper.partName;
    const process_status = paper.process_status ? paper.process_status : "-";
    const partName = partData.part_name;
    const partCode = partData.part_code;
    // const imageData = partData.attributes? 
    const imagePart = partData.image.url || null

    const reviseData = [];
    const dataOldPart = partData.revise;
    const sliceDate = dataOldPart.createdAt.slice(0, 10)
    const splitDate = sliceDate.split("-").reverse();
    const revise_date =
      splitDate[0] + "-" + splitDate[1] + "-" + splitDate[2];
    const reviseFromRealPart = {
      id: partData.id,
      date: revise_date,
      revise_time: partData.revise_time,
      revisionRecord: partData.revise.revisionRecord,
      releaseForRevise: partData.revise.release_id.name,
      checkerForRevise: partData.revise.checker_id.name,
      approverForRevise: partData.revise.approver_id.name,
    };
    const old_parts = partData.old_parts
    if (old_parts.length > 0) {
      const dataOldPart = partData.old_parts
      dataOldPart.forEach((loopOldPart) => {
        const dataRevise = loopOldPart.revise;
        const sliceDate = dataRevise.createdAt.slice(0, 10);
        const splitDate = sliceDate.split("-").reverse();
        const date = splitDate[0] + "-" + splitDate[1] + "-" + splitDate[2];
        reviseData.push({
          id: loopOldPart.id,
          date: date,
          revise_time: loopOldPart.attributes ? loopOldPart.attributes.revise_time : loopOldPart.revise_time,
          revisionRecord: dataRevise.revisionRecord,
          releaseForRevise: dataRevise.release_id.name,
          checkerForRevise: dataRevise.checker_id.name,
          approverForRevise: dataRevise.approver_id.name,
        });
      });
      reviseData.push(reviseFromRealPart);
    } else {
      reviseData.push(reviseFromRealPart);
    }
    let dataQuestion = [];
    console.log("partData 94:", partData);
    paper.answer.sub_categories.forEach((subCat) => {
      subCat.questions.forEach((question) => {
        dataQuestion.push({
          spec_min: question.spec_min,
          spec_max: question.spec_max,
          checkPoint: question.questionName,
          answer1: question.answer[0],
          answer2: question.answer[1],
          answer3: question.answer[2],
          actual1: question.actual[0],
          actual2: question.actual[1],
          actual3: question.actual[2],
          comment1: question.comment[0],
          comment2: question.comment[1],
          comment3: question.comment[2],
          typeCheck: subCat.category,
          revise_time: question.revise_time,
          lastRevise: partData.revise_time === question.revise_time,
          sub_category: subCat.sub_category,
        });
      });
    });

    dataExport = {
      dataquestion: dataQuestion,
      date: paper.date,
      time: paper.time,
      shift: shift,
      process_status: process_status,
      partName: partName,
      partCode: partCode,
      imagePart: imagePart,
      reviseData: reviseData,
      signature_approver:
        paper.signature_approver && paper.signature_approver,
      //config.server + 

      signature_checker:
        paper.signature_checker && paper.signature_checker,
      // config.server + 

      signature_inspector:
        paper.signature_inspector &&
        paper.signature_inspector,

      // config.server + 
    };
  }
  return dataExport;
}

export {
  getToday,
  getTomorrow,
  getYesterday,
  parseFullNameFromUser,
  parseStatus,
  getBeforeSevenDay,
  getLastMonth,
  parseStatusRevise,
  statusRevise,
  sortData,
  statusPartSuccess,
  scrollToRef,
  getBase64,
  sortSignature,
  exportData,
};
