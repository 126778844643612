import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const DeletePartModal = ({
  open,
  setOpen,
  action,
  header,
  message,
  confirmText,
  cancelText,
  data
}) => {
  const handleClose = () => {
    setOpen((prev) => ({ ...prev, open: false }));
  };

    const handleOnClick = (result) => {
      setOpen((prev) => ({ ...prev, open: false }));
      action(result, data);
    };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {header || "Header"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || "Content"}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
        onClick={() => handleOnClick(false)}
        >
          {cancelText || "Disagree"}
        </Button>
        <Button
          variant="contained"
            onClick={() => handleOnClick(true)}
          autoFocus
        >
          {confirmText || "Agree"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeletePartModal;
