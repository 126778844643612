import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import TableReport from "../Components/TableReport";
import { APIServices } from "../helpers/APIServices";
import dayjs from "dayjs";
import LineChart from "../Components/LineChart";
import DateTime from "../Components/DateTime";
import Button from "@mui/material/Button";
import config from "../config";
import { PartContext } from "./../App";
import { UserContext } from "../Layout";
import TableControlLimit from "../Components/TableControlLimit";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import SimpleBackdrop from "../Components/Backdrop";
import Download from "@mui/icons-material/Download";
import * as XLSX from "xlsx";
const headers = [
  // {
  //   name: "id",
  // },
  {
    name: "No.",
  },
  {
    name: "create date",
  },
  {
    name: "shift",
  },
  {
    name: "actual",
    col: [
      {
        name: "No.1",
      },
      {
        name: "No.2",
      },
      {
        name: "No.3",
      },
    ],
  },
  // {
  //   name: "answer",
  //   col: [
  //     {
  //       name: "No.1",
  //     },
  //     {
  //       name: "No.2",
  //     },
  //     {
  //       name: "No.3",
  //     },
  //   ],
  // },
  {
    name: "average",
  },
  {
    name: "range",
  },
  {
    name: "inspector",
  },
  {
    name: "reviewer",
  },
];

const controlLimit = [
  {
    name: "categories",
    col: [
      {
        name: "UCL",
      },
      {
        name: "CL",
      },
      {
        name: "LCL",
      },
    ],
  },
];

const PerPath = () => {
  const defaultDateTime = {
    start: `${dayjs().subtract(1, "d").format("YYYY-MM-DD")} 00:00:00`,
    end: `${dayjs().format("YYYY-MM-DD")} 23:59:59`,
  };
  const params = useParams();
  const [reportData, setReportData] = useState([]);
  const [dataAverage, setDataAverage] = useState([]);
  const [dataRange, setDataRange] = useState([]);
  const [startDate, setStartDate] = useState({
    error: false,
    value: defaultDateTime.start,
  });
  const [endDate, setEndDate] = useState({
    error: false,
    value: defaultDateTime.end,
  });
  const [loading, setLoading] = useState(false);

  const [checkDate, setCheckDate] = useState({
    error: false,
    message: "end date should more than start date",
  });
  const [xBar, setXBar] = useState();
  const [rChart, setRChart] = useState([]);
  const [part, setPart] = useState({});
  const [question, setQuestion] = useState({});
  const d2neq3 = 1.693;
  let location = useLocation();
  let locationArr = location.pathname.split("/").reverse();
  const user = useContext(UserContext);

  const findOnePart = async (startDate, endDate) => {
    if (!startDate && !endDate) {
      startDate = defaultDateTime.start;
      endDate = defaultDateTime.end;
    }
    setLoading(true);
    const responseFromFindOnePart = await APIServices.findOnePart(
      locationArr[4]
    );
    const responseQuestion = await APIServices.findOneQuestion(params.id);
    const questions = responseQuestion.data.data;
    const resultFromFindOnePart = responseFromFindOnePart.data;
    if (resultFromFindOnePart.data) {
      const part = resultFromFindOnePart.data;
      let questionSC = {
        question_name: questions.attributes.question,
        spec_min: questions.attributes.spec_min,
        spec_max: questions.attributes.spec_max,
        CL_Rchart: questions.attributes.CL_Rchart
          ? questions.attributes.CL_Rchart.toFixed(3)
          : "-",
        LCL_Rchart: questions.attributes.LCL_Rchart
          ? questions.attributes.LCL_Rchart.toFixed(3)
          : "-",
        UCL_Rchart: questions.attributes.UCL_Rchart
          ? questions.attributes.UCL_Rchart.toFixed(3)
          : "-",
        CL_Xbar: questions.attributes.CL_Xbar
          ? questions.attributes.CL_Xbar.toFixed(3)
          : "-",
        LCL_Xbar: questions.attributes.LCL_Xbar
          ? questions.attributes.LCL_Xbar.toFixed(3)
          : "-",
        UCL_Xbar: questions.attributes.UCL_Xbar
          ? questions.attributes.UCL_Xbar.toFixed(3)
          : "-",
      };
      // for (let i = 0; i < part.attributes.sub_categories.data.length; i++) {
      //   const subCatPerLoop = part.attributes.sub_categories.data[i];
      //   console.log("Subcate 135", subCatPerLoop);
      //   let checkBreak = false;
      //   for (
      //     let j = 0;
      //     j < subCatPerLoop.attributes.questions.data.length;
      //     j++
      //   ) {
      //     const questions = subCatPerLoop.attributes.questions.data[j];
      //     console.log("questions", questions.attributes);
      //     if (questions.attributes.sc_status) {
      //       checkBreak = true;
      //       questionSC = {
      //         question_name: questions.attributes.question,
      //         spec_min: questions.attributes.spec_min,
      //         spec_max: questions.attributes.spec_max,
      //         CL_Rchart: questions.attributes.CL_Rchart
      //           ? questions.attributes.CL_Rchart.toFixed(3)
      //           : "-",
      //         LCL_Rchart: questions.attributes.LCL_Rchart
      //           ? questions.attributes.LCL_Rchart.toFixed(3)
      //           : "-",
      //         UCL_Rchart: questions.attributes.UCL_Rchart
      //           ? questions.attributes.UCL_Rchart.toFixed(3)
      //           : "-",
      //         CL_Xbar: questions.attributes.CL_Xbar
      //           ? questions.attributes.CL_Xbar.toFixed(3)
      //           : "-",
      //         LCL_Xbar: questions.attributes.LCL_Xbar
      //           ? questions.attributes.LCL_Xbar.toFixed(3)
      //           : "-",
      //         UCL_Xbar: questions.attributes.UCL_Xbar
      //           ? questions.attributes.UCL_Xbar.toFixed(3)
      //           : "-",
      //       };
      //       break;
      //     }
      //   }
      //   if (checkBreak) {
      //     break;
      //   }
      // }

      const image = part.attributes.image
        ? part.attributes.image.data.attributes.url
        : "";
      setPart({
        part_name: part.attributes.part_name,
        part_code: part.attributes.part_code,
        part_image: image,
        ...questionSC,
      });
      setXBar({
        UCL: questionSC.UCL_Xbar,
        CL: questionSC.CL_Xbar,
        LCL: questionSC.LCL_Xbar,
      });
      setRChart({
        UCL: questionSC.UCL_Rchart,
        CL: questionSC.CL_Rchart,
        LCL: questionSC.LCL_Rchart,
      });
    }
    // console.log("locationArr 195", locationArr);
    const response = await APIServices.getPaper(
      startDate,
      endDate,
      locationArr[4],
      user
    );
    const result = response.data;
    if (result.data) {
      const papers = result.data;
      let report = [];
      const arrAverage = [];
      const arrRange = [];
      let questionId;
      papers.forEach(async (paper, index) => {
        try {
          let realSC,
            average = 0,
            range = 0;
          const newFormatPaper = {};
          newFormatPaper.id = paper.id;
          newFormatPaper.createAt = paper.attributes.createdAt;
          newFormatPaper.shift = paper.attributes.shift;

          if (!Array.isArray(paper.attributes.answer.sub_categories)) {
            throw Error(
              "paper.attributes.answer.sub_categories should array type"
            );
          }
          const sub_categories = paper.attributes.answer.sub_categories;
          let isSkip = false;
          sub_categories.forEach(async (sub_category) => {
            const SC = sub_category.questions.find((question) => {
              return question.questionId === parseInt(locationArr[0]);
            });
            if (SC) {
              // console.log("230: ", SC);
              if (SC.skipQuestion) {
                // console.log("skip !");
                isSkip = true;
                return;
              }
              if (!realSC) {
                realSC = SC;
                if (!questionId) {
                  questionId = SC.questionId;
                }
                if (SC.actual) {
                  // const actualInt = SC.actual.map((actualLoop) =>{
                  // console.log("actualLoop",actualLoop)
                  // if (actualLoop) {
                  //  return  parseInt(actualLoop)
                  // }
                  // });
                  // console.log(
                  //   "actualInt",actualInt
                  // );
                  // average = parseFloat(
                  //   (
                  //     actualInt.reduce((a, b) => a + b) / actualInt.length
                  //   ).toFixed(2)
                  // );
                  const actualInt = SC.actual.map(
                    (actualLoop) => parseFloat(actualLoop)
                    // isNaN(parseInt(actualLoop)) ? 0 : parseInt(actualLoop)
                  );
                  const sumActual = actualInt.reduce((a, b) => {
                    if (isNaN(a)) {
                      return b;
                    }
                    if (isNaN(b)) {
                      return a;
                    }
                    return a + b;
                  });
                  average = parseFloat(
                    (
                      sumActual / actualInt.filter((d) => !isNaN(d)).length
                    ).toFixed(2)
                  );
                  const min = Math.min(...SC.actual);
                  const max = Math.max(...SC.actual);
                  range = max - min;
                  // console.table({
                  //   min: Math.min(...SC.actual),
                  //   max: Math.max(...SC.actual),
                  //   average: average,
                  // });
                }
              }
            }
          });
          if (isSkip) {
            // console.log("skip paper");
            return;
          }
          const inspector = paper.attributes.inspector_id.data.attributes.name;
          const checker = paper.attributes.checker_id.data
            ? paper.attributes.checker_id.data.attributes.name
            : "-";
          if (realSC !== undefined) {
            report.push({
              id: paper.id,
              createAt: dayjs(paper.attributes.createdAt).format(
                "YYYY-MM-DD HH:mm:ss"
              ),
              shift: paper.attributes.shift,
              sc: realSC,
              average: average,
              range: range.toFixed(2),
              inspector: inspector,
              checker: checker,
              statusDelete: paper.attributes.delete,
            });
            if (!isNaN(average)) {
              arrAverage.push(average);
              arrRange.push(range);
            }
          }
        } catch (error) {
          console.error(error);
        }
      });
      console.log("arrAverage", arrAverage);
      setReportData(report);
      setDataAverage(arrAverage);
      setDataRange(arrRange);
      getQuestionByID(questionId);
    }
    setLoading(false);
  };

  const getQuestionByID = async (questionId) => {
    if (!questionId) {
      console.error("questionId is empty");
      return;
    }
    const response = await APIServices.findOneQuestion(questionId);
    const result = response.data;
    if (result.data) {
      const question = result.data;
      setQuestion(question.attributes);
    }
  };

  const handleOnChangeStartDate = (date, time) => {
    if (!date || !time) {
      setStartDate((prev) => ({
        ...prev,
        value: undefined,
      }));
      return;
    }
    const startDate = dayjs(`${date} ${time}`).format("YYYY-MM-DD HH:mm:ss");
    setStartDate((prev) => ({
      ...prev,
      value: startDate,
      error: false,
    }));
  };

  const handleOnChangeEndDate = (date, time) => {
    if (!date || !time) {
      setEndDate((prev) => ({
        ...prev,
        value: undefined,
      }));
      return;
    }
    const endDate = dayjs(`${date} ${time}`).format("YYYY-MM-DD HH:mm:ss");
    setEndDate((prev) => ({
      ...prev,
      value: endDate,
      error: false,
    }));
  };

  const handleSubmitSearch = async (startDate, endDate) => {
    if (!startDate.value) {
      setStartDate((prev) => ({
        ...prev,
        error: true,
      }));
      return;
    }
    if (!endDate.value) {
      setEndDate((prev) => ({
        ...prev,
        error: true,
      }));
      return;
    }

    if (
      dayjs(endDate.value).isBefore(startDate.value, "minute") ||
      dayjs(endDate.value).isSame(startDate.value, "minute")
    ) {
      setCheckDate((prev) => ({
        ...prev,
        error: true,
      }));
      setTimeout(() => {
        setCheckDate((prev) => ({
          ...prev,
          error: false,
        }));
      }, 2000);
      return;
    }
    setLoading(true);
    await findOnePart(startDate.value, endDate.value);
    setLoading(false);
  };

  const calX = (arrayAverage) => {
    if (Array.isArray(arrayAverage)) {
      if (arrayAverage.length === 0) {
        return 0;
      }
      const sum = arrayAverage.reduce((prev, current) => prev + current);
      return sum / arrayAverage.length;
    }
    throw Error("arrayAverage should array type !!!");
  };

  const calR = (arrayRange) => {
    if (Array.isArray(arrayRange)) {
      if (arrayRange.length === 0) {
        return 0;
      }
      const sum = arrayRange.reduce((prev, current) => prev + current);
      return sum / arrayRange.length;
    }
    throw Error("arrayRange should array type !!!");
  };

  const calSigma = (dataRange, d2neq3) => {
    const R = calR(dataRange);
    const sigma = R / d2neq3;
    return sigma;
  };

  const calCP = (max, min, dataRange, d2neq3) => {
    const maxSubMin = max - min;
    const R = calR(dataRange);
    const CP = maxSubMin / (parseFloat(R / d2neq3) * 6);
    return CP;
  };

  const calCPU = (max, dataAverage, dataRange, d2neq3) => {
    console.table({ max, dataAverage, dataRange, d2neq3 });
    const X = calX(dataAverage);
    const R = calR(dataRange);
    const CPU = (max - X) / (3 * (R / d2neq3));
    return CPU;
  };

  const calCPL = (dataAverage, min, dataRange, d2neq3) => {
    const X = calX(dataAverage);
    const CPL = (X - min) / (3 * calSigma(dataRange, d2neq3));
    return CPL;
    // ( (calX(dataAverage) - part.spec_min) / (3 * (calR(dataRange) / d2neq3)) ).toFixed(2)
  };

  const calCPK = (CPU, CPL) => {
    if (typeof CPU !== "number") {
      return "";
    }
    if (typeof CPL !== "number") {
      return "";
    }
    if (CPU < CPL) {
      return CPU.toFixed(2);
    } else {
      return CPL.toFixed(2);
    }
  };

  const checkJudge = (CPK) => {
    const numCPK = parseFloat(CPK);
    if (isNaN(numCPK)) {
      return "";
    }
    if (numCPK > 1.33) {
      return "OK";
    } else {
      return "NG";
    }
  };

  const handleExportSPC = async () => {
    console.log("export");
    const fileName = "namo";
    const workBook = XLSX.utils.book_new();
    const workSheet = XLSX.utils.json_to_sheet([
      {
        X: calX(dataAverage).toFixed(2),
        R: calR(dataRange).toFixed(2),
        USL: part.spec_max,
        LSL: part.spec_min,
        "d2 (n=3)": d2neq3,
        Sigma: calSigma(dataRange, d2neq3).toFixed(2),
        Cp: calCP(part.spec_max, part.spec_min, dataRange, d2neq3).toFixed(2),
        Cpu: calCPU(part.spec_max, dataAverage, dataRange, d2neq3).toFixed(2),
        Cpl: calCPL(dataAverage, part.spec_min, dataRange, d2neq3).toFixed(2),
        Cpk: calCPK(
          calCPU(part.spec_max, dataAverage, dataRange, d2neq3),
          calCPL(dataAverage, part.spec_min, dataRange, d2neq3)
        ),
        Judge: checkJudge(
          calCPK(
            calCPU(part.spec_max, dataAverage, dataRange, d2neq3),
            calCPL(dataAverage, part.spec_min, dataRange, d2neq3)
          )
        ),
      },
    ]);
    XLSX.utils.book_append_sheet(workBook, workSheet, fileName);
    XLSX.writeFile(workBook, "Report SPC.xlsx");
  };

  useEffect(() => {
    findOnePart();
  }, []);

  return (
    <div>
      {/* <pre>{JSON.stringify(part)}</pre> */}
      {/* <div className="bg-black w960 h-40 flex justify-center items-center">
      <div className="py-2 px-4 bg-white rounded-full hover:bg-yellow-300 transition">
        Sawanon Wattanasit
      </div>
      </div> */}
      <SimpleBackdrop open={loading} setOpen={setLoading} />

      <div className={`lg:flex lg:flex-row p-2  justify-center flex flex-col`}>
        {/* <div>{window.screen.availHeight}</div>
        <div>{window.screen.availWidth}</div> */}
        <div
          className={` bg-white rounded-md drop-shadow py-2 px-4 lg:mt-0 lg:basis-3/5 lg:m-1 flex flex-row`}>
          <div className={`basis-1/2 `}>
            <div className='flex'>
              <div className={`basis-1/2 text-gray-500`}>Part name :</div>
              <div className={`basis-1/2 text-end`}>{part.part_name}</div>
            </div>
            <div className='flex mt-2'>
              <div className={`basis-1/2 text-gray-500`}>Part No. :</div>
              <div className={`basis-1/2 text-end`}>{part.part_code}</div>
            </div>
            <div className='flex mt-2'>
              <div className={`basis-1/2 text-gray-500`}>Characteristic :</div>
              <div className={`basis-1/2 text-end`}>{part.question_name}</div>
            </div>
            <div className='flex mt-2'>
              <div className={`basis-1/2 text-gray-500`}>Specification :</div>
              <div className={`basis-1/2 text-end`}>
                {part.spec_min && (
                  <span className={``}>{`(Min) ${part.spec_min.toFixed(
                    2
                  )}`}</span>
                )}
                {part.spec_max && (
                  <span className={`ml-4`}>{`(Max) ${part.spec_max.toFixed(
                    2
                  )}`}</span>
                )}
              </div>
            </div>
          </div>
          <div className={`flex justify-center items-center basis-1/2`}>
            {part.part_image !== null ? (
              <img
                src={part.part_image}
                //config.server +
                className=' object-contain h-[300px]'
              />
            ) : (
              <div
                style={{ width: "6rem", height: "6rem" }}
                className='bg-gray-100 flex items-center justify-center rounded-lg'>
                <NoPhotographyIcon />
              </div>
            )}
          </div>
        </div>
        <div
          className={` bg-white rounded-md drop-shadow py-2 px-4 mt-4 lg:mt-0 lg:basis-2/5 lg:m-1`}>
          <div className={`text-base text-center border-b py-1 font-bold`}>
            Control Limit
          </div>
          <TableControlLimit data={controlLimit[0]} partData={part} />
        </div>
      </div>
      <div className={`gap-4 mt-4 p-4 lg:flex`}>
        <div className='basis-3/5'>
          <div className={`flex gap-4 items-end relative justify-center`}>
            {checkDate.error && (
              <div
                className={`bg-red-500 py-2 px-4 text-white absolute rounded-md -bottom-10`}>
                {checkDate.message}
              </div>
            )}
            <div className={`${startDate.error && `bg-red-300 rounded`}`}>
              <DateTime
                value={startDate.value}
                autoFocus={startDate.error}
                label={`Start date`}
                onChange={handleOnChangeStartDate}
              />
            </div>
            <div className={`${endDate.error && `bg-red-300 rounded`}`}>
              <DateTime
                value={endDate.value}
                autoFocus={endDate.error}
                label={`End date`}
                onChange={handleOnChangeEndDate}
              />
            </div>
            <div>
              <Button
                variant='contained'
                onClick={() => handleSubmitSearch(startDate, endDate)}>
                Search
              </Button>
            </div>
          </div>
          {reportData.length > 0 ? (
            <TableReport
              className={`mt-4`}
              data={reportData}
              headers={headers}
            />
          ) : (
            <div className={`text-2xl flex justify-center items-center h-80`}>
              No data
            </div>
          )}
        </div>
        <div className='basis-2/5'>
          <div
            className={`bg-white drop-shadow rounded-md mt-4 lg:mt-0 lg:w-80 xl:w-full`}>
            <div
              className={`flex relative justify-end items-center text-base border-b p-1 font-bold`}>
              <div
                className={`absolute inset-0 flex justify-center items-center`}>
                Process Capability Studies
              </div>
              <div
                className={`relative cursor-pointer m-1 text-white p-1 rounded-full bg-blue-500 hover:bg-white hover:text-blue-500 transition`}
                onClick={handleExportSPC}>
                <Download color='inherit' />
              </div>
            </div>
            <div className='flex xl:flex-col'>
              <div
                className={`xl:text-center flex flex-col xl:flex-row basis-1/2 xl:mt-4`}>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  X
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  R
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  USL
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  LSL
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  d2 (n=3)
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  Sigma
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  Cp
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  Cpu
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  Cpl
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  Cpk
                </div>
                <div
                  className={`xl:border-b-0 pl-4 xl:pl-0 py-1  text-sm basis-1/6 border-b`}>
                  Judge
                </div>
              </div>
              <div className='xl:text-center lg:text-end flex flex-col xl:flex-row basis-1/2'>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* X */}
                  {calX(dataAverage).toFixed(2)}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* R */}
                  {calR(dataRange).toFixed(2)}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* USL */}
                  {part.spec_max}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* LSL */}
                  {part.spec_min}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* d2 (n=3) */}
                  {d2neq3}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* Sigma */}
                  {calSigma(dataRange, d2neq3).toFixed(2)}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* Cp */}
                  {calCP(
                    part.spec_max,
                    part.spec_min,
                    dataRange,
                    d2neq3
                  ).toFixed(2)}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* Cpu */}
                  {calCPU(
                    part.spec_max,
                    dataAverage,
                    dataRange,
                    d2neq3
                  ).toFixed(2)}
                  {/* {( (part.spec_max - calX(dataAverage)) / (3 * (calR(dataRange) / d2neq3)) ).toFixed(2)} */}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* Cpl */}
                  {calCPL(
                    dataAverage,
                    part.spec_min,
                    dataRange,
                    d2neq3
                  ).toFixed(2)}
                  {/* {( (calX(dataAverage) - part.spec_min) / (3 * (calR(dataRange) / d2neq3)) ).toFixed(2)} */}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* Cpk */}
                  {calCPK(
                    calCPU(part.spec_max, dataAverage, dataRange, d2neq3),
                    calCPL(dataAverage, part.spec_min, dataRange, d2neq3)
                  )}
                </div>
                <div className='xl:border-b-0 pr-4 xl:pr-0 py-1  text-sm basis-1/6 border-b'>
                  {" "}
                  {/* Judge */}
                  {checkJudge(
                    calCPK(
                      calCPU(part.spec_max, dataAverage, dataRange, d2neq3),
                      calCPL(dataAverage, part.spec_min, dataRange, d2neq3)
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`mt-4`}>
            <LineChart
              arrData={dataAverage}
              chartName={`X-bar`}
              dataSetName={`X`}
              controlLimit={xBar}
            />
            {/* <LineChart arrData={reportData} chartName={`X-bar`} /> */}
          </div>
          <div>
            <LineChart
              arrData={dataRange}
              chartName={`R-chart`}
              dataSetName={`R`}
              controlLimit={rChart}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PerPath;
