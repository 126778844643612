import React from "react";
import config from "../config";

const SignatureBox = ({ user, signatureURL, className }) => {

  return (
    <div
      className={`bg-white drop-shadow p-4 flex justify-between w-max min-w-[250px] rounded-md${
        className && ` ${className}`
      }`}
    >
      <div>
        {user.profile_image.data  && (
          <div className="h-12 w-12 rounded-full overflow-hidden">
            <img
              src={`${config.server}${user.profile_image.data.attributes.url}`}
              alt=""
            />
          </div>
        )}
        <div className="mt-2">
          {user.name} {user.lastname}
        </div>
      </div>
      <div className="h-20 w-20 flex justify-center items-center">
        {signatureURL && <img src={`${config.server}${signatureURL}`} alt="" />}
      </div>
    </div>
  );
};

export default SignatureBox;
