import { Link, useNavigate } from "react-router-dom";
import { styled, useTheme } from "@mui/material/styles";

import Box from "@mui/material/Box";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import IconButton from "@mui/material/IconButton";
import LineAxisOutlinedIcon from "@mui/icons-material/LineAxisOutlined";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import React, { useContext } from "react";
import Storages from "../helpers/localStorage";
import menuItem from "./menuItem.jsx";
import PersonIcon from "@mui/icons-material/Person";
import QuizIcon from "@mui/icons-material/Quiz";
import DescriptionIcon from "@mui/icons-material/Description";
import LineAxisIcon from "@mui/icons-material/LineAxis";
import SettingsIcon from "@mui/icons-material/Settings";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import ArticleIcon from "@mui/icons-material/Article";
import { UserContext } from ".";
import config from "../config.js";

const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

export const Sidebar = ({ open, setOpen }) => {
  const theme = useTheme();
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  const onLogout = () => {
    Storages.deleteStorage("QCAPPuser");
    Storages.deleteStorage("QCAPPjwt");
    Storages.deleteStorage("ESSjwt");
    navigate("/login");
  };

  // const user = JSON.parse(localStorage.getItem("QCAPPuser"));
  const user = useContext(UserContext);
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <div className={`w-full flex items-center justify-between`}>
            <div className={`w-full flex justify-center transition ${open ? 'opacity-0 duration-[4000ms]' : 'opacity-100'}`}>
              <span className={`text-gray-600 text-xs`}>{`${config.version}`}</span>
            </div>
            <div>
              <IconButton onClick={handleDrawerClose}>
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
          </div>
        </DrawerHeader>
        <Divider />
        <div>
          <div>
            <List>
              {menuItem.map((group, index) => {
                if(!group.visible){
                  return <span key={`span${index}`}></span>
                }
                const result = group.menu.some(menu => menu.role.some(role => role === user.qc_role))
                if(!result){
                  return <span key={`span${index}`}></span>
                }
                return (
                  <React.Fragment key={`link${index}`}>
                    <div className={`px-4 py-2 text-gray-400 text-sm`}>
                      {`${group.groupName}`}
                    </div>
                    {group.menu.map(menu => (
                      menu.role.some((role) => role === user.qc_role) && (
                        <Link
                          onClick={() => setOpen(false)}
                          key={`link${menu.text}`}
                          to={menu.path}
                        >
                          <ListItem key={`listitem${menu.text}`} disablePadding>
                            <ListItemButton>
                              <ListItemIcon>
                                {menu.icon}
                              </ListItemIcon>
                              <ListItemText primary={menu.text} />
                            </ListItemButton>
                          </ListItem>
                        </Link>
                      )
                    ))
                    }
                  </React.Fragment>
                );
              })}
            </List>
            <Divider />
          </div>

          <List>
            <ListItem key={"Logout"} disablePadding onClick={() => onLogout()}>
              <ListItemButton>
                <ListItemIcon>
                  <LogoutOutlinedIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} />
              </ListItemButton>
            </ListItem>
          </List>
        </div>
      </Drawer>
    </Box>
  );
};
