import {
  Alert,
  Button,
  Checkbox,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import { APIServices } from "../../helpers/APIServices";
import NoPhotography from "@mui/icons-material/NoPhotography";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import FormAddImageAnswerV2 from "./FormAddImageAnswerV2";
import { BootstrapDialog } from "./FormAddImageAnswer";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { UserContext } from "../../Layout";
import SimpleBackdrop from "../../Components/Backdrop";
import { calculateFormula, searchQuestionByID } from "../../Components/Formula";
import Swal from "sweetalert2";
import { Add, Remove } from "@mui/icons-material";
import _ from "lodash";
import { sortData } from "../../helpers/common";
import NextPlanIcon from '@mui/icons-material/NextPlan';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
export const IconCheckNG = ({ isNG, show }) => {
  if (show) {
    if (isNG) {
      return <ErrorIcon color="error" fontSize="large" />;
    } else {
      return <CheckCircleIcon color="success" fontSize="large" />;
    }
  }

  return <div></div>;
};

export const processStatus = [
  {
    id: 1,
    name: "Inprocess",
  },
  {
    id: 2,
    name: "Incoming",
  },
  {
    id: 3,
    name: "Final",
  },
];

function PaperV2() {
  const userContext = useContext(UserContext);
  const [alertPayload, setAlertPayload] = useState({
    open: false,
    type: "info",
    message: "",
    duration: 3000,
  });
  const { state } = useLocation();
  const params = useParams();

  const date = dayjs().format("DD-MM-YYYY");
  const today = dayjs().minute(0);
  const shift = today.hour() >= 8 && today.hour() < 20 ? "Day" : "Night";
  const [part, setPart] = useState();
  const [subCategories, setSubCategories] = useState([]);
  const [paper, setPaper] = useState();
  const [isOpenAddPhoto, setIsOpenAddPhoto] = useState({
    open: false,
    subcategoryID: undefined,
    questionID: undefined,
    indexAnswer: undefined,
  });
  const [process, setProcess] = useState("none");
  const [isOpenPartPhoto, setIsOpenPartPhoto] = useState(false);
  const [shiftTeam, setShiftTeam] = useState("none");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const keepPaper = JSON.parse(localStorage.getItem("keepPaper"));
  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    content: undefined,
  });
  const [NG, setNG] = useState([]);
  const [inputPerQuestion, setInputPerQuestion] = useState([]);
  const [isFullImg, setIsFullImg] = useState(false);
  const [cavity, setCavity] = useState("none")

  const sortQuestion = (arr) => {
    if(!Array.isArray(arr)){
      throw Error("arrQuestion should be array type").stack
    }
    const parseData = arr.map(data => ({
      id: data.id,
      ...data.attributes,
    }))
    const result = sortData(parseData, "question")
    // console.log("result 114", result);
    return result
  }

  useEffect(() => {
    const getPart = async (partID) => {
      try {
        setLoading(true);
        const response = await APIServices.getPartName(partID);
        const result = response.data;
        if (result.data) {
          const part = {
            id: result.data.id,
            ...result.data.attributes,
            image: result.data.attributes.image.data,
          };
          const subCategories = part.sub_categories.data
            .map((subCategory, index) => {
              // if(index === 1){
              const questions = sortQuestion(subCategory.attributes.questions.data)
              // }
              console.log("questions 135", questions);
              return {
                id: subCategory.id,
                ...subCategory.attributes,
                // questions:  subCategory.attributes.questions.data.map(
                questions:  questions.map(
                  (question) => ({
                    id: question.id,
                    error: {
                      actual: false,
                      answer: false,
                      comment: false,
                      imageURL: false,
                    },
                    skipQuestion: false,
                    // ...question.attributes,
                    ...question,
                  })
                )
                .filter((question) => question.status === true),
              }
            })
            .filter((subcategory) => subcategory.status === true);
          setPart(part);
          // console.log("subCategories 157", subCategories);
          setSubCategories(subCategories);
          const inputPerQuestion = [];
          const paper = {
            partId: part.id,
            partName: part.part_name,
            partImage: part.image.attributes.url,
            sub_categories: subCategories
            
            .map(
              (subCategory, subcategoryIndex) => {
                inputPerQuestion.push([]);
                return {
                  subId: subCategory.id,
                  category: subCategory.category.data.attributes.category,
                  sub_category: subCategory.sub_categories,
                  revise_time_subCat: subCategory.revise_time,
                  questions: subCategory.questions
                  .filter((question) => question.status === true)
                  .map(
                    (question, questionIndex) => {
                      if (keepPaper) {
                        if (keepPaper.partId === part.id) {
                          let checkKeeper = keepPaper
                            ? keepPaper.sub_categories[
                                subcategoryIndex
                              ].questions[questionIndex].answer.filter(
                                (d) => d !== ""
                              ).length
                            : 1;
                          if (checkKeeper === 0) {
                            checkKeeper++;
                          }
                          inputPerQuestion[subcategoryIndex].push(checkKeeper);
                        } else {
                          inputPerQuestion[subcategoryIndex].push(1);
                        }
                      } else {
                        inputPerQuestion[subcategoryIndex].push(1);
                      }

                      return {
                        questionId: question.id,
                        questionName: question.question,
                        spec_max: question.spec_max,
                        spec_min: question.spec_min,
                        isSc: question.sc_status,
                        actual: ["", "", ""],
                        answer: ["", "", ""],
                        comment: ["", "", ""],
                        imageURL: [[], [], []],
                        revise_time: question.revise_time,
                      };
                    }
                  ),
                };
              }
            ),
          };
          setInputPerQuestion(inputPerQuestion);
          console.log("keepPaper 191", keepPaper);
          if (keepPaper) {
            if (keepPaper.partId === paper.partId) {
              setPaper(keepPaper);
              return;
            }
          } else {
            localStorage.setItem("keepPaper", JSON.stringify(paper));
          }
          setPaper(paper);
        }
      } catch (error) {
        console.error(error);
        setAlertPayload((prev) => ({
          open: true,
          duration: 5000,
          message: JSON.stringify(error),
          type: "error",
        }));
      }
    };
    const partID = params.id;
    getPart(partID).finally(() => {
      setLoading(false);
    });
  }, []);

  const setKeepPaper = (paper) => {
    let newClone = JSON.parse(JSON.stringify(paper));
    const subCategories = newClone.sub_categories;
    newClone.sub_categories.forEach((subCategories) => {
      subCategories.questions.forEach((question) => {
        question.imageURL = [[], [], []];
      });
    });
    localStorage.setItem("keepPaper", JSON.stringify(newClone));
  };
  const handleOnChangeAnswer = (e, sub_index, ques_index, index) => {
    // const handleOnChangeAnswer = (e, subcategoryID, questionID, index) => {

    // try {
    //   const clonePaper = { ...paper };
    //   const cloneSubcategory = clonePaper.sub_categories.find(
    //     (sub_category) => sub_category.subId === subcategoryID
    //   );
    //   const cloneQuestion = cloneSubcategory.questions.find(
    //     (question) => question.questionId === questionID
    //   );
    //   cloneQuestion.answer[index] = e.target.value;
    //   setPaper(clonePaper);
    //   localStorage.setItem("keepPaper", JSON.stringify(clonePaper));
    // } catch (error) {
    //   setAlertPayload((prev) => ({
    //     open: true,
    //     duration: 5000,
    //     message: JSON.stringify(error),
    //     type: "error",
    //   }));
    // }

    try {
      const clonePaper = _.cloneDeep(paper);
      clonePaper.sub_categories[sub_index].questions[ques_index].answer[index] =
        e.target.value;
      clonePaper.sub_categories[sub_index].questions[ques_index].imageURL =
        paper.sub_categories[sub_index].questions[ques_index].imageURL;
      setPaper(clonePaper);
      setKeepPaper(clonePaper, sub_index, ques_index);
    } catch (error) {
      setAlertPayload((prev) => ({
        open: true,
        duration: 5000,
        message: JSON.stringify(error),
        type: "error",
      }));
    }
  };
  
  const handleOnChangeSkipQuestion = (e, sub_index, ques_index) => {
    try {
      const clonesubCategories = _.cloneDeep(subCategories);
      console.log("subCategories 273", e.target.checked, sub_index, ques_index, clonesubCategories);
      clonesubCategories[sub_index].questions[ques_index].skipQuestion = e.target.checked
      setSubCategories(clonesubCategories)
      // return
      // clonePaper.sub_categories[sub_index].questions[ques_index].skipQuestion = e.target.checked;
      // setPaper(clonePaper);
      // setKeepPaper(clonePaper);
    } catch (error) {
      console.error(Error(error).stack)
      setAlertPayload((prev) => ({
        open: true,
        duration: 5000,
        message: JSON.stringify(error),
        type: "error",
      }));
    }
  }

  const handleOnBlurActual = (e, sub_index, ques_index, index) => {
    // const handleOnBlurActual = (e, subcategoryID, questionID, index) => {
    // if (e.target.value === "") {
    //   return;
    // }
    const actual = isNaN(parseFloat(e.target.value)) ? '' : parseFloat(e.target.value);
    const clonePaper = _.cloneDeep(paper);

    // const cloneSubcategory = clonePaper.sub_categories.find(
    //   (subcategory) => subcategory.subId === subcategoryID
    // );
    // const cloneQuestion = cloneSubcategory.questions.find(
    //   (question) => question.questionId === questionID
    // );
    // const subCategoryMaster = subCategories.find(
    //   (subcategory) => subcategory.id === subcategoryID
    // );
    // const questionMaster = subCategoryMaster.questions.find(
    //   (question) => question.id === questionID
    // );
    // const { spec_min, spec_max } = questionMaster;
    // if (!isNaN(actual)) {
    //   clonePaper.sub_categories[sub_index].questions[ques_index].answer[index] =
    //     spec_min <= actual && actual <= spec_max ? "OK" : "NG";
    // } else {
    //   clonePaper.sub_categories[sub_index].questions[ques_index].answer[index] = "";
    // }
    // questionMaster.error = {
    //   ...questionMaster.error,
    //   actual: true,
    // };
    const cloneSubCategories = JSON.parse(JSON.stringify(subCategories));
    const subCategoryMaster = cloneSubCategories[sub_index];
    const questionMaster = subCategoryMaster.questions[ques_index];
    const { spec_min, spec_max } = questionMaster;
    // console.log("354", clonePaper);
    if (!isNaN(actual) && actual !== "") {
      if(spec_min !== null && spec_max !== null){
        console.log("min max", spec_min, spec_max);
        console.log("min max value", actual);
        clonePaper.sub_categories[sub_index].questions[ques_index].answer[index] =
          spec_min <= actual && actual <= spec_max ? "OK" : "NG";
      }else{
        clonePaper.sub_categories[sub_index].questions[ques_index].answer[index] =
          "";
      }
    }else{
      clonePaper.sub_categories[sub_index].questions[ques_index].answer[index] =
        "";
    }

    clonePaper.sub_categories[sub_index].questions[ques_index].actual[index] =
      actual;
    clonePaper.sub_categories[sub_index].questions[ques_index].imageURL =
      paper.sub_categories[sub_index].questions[ques_index].imageURL;
    setPaper(clonePaper);
    // setPaper(clonePaper);
    setKeepPaper(clonePaper, sub_index, ques_index);
    cloneSubCategories[sub_index].questions[ques_index].error = {
      ...questionMaster.error,
      actual: true,
    };
    setSubCategories(cloneSubCategories);
  };
  const handleOnChangeComment = (e, sub_index, ques_index, index) => {
    // const handleOnChangeComment = (e, subcategoryID, questionID, index) => {
    const comment = e.target.value;
    // const clonePaper = { ...paper };
    // const cloneSubcategory = clonePaper.sub_categories.find(
    //   (subcategory) => subcategory.subId === subcategoryID
    // );
    // const cloneQuestion = cloneSubcategory.questions.find(
    //   (question) => question.questionId === questionID
    // );
    // cloneQuestion.comment[index] = comment;
    // setPaper(clonePaper);

    const clonePaper = _.cloneDeep(paper);

    clonePaper.sub_categories[sub_index].questions[ques_index].comment[index] =
      comment;

    setPaper((prev) => {
      return clonePaper;
    });

    setKeepPaper(clonePaper, sub_index, ques_index);
  };
  const handleDeleteQuestion = (sub_index, ques_index) => {
    const clonePaper = _.cloneDeep(paper);

    let index = inputPerQuestion[sub_index][ques_index] - 1;
    clonePaper.sub_categories[sub_index].questions[ques_index].actual[index] =
      "";
    clonePaper.sub_categories[sub_index].questions[ques_index].answer[index] =
      "";
    clonePaper.sub_categories[sub_index].questions[ques_index].comment[index] =
      "";
    clonePaper.sub_categories[sub_index].questions[ques_index].imageURL[index] =
      [];
    setPaper((prev) => {
      return clonePaper;
    });
    setKeepPaper(clonePaper, sub_index, ques_index);
    setInputPerQuestion((prev) => {
      const cloneInputPerQuestion = [...prev];
      cloneInputPerQuestion[sub_index][ques_index] = index;
      return cloneInputPerQuestion;
    });
  };

  const handleCalculate = (
    subcategoryIndex,
    questionIndex,
    index,
    formula,
    min,
    max
  ) => {
    // try {
    //   const clonePaper = { ...paper };
    //   const formulaData = formula.data;
    //   if (typeof formulaData !== "string") {
    //     throw Error("formula should be string type").stack;
    //   }
    //   if (typeof min !== "number" || typeof max !== "number") {
    //     throw Error("min or max should be number type").stack;
    //   }
    //   let parsedFormula = formulaData;
    //   const arrQuestionID = formulaData.match(/{.*?}/g);

    //   if (arrQuestionID !== null) {
    //     arrQuestionID.forEach((questionIDandBacket) => {
    //       const questionIDInFormula = parseInt(
    //         questionIDandBacket.substring(1, questionIDandBacket.length - 1)
    //       );
    //       const questionValue = findValueInPaperByQuestionID(
    //         questionIDInFormula,
    //         index
    //       );
    //       if (questionValue.actual === "") {
    //         const requiredQuestion = searchQuestionByID(
    //           questionIDInFormula,
    //           subCategories
    //         );
    //         throw Error(
    //           `กรุณตอบคำถาม ${requiredQuestion.question} ช่องที่ ${index + 1}`
    //         );
    //       }
    //       parsedFormula = parsedFormula.replace(
    //         questionIDandBacket,
    //         questionValue.actual
    //       );
    //     });
    //   }
    //   parsedFormula = calculateFormula(parsedFormula);
    //   const actual = eval(parsedFormula);
    //   clonePaper.sub_categories[subcategoryIndex].questions[
    //     questionIndex
    //   ].answer[index] = min <= actual && actual <= max ? "OK" : "NG";
    //   clonePaper.sub_categories[subcategoryIndex].questions[
    //     questionIndex
    //   ].actual[index] = actual;
    //   setPaper(clonePaper);
    //   localStorage.setItem("keepPaper", JSON.stringify(clonePaper));
    // } catch (error) {
    //   console.error(error);
    //   setAlertPayload({
    //     duration: 5000,
    //     type: "error",
    //     message: `${error}`,
    //     open: true,
    //   });
    // }
    try {
      const clonePaper = _.cloneDeep(paper);
      const formulaData = formula.data;
      if (typeof formulaData !== "string") {
        throw Error("formula should be string type").stack;
      }
      if (typeof min !== "number" || typeof max !== "number") {
        throw Error("min or max should be number type").stack;
      }
      let parsedFormula = formulaData;
      const arrQuestionID = formulaData.match(/{.*?}/g);

      if (arrQuestionID !== null) {
        arrQuestionID.forEach((questionIDandBacket) => {
          const questionIDInFormula = parseInt(
            questionIDandBacket.substring(1, questionIDandBacket.length - 1)
          );
          const questionValue = findValueInPaperByQuestionID(
            questionIDInFormula,
            index
          );
          if (questionValue.actual === "") {
            const requiredQuestion = searchQuestionByID(
              questionIDInFormula,
              subCategories
            );
            throw Error(
              `กรุณตอบคำถาม ${requiredQuestion.question} ช่องที่ ${index + 1}`
            );
          }
          parsedFormula = parsedFormula.replace(
            questionIDandBacket,
            questionValue.actual
          );
        });
      }
      parsedFormula = calculateFormula(parsedFormula);
      const actual = parseFloat(parseFloat(eval(parsedFormula)).toFixed(2));
      clonePaper.sub_categories[subcategoryIndex].questions[
        questionIndex
      ].answer[index] = min <= actual && actual <= max ? "OK" : "NG";
      clonePaper.sub_categories[subcategoryIndex].questions[
        questionIndex
      ].actual[index] = actual;
      setPaper(clonePaper);
      setKeepPaper(clonePaper, subcategoryIndex, questionIndex);

      // localStorage.setItem("keepPaper", JSON.stringify(clonePaper));
    } catch (error) {
      console.error(error);
      setAlertPayload({
        duration: 5000,
        type: "error",
        message: `${error}`,
        open: true,
      });
    }
  };

  const findValueInPaperByQuestionID = (questionID, index) => {
    if (typeof questionID !== "number") {
      throw Error(
        `questionID should be string type. typeof questionID is '${typeof questionID}'`
      ).stack;
    }
    const clonePaper = { ...paper };
    let questionValue;
    for (let i = 0; i < clonePaper.sub_categories.length; i++) {
      const subcategory = clonePaper.sub_categories[i];
      const foundQuestionValue = subcategory.questions.find(
        (question) => question.questionId === questionID
      );
      if (foundQuestionValue) {
        questionValue = foundQuestionValue;
        break;
      }
    }
    return {
      actual: questionValue.actual[index],
    };
  };

  const inspectorSignature =
    userContext.signature[userContext.signature.length - 1];

  if (!inspectorSignature) {
    Swal.fire({
      title: "ผู้ใช้งานนี้ไม่มีข้อมูลลายเซ็น",
      text: "กรุณาเพิ่มลายเซ็น",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "เพิ่มลายเซ็น",
    }).then((result) => {
      if (result.isConfirmed) {
        navigate(`/layout/profile/${userContext.id}`);
      }
    });
  }

  const createPaper = async () => {

    try {
      if (!userContext) {
        throw Error("userContext is empty").stack;
      }
      if(cavity === undefined || cavity === "none"){
        setAlertPayload({
          duration: 3000,
          type: "error",
          message: "กรุณาเลือก Cavity",
          open: true,
        })
        return
      }
      if (process === undefined || process === "none") {
        setAlertPayload({
          duration: 3000,
          type: "error",
          message: "กรุณาเลือก Process",
          open: true,
        });
        return;
      }
      if (shiftTeam === "none") {
        setAlertPayload({
          duration: 3000,
          type: "error",
          message: "กรุณากรอกข้อมูล Shift",
          open: true,
        });
        return;
      }
      const validate = validatePaper(paper);
      if (validate.isValid) {
        setLoading(true);
        let newPayload = { ...paper };
        for (
          let subcategoryIndex = 0;
          subcategoryIndex < paper.sub_categories.length;
          subcategoryIndex++
        ) {
          const subcategory = paper.sub_categories[subcategoryIndex];
          for (
            let questionIndex = 0;
            questionIndex < subcategory.questions.length;
            questionIndex++
          ) {
            const isSkip = subCategories[subcategoryIndex].questions[questionIndex].skipQuestion
            if(isSkip){
              console.log("626 skip");
              newPayload.sub_categories[subcategoryIndex].questions[questionIndex].skipQuestion = true;
              // return;
              subcategory.questions[questionIndex].imageURL = [[],[],[]];
              subcategory.questions[questionIndex].actual = ['','',''];
              subcategory.questions[questionIndex].actual = ['','',''];
              subcategory.questions[questionIndex].comment = ['','',''];
            }
            console.log("626 out else", subcategory);
            const question = subcategory.questions[questionIndex];
            for (
              let indexImage = 0;
              indexImage < question.imageURL.length;
              indexImage++
            ) {
              const arrImage = question.imageURL[indexImage];
              for (
                let indexPerImage = 0;
                indexPerImage < arrImage.length;
                indexPerImage++
              ) {
                const image = arrImage[indexPerImage];
                const formData = new FormData();
                formData.append("files", image);
                const responseFromAddImage = await APIServices.addUserImage(
                  formData
                );
                newPayload.sub_categories[subcategoryIndex].questions[
                  questionIndex
                ].imageURL[indexImage][indexPerImage] =
                  responseFromAddImage.data[0].url;
              }
            }
          }
        }
        const responseFromCreatePaper = await APIServices.createPaper(
          newPayload,
          newPayload.partId,
          userContext.id,
          shift,
          new Date(),
          dayjs().format("DD/MM/YYYY"),
          dayjs().format("HH:mm:ss"),
          shiftTeam,
          inspectorSignature.url,
          process,
          cavity,
        );
        if (responseFromCreatePaper.status === 200) {
          // - start send NG
          if (NG.length > 0) {
            // TODO: from setting
            await sendNGAlert();
          }
          // - end send NG
          localStorage.removeItem("keepPaper");
          setLoading(false);
          setAlertPayload({
            duration: 3000,
            type: "success",
            message: "เพิ่มข้อมูล paper สำเร็จ",
            open: true,
          });
          setTimeout(() => {
            navigate("/layout/paper");
          }, 2000);
        } else {
          throw Error(responseFromCreatePaper.data).stack;
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const validatePaper = (paper) => {
    const allSubcategoriesError = [];
    const NGQuestions = [];
    console.log("paper 642", paper);
    paper.sub_categories.forEach((subcategory, subcategoryIndex) => {
      // subcategoryLoop
      const arrQuestionError = [];
      subcategory.questions.forEach((question, questionIndex) => {
        // questionLoop
        // console.log(`698: subIndex: ${subcategoryIndex}, questionIndex: ${questionIndex}, subCategories:`, subCategories[subcategoryIndex].questions[questionIndex]);
        const isSkip = subCategories[subcategoryIndex].questions[questionIndex].skipQuestion
        if(isSkip){
          return
        }
        const questionData = findOgQuestion(
          subcategory.subId,
          question.questionId
        );
        if(subcategory.category === "Dimension" && (question.spec_min === null && question.spec_max === null)){
          console.log("question.actual", question.actual);
          const isNotEmpty = question.actual.some(actual => actual !== "")
          console.log("question.actual", isNotEmpty);
          if(!isNotEmpty){
            console.log("question 727", questionData.question);
            arrQuestionError.push(questionData.question)
            return
          }else{
            return
          }
        }
        console.log("questionData 652", questionData, subcategory.subId, question.questionId);

        const answerError = [];
        if (questionData.status === true) {
          question.answer.forEach((answer, answerIndex) => {
            // answerLoop
            if (!answer) {
              answerError.push(answerIndex);
            } else {
              if (answer === "NG") {
                // TODO: check comment and photo
                let NGrequired = false;
                if (question.comment[answerIndex] === "") {
                  NGrequired = true;
                }
                if (question.imageURL[answerIndex].length === 0) {
                  NGrequired = true;
                }
                if (NGrequired) {
                  arrQuestionError.push(questionData.question);
                }
                NGQuestions.push({
                  questionOG: questionData,
                  questionValue: question,
                });
              }
            }
          });

          // TODO: Big change !! Deactive check SC required 3 time. ;( sawanon:20230601
          // if (questionData.sc_status === true) {
          //   if (answerError.length > 0) {
          //     arrQuestionError.push(questionData.question);
          //   }
          // } else {
          if (answerError.length === question.answer.length) {
            console.log("769", questionData.question);
            arrQuestionError.push(questionData.question);
            return;
          }
          // }
          if (
            question.answer.length - answerError.length !==
            inputPerQuestion[subcategoryIndex][questionIndex]
          ) {
            arrQuestionError.push(questionData.question);
          }
        }
      });
      console.log("781 question", arrQuestionError);
      if (arrQuestionError.length > 0) {
        const subcategoryData = findSubcategoryByID(subcategory.subId);
        allSubcategoriesError.push({
          subcategoriseError: subcategoryData.sub_categories,
          questionsError: arrQuestionError,
        });
      }
    });
    console.log("allSubcategoriesError 758", allSubcategoriesError);
    if (allSubcategoriesError.length > 0) {
      setAlertPayload({
        duration: 5000,
        type: "error",
        message: (
          <div>
            <div className={`text-lg`}>
              กรุณากรอกข้อมูลให้ครบ หาก NG กรุณา Comment และเพิ่มรูปภาพ
            </div>
            {allSubcategoriesError.map(
              (subcategoryError, subcategoryErrorindex) => (
                <div key={`errorsubcontainer${subcategoryErrorindex}`}>
                  <div
                    className={`text-lg font-semibold`}
                  >{`${subcategoryError.subcategoriseError}`}</div>
                  {subcategoryError.questionsError.map(
                    (question, questionErrorindex) => (
                      <div key={`alert${questionErrorindex}`}>
                        Check point: {question}
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </div>
        ),
        open: true,
      });
      return {
        isValid: false,
        checkData: undefined,
      };
    } else {
      return {
        isValid: true,
        checkData: {
          NGQuestions,
        },
      };
    }
  };

  const findSubcategoryByID = (subcategoryID) => {
    return subCategories.find(
      (subcategory) => subcategory.id === subcategoryID
    );
  };

  const findOgQuestion = (subcategoryID, questionID) => {
    const subcategory = subCategories.find(
      (subcategory) => subcategory.id === subcategoryID
    );
    const question = subcategory.questions.find(
      (question) => question.id === questionID
    );
    return question;
  };

  const handleOnClickPhotoIcon = (subcategoryID, questionID, indexAnswer) => {
    setIsOpenAddPhoto({
      open: true,
      subcategoryID: subcategoryID,
      questionID: questionID,
      indexAnswer: indexAnswer,
    });
  };
  const checkDisableInput = (
    paper,
    index,
    question,
    subCategoryIndex,
    questionIndex,
    isActualCommentAndPictrue
  ) => {
    // console.log("subCategories 807", subCategories);
    if(subCategories.length > 0){
      // console.log("subCategories[subCategoryIndex] 807", subCategories[subCategoryIndex]);
      if(subCategories[subCategoryIndex].questions[questionIndex].skipQuestion){
        return true
      }
    }
    if (question.spec_min !== null && question.spec_max !== null) {
      if (isActualCommentAndPictrue) {
        if (paper) {
          // if(question.formula !== null){
          //   const currentValue = paper.sub_categories[subCategoryIndex].questions[questionIndex].answer[index]
          //   currentValue
          //   return true
          // }
          if (index !== 0) {
            const currentValue =
              paper.sub_categories[subCategoryIndex].questions[questionIndex]
                .answer[index - 1];
            if (!currentValue || currentValue === "") {
              return true;
            }
          }
        }
        return false;
      }
      return true;
    } else {
      // paper.sub_categories[subCategoryIndex].questions[questionIndex].answer[index]
      if (paper) {
        if (index !== 0) {
          const currentValue =
            paper.sub_categories[subCategoryIndex].questions[questionIndex]
              .answer[index - 1];
          if (!currentValue || currentValue === "") {
            return true;
          }
        }
      }
      return false;
    }
  };

  const checkNG = (paper, subCategoryIndex, questionIndex, index) => {
    if (paper) {
      const currentAnswer =
        paper.sub_categories[subCategoryIndex].questions[questionIndex].answer[
          index
        ];
      if (currentAnswer === "NG") {
        return true;
      }
    }
  };

  const handleChangeShiftAB = (event) => {
    const eventUpperCase = event.target.value.toUpperCase();
    setShiftTeam(eventUpperCase);
  };

  const handleChangeProcess = (event) => {
    // id หรือ name thanaporn - sawanon:20230530
    setProcess(event.target.value); //id
  };

  const handleChangeCavity = (e) => {
    setCavity(e.target.value)
  }

  const confirmPaper = (paper) => {
    let isNG = false;
    let countNG = 0;
    const subcategoriesNG = [];
    // วน subcategories - sawanon:20230530
    paper.sub_categories.forEach((sub_category) => {
      const questionsNG = [];

      sub_category.questions.forEach((question) => {
        // ถ้า findNG มีข้อมูล แสดงว่ามี NG ในสักรอบ ["NG", "OK", "OK"] - sawanon:20230530
        const findNG = question.answer.find((answer) => answer === "NG");
        if (findNG !== undefined) {
          isNG = true; //ทำให้รู้ว่ามี NG
          countNG++; //นับจุดที่มี NG
          questionsNG.push({
            questionName: question.questionName,
          });
        }
      });
      // ถ้าในรอบของ question มี NG ถึงจะ push subcategory เข้าไปใน array - sawanon: 20230530
      if (questionsNG.length > 0) {
        subcategoriesNG.push({
          subcategoryName: sub_category.sub_category,
          questions: questionsNG,
        });
      }
    });
    let contents = "คุณยืนยันจะบันทึกข้อมูล?";
    if (isNG) {
      setNG(subcategoriesNG);
      contents = (
        <div className={`space-y-2`}>
          คำเตือน: มีคำตอบ{" "}
          <span className={`underline text-red-800`}>
            NG {`${countNG} จุด`}
          </span>{" "}
          คุณยืนยันการบันทึกข้อมูล?
          <div className={`divide-y`}>
            {subcategoriesNG.map((subcategoryNG, indexSubcategoryNG) => (
              <div key={`indexNG${indexSubcategoryNG}`} className={``}>
                <div>หัวข้อ: {`${subcategoryNG.subcategoryName}`}</div>
                {subcategoryNG.questions.map((questionNG, indexQuestionNG) => (
                  <div key={`indexQuestionNG${indexQuestionNG}`}>
                    <span className={`text-gray-500 text-sm`}>
                      - Point check:
                    </span>{" "}
                    {`${questionNG.questionName}`}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      );
    }
    setConfirmDialog((prev) => ({
      ...prev,
      isOpen: true,
      content: contents,
    }));
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialog((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setNG([]);
  };

  const sendNGAlert = async () => {
    const responseFromSendEmailNG = await APIServices.sendEmailNG(NG);
  };

  // const [check, setCheck] = useState(`2`)
  return (
    <div className={`flex flex-col absolute inset-0`}>
      <SimpleBackdrop open={loading} setOpen={setLoading} />
      {isOpenAddPhoto.open && (
        <FormAddImageAnswerV2
          paper={paper}
          setPaper={setPaper}
          open={isOpenAddPhoto.open}
          onClose={() =>
            setIsOpenAddPhoto((prev) => ({ ...prev, open: false }))
          }
          data={isOpenAddPhoto}
        />
      )}
      {isOpenPartPhoto && (
        <BootstrapDialog
          open={isOpenPartPhoto}
          onClose={() => setIsOpenPartPhoto(false)}
        >
          <img src={`${config.server}${part.image.attributes.url}`} />
        </BootstrapDialog>
      )}
      <Snackbar
        open={alertPayload.open}
        autoHideDuration={alertPayload.duration}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={() => setAlertPayload((prev) => ({ ...prev, open: false }))}
      >
        <Alert
          severity={alertPayload.type}
          onClose={() => setAlertPayload((prev) => ({ ...prev, open: false }))}
        >
          {alertPayload.message}
        </Alert>
      </Snackbar>
      {part && (
        <div className={`mb-2`}>
          <Typography variant="h4">{part.part_name}</Typography>
          <Typography variant="h6">{part.part_code}</Typography>
        </div>
      )}
      <div className={`flex items-center flex-shrink-0`}>
        <div>
          <Stack direction="row" spacing={1}>
            {/* <Chip label={`ครั้งที่: ${count}`} /> */}
            <Chip label={`วันที่: ${date}`} />
            <Chip label={`ช่วงเวลา: ${shift}`} />
          </Stack>
        </div>
        <div className={`ml-4`}>
          <FormControl
            size={`small`}
            sx={{
              minWidth: 120,
              m: 1,
            }}
          >
            <InputLabel id="shiftlabel">
              Shift
            </InputLabel>
            <Select
              labelId="shiftlabel"
              label="Process"
              id="shiftSelect"
              defaultValue={`none`}
              renderValue={(e) => {
                if (e === "none")
                  return <span className={`text-gray-400`}>{`${e}`}</span>;
                return e;
              }}
              onChange={handleChangeShiftAB}
            >
              <MenuItem value={`none`} >none</MenuItem>
              <MenuItem value={`A`} >A</MenuItem>
              <MenuItem value={`B`} >B</MenuItem>
            </Select>
          </FormControl>
          {/* <TextField
            label="Shift"
            size="small"
            value={shiftTeam}
            onChange={(e) => handleChangeShiftAB(e)}
            inputProps={{ maxLength: 1 }}
          /> */}
        </div>
        <div className={`ml-4`}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-helper-label">
              Process
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              // defaultValue={process}
              value={process}
              label="Process"
              onChange={(e) => handleChangeProcess(e)}
              renderValue={(e) => {
                if (e === "none")
                  return <span className={`text-gray-400`}>{`${e}`}</span>;
                return e;
              }}
            >
              <MenuItem value={"none"}>none</MenuItem>
              {processStatus.map((data, index) => (
                <MenuItem key={index} value={data.name}>
                  {data.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className={`ml-4`}>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-helper-label">
              Cavity
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={cavity}
              label="Cavity"
              onChange={handleChangeCavity}
              renderValue={(e) => {
                if (e === "none")
                  return <span className={`text-gray-400`}>{`${e}`}</span>;
                return e;
              }}
            >
              <MenuItem value={"none"}>none</MenuItem>
              <MenuItem value={1}>1</MenuItem>
              <MenuItem value={2}>2</MenuItem>
              <MenuItem value={3}>3</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>
      {/* TODO: */}
      <div className={`flex flex-col lg:flex-row relative gap-4 mt-4 lg:overflow-y-visible overflow-y-auto`}>
        <div className={` lg:relative`}>
          <div
            className={`flex justify-center items-center relative lg:sticky top-0 rounded-md drop-shadow-md`}
          >
            {part && part.image ? (
              <div
                className={`w-full flex justify-center`}
              >
                <div
                  className={`lg:hidden absolute right-0 top-0 m-2 z-10 bg-black bg-opacity-40 p-2 rounded-md`}
                  onClick={() => setIsFullImg(prev => !prev)}
                >
                  {isFullImg ? <CloseFullscreenIcon sx={{color: `#fff`}} /> : <OpenInFullIcon sx={{color: `#fff`}} />}
                </div>
                <img
                  className={`object-contain cursor-pointer lg:h-[300px]`}
                  style={{
                    height: isFullImg ? '300px' : '100px',
                  }}
                  src={`${config.server}${part.image.attributes.url}`}
                  onClick={() => setIsOpenPartPhoto(true)}
                />
              </div>
            ) : (
              <div
                style={{ width: "12rem", height: "12rem" }}
                className="bg-gray-100 flex items-center justify-center rounded-lg"
              >
                <NoPhotography sx={{ fontSize: 80 }} />
              </div>
            )}
          </div>
        </div>
        <div
          className={`lg:h-full overflow-auto lg:overflow-visible lg:relative rounded-md space-y-4`}
        >
          {subCategories.length === 0 ? (
            <div className="p-4 mb-4 mt-4 flex justify-center w-full">
              <span>ไม่มีข้อมูล</span>
            </div>
          ) : (
            subCategories
              .filter((sc) => sc.status === true)
              .map(
                (subCategory, subCategoryIndex) =>
                  subCategory.questions.length !== 0 && (
                    <div
                      className={`bg-white drop-shadow p-4 rounded-md`}
                      key={`subCategory${subCategoryIndex}`}
                    >
                      <div className="flex flex-row pb-4">
                        <div className="flex items-center rounded-full text-xl">
                          <Chip
                            label={
                              subCategory.category.data.attributes.category
                            }
                            variant="outlined"
                          />
                        </div>
                        <div className="flex items-center text-xl font-semibold ml-4">
                          {subCategory.sub_categories}
                        </div>
                      </div>
                      <hr />
                      <div>
                        {
                          subCategory.questions
                          // sortData(subCategory.questions, "question")
                            // .filter((qt) => qt.status === true)
                            .map((question, questionIndex) => (
                              <div key={`question${questionIndex}`}>
                                <div className={`flex justify-between mt-4`}>
                                  <div
                                    className={`text-lg flex flex-row items-center`}
                                  >
                                    {question.question}
                                    {question.spec_min !== null &&
                                      question.spec_max !== null && (
                                        <div className={`flex gap-2 ml-4`}>
                                          <span>
                                            {parseFloat(
                                              question.spec_min
                                            ).toFixed(2)}
                                          </span>
                                          <span>~</span>
                                          <span>
                                            {parseFloat(
                                              question.spec_max
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                      )}
                                    {question.sc_status === true && (
                                      <div className="ml-2">
                                        <Chip
                                          label="SC"
                                          color="primary"
                                          variant="outlined"
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div>
                                    <FormControlLabel
                                      label={`Skip question?`}
                                      control={
                                        <Checkbox
                                          size="medium"
                                          sx={{ '& .MuiSvgIcon-root': { fontSize: 28 } }}
                                          checkedIcon={<NextPlanIcon />}
                                          checked={question.skipQuestion}
                                        />
                                      }
                                      onChange={(e) => {
                                        console.log("event", e.target.checked);
                                        handleOnChangeSkipQuestion(e, subCategoryIndex, questionIndex)
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className={`mt-4`}>
                                  {/* TODO: add row */}
                                  {Array(
                                    inputPerQuestion[subCategoryIndex][
                                      questionIndex
                                    ]
                                      ? inputPerQuestion[subCategoryIndex][
                                          questionIndex
                                        ]
                                      : 0
                                  )
                                    .fill(0)
                                    .map((empty, index) => (
                                      <div
                                        key={`answer${index}`}
                                        className={`flex mt-4`}
                                      >
                                        {subCategory.category.data.attributes.category === "Dimension" && (question.spec_min === null && question.spec_max === null)
                                        ?
                                           <div className={`w-[141px]`}></div>
                                        :
                                          <RadioGroup
                                            row
                                            name="OKNG-radio-group"
                                            value={
                                              paper
                                                ? paper.sub_categories[
                                                    subCategoryIndex
                                                  ].questions[questionIndex]
                                                    .answer[index]
                                                : ""
                                            }
                                            onChange={(e) =>
                                              // handleOnChangeAnswer(
                                              //   e,
                                              //   subCategory.id,
                                              //   question.id,
                                              //   index
                                              // )
                                              handleOnChangeAnswer(
                                                e,
                                                subCategoryIndex,
                                                questionIndex,
                                                index
                                              )
                                            }
                                          >
                                            <FormControlLabel
                                              value={`OK`}
                                              control={
                                                <Radio
                                                  // disabled={
                                                  //   question.spec_min && question.spec_max
                                                  //     ? true
                                                  //     : false
                                                  // }
                                                  disabled={checkDisableInput(
                                                    paper,
                                                    index,
                                                    question,
                                                    subCategoryIndex,
                                                    questionIndex
                                                  )}
                                                />
                                              }
                                              label={`OK`}
                                            />
                                            <FormControlLabel
                                              value={`NG`}
                                              label={`NG`}
                                              control={
                                                <Radio
                                                  disabled={checkDisableInput(
                                                    paper,
                                                    index,
                                                    question,
                                                    subCategoryIndex,
                                                    questionIndex
                                                  )}
                                                />
                                              }
                                            />
                                            <div className={``}></div>
                                          </RadioGroup>
                                        }
                                        <div
                                          className={`flex gap-4 items-center`}
                                        >
                                          {/* {question.spec_min !== null &&
                                            question.spec_max !== null && */}
                                          {((question.spec_min !== null && question.spec_max !== null) || subCategory.category.data.attributes.category === "Dimension") &&
                                            (question.formula &&
                                            question.formula.data ? (
                                              <div className="flex">
                                                <div
                                                  className={`cursor-default border border-gray-500 rounded h-10 w-12 px-2 flex items-center justify-center`}
                                                >
                                                  {paper
                                                    ? paper.sub_categories[
                                                        subCategoryIndex
                                                      ].questions[questionIndex]
                                                        .actual[index] &&
                                                      paper.sub_categories[
                                                        subCategoryIndex
                                                      ].questions[
                                                        questionIndex
                                                      ].actual[index].toFixed(1)
                                                    : ""}
                                                </div>
                                                <div
                                                  className={`cursor-pointer ml-4 bg-blue-500 hover:bg-blue-600 h-10 w-24 rounded flex justify-center items-center text-white`}
                                                  onClick={() =>
                                                    handleCalculate(
                                                      subCategoryIndex,
                                                      questionIndex,
                                                      index,
                                                      question.formula,
                                                      question.spec_min,
                                                      question.spec_max
                                                    )
                                                  }
                                                >
                                                  คำนวณ
                                                </div>
                                              </div>
                                            ) : (
                                              <>
                                                <TextField
                                                  disabled={question.skipQuestion}
                                                  id="actual"
                                                  label="Actual"
                                                  type={`number`}
                                                  size={`small`}
                                                  defaultValue={
                                                    paper
                                                      ? paper.sub_categories[
                                                          subCategoryIndex
                                                        ].questions[
                                                          questionIndex
                                                        ].actual[index]
                                                      : ""
                                                  }
                                                  onBlur={
                                                    (e) =>
                                                      handleOnBlurActual(
                                                        e,
                                                        subCategoryIndex,
                                                        questionIndex,
                                                        index
                                                      )
                                                    // handleOnBlurActual(
                                                    //   e,
                                                    //   subCategory.id,
                                                    //   question.id,
                                                    //   index
                                                    // )
                                                  }
                                                  onWheel={(event) =>
                                                    event.target.blur()
                                                  }
                                                  // disabled={
                                                  //   // question.formula !== null ||
                                                  //   question.formula.data !== "" ||
                                                  //   checkDisableInput(
                                                  //     paper,
                                                  //     index,
                                                  //     question,
                                                  //     subCategoryIndex,
                                                  //     questionIndex,
                                                  //     true
                                                  //   )
                                                  // }
                                                />
                                              </>
                                            ))}
                                          <TextField
                                            id="comment"
                                            label="Comment"
                                            type={`text`}
                                            size={`small`}
                                            defaultValue={
                                              paper
                                                ? paper.sub_categories[
                                                    subCategoryIndex
                                                  ].questions[questionIndex]
                                                    .comment[index]
                                                : ""
                                            }
                                            onChange={
                                              (e) =>
                                                handleOnChangeComment(
                                                  e,
                                                  subCategoryIndex,
                                                  questionIndex,
                                                  index
                                                )
                                              // handleOnChangeComment(
                                              //   e,
                                              //   subCategory.id,
                                              //   question.id,
                                              //   index
                                              // )
                                            }
                                            error={question.error.comment}
                                            disabled={checkDisableInput(
                                              paper,
                                              index,
                                              question,
                                              subCategoryIndex,
                                              questionIndex,
                                              true
                                            )}
                                          />
                                          <Fab
                                            // disabled
                                            color="primary"
                                            variant="contained"
                                            component="label"
                                            onClick={(e) =>
                                              handleOnClickPhotoIcon(
                                                subCategory.id,
                                                question.id,
                                                index
                                              )
                                            }
                                            disabled={checkDisableInput(
                                              paper,
                                              index,
                                              question,
                                              subCategoryIndex,
                                              questionIndex,
                                              true
                                            )}
                                            // onClick={() =>
                                            //   handleClickOpen(
                                            //     sub_index,
                                            //     question_index,
                                            //     data.id,
                                            //     qt.id,
                                            //     index,
                                            //     isSC
                                            //   )
                                            // }
                                          >
                                            {paper &&
                                            paper.sub_categories[
                                              subCategoryIndex
                                            ].questions[questionIndex].imageURL[
                                              index
                                            ].length > 0 ? (
                                              `${paper.sub_categories[subCategoryIndex].questions[questionIndex].imageURL[index].length} รูป`
                                            ) : (
                                              <AddPhotoAlternateIcon />
                                            )}
                                          </Fab>
                                          <IconCheckNG
                                            show={
                                              paper
                                                ? paper.sub_categories[
                                                    subCategoryIndex
                                                  ].questions[questionIndex]
                                                    .answer[index] !== ""
                                                : false
                                            }
                                            isNG={checkNG(
                                              paper,
                                              subCategoryIndex,
                                              questionIndex,
                                              index
                                            )}
                                          />
                                        </div>
                                      </div>
                                    ))}
                                </div>
                                <div className={`space-x-2`}>
                                  <Fab
                                    size="medium"
                                    color="success"
                                    disabled={
                                      question.skipQuestion
                                      ||
                                      inputPerQuestion[subCategoryIndex][
                                        questionIndex
                                      ] >= 3
                                    }
                                    onClick={() => {
                                      setInputPerQuestion((prev) => {
                                        const cloneInputPerQuestion = [...prev];
                                        cloneInputPerQuestion[subCategoryIndex][
                                          questionIndex
                                        ]++;
                                        return cloneInputPerQuestion;
                                      });
                                    }}
                                  >
                                    <Add />
                                  </Fab>
                                  <Fab
                                    size="medium"
                                    color="error"
                                    disabled={
                                      question.skipQuestion
                                      ||
                                      inputPerQuestion[subCategoryIndex][
                                        questionIndex
                                      ] === 1
                                    }
                                    onClick={() => {
                                      handleDeleteQuestion(
                                        subCategoryIndex,
                                        questionIndex
                                      );
                                    }}
                                  >
                                    <Remove />
                                  </Fab>
                                </div>
                              </div>
                            ))
                        }
                      </div>
                    </div>
                  )
              )
          )}
        </div>
      </div>
      {/* TODO: */}
      <div className={`flex justify-end flex-shrink-0 pb-2`}>
        <Button
          variant="contained"
          size="large"
          onClick={() => {
            confirmPaper(paper);
            // createPaper(paper)
          }}
        >
          บันทึกข้อมูล
        </Button>
      </div>
      <Dialog
        open={confirmDialog.isOpen}
        onClose={handleCloseConfirmDialog}
        fullWidth
        maxWidth={`sm`}
      >
        <FormControl
          onSubmit={() => {
            console.log("submit");
          }}
        >
          <DialogTitle>ยืนยันการบันทึกข้อมูล</DialogTitle>
          <DialogContent dividers>{confirmDialog.content}</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={handleCloseConfirmDialog}>
              ปิด
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                createPaper(paper);
                handleCloseConfirmDialog();
              }}
            >
              ยืนยัน
            </Button>
          </DialogActions>
        </FormControl>
      </Dialog>
    </div>
  );
}

export default PaperV2;
