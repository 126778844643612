import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { APIServices } from "./../../helpers/APIServices";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import config from "../../config";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import SimpleBackdrop from "../../Components/Backdrop";
import Switch from "@mui/material/Switch";
import Checkbox from "@mui/material/Checkbox";
import { UserContext } from "../../Layout";
import { changeIDFormula } from "./FormUpdateQuestion";
import { useNavigate } from "react-router-dom";
import { AlertTitle } from "@mui/material";
import { parseStatus } from "../../helpers/common";

const labelCheckbox = { inputProps: { "aria-label": "Checkbox demo" } };

const label = { inputProps: { "aria-label": "Switch demo" } };

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const FormUpdatePart = ({ state, setSuccess }) => {
  const [value, setValue] = [...state];
  const [imgPart, setImgPart] = useState({
    base: null,
    file: null,
  });
  const user = useContext(UserContext);
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    massage: "",
  });
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [revise, setRevise] = useState(false);
  const [revisionRecord, setRevisionRecord] = useState("");
  const handleClose = () => {
    setValue({ open: false, part: null });
    setImgPart({
      base: null,
      file: null,
    });
    setRevise(false);
  };

  const handleCloseAlert = () => {
    setAlert((data) => ({ ...data, open: false }));
  };

  useEffect(() => {
    const getPart = async () => {
      // console.log("value 125", value);
      // return
      const response = await APIServices.getPartByID(value.part.id);
      const _part = {
        id: response.data.data.id,
        ...response.data.data.attributes,
      };
      setValue((prev) => ({
        ...prev,
        part: _part,
      }));
    };
    getPart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitEdit = async (image, part, id) => {
    if (part === null) {
      setAlert({
        open: true,
        type: "error",
        massage: "กรอกข้อมูลไม่ครบถ้วน",
      });
      return;
    }
    const signature =
      user.signature !== null
        ? user.signature[user.signature.length - 1].url
        : "";
    const partName = part.part_name;
    const partCode = part.part_code;
    const status = part.status;
    const revise_time = part.revise_time !== null ? part.revise_time : null;
    const _revisionRecord = revise
      ? revisionRecord
      : part.revise.data.attributes.revisionRecord;
    const reviseID = part.revise.data.id;
    // console.log("part on sumbit ", part);
    // console.log("revisionRecord ", _revisionRecord);
    // return;
    if (revise === false) {
      if (partName === "" || partCode === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "กรอกข้อมูลไม่ครบถ้วน",
        });
      } else {
        setLoading(true);
        await APIServices.updatePart(partName, partCode, id, status);
        await APIServices.updateRevisionRecord(reviseID, _revisionRecord);
        if (image !== null) {
          if (value.part.image.data !== null) {
            await APIServices.removeUserOldImage(value.part.image.data.id);
            const formData = new FormData();
            formData.append("ref", "api::qc-part.qc-part");
            formData.append("refId", value.part.id);
            formData.append("field", "image");
            formData.append("files", image[0]);
            await APIServices.addUserImage(formData);
          } else {
            const formData = new FormData();
            formData.append("ref", "api::qc-part.qc-part");
            formData.append("refId", value.part.id);
            formData.append("field", "image");
            formData.append("files", image[0]);
            await APIServices.addUserImage(formData);
          }
        }
        setTimeout(() => {
          setLoading(false);
          setValue({ open: false, part: null });
          setRevise(false);
          setImgPart({
            base: null,
            file: null,
          });
          setSuccess(true);
        }, 1500);
      }
    } else {
      if (partName === "" || partCode === "" || _revisionRecord === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "กรอกข้อมูลไม่ครบถ้วน",
        });
      } else {
        setLoading(true);
        let partRevise;
        let keepNewData = [];
        let dataQuestionFormula = [];
        // console.log("sub categories 227: ", _part);
        // return
        if (part.old_parts?.data.length === 0) {
          const responseReviseForPart = await APIServices.createPartForRevise(
            partName,
            partCode,
            revise_time,
            id,
            false,
            true
          );
          partRevise = responseReviseForPart;
        } else {
          let oldPartID = [];
          part.old_parts.data.forEach((oldPart) => {
            oldPartID.push(oldPart.id);
          });
          oldPartID.push(id);
          const responseReviseForPart = await APIServices.createPartForRevise(
            partName,
            partCode,
            revise_time,
            oldPartID,
            false,
            true
          );
          partRevise = responseReviseForPart;
        }

        await APIServices.updatePart(partName, partCode, id, false, false);

        const part_data = partRevise.data.data.attributes;
        const part_id = partRevise.data.data.id;
        if (part.sub_categories.data.length !== 0) {
          for (
            let indexSubCat = 0;
            indexSubCat < part.sub_categories.data.length;
            indexSubCat++
          ) {
            const subCat = part.sub_categories.data[indexSubCat];
            const categoryTD = subCat.attributes.category.data.id;
            const subCatName = subCat.attributes.sub_categories;
            const responseSubCat = await APIServices.createSubCategories(
              categoryTD,
              subCatName,
              part_id,
              true
            );
            const realSubCatID = responseSubCat.data.data.id;

            if (subCat.attributes.questions.data.length !== 0) {
              for (
                let indexQuestion = 0;
                indexQuestion < subCat.attributes.questions.data.length;
                indexQuestion++
              ) {
                const realQuestion =
                  subCat.attributes.questions.data[indexQuestion];

                const specMin = realQuestion.attributes.spec_min;
                const specMax = realQuestion.attributes.spec_max;
                const sc_status = realQuestion.attributes.sc_status;
                const question = realQuestion.attributes.question;
                const UCL_xBar = realQuestion.attributes.UCL_Xbar;
                const CL_xBar = realQuestion.attributes.CL_Xbar;
                const LCL_xBar = realQuestion.attributes.LCL_Xbar;
                const UCL_rChart = realQuestion.attributes.UCL_Rchart;
                const CL_rChart = realQuestion.attributes.CL_Rchart;
                const LCL_rChart = realQuestion.attributes.LCL_Rchart;
                const subCategory = realSubCatID;
                const status = realQuestion.attributes.status;
                const formula = realQuestion.attributes.formula
                  ? realQuestion.attributes.formula.data
                  : null;

                const responseQuestion = await APIServices.createQuestion(
                  specMin,
                  specMax,
                  sc_status,
                  question,
                  UCL_xBar,
                  CL_xBar,
                  LCL_xBar,
                  UCL_rChart,
                  CL_rChart,
                  LCL_rChart,
                  subCategory,
                  status,
                  formula
                );
                if (responseQuestion.data.data.attributes.formula.data) {
                  keepNewData.push({
                    id: responseQuestion.data.data.id,
                    formula: responseQuestion.data.data.attributes.formula,
                  });
                }
                dataQuestionFormula.push({
                  old_id: realQuestion.id,
                  new_id: responseQuestion.data.data.id,
                });
              }
            }
          }
        }
        if (partRevise.data.data !== null) {
          if (image === null) {
            if (part.image.data !== null) {
              const files = await fetch(
                // "http://agrappl03.aapico.com:1339/uploads/9_FC_8_B6_E7_F948_451_A_8_CB_6_4_A821_B407339_a1915890e4.jpeg"
                `${part.image.data.attributes.url}`
                // config.server +
              )
                .then((response) => response.blob())
                .then((blob) => {
                  const myFile = new File(
                    [blob],
                    `${part.image.data.attributes.name}`,
                    {
                      type: blob.type,
                    }
                  );
                  return myFile;
                });

              const formData = new FormData();
              formData.append("ref", "api::qc-part.qc-part");
              formData.append("refId", part_id);
              formData.append("field", "image");
              formData.append("files", files);
              await APIServices.addUserImage(formData);
            }
          } else {
            const formData = new FormData();
            formData.append("ref", "api::qc-part.qc-part");
            formData.append("refId", part_id);
            formData.append("field", "image");
            formData.append("files", image[0]);
            await APIServices.addUserImage(formData);
          }
          if (partRevise.data.data.id !== null) {
            await APIServices.reviseForUpdate(
              _revisionRecord,
              new Date(),
              signature,
              user.id,
              part_data.revise_time,
              part_id
            );
          }
          changeIDFormula(keepNewData, dataQuestionFormula);
        }
        setTimeout(() => {
          setRevisionRecord("");
          setLoading(false);
          setValue({ open: false, part: null });
          setRevise(false);
          setImgPart({
            base: null,
            file: null,
          });
          navigate("/layout/revise");
          setSuccess(true);
        }, 1500);
      }
    }
  };

  const handleChangeFile = async (e) => {
    const files = Array.from(e.target.files);
    const base64Img = await getBase64(files[0]);
    setImgPart({
      base: base64Img,
      file: files,
    });
  };

  const checkReviseStatus = () => {
    if (value.part) {
      // if (value.part.revise.data) {
      if (value.part.revise.data.attributes.revise_status === "4") {
        return true;
      } else {
        return false;
      }
    }
    // }
    return false;
  };

  const checkRole = () => {
    if (
      user.qc_role === "super_admin" ||
      user.qc_role === "admin" ||
      user.qc_role === "release"
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={value.open}>
        <SimpleBackdrop open={loading} setOpen={setLoading} />
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}>
          <span>แบบฟอร์มแก้ไขข้อมูล Part</span>
        </BootstrapDialogTitle>
        <div>
          <Snackbar
            open={alert.open}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleCloseAlert}>
            <Alert
              onClose={handleCloseAlert}
              severity={alert.type}
              sx={{ width: "100%" }}>
              {alert.massage}
            </Alert>
          </Snackbar>
        </div>
        <DialogContent dividers>
          <Box
            component='form'
            sx={{
              "& .MuiTextField-root": {
                maxWidth: "100%",
                mt: 2,
              },
            }}
            noValidate
            autoComplete='off'>
            <div className='flex flex-col first-letter '>
              {value.part &&
                value.part.revise.data &&
                value.part.revise.data.attributes.remark &&
                value.part.revise.data.attributes.remark.map(
                  (remark, remarkIndex) => {
                    // statusRevise
                    return (
                      <div key={remarkIndex} className='mb-2'>
                        {remark.status &&
                          (remark.status === "2" || remark.status === "4" ? (
                            <Alert severity='success'>
                              <AlertTitle>
                                {parseStatus(remark.status)}
                              </AlertTitle>
                              <AlertTitle>{remark.remark}</AlertTitle>
                              {remark.userFirstName +
                                " " +
                                remark.userLastName}{" "}
                              — <strong>{remark.role}</strong>
                            </Alert>
                          ) : (
                            <Alert severity='error'>
                              <AlertTitle>
                                {parseStatus(remark.status)}
                              </AlertTitle>
                              <AlertTitle>{remark.remark}</AlertTitle>
                              {remark.userFirstName +
                                " " +
                                remark.userLastName}{" "}
                              — <strong>{remark.role}</strong>
                            </Alert>
                          ))}
                      </div>
                    );
                  }
                )}
              {/* <div className="flex items-center ">
                <div>เพิ่มข้อมูลสำเร็จ :</div>
                <Checkbox
                  // disabled={checkReviseStatus}
                  {...labelCheckbox}
                  onChange={(e) => setPartSuccess(e.target.checked)}
                />
              </div> */}{" "}
              {/* <div className="flex items-center">
                <Checkbox
                  // disabled={checkReviseStatus}
                  {...labelCheckbox}
                  onChange={(e) => setPartSuccess(e.target.checked)}
                />
                <div >แก้ไขข้อมูลตามหมายเหตุสำเร็จ</div>
              </div> */}
              <div className='flex flex-col mb-4'>
                {/* {value.part !== null && value.part.revise_time && ( */}
                <div className='flex flex-row '>
                  <div className='flex flex-row mr-4 items-center'>
                    <div>Current REV : </div>
                    {value.part !== null &&
                    value.part.revise_time !== null &&
                    value.part.revise.data.attributes.revise_status === "4" ? (
                      <span className='font-bold ml-2'>
                        {value.part && value.part.revise_time !== null
                          ? value.part.revise_time
                          : "-"}
                      </span>
                    ) : (
                      <span className='font-bold ml-2'>
                        {value.part &&
                          value.part.revise_time !== null &&
                          (value.part.revise_time === 0
                            ? "-"
                            : value.part.revise_time - 1)}
                      </span>
                    )}
                    {revise === false && (
                      <div className=' flex flex-row ml-4 items-center'>
                        <TextField
                          sx={{ width: "100%" }}
                          label='Revision Record'
                          value={
                            value.part !== null
                              ? value.part.revise.data.attributes.revisionRecord
                              : ""
                          }
                          onChange={(e) => {
                            setValue((prev) => {
                              return {
                                ...prev,
                                part: {
                                  ...prev.part,
                                  revise: {
                                    ...prev.part.revise,
                                    data: {
                                      ...prev.part.revise.data,
                                      attributes: {
                                        ...prev.part.revise.data.attributes,
                                        revisionRecord: e.target.value,
                                      },
                                    },
                                  },
                                },
                              };
                            });
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
                {checkRole() && checkReviseStatus() && (
                  <div className='flex flex-row '>
                    {/* <div className="flex items-center mr-4">
                      <div>Revise :</div>
                      <Checkbox
                        // disabled={checkReviseStatus}
                        {...labelCheckbox}
                        onChange={(e) => setRevise(e.target.checked)}
                      />
                    </div> */}
                    {revise === true && (
                      <div className='flex items-center '>
                        <TextField
                          value={revisionRecord}
                          label='New Revision Record'
                          onChange={(e) => {
                            setRevisionRecord(e.target.value);
                            // console.log("value 588", value);
                            // setValue(prev => ({
                            //   ...prev,
                            //   part: {
                            //     ...prev.part,
                            //     revise: {
                            //       ...prev.part.revise,
                            //       data: {
                            //         ...prev.part.revise.data,
                            //         attributes: {
                            //           ...prev.part.revise.data.attributes,
                            //           revisionRecord: e.target.value,
                            //         }
                            //       }
                            //     }
                            //   }
                            // }))
                          }}
                        />
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className='flex justify-center w-full '>
                <div className=' flex items-center mr-2 '>
                  <span>Active</span>
                  <Switch
                    checked={
                      value.part !== null && value.part.status === true
                        ? true
                        : false
                    }
                    onChange={(e) =>
                      setValue((prev) => {
                        return {
                          ...prev,
                          part: {
                            ...prev.part,
                            status: e.target.checked,
                          },
                        };
                      })
                    }
                    {...label}
                  />
                </div>
                <div className=' flex items-center mr-2'>
                  <TextField
                    value={value.part !== null ? value.part.part_name : ""}
                    label='Part Name'
                    onChange={(e) => {
                      setValue((prev) => {
                        return {
                          ...prev,
                          part: {
                            ...prev.part,
                            part_name: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </div>
                <div className='flex items-center mr-2'>
                  <TextField
                    value={value.part !== null ? value.part.part_code : ""}
                    label='Part Code'
                    onChange={(e) => {
                      setValue((prev) => {
                        return {
                          ...prev,
                          part: {
                            ...prev.part,
                            part_code: e.target.value,
                          },
                        };
                      });
                    }}
                  />
                </div>
              </div>
              <div className='col-span-4 mt-4 flex flex-col items-center '>
                {checkRole() && (
                  <div className='flex items-center justify-center '>
                    <Button variant='contained' component='label'>
                      Upload image
                      <input
                        type='file'
                        hidden
                        onChange={(e) => handleChangeFile(e)}
                      />
                    </Button>
                  </div>
                )}
                <div className='mt-4 mb-4'>
                  {value.part !== null &&
                    (imgPart.base === null && value.part.image.data === null ? (
                      <div
                        style={{ height: 250, width: 250 }}
                        className='flex items-center justify-center rounded-lg'>
                        <NoPhotographyIcon sx={{ fontSize: 80 }} />
                      </div>
                    ) : (
                      <img
                        alt=''
                        draggable='false'
                        className='max-w-96 max-h-96 mx-auto'
                        src={
                          imgPart.base !== null
                            ? imgPart.base
                            : `${value.part.image.data.attributes.url}`
                        }
                      />
                    ))}
                </div>
              </div>
            </div>
          </Box>
        </DialogContent>

        {checkRole() && (
          <DialogActions>
            <Button
              variant='contained'
              autoFocus
              onClick={() =>
                handleSubmitEdit(imgPart.file, value.part, value.part.id)
              }>
              บันทึก
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
};

export default FormUpdatePart;
