import { Route, Routes } from "react-router-dom";
import AddCategories from "./Admin/Question/AddCategories";
import AddQuestion from "./Admin/Question/addQuestion";
import { AuthRoute } from "./authenRoute";
import Layout from "./Layout/";
import Login from "./login/login";
import Question from "./Admin/Question/index";
import User from "./Admin/User";
import Answer from "./User/Answer";
import Paper from "./User/Answer/Paper";
import History from "./User/History";
import Dashboard from "./Dashboard/index";
import PerPath from "./Dashboard/PerPath";
import EditPaper from "./User/Answer/EditPaper";
import EditPaperV2 from "./User/Answer/EditPaperV2";
import EditPaperV3 from "./User/Answer/EditPaperV3";
import Profile from "./Profile/index.jsx";
import ViewPaper from "./User/Answer/ViewPaper";
import ViewPaperV2 from "./User/Answer/ViewPaperV2";
import PaperV2 from "./User/Answer/PaperV2";
import Setting from "./Setting/index.jsx";
import Category from "./Dashboard/Category";
import QuestionDashboard from "./Dashboard/Question";
import PerPathNormalQT from "./Dashboard/PerPathNormalQT";
import PerPathNormalQTWithActual from "./Dashboard/PerPathNormalQTWithActual";
import { useState } from "react";
import { createContext } from "react";
import Revise from "./Revise";
import ViewRevise from "./Revise/Revise";
import TestExportFile from "./Test/TestExportFile";
import Report from "./Report";
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs"
import RevisePart from "./RevisePart/index.jsx";
import PaperView from "./Components/PaperView";

export const PartContext = createContext();

function App() {
  const [selectedPart, setSelectedPart] = useState();
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <PartContext.Provider value={[selectedPart, setSelectedPart]}>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route element={<AuthRoute />}>
            <Route exact path="/layout" element={<Layout />}>
              <Route path="part">
                <Route index element={<Question />} />
                <Route path="add_categories/:id">
                  <Route index element={<AddCategories />} />
                  <Route path="add_question/:id" element={<AddQuestion />} />
                </Route>
                <Route path="revise/:id" element={<RevisePart />} />
              </Route>
              <Route path="user">
                <Route index element={<User />} />
              </Route>
              <Route path="paper">
                <Route index element={<Answer />} />
                <Route path="answer/:id">
                  <Route index element={<Paper />} />
                </Route>
                {/* http://agrappl03.aapico.com:8082/paper/view/:partId */}
                <Route path="view/:partId/:paperId">
                  <Route index element={<PaperView />} />
                </Route>
                <Route path="answerv2/:id">
                  <Route index element={<PaperV2 />} />
                </Route>
                <Route path="history/:id">
                  <Route index element={<History />} />
                  <Route path="edit_paper/:idPaper" element={<EditPaper />} />
                  <Route
                    path="edit_paper_v2/:idPaper"
                    element={<EditPaperV2 />}
                  />
                  <Route
                    path="edit_paper_v3/:idPaper"
                    element={<EditPaperV3 />}
                  />
                  <Route path="view_paper/:idPaper" element={<ViewPaper />} />
                  <Route
                    path="view_paper_v2/:idPaper"
                    element={<ViewPaperV2 />}
                  />
                </Route>
              </Route>
              <Route path="dashboard">
                <Route index element={<Dashboard />} />
                <Route path="category/:id">
                  <Route index element={<Category />} />
                  <Route path="question/:id">
                    <Route index element={<QuestionDashboard />} />
                    <Route path="data/:id">
                      <Route index element={<PerPath />} />
                    </Route>
                    <Route path="normal_data/:id">
                      <Route index element={<PerPathNormalQT />} />
                    </Route>
                    <Route path="normal_data_with_actual/:id">
                      <Route index element={<PerPathNormalQTWithActual />} />
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route path="profile/:id" element={<Profile />}></Route>
              <Route path="setting">
                <Route index element={<Setting />} />
              </Route>
              <Route path="revise">
                <Route index element={<Revise />} />
                <Route path="view_revise/:id" element={<ViewRevise />} />
              </Route>
              <Route path="test">
                <Route index element={<TestExportFile />} />
              </Route>
              <Route path="report">
                <Route index element={<Report />} />
              </Route>
              {/* <Route path="about" element={<div>about</div>} />
            <Route path="read" element={<div>read</div>} /> */}
            </Route>
          </Route>
          {/* <Route path="/*" element={<div>404</div>} /> */}
          <Route path="/" element={<AuthRoute />}>
            <Route index element={<Layout />}></Route>
          </Route>
          {/* <Route path="/*" element={<Login />} /> */}
        </Routes>
      </PartContext.Provider>
    </LocalizationProvider>
  );
}

export default App;
