import React, { useEffect, useRef, useState } from "react";
import { Close, Add, Delete } from "@mui/icons-material";
import { DialogContent, DialogTitle, Fab, IconButton } from "@mui/material";
import { BootstrapDialog, getBase64 } from "./FormAddImageAnswer";
import config from "../../config";

const FormEditImageAnswerV3 = ({ paper, setPaper, select, setOpen, open }) => {
  const indexQT = select.index;
  const subCatIndex = select.subCatIndex;
  const questionIndex = select.questionIndex;
  const clonePaper = { ...paper };
  const [showImages, setShowImages] = useState([]);

  const cloneSubcategory = clonePaper.dataSub.find(
    (subcategory) => subcategory.subID === select.subID
  );
  const cloneQuestion = cloneSubcategory.questionForAnswer.find(
    (question) => question.questionID === select.questionID
  );

  const setupImages = async () => {
    const images = Array.from(cloneQuestion.imageURL[indexQT]);
    const arrImageShow = [];
    for (let i = 0; i < images.length; i++) {
      let imagePerLoop = images[i];
      if (typeof imagePerLoop === "string") {
        imagePerLoop = imagePerLoop;
        //config.server +
      } else {
        imagePerLoop = await getBase64(imagePerLoop);
      }
      arrImageShow.push(imagePerLoop);
    }
    setShowImages(arrImageShow);
  };

  const handleOnChangeImage = async (e) => {
    try {
      const files = Array.from(e.target.files);
      files.forEach((file) => {
        cloneQuestion.imageURL[indexQT].push(file);
      });
      setPaper(clonePaper);
    } catch (error) {
      console.error(Error(error).stack);
    }
  };

  const handleDeleteImage = (indexShowImage) => {
    cloneQuestion.imageURL[indexQT].splice(indexShowImage, 1);
    setPaper(clonePaper);
  };

  useEffect(() => {
    setupImages();
  }, [paper]);

  return (
    <div>
      <BootstrapDialog onClose={() => setOpen(false)} open={open}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          แก้ไขรูปภาพ
          <IconButton
            aria-label='close'
            onClick={() => setOpen(false)}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}>
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className={`flex flex-wrap gap-4`}>
            {paper &&
              showImages.length > 0 &&
              showImages.map((showImage, indexShowImage) => (
                <div className={`relative`} key={`image${indexShowImage}`}>
                  <div
                    className={`absolute -top-4 -right-4`}
                    onClick={() => handleDeleteImage(indexShowImage)}>
                    <Fab
                      color='error'
                      variant='contained'
                      component='label'
                      size='small'>
                      <Delete />
                    </Fab>
                  </div>
                  <div
                    className={`overflow-hidden w-[200px] h-[200px] border-2 border-blue-500 rounded-md`}>
                    <img src={showImage} />
                  </div>
                </div>
              ))}
            <AddPhotoButton onChange={(e) => handleOnChangeImage(e)} />
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};
export default FormEditImageAnswerV3;

const AddPhotoButton = ({ onChange }) => {
  const inputFileRef = useRef();

  const handleOnClick = () => {
    inputFileRef.current.click();
  };
  return (
    <div
      onClick={handleOnClick}
      className={`cursor-pointer rounded-md border-dashed border-2 border-blue-500 bg-blue-100 hover:bg-blue-200 w-[200px] h-[200px] flex items-center justify-center`}>
      <input
        type='file'
        hidden
        multiple
        onChange={onChange}
        ref={inputFileRef}
      />
      <Add sx={{ fontSize: 50, color: "rgb(59 130 246)" }} />
    </div>
  );
};
