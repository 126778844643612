import React, { useState, useContext, useEffect } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { APIServices } from "./../../helpers/APIServices";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";
import SimpleBackdrop from "../../Components/Backdrop";
import Formula from "../../Components/Formula";
import { searchPartID } from "./FormAddQuestion";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../Layout";
import config from "../../config";
import Checkbox from "@mui/material/Checkbox";
import DeleteSubCatModal from "../../Components/DeleteSubCatModal";
import { Chip } from "@mui/material";
const labelCheckbox = { inputProps: { "aria-label": "Checkbox demo" } };

const label = { inputProps: { "aria-label": "Switch demo" } };

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const changeIDFormula = async (keepNewData, dataQuestionFormula) => {
  let parsedFormula;
  for (let i = 0; i < dataQuestionFormula.length; i++) {
    const data = dataQuestionFormula[i];
    const oldID = keepNewData.find((id) => id.id === data.new_id);
    parsedFormula = oldID ? oldID.formula.data : null;
    if (parsedFormula) {
      const splitOldData = oldID.formula.data.match(/{.*?}/g);
      for (let i = 0; i < splitOldData.length; i++) {
        const questionIDandBacket = splitOldData[i];
        const questionIDInFormula = parseInt(
          questionIDandBacket.substring(1, questionIDandBacket.length - 1)
        );
        const findNewID = dataQuestionFormula.find(
          (id) => id.old_id === questionIDInFormula
        );
        parsedFormula = parsedFormula.replace(
          questionIDandBacket,
          `{${findNewID.new_id}}`
        );
      }
      await APIServices.updateFormulaInQuestion(oldID.id, parsedFormula);
    }
  }
  return {
    formula: { data: parsedFormula },
  };
};

const FormUpdateQuestion = ({
  state,
  setSuccess,
  subCategory,
  category,
  partName,
  partCode,
}) => {
  const location = useLocation();
  const partId = searchPartID(location.pathname);
  const [value, setValue] = [...state];
  const [revise, setRevise] = useState(false);
  const [revisionRecord, setRevisionRecord] = useState("");
  const [part, setPart] = useState("");
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [changeData, setChangeData] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    massage: "",
  });
  const [loading, setLoading] = useState(false);
  const [formula, setFormula] = useState(
    value.data.formula !== null ? value.data.formula.data : undefined
  );
  const [openDelete, setOpenDelete] = useState({
    open: false,
    message: "",
    status_revise: false,
    trueButton: "ใช่",
  });

  const checkError = () => {
    if (!value.data) {
      return false;
    }
    if (value.data.spec_min === "" && value.data.spec_max === "") {
      return true;
    }
    if (parseFloat(value.data.spec_min) === parseFloat(value.data.spec_max)) {
      return true;
    }
    return parseFloat(value.data.spec_min) > parseFloat(value.data.spec_max);
  };

  const handleClose = () => {
    setValue({ open: false, data: null });
    setRevisionRecord("");
  };

  const handleCloseAlert = () => {
    setAlert((data) => ({ ...data, open: false }));
  };

  const signature =
    user.signature !== null
      ? user.signature[user.signature.length - 1].url
      : "";

  const handleSubmitEdit = async (question, id) => {
    if (question === null) {
      setAlert({
        open: true,
        type: "error",
        massage: "Question is empty",
      });
      return;
    }
    // if (part.papers.data.length > 0) {
    //   if (revise === false) {
    //     setAlert({
    //       open: true,
    //       type: "error",
    //       massage: "พาร์ทนี้มีการเพิ่ม paper กรุณาทำการกด revise",
    //     });
    //     return;
    //   }
    // }

    const questionData = question.question;
    const specMin = question.spec_min;
    const specMax = question.spec_max;
    const statusSC = question.sc_status;
    const status = question.status;
    const UCL_xBar = question.UCL_Xbar;
    const CL_xBar = question.CL_Xbar;
    const LCL_xBar = question.LCL_Xbar;
    const UCL_rChart = question.UCL_Rchart;
    const CL_rChart = question.CL_Rchart;
    const LCL_rChart = question.LCL_Rchart;

    if (questionData === "") {
      setAlert({
        open: true,
        type: "error",
        massage: "Question is empty",
      });
      return;
    }
    // if (checkFormulaError(formula)) {
    //   setAlert({
    //     open: true,
    //     type: "error",
    //     massage: "รูปแบบสูตรของคุณผิด !!!",
    //   });
    //   return;
    if (revise === false) {
      if (questionData === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "ยังไม่ได้ทำการเพิ่มคำถาม",
        });
      } else {
        setLoading(true);
        await APIServices.updateQuestion(
          questionData,
          specMin,
          specMax,
          statusSC,
          id,
          status,
          UCL_xBar,
          CL_xBar,
          LCL_xBar,
          UCL_rChart,
          CL_rChart,
          LCL_rChart,
          formula,
          "2"
        );
        // if (image !== null) {
        //   if (value.data.image.data !== null) {
        //     await APIServices.removeUserOldImage(value.data.image.data.id);
        //     const formData = new FormData();
        //     formData.append("ref", "api::question.question");
        //     formData.append("refId", value.data.id);
        //     formData.append("field", "image");
        //     formData.append("files", image[0]);
        //     await APIServices.addUserImage(formData);
        //   } else {
        //     const formData = new FormData();
        //     formData.append("ref", "api::question.question");
        //     formData.append("refId", value.data.id);
        //     formData.append("field", "image");
        //     formData.append("files", image[0]);
        //     await APIServices.addUserImage(formData);
        //   }
        // }
        setTimeout(() => {
          setLoading(false);
          setValue({ open: false, data: null });

          setSuccess(true);
        }, 1500);
      }
    } else {
      if (questionData === "" || revisionRecord === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "ยังไม่ได้ทำการเพิ่มคำถาม",
        });
      } else {
        setLoading(true);
        const reviseTime = part.revise_time ? part.revise_time : null;
        let partRevise;
        let keepNewData = [];
        let dataQuestionFormula = [];
        if (part.old_parts.data.length === 0) {
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            part.id,
            false,
            true
          );
          partRevise = responseReviseForPart;
        } else {
          let oldPartID = [];
          part.old_parts.data.forEach((oldPart) => {
            oldPartID.push(oldPart.id);
          });
          oldPartID.push(part.id);
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            oldPartID,
            false,
            true
          );
          partRevise = responseReviseForPart;
        }
        await APIServices.updatePart(
          part.part_name,
          part.part_code,
          part.id,
          false,
          false
        );

        const part_data = partRevise.data.data.attributes;
        const part_id = partRevise.data.data.id;

        if (part.sub_categories.data.length !== 0) {
          for (
            let indexSubCat = 0;
            indexSubCat < part.sub_categories.data.length;
            indexSubCat++
          ) {
            const subCatData = part.sub_categories.data[indexSubCat];
            const categoryID = subCatData.attributes.category.data.id;
            const subCatName = subCatData.attributes.sub_categories;
            const reviseTimeSubCat = subCatData.attributes.revise_time;
            let responseSubCat;
            if (subCatData.attributes.old_sub_categories.data.length === 0) {
              const response = await APIServices.createSubCategories(
                categoryID,
                subCatName,
                part_id,
                true,
                "0",
                reviseTimeSubCat,
                subCatData.id
              );
              responseSubCat = response;
            } else {
              let oldSubCatID = [];
              subCatData.attributes.old_sub_categories.data.forEach(
                (oldSub) => {
                  oldSubCatID.push(oldSub.id);
                }
              );
              oldSubCatID.push(subCatData.id);
              const response = await APIServices.createSubCategories(
                categoryID,
                subCatName,
                part_id,
                true,
                "0",
                reviseTimeSubCat,
                oldSubCatID
              );
              responseSubCat = response;
            }
            const realSubCatID = responseSubCat.data.data.id;

            const attributesQT = {
              spec_min: specMin,
              spec_max: specMax,
              sc_status: statusSC,
              question: questionData,
              UCL_Xbar: UCL_xBar,
              CL_Xbar: CL_xBar,
              LCL_Xbar: LCL_xBar,
              UCL_Rchart: UCL_rChart,
              CL_Rchart: CL_rChart,
              LCL_Rchart: LCL_rChart,
              sub_category: realSubCatID,
              status: true,
              formula: {
                data: formula,
              },
            };

            if (subCatData.attributes.questions.data.length !== 0) {
              for (
                let indexQuestion = 0;
                indexQuestion < subCatData.attributes.questions.data.length;
                indexQuestion++
              ) {
                const realQuestion =
                  subCatData.attributes.questions.data[indexQuestion];
                const oldStatus = realQuestion.attributes.status;
                const reviseTimeQuestion = realQuestion.attributes.revise_time;
                let reviseStatus = "0";
                if (realQuestion.id === id) {
                  if (changeData) {
                    reviseStatus = "2";
                  }
                  if (status === false && oldStatus === true) {
                    reviseStatus = "3";
                  }
                  if (realQuestion.attributes.old_question.data.length === 0) {
                    const responseQuestion = await APIServices.createQuestion(
                      attributesQT.spec_min,
                      attributesQT.spec_max,
                      attributesQT.sc_status,
                      attributesQT.question,
                      attributesQT.UCL_Xbar,
                      attributesQT.CL_Xbar,
                      attributesQT.LCL_Xbar,
                      attributesQT.UCL_Rchart,
                      attributesQT.CL_Rchart,
                      attributesQT.LCL_Rchart,
                      attributesQT.sub_category,
                      true,
                      attributesQT.formula.data,
                      reviseStatus,
                      part_data.revise_time,
                      realQuestion.id
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  } else {
                    let oldQuestionID = [];
                    realQuestion.attributes.old_question.data.forEach(
                      (oldQuestion) => {
                        oldQuestionID.push(oldQuestion.id);
                      }
                    );
                    oldQuestionID.push(realQuestion.id);
                    const responseQuestion = await APIServices.createQuestion(
                      attributesQT.spec_min,
                      attributesQT.spec_max,
                      attributesQT.sc_status,
                      attributesQT.question,
                      attributesQT.UCL_Xbar,
                      attributesQT.CL_Xbar,
                      attributesQT.LCL_Xbar,
                      attributesQT.UCL_Rchart,
                      attributesQT.CL_Rchart,
                      attributesQT.LCL_Rchart,
                      attributesQT.sub_category,
                      true,
                      attributesQT.formula.data,
                      reviseStatus,
                      part_data.revise_time,
                      oldQuestionID
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  }
                } else {
                  const spec_min = realQuestion.attributes.spec_min;
                  const spec_max = realQuestion.attributes.spec_max;
                  const SCStatus = realQuestion.attributes.sc_status;
                  const questionName = realQuestion.attributes.question;
                  const ucl_xBar = realQuestion.attributes.UCL_Xbar;
                  const cl_xBar = realQuestion.attributes.CL_Xbar;
                  const lcl_xBar = realQuestion.attributes.LCL_Xbar;
                  const ucl_rChart = realQuestion.attributes.UCL_Rchart;
                  const cl_rChart = realQuestion.attributes.CL_Rchart;
                  const lcl_rChart = realQuestion.attributes.LCL_Rchart;
                  const sub_category = realSubCatID;
                  const statusQT = realQuestion.attributes.status;
                  const formulaQT = realQuestion.attributes.formula
                    ? realQuestion.attributes.formula.data
                    : null;

                  if (realQuestion.attributes.old_question.data.length === 0) {
                    const responseQuestion = await APIServices.createQuestion(
                      spec_min,
                      spec_max,
                      SCStatus,
                      questionName,
                      ucl_xBar,
                      cl_xBar,
                      lcl_xBar,
                      ucl_rChart,
                      cl_rChart,
                      lcl_rChart,
                      sub_category,
                      statusQT,
                      formulaQT,
                      "0", //ไม่มีการเปลี่ยนแปลง
                      reviseTimeQuestion,
                      realQuestion.id
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  } else {
                    let oldQuestionID = [];
                    realQuestion.attributes.old_question.data.forEach(
                      (oldQuestion) => {
                        oldQuestionID.push(oldQuestion.id);
                      }
                    );
                    oldQuestionID.push(realQuestion.id);
                    const responseQuestion = await APIServices.createQuestion(
                      spec_min,
                      spec_max,
                      SCStatus,
                      questionName,
                      ucl_xBar,
                      cl_xBar,
                      lcl_xBar,
                      ucl_rChart,
                      cl_rChart,
                      lcl_rChart,
                      sub_category,
                      statusQT,
                      formulaQT,
                      "0", //ไม่มีการเปลี่ยนแปลง
                      reviseTimeQuestion,
                      oldQuestionID
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  }
                }
              }
            }
          }
        }

        if (partRevise.data.data !== null) {
          if (part.image.data !== null) {
            const files = await fetch(
              // "http://agrappl03.aapico.com:1339/uploads/9_FC_8_B6_E7_F948_451_A_8_CB_6_4_A821_B407339_a1915890e4.jpeg"
              `${part.image.data.attributes.url}`
              // config.server +
            )
              .then((response) => response.blob())
              .then((blob) => {
                const myFile = new File(
                  [blob],
                  `${part.image.data.attributes.name}`,
                  {
                    type: blob.type,
                  }
                );
                return myFile;
              });

            const formData = new FormData();
            formData.append("ref", "api::qc-part.qc-part");
            formData.append("refId", part_id);
            formData.append("field", "image");
            formData.append("files", files);
            await APIServices.addUserImage(formData);
          }
        }

        if (partRevise.data.data.id !== null) {
          await APIServices.reviseForUpdate(
            revisionRecord,
            new Date(),
            signature,
            user.id,
            part_data.revise_time,
            part_id
          );
        }

        // if (keepOldData && dataQuestionFormula) {
        changeIDFormula(keepNewData, dataQuestionFormula);
        setTimeout(() => {
          setLoading(false);
          setValue({ open: false, data: null });
          setRevisionRecord("");
          setSuccess(true);
          navigate("/layout/revise");
        }, 1500);
      }
    }
  };

  const checkRole = () => {
    if (
      user.qc_role === "super_admin" ||
      user.qc_role === "admin" ||
      user.qc_role === "release"
    ) {
      return true;
    }
  };

  const handleRevise = (e) => {
    setChangeData(true);
    setRevise(e.target.checked);
  };

  const handleDeleteQuestion = () => {
    let message,
      revise = false,
      trueButton = "ใช่";
    // allow delete question has paper - sawanon:20230718
    // if (part.papers.data.length > 0) {
    //   message = (
    //     <div>
    //       <Typography>
    //         พาร์ทนี้มีการเพิ่ม paper ระบบจะทำการ revise อัตโนมัติ
    //       </Typography>
    //       <Typography>กรุณากรอก Revision Record</Typography>
    //     </div>
    //   );
    //   revise = true;
    //   trueButton = "ตกลง";
    // } else {
    message = "คุณต้องการลบข้อมูลใช่หรือไม่";
    // }
    setOpenDelete({
      open: true,
      message: message,
      status_revise: revise,
      trueButton: trueButton,
    });
  };

  const checkIDFormula = (id, data) => {
    let formulaData = [];
    if (part.sub_categories) {
      part.sub_categories.data.forEach((subCat) => {
        const questions = subCat.attributes.questions.data;

        let questionData = [];
        questions.forEach((question) => {
          let questionID = [];

          if (question.attributes.formula.data) {
            const hasID = question.attributes.formula.data.includes(id);
            if (hasID) {
              questionID.push(id);
            }
          }

          if (questionID.length !== 0) {
            if (question.attributes.status) {
              questionData.push({
                myQuestionID: questionID,
                questionID: question.id,
                question: question.attributes.question,
                status_question: question.attributes.status,
              });
              return;
            }
          }
        });
        if (questionData.length !== 0) {
          if (subCat.attributes.status) {
            formulaData.push({
              statusForAlert: true,
              subCategory: subCat.attributes.sub_categories,
              subCategoryID: subCat.id,
              category: subCat.attributes.category.data.attributes.category,
              status_subCategory: subCat.attributes.status,
              question: questionData,
            });
          }
        }
      });
    }

    return {
      data: formulaData,
    };
  };

  const deleteQuestion = async (result, data) => {
    const questionData = data.data.question;
    const specMin = data.data.spec_min;
    const specMax = data.data.spec_max;
    const statusSC = data.data.sc_status;
    // const status = data.data.status;
    const UCL_xBar = data.data.UCL_Xbar;
    const CL_xBar = data.data.CL_Xbar;
    const LCL_xBar = data.data.LCL_Xbar;
    const UCL_rChart = data.data.UCL_Rchart;
    const CL_rChart = data.data.CL_Rchart;
    const LCL_rChart = data.data.LCL_Rchart;
    const id = data.data.id;
    const idFormula = checkIDFormula(id, data);

    if (result) {
      if (idFormula && idFormula.data.length !== 0) {
        setAlert({
          open: true,
          type: "error",
          massage: (
            <>
              <div>มีการใช้คำถามนี้ในการคำนวณคำตอบ กรุณาทำการแก้ไขที่</div>
              {idFormula.data.map((subCat, subIndex) => {
                return (
                  <div
                    key={subIndex}
                    className='flex flex-row items-center mb-2 mt-2  pt-2 pb-2'>
                    <div className='flex mr-2 p-4 bg-white text-[#D32F2F] w-2 h-2 rounded-full items-center justify-center'>
                      {subIndex + 1}
                    </div>
                    <div className='flex flex-col'>
                      <div>Category : {subCat.category}</div>
                      <div>Sub Category : {subCat.subCategory}</div>
                      {subCat.question.map((question, questionIndex) => {
                        return (
                          <div key={questionIndex}>
                            - Check point : {question.question}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          ),
        });
        return;
      }
      // return;
      if (openDelete.status_revise) {
        if (revisionRecord === "") {
          setAlert({
            open: true,
            type: "error",
            massage: "กรุณากรอก Revision Record",
          });
          return;
        }
        setLoading(true);
        const reviseTime = part.revise_time ? part.revise_time : null;
        let partRevise;
        let keepNewData = [];
        let dataQuestionFormula = [];
        if (part.old_parts.data.length === 0) {
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            part.id,
            false,
            true
          );
          partRevise = responseReviseForPart;
        } else {
          let oldPartID = [];
          part.old_parts.data.forEach((oldPart) => {
            oldPartID.push(oldPart.id);
          });
          oldPartID.push(part.id);
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            oldPartID,
            false,
            true
          );
          partRevise = responseReviseForPart;
        }
        await APIServices.updatePart(
          part.part_name,
          part.part_code,
          part.id,
          false,
          false
        );
        const part_data = partRevise.data.data.attributes;
        const part_id = partRevise.data.data.id;

        if (part.sub_categories.data.length !== 0) {
          for (
            let indexSubCat = 0;
            indexSubCat < part.sub_categories.data.length;
            indexSubCat++
          ) {
            const subCatData = part.sub_categories.data[indexSubCat];
            const categoryID = subCatData.attributes.category.data.id;
            const subCatName = subCatData.attributes.sub_categories;
            const reviseTimeSubCat = subCatData.attributes.revise_time;
            let responseSubCat;
            if (subCatData.attributes.old_sub_categories.data.length === 0) {
              const response = await APIServices.createSubCategories(
                categoryID,
                subCatName,
                part_id,
                true,
                "0",
                reviseTimeSubCat,
                subCatData.id
              );
              responseSubCat = response;
            } else {
              let oldSubCatID = [];
              subCatData.attributes.old_sub_categories.data.forEach(
                (oldSub) => {
                  oldSubCatID.push(oldSub.id);
                }
              );
              oldSubCatID.push(subCatData.id);
              const response = await APIServices.createSubCategories(
                categoryID,
                subCatName,
                part_id,
                true,
                "0",
                reviseTimeSubCat,
                oldSubCatID
              );
              responseSubCat = response;
            }
            const realSubCatID = responseSubCat.data.data.id;

            const attributesQT = {
              spec_min: specMin,
              spec_max: specMax,
              sc_status: statusSC,
              question: questionData,
              UCL_Xbar: UCL_xBar,
              CL_Xbar: CL_xBar,
              LCL_Xbar: LCL_xBar,
              UCL_Rchart: UCL_rChart,
              CL_Rchart: CL_rChart,
              LCL_Rchart: LCL_rChart,
              sub_category: realSubCatID,
              status: true,
              formula: {
                data: formula,
              },
            };

            if (subCatData.attributes.questions.data.length !== 0) {
              for (
                let indexQuestion = 0;
                indexQuestion < subCatData.attributes.questions.data.length;
                indexQuestion++
              ) {
                const realQuestion =
                  subCatData.attributes.questions.data[indexQuestion];
                const oldStatus = realQuestion.attributes.status;
                const reviseTimeQuestion = realQuestion.attributes.revise_time;
                let reviseStatus = "0";
                if (realQuestion.id === id) {
                  if (oldStatus === true) {
                    reviseStatus = "3";
                  }
                  if (realQuestion.attributes.old_question.data.length === 0) {
                    const responseQuestion = await APIServices.createQuestion(
                      attributesQT.spec_min,
                      attributesQT.spec_max,
                      attributesQT.sc_status,
                      attributesQT.question,
                      attributesQT.UCL_Xbar,
                      attributesQT.CL_Xbar,
                      attributesQT.LCL_Xbar,
                      attributesQT.UCL_Rchart,
                      attributesQT.CL_Rchart,
                      attributesQT.LCL_Rchart,
                      attributesQT.sub_category,
                      false,
                      attributesQT.formula.data,
                      reviseStatus,
                      part_data.revise_time,
                      realQuestion.id
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  } else {
                    let oldQuestionID = [];
                    realQuestion.attributes.old_question.data.forEach(
                      (oldQuestion) => {
                        oldQuestionID.push(oldQuestion.id);
                      }
                    );
                    oldQuestionID.push(realQuestion.id);
                    const responseQuestion = await APIServices.createQuestion(
                      attributesQT.spec_min,
                      attributesQT.spec_max,
                      attributesQT.sc_status,
                      attributesQT.question,
                      attributesQT.UCL_Xbar,
                      attributesQT.CL_Xbar,
                      attributesQT.LCL_Xbar,
                      attributesQT.UCL_Rchart,
                      attributesQT.CL_Rchart,
                      attributesQT.LCL_Rchart,
                      attributesQT.sub_category,
                      false,
                      attributesQT.formula.data,
                      reviseStatus,
                      reviseTimeQuestion,
                      oldQuestionID
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  }
                } else {
                  const spec_min = realQuestion.attributes.spec_min;
                  const spec_max = realQuestion.attributes.spec_max;
                  const SCStatus = realQuestion.attributes.sc_status;
                  const questionName = realQuestion.attributes.question;
                  const ucl_xBar = realQuestion.attributes.UCL_Xbar;
                  const cl_xBar = realQuestion.attributes.CL_Xbar;
                  const lcl_xBar = realQuestion.attributes.LCL_Xbar;
                  const ucl_rChart = realQuestion.attributes.UCL_Rchart;
                  const cl_rChart = realQuestion.attributes.CL_Rchart;
                  const lcl_rChart = realQuestion.attributes.LCL_Rchart;
                  const sub_category = realSubCatID;
                  const statusQT = realQuestion.attributes.status;
                  const formulaQT = realQuestion.attributes.formula
                    ? realQuestion.attributes.formula.data
                    : null;

                  if (realQuestion.attributes.old_question.data.length === 0) {
                    const responseQuestion = await APIServices.createQuestion(
                      spec_min,
                      spec_max,
                      SCStatus,
                      questionName,
                      ucl_xBar,
                      cl_xBar,
                      lcl_xBar,
                      ucl_rChart,
                      cl_rChart,
                      lcl_rChart,
                      sub_category,
                      statusQT,
                      formulaQT,
                      "0", //ไม่มีการเปลี่ยนแปลง
                      reviseTimeQuestion,
                      realQuestion.id
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  } else {
                    let oldQuestionID = [];
                    realQuestion.attributes.old_question.data.forEach(
                      (oldQuestion) => {
                        oldQuestionID.push(oldQuestion.id);
                      }
                    );
                    oldQuestionID.push(realQuestion.id);
                    const responseQuestion = await APIServices.createQuestion(
                      spec_min,
                      spec_max,
                      SCStatus,
                      questionName,
                      ucl_xBar,
                      cl_xBar,
                      lcl_xBar,
                      ucl_rChart,
                      cl_rChart,
                      lcl_rChart,
                      sub_category,
                      statusQT,
                      formulaQT,
                      "0", //ไม่มีการเปลี่ยนแปลง
                      reviseTimeQuestion,
                      oldQuestionID
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  }
                }
              }
            }
          }
        }

        if (partRevise.data.data !== null) {
          if (part.image.data !== null) {
            const files = await fetch(
              // "http://agrappl03.aapico.com:1339/uploads/9_FC_8_B6_E7_F948_451_A_8_CB_6_4_A821_B407339_a1915890e4.jpeg"
              `${part.image.data.attributes.url}`
              // config.server +
            )
              .then((response) => response.blob())
              .then((blob) => {
                const myFile = new File(
                  [blob],
                  `${part.image.data.attributes.name}`,
                  {
                    type: blob.type,
                  }
                );
                return myFile;
              });

            const formData = new FormData();
            formData.append("ref", "api::qc-part.qc-part");
            formData.append("refId", part_id);
            formData.append("field", "image");
            formData.append("files", files);
            await APIServices.addUserImage(formData);
          }
        }

        if (partRevise.data.data.id !== null) {
          await APIServices.reviseForUpdate(
            revisionRecord,
            new Date(),
            signature,
            user.id,
            part_data.revise_time,
            part_id
          );
        }

        // if (keepOldData && dataQuestionFormula) {
        changeIDFormula(keepNewData, dataQuestionFormula);
        setTimeout(() => {
          setLoading(false);
          setRevisionRecord("");
          setRevise(false);
          setValue({ open: false, data: null });
          setSuccess(true);
          navigate("/layout/revise");
        }, 1500);
      } else {
        setLoading(true);
        await APIServices.updateQuestion(
          questionData,
          specMin,
          specMax,
          statusSC,
          id,
          false,
          UCL_xBar,
          CL_xBar,
          LCL_xBar,
          UCL_rChart,
          CL_rChart,
          LCL_rChart,
          formula,
          "3"
        );
        setTimeout(() => {
          setRevise(false);
          setRevisionRecord("");
          setValue({ open: false, data: null });
          setSuccess(true);
          setLoading(false);
        }, 1500);
      }
    }
  };

  useEffect(() => {
    const findOnePart = async () => {
      setLoading(true);
      const findPart = await APIServices.findOnePart(partId);
      const partData = findPart.data.data;
      setPart({
        id: partData.id,
        ...partData.attributes,
      });
      setLoading(false);
    };
    findOnePart();
  }, [partId]);

  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={value.open}>
        <SimpleBackdrop open={loading} setOpen={setLoading} />
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}>
          แบบฟอร์มแก้ไขข้อมูลคำถาม
        </BootstrapDialogTitle>
        <div>
          <Snackbar
            open={alert.open}
            autoHideDuration={4000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleCloseAlert}>
            <Alert
              onClose={handleCloseAlert}
              severity={alert.type}
              sx={{ width: "100%" }}>
              {alert.massage}
            </Alert>
          </Snackbar>
        </div>
        <DialogContent dividers>
          <Box
            component='form'
            sx={{
              "& .MuiTextField-root": {
                // mr: 2,
                width: "100%",
                // maxWidth: "100%",
                mt: 2,
              },
            }}
            noValidate
            autoComplete='off'>
            <div className={`flex flex-col justify-center items-start mb-2`}>
              <div className={`flex flex-col items-baseline mb-2`}>
                <div className={`ml-2 font-bold text-lg`}>{partName}</div>
                <div className={`ml-2 `}>({partCode})</div>
              </div>
              <div className={`flex mb-2 flex-row items-center`}>
                <Chip
                  label={"Category: "}
                  color='primary'
                  size='small'
                  variant='outlined'
                />
                <div className={`ml-2 font-medium`}>{category}</div>
              </div>
              <div className={`flex mb-2 flex-row items-center`}>
                <Chip
                  label={"Sub category: "}
                  color='primary'
                  size='small'
                  variant='outlined'
                />
                <div className={`ml-2 font-medium`}>{subCategory}</div>
              </div>
            </div>

            <div className='flex flex-col'>
              {/* {checkRole() && (
                <div className="flex flex-row">
                  {part &&
                    part.revise &&
                    part.revise.data.attributes.revise_status === "4" && (
                      <div className="flex flex-row justify-center items-center mr-6">
                        <div>Revise :</div>
                        <Checkbox
                          {...labelCheckbox}
                          // disabled={
                          //   keepPart.revise.data.attributes.revise_status !== "4"
                          // }
                          checked={revise}
                          onChange={(e) => handleRevise(e)}
                        />
                      </div>
                    )}
                  {revise === true && (
                    <div className="col-span-3">
                      <TextField
                        label="Revision Record"
                        value={revisionRecord ? revisionRecord : ""}
                        onChange={(e) => setRevisionRecord(e.target.value)}
                      />
                    </div>
                  )}
                </div>
              )} */}
              <div className='flex '>
                {/* <div className=" flex items-center mr-4">
                  <span>Active</span>
                  <Switch
                    checked={
                      value.data !== null && value.data.status === true
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      // if (e.target.checked === false) {
                      //   setRevise(true);
                      // }

                      setValue((prev) => {
                        return {
                          ...prev,
                          data: {
                            ...prev.data,
                            status: e.target.checked,
                          },
                        };
                      });
                    }}
                    {...label}
                  />
                </div> */}
              </div>

              <div className='flex justify-center '>
                <div className='flex flex-row justify-center items-center'>
                  <span>SC</span>
                  <Switch
                    checked={
                      value.data !== null && value.data.sc_status === true
                        ? true
                        : false
                    }
                    onChange={(e) => {
                      setChangeData(true);
                      setValue((prev) => {
                        return {
                          ...prev,
                          data: {
                            ...prev.data,
                            sc_status: e.target.checked,
                          },
                        };
                      });
                    }}
                    disabled={value.data.status === false}
                    {...label}
                  />
                </div>
                <TextField
                  fullWidth
                  label='คำถาม'
                  value={
                    value && value.data !== null ? value.data.question : ""
                  }
                  onChange={(e) => {
                    setChangeData(true);
                    setValue((prev) => {
                      return {
                        ...prev,
                        data: {
                          ...prev.data,
                          question: e.target.value,
                        },
                      };
                    });
                  }}
                  disabled={value.data.status === false}
                />
              </div>

              <div className='flex gap-4'>
                <div className='basis-1/2'>
                  <TextField
                    value={
                      value && value.data !== null ? value.data.spec_min : ""
                    }
                    type={"number"}
                    label='min'
                    error={checkError()}
                    onChange={(e) => {
                      setChangeData(true);
                      setValue((prev) => {
                        return {
                          ...prev,
                          data: {
                            ...prev.data,
                            spec_min: e.target.value,
                          },
                        };
                      });
                    }}
                    disabled={value.data.status === false}
                    helperText={
                      checkError() && "มีค่ามากกว่าหรือเท่ากับ Spec Max"
                    }
                  />
                </div>

                <div className='basis-1/2'>
                  <TextField
                    error={checkError()}
                    type={"number"}
                    label='max'
                    value={
                      value && value.data !== null ? value.data.spec_max : ""
                    }
                    onChange={(e) => {
                      setChangeData(true);
                      setValue((prev) => {
                        return {
                          ...prev,
                          data: {
                            ...prev.data,
                            spec_max: e.target.value,
                          },
                        };
                      });
                    }}
                    disabled={value.data.status === false}
                    helperText={
                      checkError() && "มีค่าน้อยกว่าหรือเท่ากับ Spec Min"
                    }
                  />
                </div>
              </div>

              <div className='flex flex-col mt-2'>
                <span className='mt-2  font-semibold'>X-bar</span>
                <div className='flex flex-row'>
                  <div className='m-1'>
                    <TextField
                      value={
                        value && value.data !== null ? value.data.UCL_Xbar : ""
                      }
                      onChange={(e) => {
                        setChangeData(true);
                        setValue((prev) => {
                          return {
                            ...prev,
                            data: {
                              ...prev.data,
                              UCL_Xbar: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={value.data.sc_status === false}
                      label='UCL'
                      type='number'
                    />
                  </div>
                  <div className='m-1'>
                    <TextField
                      value={
                        value && value.data !== null ? value.data.CL_Xbar : ""
                      }
                      onChange={(e) => {
                        setChangeData(true);
                        setValue((prev) => {
                          return {
                            ...prev,
                            data: {
                              ...prev.data,
                              CL_Xbar: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={value.data.sc_status === false}
                      label='CL'
                      type='number'
                    />
                  </div>
                  <div className='m-1'>
                    <TextField
                      value={
                        value && value.data !== null ? value.data.LCL_Xbar : ""
                      }
                      onChange={(e) => {
                        setChangeData(true);
                        setValue((prev) => {
                          return {
                            ...prev,
                            data: {
                              ...prev.data,
                              LCL_Xbar: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={value.data.sc_status === false}
                      label='LCL'
                      type='number'
                    />
                  </div>
                </div>
              </div>

              <div className='flex flex-col mt-2'>
                <span className='mt-2 font-semibold'>R-chart</span>
                <div className='flex flex-row'>
                  <div className='m-1'>
                    <TextField
                      value={
                        value && value.data !== null
                          ? value.data.UCL_Rchart
                          : ""
                      }
                      onChange={(e) => {
                        setChangeData(true);
                        setValue((prev) => {
                          return {
                            ...prev,
                            data: {
                              ...prev.data,
                              UCL_Rchart: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={value.data.sc_status === false}
                      label='UCL'
                      type='number'
                    />
                  </div>
                  <div className='m-1'>
                    <TextField
                      value={
                        value && value.data !== null ? value.data.CL_Rchart : ""
                      }
                      onChange={(e) => {
                        setChangeData(true);
                        setValue((prev) => {
                          return {
                            ...prev,
                            data: {
                              ...prev.data,
                              CL_Rchart: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={value.data.sc_status === false}
                      label='CL'
                      type='number'
                    />
                  </div>
                  <div className='m-1'>
                    <TextField
                      value={
                        value && value.data !== null
                          ? value.data.LCL_Rchart
                          : ""
                      }
                      onChange={(e) => {
                        setChangeData(true);
                        setValue((prev) => {
                          return {
                            ...prev,
                            data: {
                              ...prev.data,
                              LCL_Rchart: e.target.value,
                            },
                          };
                        });
                      }}
                      disabled={value.data.sc_status === false}
                      label='LCL'
                      type='number'
                    />
                  </div>
                </div>
              </div>

              {/* <div className="mt-4 flex flex-col items-center ">
                <div className="flex items-center justify-center ">
                  <Button variant="contained" component="label">
                    Upload image
                    <input
                      type="file"
                      hidden
                      onChange={(e) => handleChangeFile(e)}
                    />
                  </Button>
                </div>
                <div className="mt-4">
                  {value.data !== null &&
                    (imgQuestion.base === null &&
                    value.data.image.data === null ? (
                      <div
                        style={{ height: 250, width: 250 }}
                        className="flex items-center justify-center rounded-lg"
                      >
                        <NoPhotographyIcon sx={{ fontSize: 80 }} />
                      </div>
                    ) : (
                      <img
                        draggable="false"
                        className="max-w-96 max-h-96 mx-auto"
                        src={
                          imgQuestion.base !== null
                            ? imgQuestion.base
                            : `${
                                config.server +
                                value.data.image.data.attributes.url
                              }`
                        }
                      />
                    ))}
                </div>
              </div> */}
            </div>
          </Box>
          {value.data.status === true && (
            <Formula
              partID={partId}
              formula={formula}
              setFormula={setFormula}
              setChangeData={setChangeData}
            />
          )}
          {/* <div className={`bg-red-400`}>
            fomula
          </div> */}
        </DialogContent>
        {checkRole() && (
          <DialogActions>
            <Button
              variant='contained'
              autoFocus
              color='error'
              onClick={() => handleDeleteQuestion(value.data, value.data.id)}>
              ลบ
            </Button>
            <Button
              // disabled={checkError()}
              variant='contained'
              autoFocus
              onClick={() =>
                handleSubmitEdit(
                  // imgQuestion.file,
                  value.data,
                  value.data.id,
                  formula
                )
              }
              disabled={changeData === false}>
              บันทึก
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
      <DeleteSubCatModal
        open={openDelete.open}
        setOpen={setOpenDelete}
        openDelete={openDelete}
        setRevisionRecord={setRevisionRecord}
        revisionRecord={revisionRecord}
        action={deleteQuestion}
        confirmText={openDelete.trueButton}
        cancelText={"ยกเลิก"}
        header={"ยืนยันการลบข้อมูล"}
        message={openDelete.message}
        data={value}
      />
    </div>
  );
};

export default FormUpdateQuestion;
