import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Close from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import _ from 'lodash'
import Switch from '@mui/material/Switch'
import Formula from '../Components/Formula'
import config from '../config'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import DialogActions from '@mui/material/DialogActions'

const getFormula = (question) => {
    if(question?.formula?.data){
        return question?.formula?.data
    }else if(question?.formula && !_.isEmpty(question?.formula)){
        return question?.formula
    }
    return undefined
}

const EditQuestion = ({
    open = false,
    onClose,
    question,
    partID,
    onConfirm,
    mode = "edit",
    subcategories,
}) => {
    const [_question, setQuestion] = useState(_.cloneDeep(question))
    const [_formula, setFormula] = useState(_.cloneDeep(getFormula(_question)))
    const [dialogError, setDialogError] = useState({
        open: false,
        message: '',
    })
    let status_revise
    if(mode === "add"){
        status_revise = config.status_revise.ADD
    }else if(mode === "edit"){
        status_revise = config.status_revise.EDIT
    }

    const handleOnClose = () => {
        onClose()
    }

    const handleOnChangeName = (e) => {
        const value = e.target.value
        console.log("value", value);
        setQuestion(prev => ({
            ...prev,
            question: value,
        }))
    }

    const handleOnChangeMin = (e) => {
        const value = parseFloat(e.target.value)
        if(isNaN(value)){
            return
        }
        setQuestion(prev => ({
            ...prev,
            spec_min: value
        }))
    }

    const handleOnChangeMax = (e) => {
        const value = parseFloat(e.target.value)
        if(isNaN(value)){
            return
        }
        setQuestion(prev => ({
            ...prev,
            spec_max: value
        }))
    }

    const handleOnChangeUCL_Xbar = (e) => {
        const value = e.target.value === "" ? null : parseFloat(e.target.value)
        setQuestion(prev => ({
            ...prev,
            UCL_Xbar: value || null,
        }))
    }

    const handleOnChangeCL_Xbar = (e) => {
        const value = e.target.value === "" ? null : parseFloat(e.target.value)
        setQuestion(prev => ({
            ...prev,
            CL_Xbar: value || null,
        }))
    }

    const handleOnChangeLCL_Xbar = (e) => {
        const value = e.target.value === "" ? null : parseFloat(e.target.value)
        setQuestion(prev => ({
            ...prev,
            LCL_Xbar: value || null,
        }))
    }
    
    const handleOnChangeUCL_Rchart = (e) => {
        const value = e.target.value === "" ? null : parseFloat(e.target.value)
        setQuestion(prev => ({
            ...prev,
            UCL_Rchart: value || null,
        }))
    }

    const handleOnChangeCL_Rchart = (e) => {
        const value = e.target.value === "" ? null : parseFloat(e.target.value)
        setQuestion(prev => ({
            ...prev,
            CL_Rchart: value || null,
        }))
    }

    const handleOnChangeLCL_Rchart = (e) => {
        const value = e.target.value === "" ? null : parseFloat(e.target.value)
        setQuestion(prev => ({
            ...prev,
            LCL_Rchart: value || null,
        }))
    }

    const handleOnChangeScStatus = (e) => {
        const check = e.target.checked
        console.log("check", check);
        setQuestion(prev => ({
            ...prev,
            sc_status: check,
        }))
    }

    const validQuestion = (question) => {
        if(!question?.question){
            throw Error("กรุณากรอกข้อมูลให้ครบ (Name)")
        }
        // else if(!question?.)
    }

    const handleOnConfirm = () => {
        try {
            if(_question.status_revise === config.status_revise.ADD){
                status_revise = config.status_revise.ADD
            }
            const question = {
                ..._question,
                formula: _formula,
                status_revise: status_revise,
                status: true,
            }
            console.log("question", question);
            validQuestion(question)
            onConfirm(question, mode)
            handleOnClose()
        } catch (error) {
            setDialogError(prev => ({
                ...prev,
                open: true,
                message: `${error}`,
            }))
        }
    }

  return (
    <Dialog
        open={open}
        maxWidth={`md`}
        fullWidth
    >
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            open={dialogError.open}
            autoHideDuration={4000}
            onClose={() => setDialogError(prev => ({...prev, open: false}))}
        >
            <Alert severity='error' onClose={() => setDialogError(prev => ({...prev, open: false}))}>
                {`${dialogError.message}`}
            </Alert>
        </Snackbar>
        <div className={`p-4 overflow-y-auto`}>
            <div className={`flex justify-between items-center`}>
                <div className={`text-2xl font-bold`}>
                    EditQuestion
                </div>
                <div
                    onClick={handleOnClose}
                    className={`w-9 h-9 flex justify-center items-center bg-white hover:bg-gray-200 cursor-pointer rounded-full`}
                >
                    <Close />
                </div>
            </div>
            <div className={`mt-4 flex gap-2 items-center`}>
                <div className={`min-w-[100px]`}>
                    Name
                </div>
                <TextField
                    defaultValue={`${_question?.question || ''}`}
                    fullWidth
                    onChange={handleOnChangeName}
                />
            </div>
            <div className={`mt-4 flex gap-4 items-center`}>
                <div className={`w-1/5 flex items-center`}>
                    <div className={`min-w-[100px]`}>
                        SC
                    </div>
                    <Switch
                        checked={_question?.sc_status || false}
                        onChange={handleOnChangeScStatus}
                    />
                </div>
                <div className={`w-2/5 flex items-center gap-2`}>
                    <div className={`min-w-[100px]`}>
                        Min
                    </div>
                    <TextField
                        defaultValue={`${_question?.spec_min || ''}`}
                        fullWidth
                        onChange={handleOnChangeMin}
                        type='number'
                    />
                </div>
                <div className={`w-2/5 flex items-center gap-2`}>
                    <div className={`min-w-[100px]`}>
                        Max
                    </div>
                    <TextField
                        defaultValue={`${_question?.spec_max || ''}`}
                        fullWidth
                        onChange={handleOnChangeMax}
                        type='number'
                    />
                </div>
            </div>
            <div className={`flex items-center mt-4`}>
                <div className={`min-w-[100px]`}>
                    X-bar
                </div>
                <div className='flex-grow gap-4 flex'>
                    <div className={`flex-grow`}>
                        <TextField
                            fullWidth
                            label={`UCL_Xbar`}
                            defaultValue={`${_question?.UCL_Xbar || ''}`}
                            onChange={handleOnChangeUCL_Xbar}
                            type='number'
                            disabled={!_question?.sc_status}
                        />
                    </div>
                    <div className={`flex-grow`}>
                        <TextField
                            fullWidth
                            label={`CL_Xbar`}
                            defaultValue={`${_question?.CL_Xbar || ''}`}
                            onChange={handleOnChangeCL_Xbar}
                            type='number'
                            disabled={!_question?.sc_status}
                        />
                    </div>
                    <div className={`flex-grow`}>
                        <TextField
                            fullWidth
                            label={`LCL_Xbar`}
                            defaultValue={`${_question?.LCL_Xbar || ''}`}
                            onChange={handleOnChangeLCL_Xbar}
                            type='number'
                            disabled={!_question?.sc_status}
                        />
                    </div>
                </div>
            </div>
            <div className={`flex items-center mt-4`}>
                <div className={`min-w-[100px]`}>
                    R-chart
                </div>
                <div className='flex-grow gap-4 flex'>
                    <div className={`flex-grow`}>
                        <TextField
                            fullWidth
                            label={`UCL_Rchart`}
                            defaultValue={`${_question?.UCL_Rchart || ''}`}
                            onChange={handleOnChangeUCL_Rchart}
                            type='number'
                            disabled={!_question?.sc_status}
                        />
                    </div>
                    <div className={`flex-grow`}>
                        <TextField
                            fullWidth
                            label={`CL_Rchart`}
                            defaultValue={`${_question?.CL_Rchart || ''}`}
                            onChange={handleOnChangeCL_Rchart}
                            type='number'
                            disabled={!_question?.sc_status}
                        />
                    </div>
                    <div className={`flex-grow`}>
                        <TextField
                            fullWidth
                            label={`LCL_Rchart`}
                            defaultValue={`${_question?.LCL_Rchart || ''}`}
                            onChange={handleOnChangeLCL_Rchart}
                            type='number'
                            disabled={!_question?.sc_status}
                        />
                    </div>
                </div>
            </div>
            <div>
                <Formula
                    formula={_formula}
                    setFormula={setFormula}
                    partID={partID}
                    setChangeData={() => {
                        console.log("change")
                    }}
                    rawSubcategory={subcategories}
                />
            </div>
        </div>
        <DialogActions>
            <Button
                variant='contained'
                onClick={handleOnConfirm}
            >
                Confirm
            </Button>
        </DialogActions>
    </Dialog>
  )
}

export default EditQuestion