import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { APIServices } from "./../../helpers/APIServices";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Checkbox from "@mui/material/Checkbox";
import SimpleBackdrop from "../../Components/Backdrop";
import { UserContext } from "../../Layout";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import { changeIDFormula } from "./FormUpdateQuestion";

const labelCheckbox = { inputProps: { "aria-label": "Checkbox demo" } };

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function FromAddSubCategories({
  partId,
  setOpen,
  open,
  setSuccess,
  categories,
  setCategories,
  part,
}) {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [subCategory, setSubCategory] = useState("");
  const [revise, setRevise] = useState(false);
  const [revisionRecord, setRevisionRecord] = useState("");
  // const [part, setPart] = useState("");
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: "info",
    massage: "",
  });
  const navigate = useNavigate();
  const cloneCategories = [...categories].slice(1);
  const handleChange = (event) => {
    setSelectedCategory(event.target.value); //id
  };

  const handleClose = () => {
    setSubCategory("");
    setSelectedCategory("");
    setOpen(false);
    setRevise(false);
    setRevisionRecord("");
  };

  const handleCloseAlert = () => {
    setAlert((data) => ({ ...data, open: false }));
  };

  const handleReset = () => {
    setSubCategory("");
    setSelectedCategory("");
    setRevise("");
    setRevise(false);
    setRevisionRecord("");
  };

  // const findOnePart = async () => {
  // const findPart = await APIServices.findOnePart(partId);
  // const partData = findPart.data.data;
  // const newPart = {
  //   id: partData.id,
  //   ...partData.attributes,
  // };

  //   setPart(newPart);
  // };

  const signature =
    user.signature !== null
      ? user.signature[user.signature.length - 1].url
      : "";
  const createSubCategories = async (category, subCategories, partId) => {
    // navigate("/layout/revise");
    // return;
    // if (part.papers.data.length > 0 && revise === false) {
    //   setAlert({
    //     open: true,
    //     type: "error",
    //     massage: "พาร์ทนี้มีการเพิ่ม paper กรุณาทำการกด revise",
    //   });
    //   return;
    // }
    if (revise === false) {
      if (category === "" || subCategories === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "กรอกข้อมูลไม่ครบถ้วน",
        });
      } else {
        setLoading(true);
        const response = await APIServices.createSubCategories(
          category,
          subCategories,
          partId,
          true,
          "1",
          part.revise_time
        );
        if (response.status === 200) {
          setSuccess(true);
        }
        setLoading(false);
        setOpen(false);
        setSubCategory("");
        setSelectedCategory("");
        setRevisionRecord("");
      }
    } else {
      if (category === "" || subCategories === "" || revisionRecord === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "กรอกข้อมูลไม่ครบถ้วน",
        });
      } else {
        setLoading(true);
        const findPart = await APIServices.findOnePart(partId);
        const partData = findPart.data.data;
        const part = {
          id: partData.id,
          ...partData.attributes,
        };
        const reviseTime = part.revise_time ? part.revise_time : null;
        let partRevise;
        let keepNewData = [];
        let dataQuestionFormula = [];
        if (part.old_parts.data.length === 0) {
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            part.id,
            false,
            true
          );
          partRevise = responseReviseForPart;
        } else {
          let oldPartID = [];
          part.old_parts.data.forEach((oldPart) => {
            oldPartID.push(oldPart.id);
          });
          oldPartID.push(part.id);
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            oldPartID,
            false,
            true
          );
          partRevise = responseReviseForPart;
        }

        await APIServices.updatePart(
          part.part_name,
          part.part_code,
          part.id,
          false,
          false
        );

        const part_data = partRevise.data.data.attributes;
        const part_id = partRevise.data.data.id;

        if (part.sub_categories.data.length !== 0) {
          for (
            let indexSubCat = 0;
            indexSubCat < part.sub_categories.data.length;
            indexSubCat++
          ) {
            const subCat = part.sub_categories.data[indexSubCat];
            const categoryID = subCat.attributes.category.data.id;
            const subCatName = subCat.attributes.sub_categories;
            const reviseTimeSubCat = subCat.attributes.revise_time;
            const oldSubStatus = subCat.attributes.status;

            let responseSubCat;
            if (subCat.attributes.old_sub_categories.data.length === 0) {
              const response = await APIServices.createSubCategories(
                categoryID,
                subCatName,
                part_id,
                oldSubStatus,
                "0",
                reviseTimeSubCat,
                subCat.id
              );
              responseSubCat = response;
            } else {
              let oldSubCatID = [];
              subCat.attributes.old_sub_categories.data.forEach((oldSub) => {
                oldSubCatID.push(oldSub.id);
              });
              oldSubCatID.push(subCat.id);
              const response = await APIServices.createSubCategories(
                categoryID,
                subCatName,
                part_id,
                oldSubStatus,
                "0",
                reviseTimeSubCat,
                oldSubCatID
              );
              responseSubCat = response;
            }
            const realSubCatID = responseSubCat.data.data.id;

            if (subCat.attributes.questions.data.length !== 0) {
              for (
                let indexQuestion = 0;
                indexQuestion < subCat.attributes.questions.data.length;
                indexQuestion++
              ) {
                const realQuestion =
                  subCat.attributes.questions.data[indexQuestion];
                const specMin = realQuestion.attributes.spec_min;
                const specMax = realQuestion.attributes.spec_max;
                const sc_status = realQuestion.attributes.sc_status;
                const question = realQuestion.attributes.question;
                const UCL_xBar = realQuestion.attributes.UCL_Xbar;
                const CL_xBar = realQuestion.attributes.CL_Xbar;
                const LCL_xBar = realQuestion.attributes.LCL_Xbar;
                const UCL_rChart = realQuestion.attributes.UCL_Rchart;
                const CL_rChart = realQuestion.attributes.CL_Rchart;
                const LCL_rChart = realQuestion.attributes.LCL_Rchart;
                const subCategoryID = realSubCatID;
                const status = realQuestion.attributes.status;
                const formula = realQuestion.attributes.formula
                  ? realQuestion.attributes.formula.data
                  : null;
                const reviseTimeQuestion = realQuestion.attributes.revise_time;

                if (realQuestion.attributes.old_question.data.length === 0) {
                  const response = await APIServices.createQuestion(
                    specMin,
                    specMax,
                    sc_status,
                    question,
                    UCL_xBar,
                    CL_xBar,
                    LCL_xBar,
                    UCL_rChart,
                    CL_rChart,
                    LCL_rChart,
                    subCategoryID,
                    status,
                    formula,
                    "0",
                    reviseTimeQuestion,
                    realQuestion.id
                  );
                  if (response.data.data.attributes.formula.data) {
                    keepNewData.push({
                      id: response.data.data.id,
                      formula: response.data.data.attributes.formula,
                    });
                  }
                  dataQuestionFormula.push({
                    old_id: realQuestion.id,
                    new_id: response.data.data.id,
                  });
                } else {
                  let oldQuestionID = [];
                  realQuestion.attributes.old_question.data.forEach(
                    (oldQuestion) => {
                      oldQuestionID.push(oldQuestion.id);
                    }
                  );
                  oldQuestionID.push(realQuestion.id);
                  const response = await APIServices.createQuestion(
                    specMin,
                    specMax,
                    sc_status,
                    question,
                    UCL_xBar,
                    CL_xBar,
                    LCL_xBar,
                    UCL_rChart,
                    CL_rChart,
                    LCL_rChart,
                    subCategoryID,
                    status,
                    formula,
                    "0",
                    reviseTimeQuestion,
                    oldQuestionID
                  );
                  if (response.data.data.attributes.formula.data) {
                    keepNewData.push({
                      id: response.data.data.id,
                      formula: response.data.data.attributes.formula,
                    });
                  }
                  dataQuestionFormula.push({
                    old_id: realQuestion.id,
                    new_id: response.data.data.id,
                  });
                }
              }
            }
          }
        }
        if (partRevise.data.data !== null) {
          if (part.image.data !== null) {
            const files = await fetch(
              // "http://agrappl03.aapico.com:1339/uploads/9_FC_8_B6_E7_F948_451_A_8_CB_6_4_A821_B407339_a1915890e4.jpeg"
              `${part.image.data.attributes.url}`
              //config.server +
            )
              .then((response) => response.blob())
              .then((blob) => {
                const myFile = new File(
                  [blob],
                  `${part.image.data.attributes.name}`,
                  {
                    type: blob.type,
                  }
                );
                return myFile;
              });

            const formData = new FormData();
            formData.append("ref", "api::qc-part.qc-part");
            formData.append("refId", part_id);
            formData.append("field", "image");
            formData.append("files", files);
            await APIServices.addUserImage(formData);
          }
        }

        await APIServices.createSubCategories(
          category,
          subCategories,
          part_id,
          true,
          "1",
          part_data.revise_time
        );

        if (partRevise.data.data.id !== null) {
          await APIServices.reviseForUpdate(
            revisionRecord,
            new Date(),
            signature,
            user.id,
            part_data.revise_time,
            part_id
          );
        }
        changeIDFormula(keepNewData, dataQuestionFormula);
        setSubCategory("");
        setSelectedCategory("");
        setRevisionRecord("");
        setRevise(false);
        setLoading(false);
        setOpen(false);
        navigate("/layout/revise");
      }
    }
  };

  return (
    <BootstrapDialog
      // onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}>
      <SimpleBackdrop open={loading} setOpen={setLoading} />
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        แบบฟอร์มข้อมูลหัวข้อคำถาม
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box
          component='form'
          sx={{
            "& .MuiTextField-root": {
              m: 1,
              width: "45ch",
            },
          }}
          noValidate
          autoComplete='off'>
          <div>
            <Snackbar
              open={alert.open}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleCloseAlert}>
              <Alert
                onClose={handleCloseAlert}
                severity={alert.type}
                sx={{ width: "100%" }}>
                {alert.massage}
              </Alert>
            </Snackbar>
          </div>
          {part && (
            // <Stack spacing={1}>
            //   <Stack direction="row"  spacing={1}>
            //     <Chip label={"Part Name: " + part.part_name} />
            //   </Stack>
            //   <Stack direction="row" spacing={1}>
            //     <Chip label={"Part Code: " + part.part_code} />
            //   </Stack>
            // </Stack>

            <div className={`flex flex-col items-baseline mb-2`}>
              <div className={`ml-2 font-bold text-lg`}>{part.part_name}</div>
              <div className={`ml-2 `}>({part.part_code})</div>
            </div>
          )}
          {/* <div className="grid grid-cols-4 mt-2">
            {part.revise &&
              part.revise.data.attributes.revise_status === "4" && (
                <div className="flex flex-row justify-center items-center mr-6 ">
                  <div>Revise :</div>
                  <Checkbox
                    {...labelCheckbox}
                    // disabled={
                    //   part.revise &&
                    //   part.revise.data.attributes.revise_status !== "4"
                    // }
                    onChange={(e) => setRevise(e.target.checked)}
                  />
                </div>
              )}
            {revise === true && (
              <div className="col-span-3">
                <TextField
                  id="outlined-password-input"
                  label="Revision Record"
                  value={revisionRecord}
                  onChange={(e) => setRevisionRecord(e.target.value)}
                />
              </div>
            )}
          </div> */}
          <div className='grid grid-cols-4'>
            <div>
              <FormControl sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id='demo-simple-select-helper-label'>
                  Categories
                </InputLabel>
                <Select
                  value={selectedCategory}
                  label='Categories'
                  onChange={handleChange}>
                  {cloneCategories.map((data, index) => (
                    <MenuItem key={index} value={data.id}>
                      {data.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className='col-span-3'>
              <TextField
                label='Sub Category'
                value={subCategory}
                onChange={(e) => setSubCategory(e.target.value)}
              />
            </div>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleReset}>
          ล้างข้อมูล
        </Button>
        <Button
          variant='contained'
          autoFocus
          onClick={() =>
            createSubCategories(
              selectedCategory,
              subCategory,
              partId,
              revisionRecord
            )
          }>
          บันทึก
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default FromAddSubCategories;
