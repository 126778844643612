import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { APIServices } from "./../../helpers/APIServices";
import SimpleBackdrop from "../../Components/Backdrop";
import Checkbox from "@mui/material/Checkbox";
import { UserContext } from "../../Layout";
import config from "../../config";
import { useNavigate } from "react-router-dom";
import DeleteSubCatModal from "../../Components/DeleteSubCatModal";
import { changeIDFormula } from "./FormUpdateQuestion";

const labelCheckbox = { inputProps: { "aria-label": "Checkbox demo" } };

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const FormUpdateSubCategory = ({
  partId,
  state,
  setSuccess,
  categories,
  partName,
  partCode,
  part,
}) => {
  const [value, setValue] = [...state];
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    massage: "",
  });
  const user = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const cloneCategories = [...categories].slice(1);
  const [revise, setRevise] = useState(false);
  // const [part, 245] = useState();
  const [revisionRecord, setRevisionRecord] = useState("");
  const [changeData, setChangeData] = useState(false);
  const navigate = useNavigate();
  const [openDelete, setOpenDelete] = useState({
    open: false,
    message: "",
    status_revise: false,
    trueButton: "ใช่",
  });
  const handleChange = (e) => {
    setChangeData(true);
    setValue((prev) => {
      return {
        ...prev,
        data: {
          ...prev.data,
          category: {
            data: {
              ...prev.data.category.data,
              id: e.target.value,
            },
          },
        },
      };
    });
  };

  const selectCategory = () => {
    if (
      value.data !== null &&
      value.data.category.data.attributes.category !== undefined
    ) {
      return value.data.category.data.id;
    }
  };

  const findOnePart = async () => {
    // setLoading(true);
    const findPart = await APIServices.findOnePartForUseName(partId);
    // const findPart = {};
    const partData = findPart.data.data;
    const newPart = {
      id: partData.id,
      ...partData.attributes,
    };
    console.log("newPart 144", newPart);
    // return
    return newPart;

    // setPart(newPart);
    // setLoading(false);
  };

  const handleClose = () => {
    setValue({ open: false, data: null });
  };

  const handleCloseAlert = () => {
    setAlert((data) => ({ ...data, open: false }));
  };
  const signature =
    user.signature !== null
      ? user.signature[user.signature.length - 1].url
      : "";

  const handleUpdateSubCategory = async (data, id) => {
    if (data === null) {
      setAlert({
        open: true,
        type: "error",
        massage: "กรอกข้อมูลไม่ครบถ้วน",
      });
      return;
    }

    const categories = data.category.data.id;
    const subCategory = data.sub_categories;
    const status = data.status;

    if (revise === false) {
      if (categories === null || subCategory === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "กรอกข้อมูลไม่ครบถ้วน",
        });
      } else {
        setLoading(true);
        await APIServices.updateSubCategory(
          categories,
          subCategory,
          id,
          status
        );
        setLoading(false);
        setValue({ open: false, data: null });
        setSuccess(true);
      }
    } else {
      if (categories === null || subCategory === "" || revisionRecord === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "กรอกข้อมูลไม่ครบถ้วน",
        });
      } else {
        setLoading(true);
        const part = await findOnePart();
        const reviseTime = part.revise_time ? part.revise_time : null;
        let partRevise;
        let keepNewData = [];
        let dataQuestionFormula = [];
        if (part.old_parts.data.length === 0) {
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            part.id,
            false,
            true
          );
          partRevise = responseReviseForPart;
        } else {
          let oldPartID = [];
          part.old_parts.data.forEach((oldPart) => {
            oldPartID.push(oldPart.id);
          });
          oldPartID.push(part.id);
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            oldPartID,
            false,
            true
          );
          partRevise = responseReviseForPart;
        }
        await APIServices.updatePart(
          part.part_name,
          part.part_code,
          part.id,
          false,
          false
        );
        const part_data = partRevise.data.data.attributes;
        const part_id = partRevise.data.data.id;

        if (part.sub_categories.data.length !== 0) {
          for (
            let indexSubCat = 0;
            indexSubCat < part.sub_categories.data.length;
            indexSubCat++
          ) {
            const subCat = part.sub_categories.data[indexSubCat];
            const oldSubID = subCat.id;
            const categoryID = subCat.attributes.category.data.id;
            const subCatName = subCat.attributes.sub_categories;
            const oldStatus = subCat.attributes.status;
            const reviseTimeSubCat = subCat.attributes.revise_time;
            let reviseStatus = "0";
            let res;
            if (oldSubID === id) {
              if (changeData) {
                reviseStatus = "2";
              }
              if (status === false && oldStatus === true) {
                reviseStatus = "3";
              }
              if (subCat.attributes.old_sub_categories.data.length === 0) {
                const responseSubCat = await APIServices.createSubCategories(
                  categoryID,
                  subCategory,
                  part_id,
                  status,
                  reviseStatus,
                  part_data.revise_time,
                  oldSubID
                );
                res = responseSubCat;
              } else {
                let oldSubCatID = [];
                subCat.attributes.old_sub_categories.data.forEach((oldSub) => {
                  oldSubCatID.push(oldSub.id);
                });
                oldSubCatID.push(oldSubID);
                const responseSubCat = await APIServices.createSubCategories(
                  categoryID,
                  subCategory,
                  part_id,
                  status,
                  reviseStatus,
                  part_data.revise_time,
                  oldSubCatID
                );
                res = responseSubCat;
              }
            } else {
              if (subCat.attributes.old_sub_categories.data.length === 0) {
                const responseSubCat = await APIServices.createSubCategories(
                  categoryID,
                  subCatName,
                  part_id,
                  oldStatus,
                  reviseStatus,
                  reviseTimeSubCat,
                  oldSubID
                );
                res = responseSubCat;
              } else {
                let oldSubCatID = [];
                subCat.attributes.old_sub_categories.data.forEach((oldSub) => {
                  oldSubCatID.push(oldSub.id);
                });
                oldSubCatID.push(oldSubID);
                const responseSubCat = await APIServices.createSubCategories(
                  categoryID,
                  subCatName,
                  part_id,
                  oldStatus,
                  reviseStatus,
                  reviseTimeSubCat,
                  oldSubCatID
                );
                res = responseSubCat;
              }
            }

            // if (status === true) {
            //   const responseSubCat = await APIServices.createSubCategories(
            //     categoryID,
            //     subCatName,
            //     part_id,
            //     true,
            //     "2"
            //   );
            //   dataSubNewID = responseSubCat;
            // } else {
            //   const responseSubCat = await APIServices.createSubCategories(
            //     categoryID,
            //     subCatName,
            //     part_id,
            //     true,
            //     "3"
            //   );
            //   dataSubNewID = responseSubCat;
            // }

            const realSubCatID = res.data.data.id;

            if (subCat.attributes.questions.data.length !== 0) {
              for (
                let indexQuestion = 0;
                indexQuestion < subCat.attributes.questions.data.length;
                indexQuestion++
              ) {
                const realQuestion =
                  subCat.attributes.questions.data[indexQuestion];
                const specMin = realQuestion.attributes.spec_min;
                const specMax = realQuestion.attributes.spec_max;
                const sc_status = realQuestion.attributes.sc_status;
                const question = realQuestion.attributes.question;
                const UCL_xBar = realQuestion.attributes.UCL_Xbar;
                const CL_xBar = realQuestion.attributes.CL_Xbar;
                const LCL_xBar = realQuestion.attributes.LCL_Xbar;
                const UCL_rChart = realQuestion.attributes.UCL_Rchart;
                const CL_rChart = realQuestion.attributes.CL_Rchart;
                const LCL_rChart = realQuestion.attributes.LCL_Rchart;
                const subCategory = realSubCatID;
                const status = realQuestion.attributes.status;
                const formula = realQuestion.attributes.formula
                  ? realQuestion.attributes.formula.data
                  : null;
                const reviseTimeQuestion = realQuestion.attributes.revise_time;
                if (realQuestion.attributes.old_question.data.length === 0) {
                  const responseQuestion = await APIServices.createQuestion(
                    specMin,
                    specMax,
                    sc_status,
                    question,
                    UCL_xBar,
                    CL_xBar,
                    LCL_xBar,
                    UCL_rChart,
                    CL_rChart,
                    LCL_rChart,
                    subCategory,
                    status,
                    formula,
                    "0",
                    reviseTimeQuestion,
                    realQuestion.id
                  );

                  if (responseQuestion.data.data.attributes.formula.data) {
                    keepNewData.push({
                      id: responseQuestion.data.data.id,
                      formula: responseQuestion.data.data.attributes.formula,
                    });
                  }
                  dataQuestionFormula.push({
                    old_id: realQuestion.id,
                    new_id: responseQuestion.data.data.id,
                  });
                } else {
                  let oldQuestionID = [];
                  realQuestion.attributes.old_question.data.forEach(
                    (oldQuestion) => {
                      oldQuestionID.push(oldQuestion.id);
                    }
                  );
                  oldQuestionID.push(realQuestion.id);
                  const responseQuestion = await APIServices.createQuestion(
                    specMin,
                    specMax,
                    sc_status,
                    question,
                    UCL_xBar,
                    CL_xBar,
                    LCL_xBar,
                    UCL_rChart,
                    CL_rChart,
                    LCL_rChart,
                    subCategory,
                    status,
                    formula,
                    "0",
                    reviseTimeQuestion,
                    oldQuestionID
                  );

                  if (responseQuestion.data.data.attributes.formula.data) {
                    keepNewData.push({
                      id: responseQuestion.data.data.id,
                      formula: responseQuestion.data.data.attributes.formula,
                    });
                  }
                  dataQuestionFormula.push({
                    old_id: realQuestion.id,
                    new_id: responseQuestion.data.data.id,
                  });
                }
              }
            }
          }
        }

        // return;
        if (partRevise.data.data !== null) {
          if (part.image.data !== null) {
            const files = await fetch(
              // "http://agrappl03.aapico.com:1339/uploads/9_FC_8_B6_E7_F948_451_A_8_CB_6_4_A821_B407339_a1915890e4.jpeg"
              `${part.image.data.attributes.url}`
              // config.server +
            )
              .then((response) => response.blob())
              .then((blob) => {
                const myFile = new File(
                  [blob],
                  `${part.image.data.attributes.name}`,
                  {
                    type: blob.type,
                  }
                );
                return myFile;
              });

            const formData = new FormData();
            formData.append("ref", "api::qc-part.qc-part");
            formData.append("refId", part_id);
            formData.append("field", "image");
            formData.append("files", files);
            await APIServices.addUserImage(formData);
          }
        }

        if (partRevise.data.data.id !== null) {
          await APIServices.reviseForUpdate(
            revisionRecord,
            new Date(),
            signature,
            user.id,
            part_data.revise_time,
            part_id
          );
        }
        changeIDFormula(keepNewData, dataQuestionFormula);
      }
      setLoading(false);
      setRevisionRecord("");
      setRevise(false);
      setValue({ open: false, data: null });
      setSuccess(true);
      navigate("/layout/revise");
    }
  };

  const checkRole = () => {
    if (
      user.qc_role === "super_admin" ||
      user.qc_role === "admin" ||
      user.qc_role === "release"
    ) {
      return true;
    }
  };

  // status revise 1 = เพิ่ม
  // status revise 2 = แก้ไข
  // status revise 3 = ลบ

  const handleDeleteSubCategory = async () => {
    let message,
      revise = false,
      trueButton = "ใช่";
    setLoading(true);
    // const part = await findOnePart()
    // console.log("part 519", part);
    setLoading(false);
    // remove function check revise when delete subcategory - sawanon:20230718
    // if (part.papers.data.length > 0) {
    //   message = (
    //     <div>
    //       <div>พาร์ทนี้มีการเพิ่ม paper ระบบจะทำการ revise อัตโนมัติ</div>
    //       <div>กรุณากรอก Revision Record</div>
    //     </div>
    //   );
    //   revise = true;
    //   trueButton = "ตกลง";
    // } else {
    message = "คุณต้องการลบข้อมูลใช่หรือไม่";
    // }
    setOpenDelete({
      open: true,
      message: message,
      status_revise: revise,
      trueButton: trueButton,
    });
  };

  const checkIDFormula = (id, data) => {
    const myQuestions = data.data.questions;
    let formulaData = [];
    if (part.sub_categories) {
      part.sub_categories.data.forEach((subCat) => {
        if (subCat.id === id) {
          return;
        }
        const questions = subCat.attributes.questions.data;

        let questionData = [];
        questions.forEach((question) => {
          let questionID = [];
          myQuestions.data.forEach((myQuestion) => {
            if (question.attributes.formula.data) {
              const hasID = question.attributes.formula.data.includes(
                myQuestion.id
              );
              if (hasID) {
                if (myQuestion.id) {
                  questionID.push(myQuestion.id);
                }
              }
            }
          });
          if (questionID.length !== 0) {
            questionData.push({
              myQuestionID: questionID,
              questionID: question.id,
              question: question.attributes.question,
              status_question: question.attributes.status,
            });
            return;
          }
        });
        if (questionData.length !== 0) {
          if (subCat.attributes.status) {
            formulaData.push({
              statusForAlert: true,
              subCategory: subCat.attributes.sub_categories,
              subCategoryID: subCat.id,
              category: subCat.attributes.category.data.attributes.category,
              status_subCategory: subCat.attributes.status,
              question: questionData,
            });
          }
        }
      });
    }
    return {
      data: formulaData,
    };
  };

  const deleteSubCat = async (result, data) => {
    const categories = data.data.category.data.id;
    const subCategory = data.data.sub_categories;
    const id = data.data.id;

    if (result) {
      const idFormula = checkIDFormula(id, data);
      if (idFormula && idFormula.data.length !== 0) {
        setAlert({
          open: true,
          type: "error",
          massage: (
            <>
              <div>มีการใช้คำถามในการคำนวณคำตอบ กรุณาทำการแก้ไขที่</div>
              {idFormula.data.map((subCat, subIndex) => {
                return (
                  <div
                    key={subIndex}
                    className='flex flex-row items-center mb-2 mt-2  pt-2 pb-2'>
                    <div className='flex mr-2 p-4 bg-white text-[#D32F2F] w-2 h-2 rounded-full items-center justify-center'>
                      {subIndex + 1}
                    </div>
                    <div className='flex flex-col'>
                      <div>Category : {subCat.category}</div>
                      <div>Sub Category : {subCat.subCategory}</div>
                      {subCat.question.map((question, questionIndex) => {
                        return (
                          <div key={questionIndex}>
                            - Check point : {question.question}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          ),
        });
        return;
      }
      // return;
      if (openDelete.status_revise) {
        if (revisionRecord === "") {
          setAlert({
            open: true,
            type: "error",
            massage: "กรุณากรอก Revision Record",
          });
          return;
        }
        setLoading(true);
        const reviseTime = part.revise_time ? part.revise_time : null;
        let partRevise;
        let keepNewData = [];
        let dataQuestionFormula = [];
        if (part.old_parts.data.length === 0) {
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            part.id,
            false,
            true
          );
          partRevise = responseReviseForPart;
        } else {
          let oldPartID = [];
          part.old_parts.data.forEach((oldPart) => {
            oldPartID.push(oldPart.id);
          });
          oldPartID.push(part.id);
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            oldPartID,
            false,
            true
          );
          partRevise = responseReviseForPart;
        }
        await APIServices.updatePart(
          part.part_name,
          part.part_code,
          part.id,
          false,
          false
        );
        const part_data = partRevise.data.data.attributes;
        const part_id = partRevise.data.data.id;

        if (part.sub_categories.data.length !== 0) {
          for (
            let indexSubCat = 0;
            indexSubCat < part.sub_categories.data.length;
            indexSubCat++
          ) {
            const subCat = part.sub_categories.data[indexSubCat];
            const oldSubID = subCat.id;
            const categoryID = subCat.attributes.category.data.id;
            const subCatName = subCat.attributes.sub_categories;
            const oldStatus = subCat.attributes.status;
            const reviseTimeSubCat = subCat.attributes.revise_time;
            let reviseStatus = "0";
            let res;
            if (oldSubID === id) {
              // if (changeData) {
              //   reviseStatus = "2";
              // }
              if (oldStatus === true) {
                reviseStatus = "3";
              }
              if (subCat.attributes.old_sub_categories.data.length === 0) {
                const responseSubCat = await APIServices.createSubCategories(
                  categoryID,
                  subCategory,
                  part_id,
                  false, // status
                  reviseStatus,
                  part_data.revise_time,
                  oldSubID
                );
                res = responseSubCat;
              } else {
                let oldSubCatID = [];
                subCat.attributes.old_sub_categories.data.forEach((oldSub) => {
                  oldSubCatID.push(oldSub.id);
                });
                oldSubCatID.push(oldSubID);
                const responseSubCat = await APIServices.createSubCategories(
                  categoryID,
                  subCategory,
                  part_id,
                  false, // status
                  reviseStatus,
                  part_data.revise_time,
                  oldSubCatID
                );
                res = responseSubCat;
              }
            } else {
              if (subCat.attributes.old_sub_categories.data.length === 0) {
                const responseSubCat = await APIServices.createSubCategories(
                  categoryID,
                  subCatName,
                  part_id,
                  oldStatus,
                  reviseStatus,
                  reviseTimeSubCat,
                  oldSubID
                );
                res = responseSubCat;
              } else {
                let oldSubCatID = [];
                subCat.attributes.old_sub_categories.data.forEach((oldSub) => {
                  oldSubCatID.push(oldSub.id);
                });
                oldSubCatID.push(oldSubID);
                const responseSubCat = await APIServices.createSubCategories(
                  categoryID,
                  subCatName,
                  part_id,
                  oldStatus,
                  reviseStatus,
                  reviseTimeSubCat,
                  oldSubCatID
                );
                res = responseSubCat;
              }
            }
            const realSubCatID = res.data.data.id;

            if (subCat.attributes.questions.data.length !== 0) {
              for (
                let indexQuestion = 0;
                indexQuestion < subCat.attributes.questions.data.length;
                indexQuestion++
              ) {
                const realQuestion =
                  subCat.attributes.questions.data[indexQuestion];
                const specMin = realQuestion.attributes.spec_min;
                const specMax = realQuestion.attributes.spec_max;
                const sc_status = realQuestion.attributes.sc_status;
                const question = realQuestion.attributes.question;
                const UCL_xBar = realQuestion.attributes.UCL_Xbar;
                const CL_xBar = realQuestion.attributes.CL_Xbar;
                const LCL_xBar = realQuestion.attributes.LCL_Xbar;
                const UCL_rChart = realQuestion.attributes.UCL_Rchart;
                const CL_rChart = realQuestion.attributes.CL_Rchart;
                const LCL_rChart = realQuestion.attributes.LCL_Rchart;
                const subCategory = realSubCatID;
                const status = realQuestion.attributes.status;
                const formula = realQuestion.attributes.formula
                  ? realQuestion.attributes.formula.data
                  : null;
                const reviseTimeQuestion = realQuestion.attributes.revise_time;
                if (realQuestion.attributes.old_question.data.length === 0) {
                  const responseQuestion = await APIServices.createQuestion(
                    specMin,
                    specMax,
                    sc_status,
                    question,
                    UCL_xBar,
                    CL_xBar,
                    LCL_xBar,
                    UCL_rChart,
                    CL_rChart,
                    LCL_rChart,
                    subCategory,
                    status,
                    formula,
                    "0",
                    reviseTimeQuestion,
                    realQuestion.id
                  );

                  if (responseQuestion.data.data.attributes.formula.data) {
                    keepNewData.push({
                      id: responseQuestion.data.data.id,
                      formula: responseQuestion.data.data.attributes.formula,
                    });
                  }
                  dataQuestionFormula.push({
                    old_id: realQuestion.id,
                    new_id: responseQuestion.data.data.id,
                  });
                } else {
                  let oldQuestionID = [];
                  realQuestion.attributes.old_question.data.forEach(
                    (oldQuestion) => {
                      oldQuestionID.push(oldQuestion.id);
                    }
                  );
                  oldQuestionID.push(realQuestion.id);
                  const responseQuestion = await APIServices.createQuestion(
                    specMin,
                    specMax,
                    sc_status,
                    question,
                    UCL_xBar,
                    CL_xBar,
                    LCL_xBar,
                    UCL_rChart,
                    CL_rChart,
                    LCL_rChart,
                    subCategory,
                    status,
                    formula,
                    "0",
                    reviseTimeQuestion,
                    oldQuestionID
                  );

                  if (responseQuestion.data.data.attributes.formula.data) {
                    keepNewData.push({
                      id: responseQuestion.data.data.id,
                      formula: responseQuestion.data.data.attributes.formula,
                    });
                  }
                  dataQuestionFormula.push({
                    old_id: realQuestion.id,
                    new_id: responseQuestion.data.data.id,
                  });
                }
              }
            }
          }
        }

        // return;
        if (partRevise.data.data !== null) {
          if (part.image.data !== null) {
            const files = await fetch(
              // "http://agrappl03.aapico.com:1339/uploads/9_FC_8_B6_E7_F948_451_A_8_CB_6_4_A821_B407339_a1915890e4.jpeg"
              `${part.image.data.attributes.url}`
              // config.server +
            )
              .then((response) => response.blob())
              .then((blob) => {
                const myFile = new File(
                  [blob],
                  `${part.image.data.attributes.name}`,
                  {
                    type: blob.type,
                  }
                );
                return myFile;
              });

            const formData = new FormData();
            formData.append("ref", "api::qc-part.qc-part");
            formData.append("refId", part_id);
            formData.append("field", "image");
            formData.append("files", files);
            await APIServices.addUserImage(formData);
          }
        }

        if (partRevise.data.data.id !== null) {
          await APIServices.reviseForUpdate(
            revisionRecord,
            new Date(),
            signature,
            user.id,
            part_data.revise_time,
            part_id
          );
        }
        changeIDFormula(keepNewData, dataQuestionFormula);
        setTimeout(() => {
          setLoading(false);
          setRevisionRecord("");
          setRevise(false);
          setValue({ open: false, data: null });
          setSuccess(true);
          navigate("/layout/revise");
        }, 1500);
      } else {
        setLoading(true);
        await APIServices.updateSubCategory(
          categories,
          subCategory,
          id,
          false,
          "3"
        );
        setTimeout(() => {
          setRevise(false);
          setRevisionRecord("");
          setValue({ open: false, data: null });
          setSuccess(true);
          setLoading(false);
        }, 1500);
      }
    }
  };

  // useEffect(() => {
  //   findOnePart();
  // }, []);
  return (
    <>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby='customized-dialog-title'
        open={value.open}>
        <SimpleBackdrop open={loading} setOpen={setLoading} />
        <BootstrapDialogTitle
          id='customized-dialog-title'
          onClose={handleClose}>
          แบบฟอร์มแก้ไขข้อมูลหัวข้อคำถาม
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component='form'
            sx={{
              "& .MuiTextField-root": {
                m: 1,
                width: "45ch",
              },
            }}
            noValidate
            autoComplete='off'>
            <div>
              <Snackbar
                open={alert.open}
                autoHideDuration={3000}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                onClose={handleCloseAlert}>
                <Alert
                  onClose={handleCloseAlert}
                  severity={alert.type}
                  sx={{ width: "100%" }}>
                  {alert.massage}
                </Alert>
              </Snackbar>
            </div>

            <div className={`flex flex-col items-baseline mb-2`}>
              <div className={`ml-2 font-bold text-lg`}>{partName}</div>
              <div className={`ml-2 `}>({partCode})</div>
            </div>

            {/* <div className="flex flex-col "> */}
            {/* {checkRole() && (
              <div className="grid grid-cols-4 mt-2">
                {part &&
                  part.revise &&
                  part.revise.data.attributes.revise_status === "4" && (
                    <div className="flex flex-row justify-center items-center mr-6 ">
                      <div>Revise :</div>
                      <Checkbox
                        {...labelCheckbox}
                        checked={revise}
                        // disabled={
                        //   part &&
                        //   part.revise &&
                        //   part.revise.data.attributes.revise_status === "4"
                        // }
                        onChange={(e) => {
                          setChangeData(true);
                          setRevise(e.target.checked);
                        }}
                      />
                    </div>
                  )}
                {revise === true && (
                  <div className="col-span-3">
                    <TextField
                      id="outlined-password-input"
                      label="Revision Record"
                      value={revisionRecord}
                      onChange={(e) => setRevisionRecord(e.target.value)}
                    />
                  </div>
                )}
              </div>
            )} */}
            <div className='grid grid-cols-4'>
              <div>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <InputLabel id='demo-simple-select-helper-label'>
                    Categories
                  </InputLabel>
                  <Select
                    value={selectCategory()}
                    label='Categories'
                    onChange={handleChange}
                    disabled={value.data.status === false}>
                    {cloneCategories.map((data, index) => (
                      <MenuItem key={index} value={data.id}>
                        {data.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
              <div className='col-span-3'>
                <TextField
                  value={value.data !== null ? value.data.sub_categories : ""}
                  label='Sub Category'
                  disabled={value.data.status === false}
                  onChange={(e) => {
                    setChangeData(true);
                    setValue((prev) => {
                      return {
                        ...prev,
                        data: {
                          ...prev.data,
                          sub_categories: e.target.value,
                        },
                      };
                    });
                  }}
                />
              </div>
            </div>
            {/* </div> */}
          </Box>
        </DialogContent>
        {checkRole() && (
          <DialogActions>
            <Button
              variant='contained'
              autoFocus
              color='error'
              onClick={() =>
                handleDeleteSubCategory(value.data, value.data.id)
              }>
              ลบ
            </Button>
            <Button
              variant='contained'
              autoFocus
              onClick={() =>
                handleUpdateSubCategory(value.data, value.data.id)
              }>
              บันทึก
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
      <DeleteSubCatModal
        open={openDelete.open}
        setOpen={setOpenDelete}
        openDelete={openDelete}
        setRevisionRecord={setRevisionRecord}
        revisionRecord={revisionRecord}
        action={deleteSubCat}
        confirmText={openDelete.trueButton}
        cancelText={"ยกเลิก"}
        header={"ยืนยันการลบข้อมูล"}
        message={
          <span>
            <span>{openDelete.message}</span>
          </span>
        }
        data={value}
      />
    </>
  );
};

export default FormUpdateSubCategory;
