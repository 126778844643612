import React, { useState } from "react";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import MuiAlert from "@mui/material/Alert";
import Fab from "@mui/material/Fab";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
const dayjs = require("dayjs");

const label = { inputProps: { "aria-label": "Switch demo" } };

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-container": {
    "& .MuiPaper-root": {
      width: "100%",
      maxWidth: "600px", // Set your width here
    },
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const FormAddImageAnswer = ({
  state,
  setSuccess,
  setOpen,
  open,
  stateIndex,
}) => {
  const [value, setValue] = [...state];
  const [index, setIndex] = [...stateIndex];
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    massage: "",
  });
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFile = async (e, question_index, sub_index) => {
    const date = dayjs().format("YYYYMMDD");
    const files = Array.from(e.target.files);
    let newFile;
    if (stateIndex[0].isSC) {
      newFile = value.sub_categories[sub_index].questions[question_index].image
        ? [...value.sub_categories[sub_index].questions[question_index].image]
        : [[], [], []];
      const plus = newFile.length;
      for (let i = 0; i < files.length; i++) {
        var file = files[i];
        const blob = file.slice(0, file.size, "image/png");
        const name = `${index.subID}-${index.qtID}-${date}-SC${
          stateIndex[0].sc_index
        }-${i + plus}`;
        const newImage = new File([blob], name, { type: "image/png" });
        const base = await getBase64(files[i]);
        newFile[stateIndex[0].sc_index].push({
          base: base,
          file: newImage,
        });
      }
    } else {
      newFile = value.sub_categories[sub_index].questions[question_index].image
        ? [...value.sub_categories[sub_index].questions[question_index].image]
        : [];
      const plus = newFile.length;
      for (let i = 0; i < files.length; i++) {
        var file = files[i];
        const blob = file.slice(0, file.size, "image/png");
        const name = `${index.subID}-${index.qtID}-${date}-${i + plus}`;
        const newImage = new File([blob], name, { type: "image/png" });
        const base = await getBase64(files[i]);
        newFile.push({
          base: base,
          file: newImage,
        });
      }
    }
    setValue((prev) => {
      let newSub_categories = [...prev.sub_categories];
      let newQuestion = newSub_categories[sub_index].questions;
      if (stateIndex[0].isSC) {
        newQuestion[question_index].image = newFile;
      } else {
        newQuestion[question_index] = {
          ...newQuestion[question_index],
          image: newFile,
        };
      }
      return {
        ...prev,
        sub_categories: newSub_categories,
      };
    });
  };

  const handleDeleteImage = (imageIndex) => {
    // required sub_categories index
    // required questions index
    // required image index
    // [].splice();
    setValue((prev) => {
      let newSub_categories = [...prev.sub_categories];
      let newQuestion = newSub_categories[index.sub_index].questions;
      const newImage = newQuestion[index.question_index].image;

      // if (imageIndex === 1) {
      //   delete newQuestion[index.question_index].image;
      // } else {

      // }

      if (index.sc_index !== undefined) {
        newImage[index.sc_index].splice(imageIndex, 1);
      } else {
        newImage.splice(imageIndex, 1);
      }
      // return prev;
      newQuestion[index.question_index] = {
        ...newQuestion[index.question_index],
        image: newImage,
      };

      return {
        ...prev,
        sub_categories: newSub_categories,
      };
    });
  };

  const handleClear = () => {
    setValue((prev) => {
      let newSub_categories = [...prev.sub_categories];
      let newQuestion = newSub_categories[index.sub_index].questions;
      delete newQuestion[index.question_index].image;
      // const newImage = newQuestion[index.question_index].image;
      // newImage.splice(0);
      newQuestion[index.question_index] = {
        ...newQuestion[index.question_index],
        // image: null,
      };
      return {
        ...prev,
        sub_categories: newSub_categories,
      };
    });
  };

  if (value === undefined) {
    return <></>;
  }
  let imgArr = undefined;
  if (
    value.sub_categories[index.sub_index]?.questions[index.question_index]
      ?.image !== undefined
  ) {
    if (stateIndex[0].isSC) {
      imgArr =
        value.sub_categories[index.sub_index].questions[index.question_index]
          .image[stateIndex[0].sc_index];
    } else {
      imgArr =
        value.sub_categories[index.sub_index].questions[index.question_index]
          .image;
    }
  }

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        {/* <SimpleBackdrop open={loading} setOpen={setLoading} /> */}
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          เพิ่มรูปภาพ
        </BootstrapDialogTitle>

        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                // mr: 2,
                // maxWidth: "100%",
                mt: 2,
              },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="flex flex-col">
              <div className="mb-4">
                <Fab color="primary" variant="contained" component="label">
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={(e) =>
                      handleChangeFile(e, index.question_index, index.sub_index)
                    }
                  />
                  <AddIcon />
                </Fab>
              </div>
              <div className="mt-4 flex gap-12 flex-wrap">
                {imgArr &&
                  imgArr.map((d, index) => {
                    return (
                      <div
                        key={index}
                        className={`flex justify-evenly items-center ${
                          index === 0 ? "mx-auto" : index % 2 !== 0 && "mx-auto"
                        } relative basis-1/3 `}
                      >
                        <div className="relative ">
                          <div
                            className="absolute -top-4 -right-4 "
                            onClick={() => handleDeleteImage(index)}
                          >
                            <Fab
                              color="error"
                              variant="contained"
                              component="label"
                              size="small"
                            >
                              <DeleteIcon />
                            </Fab>
                          </div>
                          <div className="w-[200px] h-[200px] overflow-hidden ">
                            <img src={d.base} />
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </Box>
        </DialogContent>

        <DialogActions>
          <Button variant="outlined" autoFocus onClick={handleClear}>
            ล้างข้อมูล
          </Button>
          <Button variant="contained" autoFocus onClick={handleClose}>
            ยืนยัน
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
};

export default FormAddImageAnswer;
