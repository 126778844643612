import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Chip from "@mui/material/Chip";
import { Link, useParams, useLocation } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { APIServices } from "./../helpers/APIServices";
import SimpleBackdrop from "../Components/Backdrop";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Category() {
  const [subCategories, setSubCategories] = useState([]);
  const param = useParams();
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState(0);
  const [loading, setLoading] = useState(true);

  const getCategories = async () => {
    const categories = await APIServices.getCategories();
    const data = categories.data.data;
    const data2 = [
      {
        id: 0,
        label: "ALL",
      },
    ];
    data.forEach((e) => {
      data2.push({
        id: e.id,
        label: e.attributes.category,
      });
    });
    setCategories(data2);
  };
  const getSubCategories = async () => {
    setLoading(true)
    const response = await APIServices.getSubCategoriesForDashboard(param.id);
    const subCat = response.data.data;

    const newSubCat = subCat.map((data) => {
      return {
        id: data.id,
        ...data.attributes,
      };
    });

    setSubCategories(newSubCat);
    setLoading(false)

  };

  const checkSearch = (emptyCheck) => {
    const cat = emptyCheck.category.data.attributes.category;
    if (categories.length > 0) {
      const newCategorySearch = categories.find(
        (category) => category.id === categorySearch
      );
      if (
        (newCategorySearch.id === 0 || newCategorySearch.label === cat) &&
        (search === "" ||
          emptyCheck.sub_categories.toLowerCase().includes(search.toLowerCase()) ||
          emptyCheck.category.data.attributes.category.toLowerCase().includes(search.toLowerCase()))
      ) {
        return true;
      }
      return false;
    }
  };

  useEffect(() => {
    getSubCategories();
    getCategories();
  }, []);

  return (
    <>
      <SimpleBackdrop open={loading} setOpen={setLoading} />

      <div className="mb-4 flex justify-center items-center">
        <div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-helper-label">
              Categories
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={categorySearch}
              label="Categories"
              onChange={(e) => setCategorySearch(e.target.value)}
            >
              {categories.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  {data.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <TextField
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search by Part Name or Part Code"
          className="flex-1"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </div>
      {subCategories
        .filter((emptyCheck) => emptyCheck !== "" && emptyCheck.status === true && checkSearch(emptyCheck))
        .map((data, index) => (
          <Link key={`${index}`} to={`question/${data.id}`}>
            <div className="flex flex-col justify-center items-center">
              {subCategories.length === 0 && (
                <div className="bg-white p-4 mb-4 flex justify-center w-full rounded-md shadow-md">
                  <span> ไม่มีข้อมูล</span>
                </div>
              )}
              <div
                // key={data.id}
                className="bg-white p-4 mb-4 flex w-full flex-row rounded-md shadow-md  cursor-pointer hover:bg-neutral-100"
              >
                <div className="basis-1/4  flex items-center rounded-full ">
                  <Chip
                    label={data.category.data.attributes.category}
                    variant="outlined"
                  />
                </div>
                <div className="basis-1/2 flex items-center">
                  {data.sub_categories}
                </div>
                {/* <div className="basis-1/4  flex justify-end items-center">
                
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="medium"
                    disabled={data.status === false ? true : false}
                  >
                    <ArrowForwardIosIcon />
                  </Fab>
                
              </div> */}
              </div>
            </div>
          </Link>
        ))}
    </>
  );
}

export default Category;
