import React, { useState, useRef } from "react";
import Button from "@mui/material/Button";
import SignatureCanvas from "react-signature-canvas";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { APIServices } from "../helpers/APIServices";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";
import config from "../config";
import dataURLtoBlob from "blueimp-canvas-to-blob";
import { async } from "q";
import SimpleBackdrop from "../Components/Backdrop";

const dayjs = require("dayjs");

// import Signature from "./EditSignature";
const label = { inputProps: { "aria-label": "Switch demo" } };

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  // onClose: PropTypes.func.isRequired,
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Signature = ({ state, handleUpdateSignature }) => {
  const [userData, setUserData] = [...state];
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    massage: "",
  });

  const [signatureState, setSignatureState] = useState({
    base: null,
    file: null,
  });
  let refIn = useRef({});

  const date_time = dayjs().format("YYYYMMDDHHmmss");

  const handleClose = () => {
    setSignatureState({
      base: null,
      file: null,
    });
    setUserData({
      open: false,
      user: null,
    });
  };

  const handleCloseAlert = () => {
    setAlert((data) => ({ ...data, open: false }));
  };

  const handleChangeFile = async (e) => {
    const files = Array.from(e.target.files);
    var file = files[0];
    const blob = file.slice(0, file.size, "image/png");
    const name = "signature" + "_" + userData.user.username + "_" + date_time;
    const newImage = new File([blob], name, { type: "image/png" });
    const base64Img = await getBase64(files[0]);
    setSignatureState({
      base: base64Img,
      file: newImage,
    });
  };

  const drawSignature = async () => {
    let dataURL = refIn.toDataURL();
    var arr = dataURL.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    let fileImg = new File(
      [u8arr],
      "signature" + "_" + userData.user.username + "_" + date_time,
      {
        type: mime,
      }
    );

    setSignatureState({ base: dataURL, file: fileImg });
    refIn.clear();
  };

  const editSignature = async (signature) => {
    if (
      // signature === null ||
      signatureState.file === null
      // userData.user.signature !== null
    ) {
      setAlert({
        open: true,
        type: "error",
        massage: "Signature is empty",
      });
    } else {
      setLoading(true);
      // if (userData.user.signature !== null) {
      //   await APIServices.removeUserOldImage(userData.user.signature.id);
      // }
      const formData = new FormData();
      formData.append("ref", "plugin::users-permissions.user");
      formData.append("refId", userData.user.id);
      formData.append("field", "signature");
      formData.append("files", signatureState.file);
      const res = await APIServices.addUserImage(formData);
      setLoading(false);
    }
    setUserData({
      open: false,
      user: null,
    });
    return;
  };

  return (
    <BootstrapDialog
      // onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={userData.open}>
      <SimpleBackdrop open={loading} setOpen={setLoading} />
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        แก้ไขลายเซ็น
      </BootstrapDialogTitle>
      <DialogContent dividers>
        <Box
          component='form'
          sx={{
            "& .MuiTextField-root": {
              mr: 2,
              // width: 500,
              maxWidth: "100%",
              mt: 2,
            },
          }}
          noValidate
          autoComplete='off'>
          <div>
            <Snackbar
              open={alert.open}
              autoHideDuration={3000}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              onClose={handleCloseAlert}>
              <Alert
                onClose={handleCloseAlert}
                severity={alert.type}
                sx={{ width: "100%" }}>
                {alert.massage}
              </Alert>
            </Snackbar>
          </div>
          <div>
            <div className=' w-full '>
              {(userData.user !== null && userData.user.signature !== null) ||
              signatureState?.base !== null ? (
                <img
                  draggable='false'
                  className='h-48 mx-auto'
                  src={
                    signatureState.base
                      ? signatureState.base
                      : `${
                          userData.user.signature[
                            userData.user.signature.length - 1
                          ].url
                        }`
                    //config.server +
                  }

                  // src={`${config.server + user.profile_image}`}
                />
              ) : (
                <div className='flex justify-center mt-6 mb-6'>
                  ไม่มีข้อมูลลายเซ็น
                </div>
              )}
            </div>

            <div className='flex justify-center mt-2 '>
              <Button variant='contained' component='label'>
                Upload image
                <input
                  type='file'
                  hidden
                  onChange={(e) => handleChangeFile(e)}
                />
              </Button>
            </div>

            <span className='flex justify-center text-lg mt-6 mb-6'>
              ----- หรือ -----
            </span>
            <div className=' bg-zinc-100'>
              <SignatureCanvas
                ref={(ref) => {
                  refIn = ref;
                }}
                penColor='black'
                canvasProps={{
                  width: "500",
                  height: 200,
                  className: "sigCanvas",
                }}
              />
              <Button onClick={() => drawSignature()}>ยืนยัน</Button>
              <Button
                onClick={() => {
                  refIn.clear();
                }}>
                ล้างข้อมูล
              </Button>
            </div>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            setSignatureState({
              base: null,
              file: null,
            });
          }}>
          ล้างข้อมูล
        </Button>
        <Button
          disabled={signatureState.file === null}
          autoFocus
          variant='contained'
          onClick={async () => {
            await editSignature();
            handleUpdateSignature();
          }}>
          บันทึก
        </Button>
      </DialogActions>
    </BootstrapDialog>

    // <div>
    //   <div className=" bg-zinc-100">
    //     <SignatureCanvas
    //       penColor="black"
    //       canvasProps={{ width: 500, height: 200, className: "sigCanvas" }}
    //     />
    //     <Button>confirm</Button>
    //     <Button>clear</Button>
    //   </div>
    // </div>
  );
};

export default Signature;
