import * as React from "react";

import { Close, PhotoCamera } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Input,
  MenuItem,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

import { APIServices } from "../../helpers/APIServices";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import config from "../../config";
import { useSnackbarStore } from "../../helpers/state";
import { useState } from "react";
const dayjs = require("dayjs");

export default function EditUser({ state, handleUpdate }) {
  const [dialogState, setDialogState] = [...state];
  const [signatureState, setSignatureState] = React.useState(null);
  const snackBarState = useSnackbarStore();
  const date_time = dayjs().format("YYYYMMDDHHmmss");
  const [imageError, setImageError] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    onChange,
    watch,
    control,
    formState: { errors },
  } = useForm({});

  React.useEffect(() => {
    setValue("name", dialogState.user ? dialogState.user.name : "");
    setValue("lastname", dialogState.user ? dialogState.user.lastname : "");
    setValue("email", dialogState.user ? dialogState.user.email : "");
    setValue("role", dialogState.user ? dialogState.user.qc_role : "");
    setValue("blocked", dialogState.user ? !dialogState.user.blocked : false);
    setSignatureState(null);
  }, [dialogState.user, setValue]);

  const onSubmit = async (data) => {
    const updateData = {
      ...data,
      id: dialogState.user.id,
    };

    const response = await APIServices.updateUser(updateData);

    if (signatureState !== null) {
      if (
        response.data.signature !== null &&
        response.data.signature !== undefined
      ) {
        await APIServices.removeUserOldImage(dialogState.user.signature.id);
      }
      const formData = new FormData();
      formData.append("ref", "plugin::users-permissions.user");
      formData.append("refId", dialogState.user.id);
      formData.append("field", "signature");
      formData.append("files", signatureState.file);
      await APIServices.addUserImage(formData);
    }
    snackBarState.onOpen({
      msg: "แก้ไขข้อมูลสำเร็จ",
      type: "success",
    });
    handleUpdate(response.data);
    handleClose();
  };
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setSignatureState(null);
    setDialogState({ open: false, user: null });
  };

  const handleChangeFile = async (e) => {
    const files = Array.from(e.target.files);
    const _URL = window.URL || window.webkitURL;
    const vsImageURL = _URL.createObjectURL(files[0]);
    const vsImage = new Image();
    vsImage.addEventListener("load", async (ev) => {
      console.log("width", vsImage.width);
      console.log("height", vsImage.height);
      const aspectRatio = parseFloat(
        (vsImage.height / vsImage.width).toFixed(2)
      );
      console.log("aspectRatio", parseFloat(aspectRatio.toFixed(2)));
      if (aspectRatio < 0.8 && aspectRatio > 0.7) {
        const base64Img = await getBase64(files[0]);
        var file = files[0];
        const blob = file.slice(0, file.size, "image/png");
        const name =
          "signature" + "_" + dialogState.user.username + "_" + date_time;
        const newImage = new File([blob], name, { type: "image/png" });
        console.log("image", newImage);
        console.log("size", newImage.size);
        setSignatureState({
          base: base64Img,
          file: newImage,
        });
        setImageError(false);
      } else {
        setImageError(true);
      }
    });
    vsImage.src = vsImageURL;
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
  });

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackBarState.open}
        autoHideDuration={6000}
        // onClose={handleClose}
        onClose={snackBarState.onClose}>
        <Alert severity={snackBarState.type} sx={{ width: "100%" }}>
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <Dialog open={dialogState.open || false} onClose={handleClose}>
        <DialogTitle>แก้ไขข้อมูลพนักงาน</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className='flex flex-wrap justify-between'>
            <TextField
              className=' w-2/5'
              {...register("name")}
              margin='dense'
              label='Name'
              type='text'
              variant='standard'
            />
            <TextField
              className=' w-2/5'
              {...register("lastname")}
              margin='dense'
              label='Lastname'
              type='text'
              variant='standard'
            />
            <TextField
              className=' w-2/5'
              {...register("email")}
              margin='dense'
              label='Email'
              type='text'
              // fullWidth
              variant='standard'
            />
            <div className='flex w-2/5 items-center justify-between'>
              <p>Enable User</p>

              <Controller
                control={control}
                // defaultValue=""
                name='blocked'
                render={({
                  field,
                  fieldState: { invalid, isTouched, isDirty, error },
                  formState,
                }) => {
                  return (
                    <Switch
                      {...field}
                      defaultChecked={field.value}
                      // checked={true}
                      // onChange={() => null}
                      inputProps={{ "aria-label": "" }}
                      label='test'
                    />
                  );
                }}
              />
            </div>
            <Controller
              control={control}
              name='role'
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    defaultValue={dialogState.user && dialogState.user.qc_role}
                    {...field}
                    select
                    margin='dense'
                    label='Role'
                    fullWidth
                    variant='standard'>
                    <MenuItem key={"inspector"} value={"inspector"}>
                      Inspector
                    </MenuItem>
                    <MenuItem key={"checker"} value={"checker"}>
                      Checker
                    </MenuItem>
                    <MenuItem key={"approver"} value={"approver"}>
                      Approver
                    </MenuItem>
                    <MenuItem key={"release"} value={"release"}>
                      Release for Revise
                    </MenuItem>
                    <MenuItem key={"checker_revise"} value={"checker_revise"}>
                      Checker for Revise
                    </MenuItem>
                    <MenuItem key={"approver_revise"} value={"approver_revise"}>
                      Approver for Revise
                    </MenuItem>
                  </TextField>
                );
              }}
            />
            <div className='mx-auto mt-2 flex-auto flex items-center justify-between'>
              <div>
                <Typography variant='span' color='initial'>
                  Signature
                </Typography>
                <Typography
                  className={`text-sm ${
                    imageError ? "text-red-500" : "text-gray-400"
                  }`}>
                  Image must be <span className={`underline`}>Horizontal</span>{" "}
                  4:3 aspect ratio with each side between 200 x 150px and 1024 x
                  769px
                </Typography>
              </div>
              <IconButton
                color='primary'
                aria-label='upload picture'
                component='label'>
                <input
                  {...register("signature", {
                    onChange: (e) => handleChangeFile(e),
                  })}
                  hidden
                  accept='image/*'
                  type='file'
                />

                <PhotoCamera />
              </IconButton>
            </div>
            <div className='basis-full w-0'></div>
            {((dialogState.user && dialogState.user.signature !== null) ||
              signatureState !== null) && (
              <div className=' w-full  '>
                <img
                  draggable='false'
                  className='w-48 mx-auto'
                  alt={dialogState.user.username}
                  src={
                    signatureState !== null
                      ? signatureState.base
                      : `${
                          // config.server +
                          dialogState.user.signature[
                            dialogState.user.signature.length - 1
                          ].url
                        }`
                  }
                />
              </div>
            )}

            {errors.exampleRequired && <span>This field is required</span>}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button onClick={handleSubmit(onSubmit)}>ยืนยัน</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
