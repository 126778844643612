import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog'
import Close from '@mui/icons-material/Close'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Button from '@mui/material/Button'
import _ from 'lodash'
import config from '../config'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

const EditSubcategory = ({
    open = false,
    onClose,
    onConfirm,
    categories,
    subcategory,
    mode = "edit",
}) => {
    const [_subcategory, setSubcategory] = useState(_.cloneDeep(subcategory))
    const [dialogError, setDialogError] = useState({
        open: false,
        message: '',
    })
    let status_revise
    console.log("mode", mode);
    if(mode === "add"){
        status_revise = config.status_revise.ADD
    }else if(mode === "edit"){
        status_revise = config.status_revise.EDIT
    }
    
    const handleOnClose = () => {
        onClose()
    }

    const handleOnChangeName = (e) => {
        const value = e.target.value
        setSubcategory(prev => {
            const cloneSub = prev ? _.cloneDeep(prev) : {}
            cloneSub.sub_categories = value
            return cloneSub
        })
    }

    const handleOnChangeCategory = (e) => {
        const value = e.target.value
        setSubcategory(prev => {
            const cloneSub = prev ? _.cloneDeep(prev) : {}
            cloneSub.category = value === '' ? null : JSON.parse(value)
            return cloneSub
        })
    }

    const handleOnConfirm = () => {
        try {
            validSubCategory(_subcategory)
            if(_subcategory.status_revise === config.status_revise.ADD){
                status_revise = config.status_revise.ADD
            }
            onConfirm(
                {
                    questions: [],
                    ..._subcategory,
                    status: true,
                    status_revise: status_revise,
                },
                mode,
            )
            handleOnClose()
        } catch (error) {
            setDialogError({
                open: true,
                message: `${error}`
            })
        }
    }

    const handleOnCancel = () => {
        handleOnClose()
    }

    const validSubCategory = (subcategory) => {
        console.log("subcategory 56:", subcategory);
        if(!subcategory?.sub_categories){
            throw Error('กรุณากรอกข้อมูลให้ครบถ้วน Name')
        }
        if(!subcategory?.category){
            throw Error('กรุณากรอกข้อมูลให้ครบถ้วน Category')
        }
    }

  return (
    <Dialog
        open={open}
        // open={true}
        maxWidth={`md`}
        fullWidth
    >
        <Snackbar
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
            open={dialogError.open}
            autoHideDuration={4000}
            onClose={() => setDialogError(prev => ({...prev, open: false}))}
        >
            <Alert severity='error' onClose={() => setDialogError(prev => ({...prev, open: false}))}>
                {`${dialogError.message}`}
            </Alert>
        </Snackbar>
        <div className={`p-4`}>
            <div className={`flex justify-between items-center`}>
                <div className={`text-2xl font-bold`}>
                    <span className={`capitalize`}>{`${mode}`}</span> Subcategory
                </div>
                <div
                    onClick={handleOnClose}
                    className={`bg-white hover:bg-gray-200 cursor-pointer transition p-2 rounded-full w-max h-max`}
                >
                    <Close />
                </div>
            </div>
            <div className={`mt-4 flex gap-2 items-center`}>
                <div className={`min-w-[100px]`}>
                    Name:
                </div>
                <TextField
                    defaultValue={`${_subcategory?.sub_categories || ''}`}
                    sx={{
                        width: '100%'
                    }}
                    onChange={handleOnChangeName}
                />
            </div>
            <div className={`mt-2 flex gap-2 items-center`}>
                <div className={`min-w-[100px]`}>
                    category:
                </div>
                <Select
                    value={
                        _subcategory && _subcategory.category
                        ? JSON.stringify({
                            id: _subcategory.category.id,
                            category: _subcategory.category.category,
                        })
                        : ''
                    }
                    onChange={handleOnChangeCategory}
                    sx={{
                        minWidth: '100px',
                    }}
                >
                    <MenuItem value={''} className={`italic`}>
                        none
                    </MenuItem>
                    {categories.map((cateogory, index) => (
                        <MenuItem
                            value={JSON.stringify(cateogory)}
                            key={`menuItem${index}`}
                        >
                            {`${cateogory.category}`}
                        </MenuItem>
                    ))}
                    
                    {/* <MenuItem value={20}>Twenty</MenuItem>
                    <MenuItem value={30}>Thirty</MenuItem> */}
                </Select>
            </div>
            <div className={`flex justify-end gap-4 mt-4 border-t border-gray-300 pt-4`}>
                <Button
                    variant='outlined'
                    onClick={handleOnCancel}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    onClick={handleOnConfirm}
                >
                    Confirm
                </Button>
            </div>
        </div>
    </Dialog>
  )
}

export default EditSubcategory