import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import IconButton from "@mui/material/IconButton";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import LastPageIcon from "@mui/icons-material/LastPage";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/material/styles";
import { Checkbox } from "@mui/material";

function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === "rtl" ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === "rtl" ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export default function TableForSearchData({ headers, data }) {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    // <Box sx={{ width: '100%' }}>
    <Paper
      sx={{
        width: '100%',
        mb: 2,
        overflowX: 'auto' 
      }}
    >
      <TableContainer className="max-w-full">
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              {headers.map((header, index) =>
                header.col ? (
                  <TableCell
                    key={`cell${index}`}
                    align="center"
                    colSpan={header.col.length}
                  >
                    <div className="">{header.name}</div>
                    <div className="flex justify-between mt-4">
                      {header.col.map((col, index) => (
                        <div key={`col${index}`} className="">
                          {col.name}
                        </div>
                      ))}
                    </div>
                  </TableCell>
                ) : (
                  <TableCell key={`cell${index}`} align="center" colSpan={0}>
                    {header.name}
                  </TableCell>
                )
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {(rowsPerPage > 0
              ? data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              : data
            ).map((dataLoop, index) => (
              <TableRow
                key={index}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                {/* <TableCell component="th" align="center" scope="row">
                  {index + 1}
                </TableCell> */}
                {/* <TableCell align="center">{dataLoop.id}</TableCell> */}
                <TableCell align="center">{dataLoop.date}</TableCell>
                <TableCell align="center">{dataLoop.time}</TableCell>
                {/* {dataLoop.data.actual ? (
                dataLoop.data.actual.map((actual, index) => (
                  <TableCell key={`cell${index}`} align="center">
                    {actual || "-"}
                  </TableCell>
                ))
              ) : (
                <>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                </>
              )} */}
                <TableCell align="center">{dataLoop.shift}</TableCell>
                <TableCell align="center">{dataLoop.A_B}</TableCell>
                <TableCell align="center">{dataLoop.inspector_name}</TableCell>
                <TableCell align="center">{dataLoop.checker_name}</TableCell>
                <TableCell align="center">{dataLoop.approver_name}</TableCell>
                <TableCell align="center">{dataLoop.partNo}</TableCell>
                <TableCell align="center">{dataLoop.partName}</TableCell>
                <TableCell align="center">{dataLoop.A_D}</TableCell>
                <TableCell align="center">{dataLoop.point_check}</TableCell>
                <TableCell align="center">{dataLoop.spec_min}</TableCell>
                <TableCell align="center">{dataLoop.spec_max}</TableCell>

                {/* {dataLoop.answer ? (
                  dataLoop.answer.map((answer, index) => (
                    <TableCell key={`cell${index}`} align="center">
                      {answer || "-"}
                    </TableCell>
                  ))
                ) : (
                  <> */}
                <TableCell align="center">{dataLoop.answer1}</TableCell>
                <TableCell align="center">{dataLoop.answer2}</TableCell>
                <TableCell align="center">{dataLoop.answer3}</TableCell>
                {/* </>
                )} */}
                {/* {dataLoop.actual ? (
                  dataLoop.actual.map((actual, index) => (
                    <TableCell key={`cell${index}`} align="center">
                      {actual || "-"}
                    </TableCell>
                  ))
                ) : (
                  <> */}
                <TableCell align="center">{dataLoop.actual1}</TableCell>
                <TableCell align="center">{dataLoop.actual2}</TableCell>
                <TableCell align="center">{dataLoop.actual3}</TableCell>
                {/* </>
                )} */}
                {/* {dataLoop.comment ? (
                  dataLoop.comment.map((comment, index) => (
                    <TableCell key={`cell${index}`} align="center">
                      {comment || "-"}
                    </TableCell>
                  ))
                ) : (
                  <> */}
                {/* <TableCell align="center">{dataLoop.comment1}</TableCell>
                <TableCell align="center">{dataLoop.comment2}</TableCell>
                <TableCell align="center">{dataLoop.comment3}</TableCell> */}
                {/* </>
                )} */}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component={`div`}
        rowsPerPageOptions={[5, 10, 25, { label: "All", value: -1 }]}
        colSpan={3}
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        SelectProps={{
          inputProps: {
            "aria-label": "data per page",
          },
          native: true,
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        ActionsComponent={TablePaginationActions}
      />
    </Paper>
    // </Box>
  );
}

TableForSearchData.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
};
