import React, { useEffect, useState, useContext } from "react";
import { APIServices } from "../helpers/APIServices";
import TableForRevise from "../Components/TableForRevise";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../Layout";
import Swal from "sweetalert2";
const headers = [
  // {
  //   name: "ID",
  // },
  {
    name: "Date",
  },
  {
    name: "Rev.",
  },
  {
    name: "Revision Record",
  },
  {
    name: "Part code.",
  },
  {
    name: "Part name",
  },
  {
    name: "Status",
  },
  {
    name: "Release",
  },
  {
    name: "Checker",
  },
  {
    name: "Approver",
  },
];
const Revise = () => {
  const [dataReviseTable, setDataReviseTable] = useState();
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const getData = async () => {
    const response = await APIServices.findRevise();
    const reviseData = response.data.data;
    const newRevise = reviseData.map((perRound) => {
      return {
        id: perRound.id,
        ...perRound.attributes,
      };
    });

    const realData = [];
    for (let indexRevise = 0; indexRevise < newRevise.length; indexRevise++) {
      const revise = newRevise[indexRevise];

      const sliceDate = revise.createdAt.slice(0, 10);
      const splitDate = sliceDate.split("-").reverse();
      const date = splitDate[0] + "-" + splitDate[1] + "-" + splitDate[2];
      realData.push({
        id: revise.id,
        revise_time: revise.revise,
        revise_status: revise.revise_status,
        revise_record: revise.revisionRecord,
        date: date,
        release_name: revise.release_id.data
          ? revise.release_id.data.attributes.name
          : "-",
        checker_name: revise.checker_id.data
          ? revise.checker_id.data.attributes.name
          : "-",
        approver_name: revise.approver_id.data
          ? revise.approver_id.data.attributes.name
          : "-",
        partName: revise.part.data.attributes.part_name
          ? revise.part.data.attributes.part_name
          : null,
        partCode: revise.part.data.attributes.part_code
          ? revise.part.data.attributes.part_code
          : null,
      });
    }
    setDataReviseTable(realData);
  };

  if (user) {
    if (
      user.qc_role === "super_admin" ||
      user.qc_role === "admin" ||
      user.qc_role === "release" ||
      user.qc_role === "approver_revise " ||
      user.qc_role === "checker_revise"
    ) {
      if (!user.signature) {
        Swal.fire({
          title: "ผู้ใช้งานนี้ไม่มีข้อมูลลายเซ็น",
          text: "กรุณาเพิ่มลายเซ็น",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "เพิ่มลายเซ็น",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/layout/profile/${user.id}`);
          }
        });
      }
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {dataReviseTable ? (
        <TableForRevise headers={headers} data={dataReviseTable} />
      ) : (
        <div
          className={`text-2xl flex justify-center items-center lg:flex lg:justify-center lg:items-center mt-4 lg:mt-4`}
        >
          ----- No data -----
        </div>
      )}
    </>
  );
};

export default Revise;
