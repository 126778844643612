import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

export default function ConfirmModal({
  open,
  setOpen,
  action,
  header,
  message,
  confirmText,
  cancelText,
  paper,
  setRemark,
  remark,
}) {
  const handleClose = () => {
    setOpen((prev) => ({ ...prev, open: false }));
    setRemark(null);
  };

  const handleOnClick = (result) => {
    setOpen((prev) => ({ ...prev, open: false }));
    setRemark(null);
    action(paper);
  };

  return (
    <Dialog
      open={open}
      // onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{header || "Header"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message || "Content"}
        </DialogContentText>

        <TextField
          label="Remark"
          sx={{ marginTop: 2 }}
          onChange={(e) => setRemark(e.target.value)}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setOpen(false);
            setRemark(null);
          }}
        >
          {cancelText || "Disagree"}
        </Button>
        <Button
          variant="contained"
          onClick={() => handleOnClick(true)}
          autoFocus
        >
          {confirmText || "Agree"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
