import React, { useState, useRef, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { APIServices } from "./../../helpers/APIServices";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import SimpleBackdrop from "./../../Components/Backdrop";
import { UserContext } from "../../Layout";

// const label = { inputProps: { "aria-label": "Switch demo" } };
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

function FormAddPart({ setOpen, open, setSuccess }) {
  const [imagePart, setImagePart] = useState("");
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    massage: "",
  });
  const [loading, setLoading] = useState(false);
  const user = useContext(UserContext);

  const addPartName = useRef();
  const addPartCode = useRef();
  const revisionRecord = useRef();

  const handleClose = () => {
    setOpen(false);
    addPartName.current.value = "";
    addPartCode.current.value = "";
    revisionRecord.current.value = "";
    setImagePart({
      base: null,
      file: null,
    });
  };

  const handleChangeFile = async (e) => {
    const files = Array.from(e.target.files);
    const base64Img = await getBase64(files[0]);
    setImagePart({
      base: base64Img,
      file: files,
    });
  };

  const handleCloseAlert = () => {
    setAlert((data) => ({ ...data, open: false }));
  };

  const createPart = async (image, partName, partCode, revision) => {
    const signature =
      user.signature !== null
        ? user.signature[user.signature.length - 1].url
        : "";
    if (
      partName === "" ||
      partCode === "" ||
      revision === "" ||
      image === null ||
      image === undefined
    ) {
      setAlert({
        open: true,
        type: "error",
        massage: "กรอกข้อมูลไม่ครบถ้วน",
      });
    } else if (signature === null || signature === "") {
      setAlert({
        open: true,
        type: "error",
        massage: "ไม่มีลายเซ็นต์สำหรับการสร้างข้อมูลพาร์ท",
      });
    } else {
      setLoading(true);
      const response = await APIServices.createPart(
        partName,
        partCode,
        false,
        true
      );
      const part_data = response.data.data.attributes;

      if (response.data.data !== null) {
        if (image !== null && image !== undefined) {
          const formData = new FormData();
          formData.append("ref", "api::qc-part.qc-part");
          formData.append("refId", response.data.data.id);
          formData.append("field", "image");
          formData.append("files", image[0]);
          await APIServices.addUserImage(formData);
        }
        if (response.data.data.id !== null) {
          await APIServices.revise(
            revision,
            new Date(),
            signature,
            user.id,
            part_data.revise_time,
            response.data.data.id
          );
        }
      }

      setLoading(false);
      setOpen(false);
      setImagePart({
        base: null,
        file: null,
      });
      addPartName.current.value = "";
      addPartCode.current.value = "";
      revisionRecord.current.value = "";
      // navigate("/layout/revise");
    }
    setSuccess(true);
  };

  return (
    <div>
      <BootstrapDialog
        // onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth={`sm`}
        fullWidth
      >
        <SimpleBackdrop open={loading} setOpen={setLoading} />
        <div>
          <Snackbar
            open={alert.open}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleCloseAlert}
          >
            <Alert
              onClose={handleCloseAlert}
              severity={alert.type}
              sx={{ width: "100%" }}
            >
              {alert.massage}
            </Alert>
          </Snackbar>
        </div>
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={handleClose}
        >
          แบบฟอร์มเพิ่มข้อมูลพาร์ท
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component="form"
            sx={{
              "& .MuiTextField-root": {
                // mr: 2,
                // width: 500,
                maxWidth: "100%",
                mt: 2,
                alignItems: "center",
              },
            }}
            noValidate
            autoComplete="off"
          >
            <div className="flex flex-col">
              <div className="">
                <div className={`text-sm text-gray-500`}>Part Name</div>
                <TextField
                  inputRef={addPartName}
                  placeholder="Brkt; Cab MXXX"
                  className={`mt-1`}
                  fullWidth
                />
              </div>
              <div className="mt-4">
                <div className={`text-sm text-gray-500`}>Part Code</div>
                <TextField
                  inputRef={addPartCode}
                  placeholder="4345XX"
                  className={`mt-1`}
                  fullWidth
                />
              </div>
              <div className="mt-4">
                <div className={`text-sm text-gray-500`}>Revision</div>
                <TextField
                  inputRef={revisionRecord}
                  placeholder="Initial part"
                  className={`mt-1`}
                  fullWidth
                />
              </div>

              <div className=" mt-4 flex flex-col items-center ">
                <div className="flex items-center justify-center ">
                  <Button variant="contained" component="label">
                    Upload image
                    <input
                      type="file"
                      hidden
                      onChange={(e) => handleChangeFile(e)}
                    />
                  </Button>
                </div>
                <div className="mt-4">
                  {imagePart.base && (
                    <img
                      src={imagePart.base}
                      className="max-w-96 max-h-96 mx-auto"
                    />
                  )}
                </div>
                {/* </Box> */}
              </div>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={() => {
              setImagePart({
                base: null,
                file: null,
              });
              addPartName.current.value = "";
              addPartCode.current.value = "";
              revisionRecord.current.value = "";
            }}
          >
            ล้างข้อมูล
          </Button>
          <Button
            variant="contained"
            autoFocus
            onClick={() => {
              createPart(
                imagePart.file,
                addPartName.current.value,
                addPartCode.current.value,
                revisionRecord.current.value
                // addUCL.current.value,
                // addCL.current.value,
                // addLCL.current.value
              );
            }}
          >
            บันทึก
          </Button>
        </DialogActions>
      </BootstrapDialog>
    </div>
  );
}

export default FormAddPart;
