import { useEffect, useState, useContext, useRef } from "react";
import { APIServices } from "../helpers/APIServices";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import { Link, useNavigate } from "react-router-dom";
import config from "../config";
import { NoPhotography, Search } from "@mui/icons-material";
import {
  Button,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { PartContext } from "../App";
import SimpleBackdrop from "../Components/Backdrop";
import { ScrollContext } from "../Layout";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { scrollToRef } from "../helpers/common";

const Dashboard = () => {
  const [parts, setPart] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();
  const refLayout = useContext(ScrollContext)

  const getParts = async () => {
    const response = await APIServices.getPartForDashboard();
    const result = response.data;
    if (result.data) {
      const parts = result.data;
      setPart(parts);
      setLoading(false);
    }
  };
  const handleSearch = (value) => {
    setSearch(value);
  };
  const checkSearch = (emptyCheck) => {
  
    if (emptyCheck.attributes.part_name !== null) {
      if (
        search === "" ||
        emptyCheck.attributes.part_name.toLowerCase().includes(search.toLowerCase()) ||
        emptyCheck.attributes.part_code.toLowerCase().includes(search.toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    getParts();
  }, []);

  const [dialogSelectRevise, setDialogSelectRevise] = useState({
    isOpen: false,
  });
  const [selectedPart, setSelectedPart] = useState();

  const handleClickOnPart = (part) => {
    const thisPart = part.attributes;
    if (thisPart.old_parts.data.length > 0) {
      setSelectedPart(part);
      setDialogSelectRevise((prev) => ({
        ...prev,
        isOpen: true,
      }));
    } else {
      navigate(`category/${part.id}`);
    }
  };

  const refs = useRef([])
  const idKeepPartId = "scroll-to-partId-dashboard"
  const [partMarkerId] = useState(() => {
    const presistedId = sessionStorage.getItem(idKeepPartId)
    return presistedId ? presistedId : null
  })

  const keepPartId = (id) => {
    sessionStorage.setItem(idKeepPartId, id)
  }

  useEffect(() => {
    if(partMarkerId){
      const partIndex = parts.findIndex(part => part.id.toString() === partMarkerId.toString())
      console.log("in if", partIndex);
      console.log("refs: ", refs);
      scrollToRef(refs.current[partIndex])
      sessionStorage.removeItem(idKeepPartId)
    }
  }, [parts])
  return (
    <div>
      <div
        className={`z-10 fixed bottom-0 right-0 mx-8 my-4 bg-blue-400 hover:bg-blue-500 rounded-full p-4 text-white cursor-pointer flex`}
        onClick={() => {
          refLayout.current.scrollTo({top: 0, behavior: 'smooth'})
        }}
      >
        <ArrowUpwardIcon />
      </div>
      <SimpleBackdrop open={loading} setOpen={setLoading} />
      <DialogSelectRevise
        dialogSelectRevise={dialogSelectRevise}
        setDialogSelectRevise={setDialogSelectRevise}
        selectedPart={selectedPart}
      />
      <div className=" text-3xl">Select part</div>
      <div className="mb-2 mt-2 flex">
        <TextField
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          placeholder="Search by Part Name or Part Code"
          className="flex-1"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
      </div>
      {/* TODO: remote padding on production */}
      <div className={`flex gap-4 flex-wrap mt-4`}>
        {parts
        .filter((emptyCheck) => {
          return checkSearch(emptyCheck);
        })
        .map((part, index) => (
          // <Link key={`link${index}`} to={`data/${part.id}`}>
          // <Link
          //   key={`link${index}`}
          //   to={`category/${part.id}`}
          //   // onClick={() => {
          //   //   setSelectedPart({ partID: part.id });
          //   // }}
          //   // state={part}
          // >
          <Card
            className="drop-shadow cursor-pointer"
            key={`card${index}`}
            sx={{ width: 345 }}
            onClick={() => {
              handleClickOnPart(part);
              keepPartId(part.id)
            }}
            ref={element => refs.current[index] = element}
          >
            {part.attributes.image.data ? (
              <CardMedia
                sx={{ height: 250 }}
                image={`${config.server}${part.attributes.image.data.attributes.url}`}
              />
            ) : (
              <div
                style={{ height: 250 }}
                className="bg-gray-100 flex items-center justify-center"
              >
                <NoPhotography sx={{ fontSize: 80 }} />
              </div>
            )}
            <CardContent>
              <Typography gutterBottom variant="h5">
                {part.attributes.part_name}
              </Typography>
              <Typography variant="body2">
                {part.attributes.part_code}
              </Typography>
            </CardContent>
          </Card>
          // </Link>
        ))}
        {/* <TableReport /> */}
      </div>
    </div>
  );
};

export default Dashboard;

const DialogSelectRevise = ({
  dialogSelectRevise,
  setDialogSelectRevise,
  selectedPart,
}) => {
  const navigate = useNavigate();

  const [oldParts, setOldParts] = useState([]);
  const [selectPartID, setSelectPartID] = useState(`none`);
  const [error, setError] = useState({
    revise: ``,
  });

  const gotoPart = () => {
    if (selectPartID === "none") {
      setError((prev) => ({
        ...prev,
        revise: `กรุณาเลือก Revise`,
      }));
      return;
    }
    navigate(`category/${selectPartID}`);
  };

  const handleClose = () => {
    setDialogSelectRevise((prev) => ({
      ...prev,
      isOpen: false,
    }));
    setSelectPartID(`none`);
  };

  useEffect(() => {
    if (selectedPart && selectedPart.attributes.old_parts) {
      setOldParts([...selectedPart.attributes.old_parts.data, selectedPart]);
    }
  }, [selectedPart]);

  return (
    <Dialog
      open={dialogSelectRevise.isOpen}
      onClose={handleClose}
      fullWidth
      maxWidth={`sm`}
    >
      <DialogTitle>กรุณาเลือก Rev.</DialogTitle>
      <DialogContent>
        <div>
          เนื่องจาก part นี้มีการ Revise กรุณาเลือก Revise ที่ต้องการดูข้อมูล
        </div>
        <div>
          <Select
            label={`test`}
            defaultValue={`none`}
            fullWidth
            error={error.revise !== ``}
            onChange={(e, value) => {
              setSelectPartID(e.target.value);
              setError((prev) => ({
                ...prev,
                revise: ``,
              }));
            }}
          >
            <MenuItem value={`none`}>none</MenuItem>
            {oldParts.map((oldPart, indexOldPart) => {
              return (
                <MenuItem
                  key={`menuitem${indexOldPart}`}
                  value={`${oldPart.id}`}
                >
                  {`${oldPart.attributes.revise_time}`} -
                  {`${oldPart.attributes.revise.data.attributes.revisionRecord}`}
                </MenuItem>
              );
            })}
          </Select>
          {error.revise !== "" && (
            <span className={`text-red-500`}>{error.revise}</span>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleClose}>
          ปิด
        </Button>
        <Button variant="contained" onClick={gotoPart}>
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};
