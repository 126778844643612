import React, { createContext, useEffect, useState, useContext } from "react";
import AddIcon from "@mui/icons-material/Add";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";
import FormAddSubCategories from "./FormAddSubCategories";
import { APIServices } from "./../../helpers/APIServices";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Chip from "@mui/material/Chip";
import { Link, useParams } from "react-router-dom";
import FormUpdateSubCategory from "./FormUpdateSubCategory";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { UserContext } from "../../Layout";
import SimpleBackdrop from "../../Components/Backdrop";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const PartContext = createContext();

function AddCategories() {
  // const [keepPart, setKeepPart] = useState();
  const [open, setOpen] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const [success, setSuccess] = useState(true);
  const param = useParams();
  const [editSubCategory, setEditSubCategory] = useState({
    open: false,
    data: null,
  });
  const [categories, setCategories] = useState([]);
  const [search, setSearch] = useState("");
  const [categorySearch, setCategorySearch] = useState(0);
  const user = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true);
  const [part, setPart] = useState();

  const getCategories = async () => {
    const categories = await APIServices.getCategories();
    const data = categories.data.data;
    const data2 = [
      {
        id: 0,
        label: "ALL",
      },
    ];
    data.forEach((e) => {
      data2.push({
        id: e.id,
        label: e.attributes.category,
      });
    });
    setCategories(data2);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const checkSearch = (emptyCheck) => {
    const cat = emptyCheck.category.data.attributes.category;
    if (categories.length > 0) {
      const newCategorySearch = categories.find(
        (category) => category.id === categorySearch
      );
      if (
        (newCategorySearch.id === 0 || newCategorySearch.label === cat) &&
        (search === "" ||
          emptyCheck.sub_categories.toLowerCase().includes(search.toLowerCase()) ||
          emptyCheck.category.data.attributes.category.toLowerCase().includes(search.toLowerCase()))
      ) {
        return true;
      }
      return false;
    }
  };

  const checkRole = () => {
    if (
      user.qc_role === "super_admin" ||
      user.qc_role === "admin" ||
      user.qc_role === "release"
    ) {
      return true;
    }
  };

  useEffect(() => {
    getCategories();
    const findOnePart = async () => {
      const findPart = await APIServices.findOnePartForUseName(parseInt(param.id));
      const partData = findPart.data.data;
      console.log("findOnePart 105: ", partData);
      const newPart = {
        id: partData.id,
        ...partData.attributes,
      };
  
      setPart(newPart);
    };
    findOnePart();
  }, [param.id]);

  useEffect(() => {
    // run true of false
    if (success) {
      // only true
      const getSubCategories = async () => {
        const response = await APIServices.getSubCategoriesByPartIdSlim(parseInt(param.id));
        const subCat = response.data.data;
        console.log("subcate", subCat);
        const newSubCat = subCat.map((data) => {
          return {
            id: data.id,
            ...data.attributes,
          };
        });
    
        // console.log("part", part);
        setSubCategories(newSubCat);
        setIsLoading(false);
    
        // if (part) {
        //   console.log("stop");
        //   setIsLoading(false);
        //   return
        // }
      };
      getSubCategories();
      setSuccess(false);
    }
  }, [param.id, success]);

  return (
    <>
      <SimpleBackdrop open={isLoading} setOpen={setIsLoading} />
      {part && (
        // <div className={`flex flex-row justify-evenly items-start mb-4 p-2 rounded-xl shadow-md `}>
        // <div className={`flex flex-col justify-center items-start mb-4 p-2 rounded-xl shadow-md max-w-fit `}>
        <div className={`flex flex-col lg:flex lg:flex-row items-baseline mb-2`}>
           <div className={`ml-2 font-bold text-xl`}>{part.part_name} </div>
            <div className={`ml-2 `}>({part.part_code})</div>
          {/* <div className={`flex mb-2 flex-row items-center`}>
            <Chip label={"Part Name: "}  color="primary" size="small"/>
            <div className={`ml-2 font-bold`}>{part.part_name}</div>
          </div>
          <div className={`flex mb-2 flex-row items-center`}>
            <Chip label={"Part Code: "} color="primary" size="small"/>
            <div className={`ml-2 font-bold`}>{part.part_code}</div>
          </div> */}
        </div>
      )}
      <div className="mb-2 flex justify-center items-center">
        <div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
            <InputLabel id="demo-simple-select-helper-label">
              Categories
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              value={categorySearch}
              label="Categories"
              onChange={(e) => setCategorySearch(e.target.value)}
            >
              {categories.map((data, index) => (
                <MenuItem key={index} value={data.id}>
                  {data.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <TextField
          onChange={(e) => {
            setSearch(e.target.value);
          }}
          placeholder="Search by Sub Catgory"
          className="flex-1"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        ></TextField>
        {checkRole() && (
          <Button
            sx={{
              mx: "8px",
            }}
            size="large"
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => {
              handleClickOpen();
            }}
          >
            เพิ่มข้อมูลหัวข้อคำถาม
          </Button>
        )}
      </div>
      <div className="flex flex-col justify-center items-center">
        {subCategories.length === 0 && (
          <div className="bg-white p-4 mb-4 flex justify-center w-full rounded-md shadow-md">
            <span> ไม่มีข้อมูล</span>
          </div>
        )}

        {subCategories
          .filter(
            (emptyCheck) =>
              emptyCheck.status === true &&
              emptyCheck !== "" &&
              checkSearch(emptyCheck)
          )
          .sort(function (a, b) {
            return a.id - b.id;
          })
          .map((data) => (
            // <>
            //   {data.status === true && (
            <div
              key={data.id}
              className="bg-white p-4 mb-4 flex w-full flex-row rounded-md shadow-md  cursor-pointer hover:bg-neutral-100"
              onClick={() => {
                setEditSubCategory({
                  open: true,
                  data: data,
                });
              }}
            >
              <div className="basis-1/4  flex items-center rounded-full ">
                <Chip
                  label={data.category.data.attributes.category}
                  variant="outlined"
                />
              </div>
              <div className="basis-1/2  flex items-center">
                {data.sub_categories}
              </div>
              <div className="basis-1/4  flex justify-end items-center">
                <Link key={"add_question"} to={`add_question/${data.id}`}>
                  <Fab
                    color="primary"
                    aria-label="add"
                    size="medium"
                    disabled={data.status === false ? true : false}
                  >
                    <ArrowForwardIosIcon />
                  </Fab>{" "}
                </Link>
              </div>
            </div>
            // )}
            // </>
          ))}
        <div>
          <Fab variant="extended" color="primary" onClick={handleClickOpen}>
            <AddIcon />
            <span>เพิ่มข้อมูลหัวข้อคำถาม</span>
          </Fab>
        </div>
        {part &&
          <FormAddSubCategories
            partId={parseInt(param.id)}
            open={open}
            setOpen={setOpen}
            setSuccess={setSuccess}
            categories={categories}
            setCategories={setCategories}
            part={part}
          />
        }
        {editSubCategory.open && (
          <FormUpdateSubCategory
            partId={parseInt(param.id)}
            state={[editSubCategory, setEditSubCategory]}
            setSuccess={setSuccess}
            categories={categories}
            setCategories={setCategories}
            partName={part.part_name}
            partCode={part.part_code}
            part={part}
          />
        )}
      </div>
    </>
  );
}

export default AddCategories;
