import React, { useState, useEffect, useContext } from "react";
import PartCard from "./PartCard";
import AddIcon from "@mui/icons-material/Add";
import FormAddPart from "./FormAddPart";
import { APIServices } from "./../../helpers/APIServices";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../Layout";
import Swal from "sweetalert2";
import SimpleBackdrop from "../../Components/Backdrop";

function Question() {
  const [open, setOpen] = useState(false);
  const [success, setSuccess] = useState(true);
  const [part, setPart] = useState([]);
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);

  const user = useContext(UserContext);
  const navigate = useNavigate();

  const handleSearch = (value) => {
    setSearch(value);
  };

  const getPartItem = async () => {
    const response = await APIServices.getPartSlim();
    const part = response.data.data;
    const newPart = part.map((partPerRound) => {
      return {
        id: partPerRound.id,
        ...partPerRound.attributes,
      };
    });
    setPart(newPart);
    setIsLoading(false);
  };

  if (user) {
    if (
      user.qc_role === "super_admin" ||
      user.qc_role === "admin" ||
      user.qc_role === "release" ||
      user.qc_role === "approver_revise " ||
      user.qc_role === "checker_revise"
    ) {
      if (!user.signature) {
        Swal.fire({
          title: "ผู้ใช้งานนี้ไม่มีข้อมูลลายเซ็น",
          text: "กรุณาเพิ่มลายเซ็น",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "เพิ่มลายเซ็น",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate(`/layout/profile/${user.id}`);
          }
        });
      }
    }
  }

  const checkRole = () => {
    if (
      user.qc_role === "super_admin" ||
      user.qc_role === "admin" ||
      user.qc_role === "release"
    ) {
      return true;
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  
  useEffect(() => {
    if (success) {
      // only true
      getPartItem();
      setSuccess(false);
    }
  }, [success]);

  return (
    <div>
      <SimpleBackdrop open={isLoading} setOpen={setIsLoading}/>
      <div className="mb-2 flex">
        <TextField
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          placeholder="Search by Part Name or Part Code"
          className="flex-1"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        {checkRole() && (
          <Button
            sx={{
              mx: "8px",
            }}
            startIcon={<AddIcon />}
            variant="contained"
            onClick={handleClickOpen}
          >
            เพิ่มพาร์ท
          </Button>
        )}

        {/* <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <Fab color="primary" aria-label="add" onClick={handleClickOpen}>
            <AddIcon />
          </Fab>
        </Box> */}
      </div>
      <FormAddPart open={open} setOpen={setOpen} setSuccess={setSuccess} />
      <PartCard
        part={part}
        setSuccess={setSuccess}
        search={search}
        user={user}
      />
    </div>
  );
}

export default Question;
