import React, { useState } from "react";
import logo from "../aapico.jpg";
import triangle from "../triangle.png";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import axios from "axios";
import { Button } from "@mui/material";
import config from "../config";
import CircularProgress from "@mui/material/CircularProgress";
import FileDownload from "@mui/icons-material/FileDownload";

export async function export_exceljs(dataExport) {
  const image = dataExport.imagePart;
  // config.server +
  console.log("image", image);
  const workbook = new ExcelJS.Workbook({ useStyle: true });
  const worksheet = workbook.addWorksheet("sheet", {
    pageSetup: {
      paperSize: 9,
      orientation: "portrait",
      scale: 60,
      horizontalCentered: true,
      margins: {
        left: 0.0,
        right: 0.0,
        top: 0.3,
        bottom: 0.0,
        header: 0.0,
        footer: 0.0,
      },
    },
  });
  let font = { name: "AngsanaUPC", size: 16 };
  const imageBuffer = await axios.get(logo, {
    responseType: "arraybuffer",
  });
  const image_logo = workbook.addImage({
    buffer: imageBuffer.data,
    extension: "png",
  });
  const imagePartBuffer = await axios.get(image, {
    responseType: "arraybuffer",
  });
  const image_part = workbook.addImage({
    buffer: imagePartBuffer.data,
    extension: "png",
  });
  worksheet.addImage(image_logo, "A1:A2");
  worksheet.getColumn("A").width = 12;
  worksheet.getColumn("C").width = 10;
  worksheet.getColumn("I").width = 12;
  worksheet.getColumn("K").width = 10;
  worksheet.getColumn("M").width = 10;
  worksheet.getRows(5, 12).forEach((row) => {
    row.height = 15.75;
  });
  //////////////
  worksheet.getCell("B2").value = "Aapico Structural Products Co.,Ltd.";
  worksheet.getCell("B2").style.font = {
    name: "AngsanaUPC",
    bold: true,
    size: 20,
  };
  worksheet.getCell("I2").value = "Inspection Sheet";
  worksheet.getCell("I2").style.font = {
    name: "AngsanaUPC",
    bold: true,
    size: 28,
  };
  worksheet.getCell("A3").value = "Standard for :";
  worksheet.getCell("A3").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell("B3:C3").value = `${dataExport.process_status}`;
  worksheet.getCell("B3").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  // worksheet.getCell("C3").value = "Inprocess";
  // worksheet.getCell("C3").style = {
  //   font: font,
  //   border: {
  //     top: { style: "thin" },
  //     bottom: { style: "thin" },
  //   },
  // };
  // //////put color
  // worksheet.getCell("C3").fill = {
  //   type: "pattern",
  //   pattern: "solid",
  //   fgColor: { argb: "C6EFCE" },
  //   bgColor: { argb: "C6EFCE" },
  // };
  //////
  worksheet.getCell("C3").style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("D3").style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("E3").style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("F3").value = "Part No :";
  worksheet.getCell("F3").style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("G3").value = `${dataExport.partCode}`;
  worksheet.getCell("G3").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("H3").style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("I3").value = "Part Name :";
  worksheet.getCell("I3").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("J3").value = `${dataExport.partName}`;
  worksheet.getCell("J3").style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("K3").style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("L3").style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("M3").value = "Shift :";
  worksheet.getCell("M3").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("N3").value = `${dataExport.shift}`;
  worksheet.getCell("N3").style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("O3").value = "Page";
  worksheet.getCell("O3").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("P3").value = "1 / 2";
  worksheet.getCell("P3").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  ////////////////////////////////////////
  worksheet.getCell("A4").value = "Rev.";
  worksheet.getCell("A4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("A4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.mergeCells("B4:D4");
  worksheet.getCell("B4").value = "Revision Record";
  worksheet.getCell("B4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell("B4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("C4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("D4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("E4").value = "Date";
  worksheet.getCell("E4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("E4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("F4").value = "Release";
  worksheet.getCell("F4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("F4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("G4").value = "Checked";
  worksheet.getCell("G4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("G4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("H4").value = "Approved";
  worksheet.getCell("H4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("H4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  /////////////
  worksheet.getCell("I4").value = "Rev.";
  worksheet.getCell("I4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("I4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.mergeCells("J4:L4");
  worksheet.getCell("J4").value = "Revision Record";
  worksheet.getCell("J4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell("J4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("K4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell("L4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("M4").value = "Date";
  worksheet.getCell("M4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("M4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("N4").value = "Release";
  worksheet.getCell("N4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("N4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("O4").value = "Checked";
  worksheet.getCell("O4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("O4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell("P4").value = "Approved";
  worksheet.getCell("P4").style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell("P4").alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  /////////////////table rev
  // super cut off maximum lastest 12 record function - sawanon:20231128
  dataExport.reviseData.reverse();
  dataExport.reviseData.splice(12);
  dataExport.reviseData.reverse();
  let index = 0;
  for (let index_count = 0; index_count < 12; index_count++) {
    if (index === 6) {
      index = 0;
    }
    if (index_count < 6) {
      worksheet.getCell(`A${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        dataExport.reviseData[index_count].revise_time;
      worksheet.getCell(`A${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`A${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.mergeCells(`B${index + 5}:D${index + 5}`);
      worksheet.getCell(`B${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        dataExport.reviseData[index_count].revisionRecord;
      worksheet.getCell(`B${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`B${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        // horizontal: "center",
      };
      worksheet.getCell(`C${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`D${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`E${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        dataExport.reviseData[index_count].date;
      worksheet.getCell(`E${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`E${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getCell(`F${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        `K. ${dataExport.reviseData[index_count].releaseForRevise}`;
      worksheet.getCell(`F${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`F${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getCell(`G${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        `K. ${dataExport.reviseData[index_count].checkerForRevise}`;
      worksheet.getCell(`G${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`G${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getCell(`H${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        `K. ${dataExport.reviseData[index_count].approverForRevise}`;
      worksheet.getCell(`H${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`H${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
    } else {
      // worksheet.getColumn(`I${index + 5}`).width = 12;
      worksheet.getCell(`I${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        dataExport.reviseData[index_count].revise_time;
      worksheet.getCell(`I${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`I${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.mergeCells(`J${index + 5}:L${index + 5}`);
      worksheet.getCell(`J${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        dataExport.reviseData[index_count].revisionRecord;
      worksheet.getCell(`J${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`J${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getCell(`K${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`L${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`M${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        dataExport.reviseData[index_count].date;
      worksheet.getCell(`M${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`M${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getCell(`N${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        `K. ${dataExport.reviseData[index_count].releaseForRevise}`;
      worksheet.getCell(`N${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`N${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getCell(`O${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        `K. ${dataExport.reviseData[index_count].checkerForRevise}`;
      worksheet.getCell(`O${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`O${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.getCell(`P${index + 5}`).value =
        dataExport.reviseData[index_count] &&
        `K. ${dataExport.reviseData[index_count].approverForRevise}`;
      worksheet.getCell(`P${index + 5}`).style = {
        font: font,
        border: {
          left: { style: "thin" },
          right: { style: "thin" },
          bottom: index === 5 && { style: "thin" },
        },
      };
      worksheet.getCell(`P${index + 5}`).alignment = {
        //alignment ต้องอยู่ล่าง style เท่านั้น
        vertical: "middle",
        horizontal: "center",
      };
    }
    if (index < 6) {
      index++;
    }
  }
  /////////////////draw image part
  worksheet.addImage(image_part, {
    tl: { col: 0.5, row: 10.5 },
    // ext: { width: 1024, height: 1024 },
    ext: { width: 1024, height: 1024 },
  });
  for (let index_count = 11; index_count < 63; index_count++) {
    if (index_count !== 62) {
      worksheet.getCell(`A${index_count}`).style = {
        border: {
          left: { style: "thin" },
        },
      };
      worksheet.getCell(`P${index_count}`).style = {
        border: {
          right: { style: "thin" },
        },
      };
    } else {
      worksheet.getCell(`A${index_count}`).style = {
        border: {
          left: { style: "thin" },
          bottom: { style: "thin" },
        },
      };
      worksheet.getCell(`P${index_count}`).style = {
        border: {
          right: { style: "thin" },
          bottom: { style: "thin" },
        },
      };
      let cols = [
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
      ];
      cols.forEach((col) => {
        worksheet.getCell(`${col + index_count}`).style = {
          border: {
            bottom: { style: "thin" },
          },
        };
      });
    }
  }
  //////////////////
  const startTableData = 90;
  worksheet.getCell(`H${startTableData}`).value = "Date";
  worksheet.getCell(`H${startTableData}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`H${startTableData}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`H${startTableData}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.mergeCells(`I${startTableData}:N${startTableData}`);
  worksheet.getCell(`I${startTableData}`).value = dataExport.date;
  worksheet.getCell(`I${startTableData}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`I${startTableData}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`I${startTableData}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`J${startTableData}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell(`K${startTableData}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell(`L${startTableData}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell(`M${startTableData}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell(`N${startTableData}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`H${startTableData + 1}`).value = "Time";
  worksheet.getCell(`H${startTableData + 1}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`H${startTableData + 1}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`H${startTableData + 1}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.mergeCells(`I${startTableData + 1}:N${startTableData + 1}`);
  worksheet.getCell(`I${startTableData + 1}`).value = dataExport.time;
  worksheet.getCell(`I${startTableData + 1}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`I${startTableData + 1}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`I${startTableData + 1}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`N${startTableData + 1}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  // worksheet.mergeCells("K30:L30");
  // worksheet.getCell("K30").value = "11:00";
  // worksheet.getCell("K30").style = {
  //   font: font,
  //   border: {
  //     top: { style: "thin" },
  //     bottom: { style: "thin" },
  //     left: { style: "thin" },
  //   },
  // };
  // worksheet.getCell("K30").alignment = {
  //   //alignment ต้องอยู่ล่าง style เท่านั้น
  //   vertical: "middle",
  //   horizontal: "center",
  // };
  // worksheet.getCell("K30").fill = {
  //   type: "pattern",
  //   pattern: "solid",
  //   fgColor: { argb: "FFEB9C" },
  //   bgColor: { argb: "FFEB9C" },
  // };
  // worksheet.getCell("L30").style = {
  //   border: {
  //     top: { style: "thin" },
  //     bottom: { style: "thin" },
  //     right: { style: "thin" },
  //   },
  // };
  // worksheet.mergeCells("M30:N30");
  // worksheet.getCell("M30").value = "14:00";
  // worksheet.getCell("M30").style = {
  //   font: font,
  //   border: {
  //     top: { style: "thin" },
  //     bottom: { style: "thin" },
  //     left: { style: "thin" },
  //   },
  // };
  // worksheet.getCell("M30").alignment = {
  //   //alignment ต้องอยู่ล่าง style เท่านั้น
  //   vertical: "middle",
  //   horizontal: "center",
  // };
  // worksheet.getCell("M30").fill = {
  //   type: "pattern",
  //   pattern: "solid",
  //   fgColor: { argb: "FFEB9C" },
  //   bgColor: { argb: "FFEB9C" },
  // };
  // worksheet.getCell("N30").style = {
  //   border: {
  //     top: { style: "thin" },
  //     bottom: { style: "thin" },
  //     right: { style: "thin" },
  //   },
  // };
  //////////header table
  worksheet.getCell(`A${startTableData + 2}`).value = "Type Check";
  worksheet.getCell(`A${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`A${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`A${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  // TODO:
  worksheet.mergeCells(`B${startTableData + 2}:C${startTableData + 2}`);
  worksheet.getCell(`B${startTableData + 2}`).value = "Category";
  worksheet.getCell(`B${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`B${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`C${startTableData + 2}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.mergeCells(`D${startTableData + 2}:F${startTableData + 2}`);
  worksheet.getCell(`D${startTableData + 2}`).value = "Point Check";
  worksheet.getCell(`D${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`D${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "left",
  };
  worksheet.getCell(`D${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  // worksheet.getCell(`C${startTableData + 2}`).style = {
  //   border: {
  //     top: { style: "thin" },
  //     bottom: { style: "thin" },
  //   },
  // };
  // worksheet.getCell(`D${startTableData + 2}`).style = {
  //   border: {
  //     top: { style: "thin" },
  //     bottom: { style: "thin" },
  //   },
  // };
  worksheet.getCell(`E${startTableData + 2}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell(`F${startTableData + 2}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`G${startTableData + 2}`).value = "Rev.";
  worksheet.getCell(`G${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`G${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`G${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`H${startTableData + 2}`).value = "Spec";
  worksheet.getCell(`H${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`H${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`H${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`I${startTableData + 2}`).value = 1;
  worksheet.getCell(`I${startTableData + 2}`).note = "เช็คครั้งที่ 1";
  worksheet.getCell(`I${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`I${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`I${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`J${startTableData + 2}`).value = "Jude";
  worksheet.getCell(`J${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`J${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`J${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`K${startTableData + 2}`).value = 2;
  worksheet.getCell(`K${startTableData + 2}`).note = "เช็คครั้งที่ 2";
  worksheet.getCell(`K${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`K${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`K${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`L${startTableData + 2}`).value = "Jude";
  worksheet.getCell(`L${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`L${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`L${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`M${startTableData + 2}`).value = 3;
  worksheet.getCell(`M${startTableData + 2}`).note = "เช็คครั้งที่ 3";
  worksheet.getCell(`M${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`M${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`M${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`N${startTableData + 2}`).value = "Jude";
  worksheet.getCell(`N${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`N${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`N${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.mergeCells(`O${startTableData + 2}:P${startTableData + 2}`);
  worksheet.getCell(`O${startTableData + 2}`).value = "Comment";
  worksheet.getCell(`O${startTableData + 2}`).style = {
    font: font,
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`O${startTableData + 2}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "left",
  };
  worksheet.getCell(`O${startTableData + 2}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "FFEB9C" },
    bgColor: { argb: "FFEB9C" },
  };
  worksheet.getCell(`P${startTableData + 2}`).style = {
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
    },
  };
  //////////////loop render data
  let index_render = startTableData + 3;
  for (
    let index_count = 0;
    index_count < dataExport.dataquestion.length;
    index_count++
  ) {
    // index_render
    worksheet.getCell(`A${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      dataExport.dataquestion[index_count].typeCheck;
    worksheet.getCell(`A${index_render + index_count}`).style = {
      font: font,
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`A${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.mergeCells(
      `B${index_render + index_count}:C${index_render + index_count}`
    );
    worksheet.getCell(`B${index_render + index_count}`).value =
      dataExport.dataquestion[index_count].sub_category;
    worksheet.getCell(`B${index_render + index_count}`).style = {
      font: font,
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`B${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell(`C${index_render + index_count}`).style = {
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };
    worksheet.mergeCells(
      `D${index_render + index_count}:F${index_render + index_count}`
    );
    worksheet.getCell(`D${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      dataExport.dataquestion[index_count].checkPoint;
    worksheet.getCell(`D${index_render + index_count}`).style = {
      font: font,
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`D${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "left",
    };
    // worksheet.getCell(`C${index_render + index_count}`).style = {
    //   border: {
    //     top: { style: "thin" },
    //     bottom: { style: "thin" },
    //   },
    // };
    // worksheet.getCell(`D${index_render + index_count}`).style = {
    //   border: {
    //     top: { style: "thin" },
    //     bottom: { style: "thin" },
    //   },
    // };
    worksheet.getCell(`E${index_render + index_count}`).style = {
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
      },
    };
    worksheet.getCell(`F${index_render + index_count}`).style = {
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };
    if (dataExport.dataquestion[index_count].lastRevise) {
      const imageBuffer_triangle = await axios.get(triangle, {
        responseType: "arraybuffer",
      });
      const image_triangle = workbook.addImage({
        buffer: imageBuffer_triangle.data,
        extension: "png",
      });
      let point_draw_triangle = worksheet.getCell(
        `G${index_render + index_count}`
      );
      worksheet.addImage(image_triangle, {
        tl: {
          col: point_draw_triangle.col - 0.8,
          row: point_draw_triangle.row - 1,
        },
        ext: { width: 40, height: 40 },
      });
    }
    worksheet.getCell(`G${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      dataExport.dataquestion[index_count].revise_time;
    worksheet.getCell(`G${index_render + index_count}`).style = {
      font: font,
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`G${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`H${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      (dataExport.dataquestion[index_count].spec_min &&
      dataExport.dataquestion[index_count].spec_max
        ? `${dataExport.dataquestion[index_count].spec_min.toFixed(
            2
          )}-${dataExport.dataquestion[index_count].spec_max.toFixed(2)}`
        : "-");
    worksheet.getCell(`H${index_render + index_count}`).style = {
      font: font,
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`H${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`I${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      dataExport.dataquestion[index_count].actual1;
    worksheet.getCell(`I${index_render + index_count}`).style = {
      font: font,
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`I${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`J${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      dataExport.dataquestion[index_count].answer1;
    worksheet.getCell(`J${index_render + index_count}`).style = {
      font: {
        ...font,
        color: dataExport.dataquestion[index_count] &&
          dataExport.dataquestion[index_count].answer1 === "NG" && {
            argb: "FF0000",
          },
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`J${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`K${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      dataExport.dataquestion[index_count].actual2;
    worksheet.getCell(`K${index_render + index_count}`).style = {
      font: font,
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`K${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`L${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      dataExport.dataquestion[index_count].answer2;
    worksheet.getCell(`L${index_render + index_count}`).style = {
      font: {
        ...font,
        color: dataExport.dataquestion[index_count] &&
          dataExport.dataquestion[index_count].answer2 === "NG" && {
            argb: "FF0000",
          },
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`L${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`M${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      dataExport.dataquestion[index_count].actual3;
    worksheet.getCell(`M${index_render + index_count}`).style = {
      font: font,
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`M${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.getCell(`N${index_render + index_count}`).value =
      dataExport.dataquestion[index_count] &&
      dataExport.dataquestion[index_count].answer3;
    worksheet.getCell(`N${index_render + index_count}`).style = {
      font: {
        ...font,
        color: dataExport.dataquestion[index_count] &&
          dataExport.dataquestion[index_count].answer3 === "NG" && {
            argb: "FF0000",
          },
      },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`N${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "center",
    };
    worksheet.mergeCells(
      `O${index_render + index_count}:P${index_render + index_count}`
    );
    worksheet.getCell(`O${index_render + index_count}`).value = dataExport
      .dataquestion[index_count]
      ? `${
          dataExport.dataquestion[index_count] &&
          dataExport.dataquestion[index_count].comment1 &&
          "1) " + dataExport.dataquestion[index_count].comment1 + ", "
        }` +
        `${
          dataExport.dataquestion[index_count] &&
          dataExport.dataquestion[index_count].comment2 &&
          "2) " + dataExport.dataquestion[index_count].comment2 + ", "
        }` +
        `${
          dataExport.dataquestion[index_count] &&
          dataExport.dataquestion[index_count].comment3 &&
          "3) " + dataExport.dataquestion[index_count].comment3
        }`
      : "";
    worksheet.getCell(`O${index_render + index_count}`).style = {
      font: { ...font, color: { argb: "FF0000" } },
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        left: { style: "thin" },
      },
    };
    worksheet.getCell(`O${index_render + index_count}`).alignment = {
      //alignment ต้องอยู่ล่าง style เท่านั้น
      vertical: "middle",
      horizontal: "left",
    };
    worksheet.getCell(`P${index_render + index_count}`).style = {
      border: {
        top: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      },
    };
  }

  const sumPointOK =
    dataExport.dataquestion.filter((item) => item.answer1 === "OK").length +
    dataExport.dataquestion.filter((item) => item.answer2 === "OK").length +
    dataExport.dataquestion.filter((item) => item.answer3 === "OK").length;
  const sumPointNG =
    dataExport.dataquestion.filter((item) => item.answer1 === "NG").length +
    dataExport.dataquestion.filter((item) => item.answer2 === "NG").length +
    dataExport.dataquestion.filter((item) => item.answer3 === "NG").length;

  index_render += dataExport.dataquestion.length + 4;
  const plus_1 = index_render + 1;
  const plus_2 = index_render + 2;
  const plus_3 = index_render + 3;
  const plus_4 = index_render + 4;

  if (dataExport.signature_inspector !== null) {
    const image_signature_inspector_buffer = await axios.get(
      dataExport.signature_inspector,
      {
        responseType: "arraybuffer",
      }
    );
    const image_signature_inspector = workbook.addImage({
      buffer: image_signature_inspector_buffer.data,
      extension: "png",
    });
    worksheet.addImage(image_signature_inspector, `O${plus_1}:P${plus_4}`);
  }

  if (dataExport.signature_approver !== null) {
    const image_signature_approver_buffer = await axios.get(
      dataExport.signature_approver,
      {
        responseType: `arraybuffer`,
      }
    );
    const image_signature_approver = workbook.addImage({
      buffer: image_signature_approver_buffer.data,
      extension: `png`,
    });
    worksheet.addImage(image_signature_approver, `K${plus_1}:L${plus_4}`);
  }

  // let image_signature_checker;
  if (dataExport.signature_checker !== null) {
    const image_signature_checker_buffer = await axios.get(
      dataExport.signature_checker,
      {
        responseType: "arraybuffer",
      }
    );
    const image_signature_checker = workbook.addImage({
      buffer: image_signature_checker_buffer.data,
      extension: "png",
    });
    worksheet.addImage(image_signature_checker, `M${plus_1}:N${plus_4}`);
  }
  /////////////footer
  worksheet.getCell(`C${index_render}`).value = "ALL POINT";
  worksheet.getCell(`C${index_render}`).style = {
    font: { ...font, size: 14 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`C${index_render}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.mergeCells(`C${plus_1}`, `C${plus_4}`);
  // worksheet.getCell(`C${plus_1}`).value = dataExport.dataquestion.length;
  worksheet.getCell(`C${plus_1}`).value = sumPointOK + sumPointNG;
  // dataExport.dataquestion.filter(
  //   (item) =>
  //     item.answer1 === "OK" || item.answer2 === "OK" || item.answer3 === "OK"

  // ).length + dataExport.dataquestion.filter(
  //   (item) =>
  //     item.answer1 === "NG" || item.answer2 === "NG" || item.answer3 === "NG"
  // ).length
  worksheet.getCell(`C${plus_1}`).style = {
    font: { ...font, size: 24 },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`C${plus_1}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`C${plus_2}`).style = {
    border: {
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`C${plus_3}`).style = {
    border: {
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`C${plus_4}`).style = {
    border: {
      left: { style: "thin" },
      right: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell(`D${index_render}`).value = "POINT OK";
  worksheet.getCell(`D${index_render}`).style = {
    font: { ...font, size: 14 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`D${index_render}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.mergeCells(`D${plus_1}`, `D${plus_4}`);
  // worksheet.getCell("D${plus_1}").value = dataExport.dataquestion.filter(
  //   (item) =>
  //     item.answer1 === "OK" && item.answer2 === "OK" && item.answer3 === "OK"

  // ).length

  worksheet.getCell(`D${plus_1}`).value = sumPointOK;
  worksheet.getCell(`D${plus_1}`).style = {
    font: { ...font, size: 24 },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`D${plus_1}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`D${plus_2}`).style = {
    border: {
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`D${plus_3}`).style = {
    border: {
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`D${plus_4}`).style = {
    border: {
      left: { style: "thin" },
      right: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell(`E${index_render}`).value = "POINT NG";
  worksheet.getCell(`E${index_render}`).style = {
    font: { ...font, size: 14 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`E${index_render}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  // image_signature_inspector
  worksheet.mergeCells(`E${plus_1}`, `E${plus_4}`);

  worksheet.getCell(`E${plus_1}`).value = sumPointNG;
  // dataExport.dataquestion.filter(
  //   (item) =>
  //     item.answer1 === "NG" || item.answer2 === "NG" || item.answer3 === "NG"
  // ).length;
  worksheet.getCell(`E${plus_1}`).style = {
    font: { ...font, size: 24 },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`E${plus_1}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`E${plus_2}`).style = {
    border: {
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`E${plus_3}`).style = {
    border: {
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`E${plus_4}`).style = {
    border: {
      left: { style: "thin" },
      right: { style: "thin" },
      bottom: { style: "thin" },
    },
  };
  worksheet.getCell(`F${index_render}`).value = "%QL";
  worksheet.getCell(`F${index_render}`).style = {
    font: { ...font, size: 14 },
    border: {
      top: { style: "thin" },
      bottom: { style: "thin" },
      right: { style: "thin" },
      left: { style: "thin" },
    },
  };
  worksheet.getCell(`F${index_render}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.mergeCells(`F${plus_1}`, `F${plus_4}`);
  worksheet.getCell(`F${plus_1}`).value = {
    formula: `D${plus_1}/C${plus_1}`,
  };
  worksheet.getCell(`F${plus_1}`).style = {
    font: { ...font, size: 24 },
    border: {
      top: { style: "thin" },
      left: { style: "thin" },
      right: { style: "thin" },
    },
  };
  worksheet.getCell(`F${plus_1}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: "middle",
    horizontal: "center",
  };
  worksheet.getCell(`F${plus_1}`).numFmt = `0%`;
  worksheet.getCell(`F${plus_2}`).style = {
    border: {
      left: { style: `thin` },
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`F${plus_3}`).style = {
    border: {
      left: { style: `thin` },
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`F${plus_4}`).style = {
    border: {
      left: { style: `thin` },
      right: { style: `thin` },
      bottom: { style: `thin` },
    },
  };
  //////// ลายเซ็น
  worksheet.mergeCells(`K${index_render}`, `L${index_render}`);
  worksheet.getCell(`K${index_render}`).value = `Approved`;
  worksheet.getCell(`K${index_render}`).style = {
    font: font,
    border: {
      top: { style: `thin` },
      bottom: { style: `thin` },
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`K${index_render}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: `middle`,
    horizontal: `center`,
  };
  worksheet.getCell(`L${index_render}`).style = {
    border: {
      top: { style: `thin` },
      bottom: { style: `thin` },
      right: { style: `thin` },
    },
  };
  worksheet.mergeCells(`K${plus_1}`, `L${plus_4}`);
  // worksheet.addImage(
  //   image_signature_approver
  //     ? image_signature_approver
  //     : image_signature_approver,
  //   `K${plus_1}:L${plus_4}`
  // );
  worksheet.getCell(`K${plus_1}`).style = {
    border: {
      top: { style: `thin` },
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`K${plus_2}`).style = {
    border: {
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`K${plus_3}`).style = {
    border: {
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`K${plus_4}`).style = {
    border: {
      left: { style: `thin` },
      bottom: { style: `thin` },
    },
  };
  worksheet.getCell(`L${plus_1}`).style = {
    border: {
      top: { style: `thin` },
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`L${plus_2}`).style = {
    border: {
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`L${plus_3}`).style = {
    border: {
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`L${plus_4}`).style = {
    border: {
      right: { style: `thin` },
      bottom: { style: `thin` },
    },
  };
  worksheet.mergeCells(`M${index_render}`, `N${index_render}`);

  worksheet.getCell(`M${index_render}`).value = `Checked`;
  worksheet.getCell(`M${index_render}`).style = {
    font: font,
    border: {
      top: { style: `thin` },
      bottom: { style: `thin` },
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`M${index_render}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: `middle`,
    horizontal: `center`,
  };
  worksheet.getCell(`N${index_render}`).style = {
    border: {
      top: { style: `thin` },
      bottom: { style: `thin` },
      right: { style: `thin` },
    },
  };
  worksheet.mergeCells(`M${plus_1}`, `N${plus_4}`);

  worksheet.getCell(`M${plus_1}`).style = {
    border: {
      top: { style: `thin` },
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`M${plus_2}`).style = {
    border: {
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`M${plus_3}`).style = {
    border: {
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`M${plus_4}`).style = {
    border: {
      left: { style: `thin` },
      bottom: { style: `thin` },
    },
  };
  worksheet.getCell(`N${plus_1}`).style = {
    border: {
      top: { style: `thin` },
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`N${plus_2}`).style = {
    border: {
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`N${plus_3}`).style = {
    border: {
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`N${plus_4}`).style = {
    border: {
      right: { style: `thin` },
      bottom: { style: `thin` },
    },
  };
  worksheet.mergeCells(`O${index_render}`, `P${index_render}`);
  worksheet.getCell(`O${index_render}`).value = `Inspector`;
  worksheet.getCell(`O${index_render}`).style = {
    font: font,
    border: {
      top: { style: `thin` },
      left: { style: `thin` },
      bottom: { style: `thin` },
    },
  };
  worksheet.getCell(`O${index_render}`).alignment = {
    //alignment ต้องอยู่ล่าง style เท่านั้น
    vertical: `middle`,
    horizontal: `center`,
  };
  worksheet.getCell(`P${index_render}`).style = {
    border: {
      top: { style: `thin` },
      bottom: { style: `thin` },
      right: { style: `thin` },
    },
  };
  worksheet.mergeCells(`O${plus_1}`, `P${plus_4}`);

  worksheet.getCell(`O${plus_1}`).style = {
    border: {
      top: { style: `thin` },
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`O${plus_2}`).style = {
    border: {
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`O${plus_3}`).style = {
    border: {
      left: { style: `thin` },
    },
  };
  worksheet.getCell(`O${plus_4}`).style = {
    border: {
      left: { style: `thin` },
      bottom: { style: `thin` },
    },
  };
  worksheet.getCell(`P${plus_1}`).style = {
    border: {
      top: { style: `thin` },
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`P${plus_2}`).style = {
    border: {
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`P${plus_3}`).style = {
    border: {
      right: { style: `thin` },
    },
  };
  worksheet.getCell(`P${plus_4}`).style = {
    border: {
      right: { style: `thin` },
      bottom: { style: "thin" },
    },
  };
  // save under export.xlsx
  // const dateForName = dayjs().format("DD/MM/YYYY HH:mm:ss");
  await workbook.xlsx.writeBuffer().then((res) => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const fileExtension = ".xlsx";
    const blob = new Blob([res], { type: fileType });
    saveAs(blob, `${dataExport.partName}` + fileExtension);
  });
}
const ExportInspectionSheet = ({ data, isReady }) => {
  const [disabled, setDisabled] = useState(true);

  return (
    <div className='App'>
      <Button
        // style={{ padding: "10px", margin: "5px" }}
        disabled={disabled}
        onClick={() => export_exceljs(data)}
        variant='outlined'
        endIcon={<Icon isReady={isReady} setDisabled={setDisabled} />}>
        download
      </Button>
    </div>
  );
};

export default ExportInspectionSheet;

const Icon = ({ isReady, setDisabled }) => {
  if (isReady === undefined) {
    setDisabled(false);
    return <FileDownload />;
  } else {
    if (isReady) {
      setDisabled(false);
      return <FileDownload />;
    } else {
      setDisabled(true);
      return <CircularProgress size={20} />;
    }
  }
};
