import React, { useEffect, useState, useContext } from "react";
import { useParams, useLocation } from "react-router-dom";
import TableReportNormalQT from "../Components/TableReportNormalQT";
import { APIServices } from "../helpers/APIServices";
import dayjs from "dayjs";
import LineChart from "../Components/LineChart";
import DateTime from "../Components/DateTime";
import Button from "@mui/material/Button";
import { UserContext } from "../Layout";
import config from "../config";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import SimpleBackdrop from "../Components/Backdrop";

const headers = [
  {
    name: "No.",
  },
  {
    name: "create date",
  },
  {
    name: "shift",
  },
  {
    name: "answer",
    col: [
      {
        name: "No.1",
      },
      {
        name: "No.2",
      },
      {
        name: "No.3",
      },
    ],
  },
  {
    name: "inspector",
  },
  {
    name: "reviewer",
  },
];

const PerPathNormalQT = () => {
  const defaultDateTime = {
    start: `${dayjs().subtract(1, "d").format("YYYY-MM-DD")} 00:00:00`,
    end: `${dayjs().format("YYYY-MM-DD")} 23:59:59`,
  };
  const param = useParams();
  const [reportData, setReportData] = useState([]);
  const [startDate, setStartDate] = useState({
    error: false,
    value: defaultDateTime.start,
  });
  const [endDate, setEndDate] = useState({
    error: false,
    value: defaultDateTime.end,
  });
  const [loading, setLoading] = useState(false);

  const [checkDate, setCheckDate] = useState({
    error: false,
    message: "end date should more than start date",
  });
  const [part, setPart] = useState({});
  const user = useContext(UserContext);
  let location = useLocation();
  let locationArr = location.pathname.split("/").reverse();

  const findOnePart = async (startDate, endDate) => {
    if (!startDate && !endDate) {
      startDate = defaultDateTime.start;
      endDate = defaultDateTime.end;
    }
    setLoading(true);
    const responseFromFindOnePart = await APIServices.findOnePart(
      locationArr[4]
    );
    const responseFromFindOneQuestion = await APIServices.findOneQuestion(
      param.id
    );
    const paper = await APIServices.getPaper(
      startDate,
      endDate,
      locationArr[4],
      user
    );
    const resultFromFindOnePart = responseFromFindOnePart.data;
    const resultFromFindOneQuestion = responseFromFindOneQuestion.data;

    const parts = resultFromFindOnePart.data;
    let questionData;
    const result = paper.data;

    if (resultFromFindOnePart.data) {
      for (let i = 0; i < parts.attributes.sub_categories.data.length; i++) {
        const subCatPerLoop = parts.attributes.sub_categories.data[i];
        let checkBreak = false;
        const questions = subCatPerLoop.attributes.questions.data;
        const findQuestion = questions.find((qt) => {
          return qt.id === parseInt(param.id);
        });
        if (findQuestion) {
          checkBreak = true;
          questionData = {
            question_name: findQuestion.attributes.question
              ? findQuestion.attributes.question
              : null,
            spec_min: findQuestion.attributes.spec_min
              ? findQuestion.attributes.spec_min
              : null,
            spec_max: findQuestion.attributes.spec_max
              ? findQuestion.attributes.spec_max
              : null,
          };
          break;
        }
        if (checkBreak) {
          break;
        }
      }
      const image = parts.attributes.image
        ? parts.attributes.image.data.attributes.url
        : "";

      setPart({
        part_name: parts.attributes.part_name,
        part_code: parts.attributes.part_code,
        part_image: image,
        ...questionData,
      });

      if (result.data) {
        const papers = result.data;
        let report = [];
        papers.forEach(async (paper, index) => {
          try {
            let realData;
            if (!Array.isArray(paper.attributes.answer.sub_categories)) {
              throw Error(
                "paper.attributes.answer.sub_categories should array type"
              );
            }
            const sub_categories = paper.attributes.answer.sub_categories;
            let isSkip = false;
            sub_categories.forEach(async (sub_category) => {
              const realPaper = sub_category.questions.find((paper) => {
                return paper.questionId === parseInt(locationArr[0]);
              });
              if (realPaper) {
                if (realPaper.skipQuestion) {
                  isSkip = true;
                  return;
                }
                realData = realPaper;
              }
            });
            if (isSkip) {
              return;
            }

            const inspector =
              paper.attributes.inspector_id.data.attributes.name;
            const checker = paper.attributes.checker_id.data
              ? paper.attributes.checker_id.data.attributes.name
              : "-";
            if (realData !== undefined) {
              report.push({
                id: paper.id,
                createAt: dayjs(paper.attributes.createdAt).format(
                  "YYYY-MM-DD HH:mm:ss"
                ),
                data: realData,
                shift: paper.attributes.shift,
                inspector: inspector,
                checker: checker,
                statusDelete: paper.attributes.delete,
              });
            }
          } catch (error) {
            console.error(error);
          }
        });
        console.log("report 185", report);
        setReportData(report);
      }
    }
    setLoading(false);
  };

  const handleOnChangeStartDate = (date, time) => {
    if (!date || !time) {
      setStartDate((prev) => ({
        ...prev,
        value: undefined,
      }));
      return;
    }
    const startDate = dayjs(`${date} ${time}`).format("YYYY-MM-DD HH:mm:ss");
    setStartDate((prev) => ({
      ...prev,
      value: startDate,
      error: false,
    }));
  };

  const handleOnChangeEndDate = (date, time) => {
    if (!date || !time) {
      setEndDate((prev) => ({
        ...prev,
        value: undefined,
      }));
      return;
    }
    const endDate = dayjs(`${date} ${time}`).format("YYYY-MM-DD HH:mm:ss");
    setEndDate((prev) => ({
      ...prev,
      value: endDate,
      error: false,
    }));
  };

  const handleSubmitSearch = async (startDate, endDate) => {
    if (!startDate.value) {
      setStartDate((prev) => ({
        ...prev,
        error: true,
      }));
      return;
    }
    if (!endDate.value) {
      setEndDate((prev) => ({
        ...prev,
        error: true,
      }));
      return;
    }
    if (
      dayjs(endDate.value).isBefore(startDate.value, "minute") ||
      dayjs(endDate.value).isSame(startDate.value, "minute")
    ) {
      setCheckDate((prev) => ({
        ...prev,
        error: true,
      }));
      setTimeout(() => {
        setCheckDate((prev) => ({
          ...prev,
          error: false,
        }));
      }, 2000);
      return;
    }

    await findOnePart(startDate.value, endDate.value);
  };

  useEffect(() => {
    findOnePart();
  }, []);
  return (
    <div>
      {/* <pre>{JSON.stringify(part)}</pre> */}
      {/* <div className="bg-black w960 h-40 flex justify-center items-center">
      <div className="py-2 px-4 bg-white rounded-full hover:bg-yellow-300 transition">
        Sawanon Wattanasit
      </div>
      </div> */}
      {/* <div className={`lg:flex lg:flex-row p-2 justify-center flex flex-col`}> */}
      <SimpleBackdrop open={loading} setOpen={setLoading} />
      <div
        className={`bg-white rounded-md drop-shadow py-2 px-4 lg:mt-0 lg:basis-1/2 lg:m-1 flex flex-row`}>
        <div className={`basis-1/2 `}>
          {/* <div className="flex">
            <div className={`basis-1/2 text-gray-500`}>ID QT :</div>
            <div className={`basis-1/2 text-end`}>{part.part_name}</div>
          </div> */}
          <div className='flex'>
            <div className={`basis-2/5 text-gray-500 `}>Part name :</div>
            <div className={`basis-3/5 text-end`}>{part.part_name}</div>
          </div>
          <div className='flex mt-2'>
            <div className={`basis-2/5 text-gray-500`}>Part No. :</div>
            <div className={`basis-3/5 text-end`}>{part.part_code}</div>
          </div>
          <div className='flex mt-2'>
            <div className={`basis-2/5 text-gray-500`}>Characteristic :</div>
            <div className={`basis-3/5 text-end`}>{part.question_name}</div>
          </div>
          {part.spec_min && part.spec_max && (
            <div className='flex mt-2'>
              <div className={`basis-2/5 text-gray-500`}>Specification :</div>
              <div className={`basis-3/5 text-end`}>
                {part.spec_min && (
                  <span className={``}>{`(Min) ${part.spec_min.toFixed(
                    2
                  )}`}</span>
                )}
                {part.spec_max && (
                  <span className={`ml-4`}>{`(Max) ${part.spec_max.toFixed(
                    2
                  )}`}</span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={`flex justify-center items-center basis-1/2 m-1`}>
          {part.part_image !== null ? (
            <img
              src={part.part_image}
              // config.server +
              className=' object-contain h-[300px]'
            />
          ) : (
            <div
              style={{ width: "6rem", height: "6rem" }}
              className='bg-gray-100 flex items-center justify-center rounded-lg'>
              <NoPhotographyIcon />
            </div>
          )}
        </div>
      </div>
      {/* </div> */}
      <div>
        <div
          className={` md:basis-1/2 flex flex-row justify-center items-center mt-4 md:ml-2`}>
          {checkDate.error && (
            <div
              className={`bg-red-500 py-2 px-4 text-white absolute rounded-md -bottom-10`}>
              {checkDate.message}
            </div>
          )}
          <div className={`${startDate.error && `bg-red-300 rounded`}`}>
            <DateTime
              value={startDate.value}
              autoFocus={startDate.error}
              label={`Start date`}
              onChange={handleOnChangeStartDate}
            />
          </div>
          <div className={`${endDate.error && `bg-red-300 rounded`}`}>
            <DateTime
              value={endDate.value}
              autoFocus={endDate.error}
              label={`End date`}
              onChange={handleOnChangeEndDate}
            />
          </div>
          <div className={`m-2 `}>
            <Button
              variant='contained'
              onClick={() => handleSubmitSearch(startDate, endDate)}>
              Search
            </Button>
          </div>
        </div>
        <div
          className={`lg:mt-2 mt-2 p-2  flex justify-center items-center lg:flex lg:justify-center lg:items-center `}>
          {reportData.length > 0 ? (
            <TableReportNormalQT
              className={`mt-4`}
              data={reportData}
              headers={headers}
            />
          ) : (
            <div
              className={`text-2xl flex justify-center items-center lg:flex lg:justify-center lg:items-center `}>
              No data
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PerPathNormalQT;
