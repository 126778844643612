import React, { useRef, useState, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import config from "../../config";
import { Link, useNavigate, useParams } from "react-router-dom";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import { APIServices } from "./../../helpers/APIServices";
import { getToday, getTomorrow, scrollToRef } from "./../../helpers/common";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Select,
} from "@mui/material";
import SimpleBackdrop from "../../Components/Backdrop";
import _ from "lodash";

const dayjs = require("dayjs");

const label = { inputProps: { "aria-label": "Switch demo" } };

export default function PartCard({ user, setSuccess, search }) {
  const [paperData, setPaperData] = useState([]);
  const paramPart = useParams();
  const date = dayjs().format("DD/MM/YYYY");

  const today = dayjs().minute(0);
  // const shift = today.hour() >= 8 && today.hour() < 20 ? "Day" : "Night";
  // const userLocal = JSON.parse(localStorage.getItem("QCAPPuser"));
  const navigate = useNavigate();
  const [selectedPart, setSelectedPart] = useState();
  const [dialogSelectRevise, setDialogSelectRevise] = useState({
    isOpen: false,
  });

  const checkSearch = (emptyCheck) => {
    if (
      search === "" ||
      emptyCheck.part_name.toLowerCase().includes(search.toLowerCase()) ||
      emptyCheck.part_code.toLowerCase().includes(search.toLowerCase())
    ) {
      return true;
    }
    return false;
  };

  const checkTimes = () => {
    if (paperData.length === 20) {
      return true;
    }
  };

  const visibleButton = () => {
    if (user.qc_role === "inspector" || user.qc_role === "super_admin") {
      return true;
    } else {
      return false;
    }
  };

  const part_data = APIServices.usePartWithImage();
  let part = [];
  // let arrUrl = [];
  if (part_data.data) {
    const newPart = part_data.data.data.data.map((partPerRound) => {
      return {
        id: partPerRound.id,
        ...partPerRound.attributes,
      };
    });
    part = newPart;
    // for (let i = 0; i < part.length; i++) {
    //   arrUrl.push(
    //     APIServices.getUrlPaper(getToday(), getTomorrow(), part[i].id)
    //   );
    // }
  }

  // const arrPaper = APIServices.useAllFetch(arrUrl);
  // if (arrPaper.data) {
  //   for (const [index, data] of part.entries()) {
  //     part[index].history = arrPaper && arrPaper.data[index].data.data;
  //   }
  // }

  const handleClickOnPart = (partID) => {
    // console.log("part 78", partID);
    const thisPart = part.find((part) => part.id === partID);
    if (thisPart.old_parts.data.length > 0) {
      setSelectedPart(thisPart);
      setDialogSelectRevise((prev) => ({
        ...prev,
        isOpen: true,
      }));
    } else {
      navigate(`history/${partID}`);
    }
    // <Link
    //   key={"history"}
    //   to={`history/${data.id}`}
    //   state={{ data: data }}
    // ></Link>
  };

  const refs = useRef([])
  const keepPartId = "scroll-position-part-id-marker"
  const [partMakerId] = useState(() => {
    const presistedId = sessionStorage.getItem(keepPartId)
    return presistedId ? presistedId : null
  })

  const keepIdPart = (id) => {
    sessionStorage.setItem(keepPartId, id)
  }

  useEffect(() => {
    console.log("run useEffect 123:");
    if(partMakerId){
      const partIndex = part.findIndex(part => part.id.toString() === partMakerId.toString())
      console.log("partIndex", partIndex);
      scrollToRef(refs.current[partIndex])
      sessionStorage.removeItem(keepPartId)
    }
  }, [refs.current])

  return (
    <div className="h-(screen-18)">
      <SimpleBackdrop open={part_data.isLoading} />
      <DialogSelectReviseOnPaperPage
        dialogSelectRevise={dialogSelectRevise}
        setDialogSelectRevise={setDialogSelectRevise}
        selectedPart={selectedPart}
      />
      <div className="flex flex-wrap">
        {part
          .filter((emptyCheck) => emptyCheck !== "" && checkSearch(emptyCheck))
          .map((data, indexQuestion) => (
            <div
              key={data.id}
              style={{ flex: "0 1 19%" }}
              className="  border-2 mr-2 rounded-lg shadow-md mb-4 flex"
              ref={(element) => refs.current[indexQuestion] = element}
            >
              <Card
                sx={{
                  width: 345,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <div>
                  {data.image.data ? (
                    <CardMedia
                      sx={{ height: 250 }}
                      image={`${config.server}${data.image.data.attributes.url}`}
                    />
                  ) : (
                    <div
                      style={{ height: 250 }}
                      className="bg-gray-100 flex items-center justify-center"
                    >
                      <NoPhotographyIcon sx={{ fontSize: 80 }} />
                    </div>
                  )}
                  <CardContent>
                    <Typography gutterBottom variant="h5">
                      {data.part_name}
                    </Typography>
                    <Typography variant="body2">{`${data.part_code}`}</Typography>
                  </CardContent>
                </div>
                {/* {visibleButton() && (
                  <CardActions
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>วันที่: {date}</div>

                    <div>ครั้งที่: {data.history?.length}/20</div>
                  </CardActions>
                )} */}
                <CardActions
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  {visibleButton() && (
                    // <Link
                    //   key={"answer"}
                    //   to={`answer/${data.id}`}
                    //   state={{ data: data.history }}
                    // >
                    <Link
                      key={"answer"}
                      to={`answerv2/${data.id}`}
                      onClick={() => keepIdPart(data.id)}
                      // state={{ data: data.history }}
                    >
                      <Button variant="contained" disabled={checkTimes()}>
                        ตอบคำถาม
                      </Button>
                    </Link>
                  )}
                  <Button
                    variant="outlined"
                    //  disabled
                    onClick={() => {
                      handleClickOnPart(data.id);
                      keepIdPart(data.id)
                    }}
                  >
                    ประวัติการตรวจสอบ
                  </Button>
                </CardActions>
              </Card>
            </div>
          ))}
      </div>
    </div>
  );
}

const DialogSelectReviseOnPaperPage = ({
  dialogSelectRevise,
  setDialogSelectRevise,
  selectedPart,
}) => {
  const navigate = useNavigate();

  const [oldParts, setOldParts] = useState([]);
  const [selectPartID, setSelectPartID] = useState(`none`);
  const [error, setError] = useState({
    revise: ``,
  });

  const gotoPart = () => {
    if (selectPartID === "none") {
      setError((prev) => ({
        ...prev,
        revise: `กรุณาเลือก Revise`,
      }));
      return;
    }
    navigate(`history/${selectPartID}`);
  };

  const handleOnClose = () => {
    setDialogSelectRevise((prev) => ({
      ...prev,
      isOpen: false,
    }));
  };

  useEffect(() => {
    console.log("selectedPart", selectedPart);
    if (selectedPart && selectedPart.old_parts) {
      setOldParts([
        ...selectedPart.old_parts.data.map((oldPart) => ({
          id: oldPart.id,
          ...oldPart.attributes,
        })),
        selectedPart,
      ]);
    }
  }, [selectedPart]);

  return (
    <Dialog open={dialogSelectRevise.isOpen}>
      <DialogTitle>กรุณาเลือก Rev.</DialogTitle>
      <DialogContent>
        <div>
          เนื่องจาก part นี้มีการ Revise กรุณาเลือก Revise ที่ต้องการดูข้อมูล
        </div>
        <div>
          <Select
            label={`test`}
            defaultValue={`none`}
            fullWidth
            error={error.revise !== ``}
            onChange={(e, value) => {
              setSelectPartID(e.target.value);
              setError((prev) => ({
                ...prev,
                revise: ``,
              }));
            }}
          >
            <MenuItem value={`none`}>none</MenuItem>
            {oldParts.map((oldPart, indexOldPart) => {
              return (
                <MenuItem
                  key={`menuitem${indexOldPart}`}
                  value={`${oldPart.id}`}
                >
                  {`${oldPart.revise_time}`} -
                  {`${oldPart.revise.data.attributes.revisionRecord}`}
                </MenuItem>
              );
            })}
          </Select>
          {error.revise !== "" && (
            <span className={`text-red-500`}>{error.revise}</span>
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={handleOnClose}>
          ปิด
        </Button>
        <Button variant="contained" onClick={gotoPart}>
          ยืนยัน
        </Button>
      </DialogActions>
    </Dialog>
  );
};
