import {
  Autocomplete,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useContext } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import DateTime from "../Components/DateTime";
import TableForSearchData from "../Components/TableForSearchData";
import { APIServices } from "../helpers/APIServices";
import dayjs from "dayjs";
import { UserContext } from "../Layout";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import config from "../config";
import { FileDownload, Search } from "@mui/icons-material";
import DialogSelectPart from "./DialogSelectPart";
import SimpleBackdrop from "../Components/Backdrop";
import TableForQL from "./TableForQL";

const headersQL = [
  {
    name: "Date",
  },
  {
    name: "Time",
  },
  {
    name: "Shift",
  },
  {
    name: "ALL POINT",
  },
  {
    name: "POINT OK",
  },
  {
    name: "POINT NG",
  },
  {
    name: "%QL",
  },
]

const headers = [
  // {
  //   name: "ID",
  // },
  // {
  //   name: "No.",
  // },
  {
    name: "Date",
  },
  {
    name: "Time",
  },
  {
    name: "Shift",
  },
  {
    name: "A / B",
  },
  {
    name: "Inspector",
  },
  {
    name: "Checker",
  },
  {
    name: "Approver",
  },
  {
    name: "Part  No.",
  },
  {
    name: "Part name",
  },
  {
    name: "A / D",
  },

  {
    name: "Point check",
  },
  {
    name: "Max",
  },
  {
    name: "Min",
  },
  {
    name: "answer",
    col: [
      {
        name: "No.1",
      },
      {
        name: "No.2",
      },
      {
        name: "No.3",
      },
    ],
  },
  {
    name: "Actual",
    col: [
      {
        name: "No.1",
      },
      {
        name: "No.2",
      },
      {
        name: "No.3",
      },
    ],
  },
  // {
  //   name: "Comment",
  //   col: [
  //     {
  //       name: "No.1",
  //     },
  //     {
  //       name: "No.2",
  //     },
  //     {
  //       name: "No.3",
  //     },
  //   ],
  // },
  // {
  //   name: "Picture",
  //   col: [
  //     {
  //       name: "No.1",
  //     },
  //     {
  //       name: "No.2",
  //     },
  //     {
  //       name: "No.3",
  //     },
  //   ],
  // },
];

const Report = () => {
  const defaultDateTime = {
    start: `${dayjs().subtract(1, "d").format("YYYY-MM-DD")} 00:00:00`,
    end: `${dayjs().format("YYYY-MM-DD")} 23:59:59`,
  };
  const [startDate, setStartDate] = useState({
    error: false,
    value: defaultDateTime.start,
  });
  const [endDate, setEndDate] = useState({
    error: false,
    value: defaultDateTime.end,
  });
  const user = useContext(UserContext);
  const [checkDate, setCheckDate] = useState({
    error: false,
    message: "end date should more than start date",
  });
  const [partNo, setPartNo] = useState();
  const [selectPart, setSelectPart] = useState(0);
  const [paper, setPaper] = useState();
  const [search, setSearch] = useState();
  const [allParts, setAllParts] = useState([]);
  const [revise, setRevise] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedQL, setSelectedQL] = useState(false)
  const [dataQL, setDataQL] = useState([])

  const getPart = async () => {
    const response = await APIServices.getPartWithRevise();
    const parts = response.data.data;
    // let dataForSelect = [
    //   {
    //     id: 0,
    //     label: "กรุณาเลือก Part No.",
    //   },
    // ];
    const dataForSelect = [];
    parts
      .filter(
        (part) =>
          part &&
          part.attributes.revise.data &&
          part.attributes.revise.data.attributes.revise_status === "4"
      )
      .forEach((partData) => {
        dataForSelect.push({
          id: partData.id,
          label: partData.attributes.part_code,
        });
      });
    setPartNo(dataForSelect);
    setAllParts(parts);
  };

  const handleChangeSelect = (event, newValue) => {
    const partID = newValue.id;
    if (partID === "none") {
      setRevise([]);
      return;
    }
    const part = allParts.find((part) => part.id === partID);
    let revise = [];
    if (part.attributes.old_parts.data.length > 0) {
      revise = part.attributes.old_parts.data;
    }
    setRevise([...revise, part]);
  };

  const handleChangeRevise = (event, newValue) => {
    console.log("change revise");
    const partID = newValue.id;
    setSelectPart(partID);
  };

  const calculateQL = (paper) => {
    // console.log("paper 219:", paper);
    const subcategories = paper.answer.sub_categories
    let pointCheck = 0
    let pointOK = 0
    let pointNG = 0
    if(!Array.isArray(subcategories)){
      throw Error("subcategories should be array type")
    }
    // console.log("subcategories 221:", subcategories);
    subcategories.forEach(subcategory => {
      const questions = subcategory.questions
      if(!Array.isArray(questions)){
        throw Error("questions should be array type")
      }
      questions.forEach(question => {
        // console.log("question 232:", question);
        question.answer.forEach(answer => {
          if(answer !== ""){
            pointCheck++
            if(answer === "OK") pointOK++
            if(answer === "NG") pointNG++
          }
        })
      })
    })
    const percentQL = (pointOK / pointCheck * 100).toFixed(2)
    return {
      pointCheck,
      pointOK,
      pointNG,
      percentQL,
    }
  }

  const getPapers = async (startDate, endDate) => {
    if (!startDate && !endDate) {
      startDate = defaultDateTime.start;
      endDate = defaultDateTime.end;
    }

    if (selectPart) {
      const response = await APIServices.getPaperForReport(
        startDate,
        endDate,
        selectPart,
        user
      );
      const papers = response.data.data;
      const question = await APIServices.getAllQuestion();
      const realQuestion = question.data.data;
      const allSubCat = await APIServices.getSubCategories(selectPart);
      const realSubCat = allSubCat.data.data;

      let data = [];
      let dataForSearch = [];
      const dataQL = []

      if (papers) {
        for (let indexPaper = 0; indexPaper < papers.length; indexPaper++) {
          const changeShift =
            papers[indexPaper].attributes.shift === "Day" ? "D" : "N";
          let answer;
          const subCat = papers[indexPaper].attributes.answer.sub_categories;
          const paperDataQL = papers[indexPaper].attributes;
          const dataQLFromCal = calculateQL(paperDataQL)
          // console.log("dataQLFromCal 293:", dataQLFromCal);
          dataQL.push({
            date: paperDataQL.date,
            time: paperDataQL.time,
            shift: changeShift,
            ...dataQLFromCal,
          })
          for (
            let indexSubCat = 0;
            indexSubCat < subCat.length;
            indexSubCat++
          ) {
            const loopSubCat = subCat[indexSubCat];
            for (
              let indexQuestion = 0;
              indexQuestion < loopSubCat.questions.length;
              indexQuestion++
            ) {
              const question = loopSubCat.questions[indexQuestion];
              console.log("question 242", question.skipQuestion);
              if(question.skipQuestion){
                continue;
                // return
              }
              answer = question;
              const findSubCat = realSubCat.find((sub) => {
                return sub.id === loopSubCat.subId;
              });
              const findQuestion = findSubCat.attributes.questions.data.find(
                (t) => t.id === question.questionId
              );
              const category = findSubCat.attributes.category.data.attributes
                .category
                ? findSubCat.attributes.category.data.attributes.category
                : "";
              const paperData = papers[indexPaper].attributes;

              if (findQuestion.attributes.status === true) {
                data.push({
                  // id: papers[indexPaper].id,
                  // id_part: paperData.answer.partId,
                  // id_subCat: loopSubCat.subId,
                  // id_question: question.questionId,
                  date: paperData.date,
                  time: paperData.time,
                  shift: changeShift,
                  A_B: paperData.shiftAB !== "" ? paperData.shiftAB : "-",
                  inspector_name: paperData.inspector_id.data
                    ? paperData.inspector_id.data.attributes.name
                    : "-",
                  checker_name: paperData.checker_id.data
                    ? paperData.checker_id.data.attributes.name
                    : "-",
                  approver_name: paperData.approver_id.data
                    ? paperData.approver_id.data.attributes.name
                    : "-",
                  partNo: paperData.partName.data.attributes.part_code,
                  partName: paperData.partName.data.attributes.part_name,
                  A_D: category === "Appearance" ? "A" : "D",
                  // point_check: findQuestion.attributes.question,
                  // spec_min: findQuestion.attributes.spec_min
                  //   ? findQuestion.attributes.spec_min
                  //   : "-",
                  // spec_max: findQuestion.attributes.spec_max
                  //   ? findQuestion.attributes.spec_max
                  //   : "-",
                  point_check: answer.questionName,
                  spec_min: answer.spec_min ? answer.spec_min : "-",
                  spec_max: answer.spec_max ? answer.spec_max : "-",
                  actual1: answer.actual[0] ? answer.actual[0] : "-",
                  actual2: answer.actual[1] ? answer.actual[1] : "-",
                  actual3: answer.actual[2] ? answer.actual[2] : "-",
                  answer1: answer.answer[0] ? answer.answer[0] : "-",
                  answer2: answer.answer[1] ? answer.answer[1] : "-",
                  answer3: answer.answer[2] ? answer.answer[2] : "-",
                  comment1: answer.comment[0] ? answer.comment[0] : "-",
                  comment2: answer.comment[1] ? answer.comment[1] : "-",
                  comment3: answer.comment[2] ? answer.comment[2] : "-",
                  // imageURL1:
                  //   answer.imageURL[0] === ""
                  //     ? `${config.server + answer.imageURL[0]}`
                  //     : "-",
                  // imageURL2: answer.imageURL[1]
                  //   ? `${config.server + answer.imageURL[1]}`
                  //   : "-",
                  // imageURL3: answer.imageURL[2]
                  //   ? `${config.server + answer.imageURL[2]}`
                  //   : "-",
                });
              }
            }
          }
        }
      }
      dataForSearch.push({
        PartNo: selectPart,
        Date: startDate,
        To: endDate,
      });
      setSearch(dataForSearch);
      setPaper(data);
      setDataQL(dataQL)
    }
  };

  const handleOnChangeStartDate = (date, time) => {
    if (!date || !time) {
      setStartDate((prev) => ({
        ...prev,
        value: undefined,
      }));
      return;
    }
    const startDate = dayjs(`${date} ${time}`).format("YYYY-MM-DD HH:mm:ss");
    setStartDate((prev) => ({
      ...prev,
      value: startDate,
      error: false,
    }));
  };

  const handleOnChangeEndDate = (date, time) => {
    if (!date || !time) {
      setEndDate((prev) => ({
        ...prev,
        value: undefined,
      }));
      return;
    }
    const endDate = dayjs(`${date} ${time}`).format("YYYY-MM-DD HH:mm:ss");
    setEndDate((prev) => ({
      ...prev,
      value: endDate,
      error: false,
    }));
  };

  const handleSubmitSearch = async (startDate, endDate) => {
    if (!selectPart) {
      alert("กรุณาเลือก part No.");
    }
    // if (paper === undefined || paper.length === 0) {
    //   alert(`ไม่มีข้อมูลในช่วงวันที่ ${startDate.value} - ${endDate.value}`);
    // }
    if (!startDate.value) {
      setStartDate((prev) => ({
        ...prev,
        error: true,
      }));
      return;
    }
    if (!endDate.value) {
      setEndDate((prev) => ({
        ...prev,
        error: true,
      }));
      return;
    }
    if (
      dayjs(endDate.value).isBefore(startDate.value, "minute") ||
      dayjs(endDate.value).isSame(startDate.value, "minute")
    ) {
      setCheckDate((prev) => ({
        ...prev,
        error: true,
      }));
      setTimeout(() => {
        setCheckDate((prev) => ({
          ...prev,
          error: false,
        }));
      }, 2000);
      return;
    }
    if (startDate.value && endDate.value) {
      setIsLoading(true);

      await getPapers(startDate.value, endDate.value);
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    }
  };

  const handleExportFile = () => {
    // const workbook = XLSX.utils.book_new();
    // const worksheet1 = XLSX.utils.json_to_sheet(paper);
    // const worksheet2 = XLSX.utils.json_to_sheet(search);
    // worksheet1["!cols"] = [{ width: 20 }, { width: 10 }];
    // worksheet2["!cols"] = [{ width: 20 }, { width: 10 }];
    // workbook.SheetNames.push("Table 1");
    // workbook.SheetNames.push("Table 2");
    // XLSX.utils.book_append_sheet(workbook, worksheet1, "Table 1");
    // XLSX.utils.book_append_sheet(workbook, worksheet2, "Table 2");
    // XLSX.writeFile(workbook, "Inspector_Sheet.xlsx");
    const workBook = XLSX.utils.book_new();
    let data = paper
    let fileName = "Inspector Sheet"
    if(selectedQL){
      data = dataQL
      fileName = `QL Report`
    }
    const workSheet = XLSX.utils.json_to_sheet(data);
    XLSX.utils.book_append_sheet(workBook, workSheet, fileName);
    XLSX.writeFile(workBook, "Inspector_Sheet.xlsx");
  };

  useEffect(() => {
    // gelAllPaper();
    // getPapers();

    getPart();
  }, []);

  return (
    <div
      className={``}
      style={{
        width: 'calc(100vw - 48px)',
      }}
    >
      {/* <DialogSelectPart open={false} parts={parts} /> */}
      <SimpleBackdrop open={isLoading} setOpen={setIsLoading} />

      <div className={`flex flex-col lg:flex lg:flex-row`}>
        {checkDate.error && (
          <div
            className={`bg-red-500 py-2 px-4 text-white absolute rounded-md -bottom-10`}
          >
            {checkDate.message}
          </div>
        )}
        <div className={`flex flex-row mt-2`}>
          <div className={`flex`}>
            <FormControl sx={{ minWidth: 220 }}>
              <Autocomplete
                disablePortal
                options={partNo}
                disabled={!partNo}
                sx={{ width: 300 }}
                onChange={(event, newValue) => {
                  handleChangeSelect(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Part No."
                    error={!selectPart}
                    defaultValue={`none`}
                  />
                )}
              />
            </FormControl>
          </div>
          <div className={`ml-2`}>
            <FormControl sx={{ minWidth: 220 }}>
              <Autocomplete
                getOptionLabel={(data) => {
                  return `${data.attributes.revise_time} - ${data.attributes.revise.data.attributes.revisionRecord}`;
                }}
                disablePortal
                options={revise}
                sx={{ width: 300 }}
                onChange={handleChangeRevise}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    // {...params.attributes.revise_time - params.attributes.revise.data.attributes.revisionRecord}
                    label="Rev."
                    value={selectPart}
                    error={!selectPart}
                  />
                )}
              />
            </FormControl>
          </div>
        </div>
        <div className={`flex flex-row mt-2 items-center`}>
          <div className={`flex ml-2`}>
            <DateTime
              value={startDate.value}
              autoFocus={startDate.error}
              label={`Start date`}
              onChange={handleOnChangeStartDate}
            />
          </div>
          <div className={`flex items-center mr-2 ml-2`}> - </div>
          <div className={`flex ml-2`}>
            <DateTime
              value={endDate.value}
              autoFocus={endDate.error}
              label={`End date`}
              onChange={handleOnChangeEndDate}
            />
          </div>
          <div className={`flex ml-2`}>
            <Button
              variant="contained"
              onClick={() => handleSubmitSearch(startDate, endDate)}
              endIcon={<Search />}
            >
              Search
            </Button>
          </div>
          {paper && paper.length > 0 && (
            <div className={`flex ml-2`}>
              <Button
                color="success"
                variant="contained"
                onClick={handleExportFile}
                endIcon={<FileDownload />}
              >
                Export
              </Button>
            </div>
          )}
        </div>
      </div>
      <div className={`mt-2`}>
        <Button
          variant={selectedQL ? `contained` : `outlined`}
          onClick={() => setSelectedQL(prev => !prev)}
        >
          %QL
        </Button>
      </div>
      {paper && paper.length > 0 ? (
        selectedQL
        ?
        <div className={`mt-2`}>
          <TableForQL headers={headersQL} data={dataQL} />
        </div>
        :
        <div className={`mt-2`}>
          <TableForSearchData headers={headers} data={paper} />
        </div>
      ) : (
        <div
          className={`text-2xl flex justify-center items-center lg:flex lg:justify-center lg:items-center mt-4 lg:mt-4`}
        >
          No data
        </div>
      )}
    </div>
  );
};

export default Report;
