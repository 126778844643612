import React from "react";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

const TestExportFile = () => {
  const exportFileXLSX = () => {
    alert("Hi :)");
    // const workBook = { Sheets: { data: workSheet }, SheetNames: ["Test"] };
    // const excelBuffer = XLSX.write(workBook, {
    //   bookType: "xlsx",
    //   type: "array",
    // });
    // const result = new Blob([excelBuffer], { type: fileType });
    // FileSaver.saveAs(result, "myfile" + ".xlsx");
  };

  return (
    <div>
      <button onClick={exportFileXLSX}>Download File</button>
    </div>
  );
};

export default TestExportFile;
