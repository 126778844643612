import {
  Button,
  Chip,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APIServices } from "../../helpers/APIServices";
import {
  BootstrapDialogTitle,
  checkMinMaxNG,
  checkMinMaxOK,
  searchSubcategoriesName,
} from "./EditPaper";
import PhotoIcon from "@mui/icons-material/Photo";
import { BootstrapDialog, getBase64 } from "./FormAddImageAnswer";
import config from "../../config";
import ConfirmModal from "../../Components/ConfirmModal";
import { UserContext } from "../../Layout";
import SignatureBox from "../../Components/SignatureBox";
import { useSnackbarStore, useSuccessStore } from "../../helpers/state";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

const ShowImages = ({ imageURL, indexSC }) => {
  return (
    <>
      {indexSC !== undefined
        ? imageURL[indexSC].map(
            (imageURLLoop, index) =>
              typeof imageURLLoop === "string" && (
                <div
                  key={index}
                  className={`flex justify-evenly items-center ${
                    index === 0 ? "mx-auto" : index % 2 !== 0 && "mx-auto"
                  } relative basis-1/3 `}>
                  <div className='relative '>
                    <div className='w-[200px] h-[200px] overflow-hidden '>
                      <img src={`${config.server}${imageURLLoop}`} />
                    </div>
                  </div>
                </div>
              )
          )
        : imageURL.map(
            (imageURLLoop, index) =>
              typeof imageURLLoop === "string" && (
                <div
                  key={index}
                  className={`flex justify-evenly items-center ${
                    index === 0 ? "mx-auto" : index % 2 !== 0 && "mx-auto"
                  } relative basis-1/3 `}>
                  <div className='relative '>
                    <div className='w-[200px] h-[200px] overflow-hidden '>
                      <img src={`${config.server}${imageURLLoop}`} />
                    </div>
                  </div>
                </div>
              )
          )}
    </>
  );
};

const ViewPhoto = ({ open, setOpen, selectedQuestion, setPaper, paper }) => {
  return (
    <div>
      <BootstrapDialog onClose={() => setOpen(false)} open={open}>
        <BootstrapDialogTitle onClose={() => setOpen(false)}>
          รูปภาพ
        </BootstrapDialogTitle>
        <DialogContent dividers>
          <Box
            component='form'
            sx={{
              "& .MuiTextField-root": {
                mt: 2,
              },
            }}
            noValidate
            autoComplete='off'>
            <div className='flex flex-col'>
              <div className='mt-4 flex gap-12 flex-wrap'>
                {selectedQuestion.questionData.imageURL ? (
                  <ShowImages
                    imageURL={selectedQuestion.questionData.imageURL}
                    indexSC={selectedQuestion.indexSC}
                  />
                ) : (
                  <div>ไม่มีข้อมูลรูปภาพ</div>
                )}
              </div>
            </div>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

const SubCategories = ({
  subCategories,
  subCatPerLoop,
  subCatIndex,
  setOpenViewPhoto,
  setSelectedQuestion,
}) => {
  const subData = searchSubcategoriesName(subCategories, subCatPerLoop.subId);
  return (
    <div
      key={`paper${subCatPerLoop.subId}`}
      className={`bg-white p-4 mb-4 flex w-full flex-col rounded-md shadow-md`}>
      <div className='flex flex-row pb-4'>
        <div className='basis-1/6  flex items-center rounded-full text-xl'>
          <Chip label={subData.categoryName} variant='outlined' />
        </div>
        <div className='basis-1/2  flex items-center text-xl font-semibold'>
          {subData.subCategoriesName}
        </div>
      </div>
      <hr />
      <div>
        {subCatPerLoop.questions.map((question, index) => (
          <QuestionContent
            key={`question${index}`}
            question={question}
            questionsPerSub={subData.questions}
            questionIndex={index}
            subCatIndex={subCatIndex}
            setOpenViewPhoto={setOpenViewPhoto}
            setSelectedQuestion={setSelectedQuestion}
          />
        ))}
      </div>
    </div>
  );
};

const QuestionContent = ({
  question,
  questionsPerSub,
  questionIndex,
  subCatIndex,
  setOpenViewPhoto,
  setSelectedQuestion,
}) => {
  const resultQuestion = questionsPerSub.find(
    (questionPerLoop) => questionPerLoop.id === question.questionId
  );
  const questionName = resultQuestion.attributes.question || "";
  const min = resultQuestion.attributes.spec_min || null;
  const max = resultQuestion.attributes.spec_max || null;
  const sc_status = resultQuestion.attributes.sc_status;
  return (
    <div>
      <div className='text-lg mt-4 flex flex-row items-center'>
        {questionName}
        {min !== null && max !== null && (
          <div className='ml-2'>
            {`${parseFloat(min).toFixed(2)} - ${parseFloat(max).toFixed(2)}`}
          </div>
        )}
        {sc_status && (
          <div className='ml-2'>
            <Chip label='SC' color='primary' variant='outlined' />
          </div>
        )}
      </div>
      <div className='mt-2 mb-2  '>
        {sc_status ? (
          new Array(3).fill(0).map((d, index) => {
            return (
              <div key={`form${index}`} className='flex flex-col mt-4'>
                <FormQuestion
                  questionMaster={resultQuestion.attributes}
                  questionData={question}
                  indexSC={index}
                  questionIndex={questionIndex}
                  subCatIndex={subCatIndex}
                  setOpenViewPhoto={setOpenViewPhoto}
                  setSelectedQuestion={setSelectedQuestion}
                />
              </div>
            );
          })
        ) : (
          <div className='flex flex-col mt-4'>
            <FormQuestion
              questionMaster={resultQuestion.attributes}
              questionData={question}
              questionIndex={questionIndex}
              subCatIndex={subCatIndex}
              setOpenViewPhoto={setOpenViewPhoto}
              setSelectedQuestion={setSelectedQuestion}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const FormQuestion = ({
  questionMaster,
  questionData,
  indexSC,
  questionIndex,
  subCatIndex,
  setOpenViewPhoto,
  setSelectedQuestion,
}) => {
  return (
    <div className='flex flex-row items-center'>
      <FormControl>
        {questionMaster.spec_min !== null &&
        questionMaster.spec_max !== null ? (
          <RadioGroup row>
            <FormControlLabel
              value={`OK`}
              control={
                <Radio
                  disabled
                  checked={checkMinMaxOK(
                    questionMaster.spec_min,
                    questionMaster.spec_max,
                    questionMaster.sc_status
                      ? questionData.actual[indexSC]
                      : questionData.actual
                  )}
                />
              }
              label='OK'
            />
            <FormControlLabel
              value={`NG`}
              control={
                <Radio
                  disabled
                  checked={checkMinMaxNG(
                    questionMaster.spec_min,
                    questionMaster.spec_max,
                    questionMaster.sc_status
                      ? questionData.actual[indexSC]
                      : questionData.actual
                  )}
                />
              }
              label='NG'
            />
          </RadioGroup>
        ) : (
          <RadioGroup row>
            <FormControlLabel
              value={`OK`}
              checked={questionData.answer === "OK"}
              control={<Radio disabled />}
              label='OK'
            />
            <FormControlLabel
              value={`NG`}
              checked={questionData.answer === "NG"}
              control={<Radio disabled />}
              label='NG'
            />
          </RadioGroup>
        )}
      </FormControl>
      <Box
        component={"form"}
        sx={{
          "& .MuiTextField-root": {
            m: 1,
            width: "25ch",
            // backgroundColor: "black",
          },
        }}
        noValidate
        autoComplete='off'>
        {questionMaster.spec_min !== null &&
          questionMaster.spec_max !== null && (
            <TextField
              id='outlined-number'
              label='Actual'
              type='number'
              size='small'
              defaultValue={
                questionMaster.sc_status
                  ? parseFloat(questionData.actual[indexSC])
                  : parseFloat(questionData.actual)
              }
              disabled
            />
          )}
        <TextField
          id='outlined-number'
          label='Comment'
          type='text'
          size='small'
          defaultValue={
            questionMaster.sc_status
              ? questionData.comment
                ? questionData.comment[indexSC]
                : ""
              : questionData.comment
          }
          disabled
        />
      </Box>
      <div className='flex flex-col max-h-fit justify-end items-center '>
        <Fab
          onClick={() => {
            setOpenViewPhoto(true);
            setSelectedQuestion({
              questionData,
              subCatIndex,
              questionIndex,
              questionMaster,
              indexSC,
            });
          }}
          color='primary'
          variant='contained'
          component='label'>
          <PhotoIcon />
        </Fab>

        {indexSC !== undefined
          ? questionData.imageURL &&
            questionData.imageURL[indexSC].length !== 0 && (
              <div className='bg-orange-400 shadow-sm rounded-full p-1 pr-2 pl-2 text-white mt-1 '>
                {questionData.imageURL[indexSC].length + " รูป"}
              </div>
            )
          : questionData.imageURL && (
              <div className='bg-orange-400 shadow-sm rounded-full p-1 pr-2 pl-2 text-white mt-1'>
                {questionData.imageURL.length + " รูป"}
              </div>
            )}
      </div>
    </div>
  );
};

const ConfirmButton = ({ onClick, className, role, status }) => {
  let disable = false;
  if (!status) {
    disable = true;
  }
  if (role === "checker" || (role === "super_admin" && status === "1")) {
    if (status === "2" || status === "3" || status === "4" || status === "5") {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant='contained'
          size='large'
          onClick={onClick}>
          ผ่านการตรวจสอบ
        </Button>
      </div>
    );
  } else if (
    role === "approver" ||
    (role === "super_admin" && status !== "1")
  ) {
    if (status === "3" || status === "4" || status === "5" || status === "1") {
      disable = true;
    }
    if (!status) {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant='contained'
          size='large'
          onClick={onClick}>
          อนุมัติ
        </Button>
      </div>
    );
  }
  return <div></div>;
};

const RejectButton = ({ onClick, className, role, status }) => {
  let disable = false;
  if (!status) {
    disable = true;
  }
  if (role === "checker" || (role === "super_admin" && status === "1")) {
    if (status === "2" || status === "3" || status === "4" || status === "5") {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant='outlined'
          size='large'
          color='error'
          onClick={onClick}>
          ไม่ผ่านการตรวจสอบ
        </Button>
      </div>
    );
  } else if (
    role === "approver" ||
    (role === "super_admin" && status !== "1")
  ) {
    let disable = false;
    if (status === "3" || status === "4" || status === "5" || status === "1") {
      disable = true;
    }
    return (
      <div className={className}>
        <Button
          disabled={disable}
          variant='outlined'
          size='large'
          color='error'
          onClick={onClick}>
          ไม่อนุมัติ
        </Button>
      </div>
    );
  }
  return <div></div>;
};

const ViewPaper = () => {
  const param = useParams();
  const dataFromLocation = useLocation();
  const [paper, setPaper] = useState();
  const [openViewPhoto, setOpenViewPhoto] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    message: "",
  });
  const [openReject, setOpenReject] = useState({
    open: false,
    message: "",
  });
  const user = useContext(UserContext);
  const snackBarState = useSnackbarStore();
  const navigation = useNavigate();

  const getPaperByID = async () => {
    const response = await APIServices.findOnePaper(param.idPaper);
    const result = response.data;
    if (result.data) {
      const paper = result.data.attributes;
      setPaper(paper);
    }
  };

  const confirm = async (paper) => {
    if (user.signature) {
      const signature = user.signature[user.signature.findLastIndex((e) => e)];
      if (
        user.qc_role === "checker" ||
        (paper.status === "1" && user.qc_role === "super_admin")
      ) {
        const response = await APIServices.updateCheckerInPaper(
          param.idPaper,
          signature.url,
          "2",
          user.id
        );
      } else if (
        user.qc_role === "approver" ||
        user.qc_role === "super_admin"
      ) {
        const response = await APIServices.updateApproverInPaper(
          param.idPaper,
          signature.url,
          "4",
          user.id
        );
      }
      snackBarState.onOpen({
        msg: "สำเร็จ",
        type: "success",
      });
      backward(2000);
    } else {
      alert("you don't exist signature please insert signature");
    }
  };

  const reject = async (paper) => {
    // if (result) {
    if (user.signature) {
      const signature = user.signature[user.signature.findLastIndex((e) => e)];
      if (
        user.qc_role === "checker" ||
        (paper.status === "1" && user.qc_role === "super_admin")
      ) {
        const response = await APIServices.updateCheckerInPaper(
          param.idPaper,
          signature.url,
          "3",
          user.id
        );
      } else if (
        user.qc_role === "approver" ||
        user.qc_role === "super_admin"
      ) {
        const response = await APIServices.updateApproverInPaper(
          param.idPaper,
          signature.url,
          "5",
          user.id
        );
      }
      snackBarState.onOpen({
        msg: "สำเร็จ",
        type: "success",
      });
      backward(2000);
    } else {
      alert("you don't exist signature please insert signature");
    }
    // }
  };

  const backward = (delay) => {
    setTimeout(() => {
      navigation(-1);
    }, delay);
  };

  const handleOnClickConfirm = () => {
    let message = "ผ่านการตรวจสอบ";
    if (user.qc_role === "approver" || user.qc_role === "super_admin") {
      message = "ผ่านการอนุมัติ";
    }
    setOpenConfirm({ open: true, message: message });
  };

  const handleOnClickReject = () => {
    let message = "ไม่ผ่านการตรวจสอบ";
    if (user.qc_role === "approver" || user.qc_role === "super_admin") {
      message = "ไม่ผ่านการอนุมัติ";
    }
    setOpenReject({ open: true, message: message });
  };

  useEffect(() => {
    getPaperByID();
  }, []);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackBarState.open}
        autoHideDuration={6000}
        onClose={snackBarState.onClose}>
        <Alert severity={snackBarState.type}>{snackBarState.message}</Alert>
      </Snackbar>
      <div className='flex flex-row justify-between'>
        <div className='mb-4 flex flex-row items-center'>
          <Stack direction='row' spacing={1}>
            {/* <Chip label={`ครั้งที่: ${dataFromLocation.state.index}`} /> */}
            <Chip
              label={`วันที่: ${
                paper && dayjs(paper.create_datetime).format("DD-MM-YYYY")
              }`}
            />
            <Chip label={`ช่วงเวลา: ${paper && paper.shift}`} />
            {paper && paper.shiftAB && (
              <Chip label={`กลุ่ม: ${paper && paper.shiftAB}`} />
            )}
          </Stack>
        </div>
        {/* <div className="">
          <Button variant="contained">Export Paper</Button>
        </div> */}
      </div>
      <div className=' flex justify-center items-center '>
        {paper && paper.answer.partImage !== "" ? (
          <div className=' flex justify-center items-center '>
            <img
              src={paper.answer.partImage}
              // config.server +
              className=' object-contain h-[300px]'
            />
          </div>
        ) : (
          <div
            style={{ width: "12rem", height: "12rem" }}
            className='bg-gray-100 flex items-center justify-center rounded-lg'>
            <NoPhotographyIcon sx={{ fontSize: 80 }} />
          </div>
        )}
      </div>
      <div>
        {paper ? (
          paper.answer.sub_categories.map((subCatData, subCatIndex) => (
            <SubCategories
              key={`subCat${subCatIndex}`}
              subCategories={paper.partName.data.attributes.sub_categories.data}
              subCatPerLoop={subCatData}
              subCatIndex={subCatIndex}
              setOpenViewPhoto={setOpenViewPhoto}
              setSelectedQuestion={setSelectedQuestion}
            />
          ))
        ) : (
          <div>No data</div>
        )}
      </div>
      {paper && (
        <React.Fragment>
          {paper.inspector_id && (
            <div className='flex justify-end'>
              <div className={`text-gray-500`}>Inspector :</div>
              <SignatureBox
                className={`ml-4`}
                user={paper.inspector_id.data.attributes}
                signatureURL={
                  paper.signature_inspector
                    ? paper.signature_inspector
                    : undefined
                }
              />
            </div>
          )}
          {paper.signature_checker && (
            <div className='flex justify-end mt-4'>
              <div className={`text-gray-500`}>Checker :</div>

              <SignatureBox
                className={`ml-4`}
                user={paper.checker_id.data.attributes}
                signatureURL={paper.signature_checker}
              />
            </div>
          )}
          {paper.signature_approver && (
            <div className='flex justify-end mt-4'>
              <div className={`text-gray-500`}>Approver :</div>
              <SignatureBox
                className={`ml-4`}
                user={paper.approver_id.data.attributes}
                signatureURL={paper.signature_approver}
              />
            </div>
          )}
          <div className={`flex justify-end mt-4`}>
            <RejectButton
              role={user.qc_role}
              status={paper.status}
              className={`ml-4`}
              onClick={() => handleOnClickReject()}
            />
            <ConfirmButton
              role={user.qc_role}
              status={paper.status}
              className={`ml-4`}
              onClick={() => handleOnClickConfirm()}
            />
          </div>
        </React.Fragment>
      )}
      {openViewPhoto && (
        <ViewPhoto
          open={openViewPhoto}
          setOpen={setOpenViewPhoto}
          selectedQuestion={selectedQuestion}
          setPaper={setPaper}
          paper={paper}
        />
      )}
      {openConfirm.open && (
        <ConfirmModal
          open={openConfirm.open}
          setOpen={setOpenConfirm}
          action={confirm}
          paper={paper}
          confirmText={"ตกลง"}
          cancelText={"ยกเลิก"}
          header={"ยืนยันการตรวจสอบ"}
          message={
            <span>
              คุณต้องการยืนยันว่าเอกสาร
              <span className='text-green-600 underline underline-offset-1'>
                {openConfirm.message}
              </span>
            </span>
          }
        />
      )}
      {openReject.open && (
        <ConfirmModal
          open={openReject.open}
          setOpen={setOpenReject}
          action={reject}
          paper={paper}
          confirmText={"ตกลง"}
          cancelText={"ยกเลิก"}
          header={"ยืนยันการตรวจสอบ"}
          message={
            <span>
              คุณต้องการยืนยันว่าเอกสาร
              <span className='text-red-600 underline underline-offset-1'>
                {openReject.message}
              </span>
            </span>
          }
        />
      )}
    </>
  );
};

export default ViewPaper;
