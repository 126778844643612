import React, { useState, useEffect, useContext } from "react";
import PartCard from "./PartCard";
import { APIServices } from "./../../helpers/APIServices";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { useSuccessStore } from "./../../helpers/state";
import { useOneUser } from "./../../helpers/APIServices";
import Swal from "sweetalert2";
import { ScrollContext, UserContext } from "../../Layout";
import { useNavigate } from "react-router-dom";
import SimpleBackdrop from "../../Components/Backdrop";
import { useRef } from "react";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

function Answer() {
  const successState = useSuccessStore();
  const [success, setSuccess] = useState(true);
  const [search, setSearch] = useState("");
  const user = JSON.parse(localStorage.getItem("QCAPPuser"));
  const { data, error, isLoading, mutate } = useOneUser(user.id);
  const userUseContext = useContext(UserContext);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const refLayout = useContext(ScrollContext)

  // const [part, setPart] = useState();

  // const getPart = async () => {
  //   const getPart = await APIServices.getPartForPaper();
  //   console.log("getPart", getPart);
  //   const realPart = getPart.data.data;
  //   if (realPart) {
  //     const newPart = realPart.map((partPerRound) => {
  //       return {
  //         id: partPerRound.id,
  //         ...partPerRound.attributes,
  //       };
  //     });
  //     setPart(newPart);
  //   }
  // };

  if (!isLoading) {
    // mutate();
    console.log("loading", data.data);
    // setLoading(false)
  }

  const handleSearch = (value) => {
    setSearch(value);
  };
  // const paper = APIServices.usePart();
  // let part = [];
  // if (paper.data) {
  //   const newPart = paper.data.data.data.map((partPerRound) => {
  //     return {
  //       id: partPerRound.id,
  //       ...partPerRound.attributes,
  //     };
  //   });
  //   part = newPart;
  // }

  if (userUseContext) {
    if (!userUseContext.signature) {
      Swal.fire({
        title: "ผู้ใช้งานนี้ไม่มีข้อมูลลายเซ็น",
        text: "กรุณาเพิ่มลายเซ็น",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "เพิ่มลายเซ็น",
      }).then((result) => {
        if (result.isConfirmed) {
          navigate(`/layout/profile/${userUseContext.id}`);
        }
      });
    }
  }


  if (error) return <div>failed to load</div>;
  // if (isLoading) return <div>loading...</div>;

  return (
    <div className={``}>
      <div
        className={`bg-blue-400 fixed bottom-0 right-0 mx-8 my-4 p-4 rounded-full flex text-white cursor-pointer hover:bg-blue-500`}
        onClick={() => {
          refLayout.current.scrollTo({top: 0, behavior: 'smooth'})
        }}
      >
        <ArrowUpwardIcon />
      </div>
      {/* <SimpleBackdrop open={paper.isLoading} setOpen={setLoading}/> */}
      <div className="mb-2 flex">
        <TextField
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          placeholder="Search by Part Name or Part Code"
          className="flex-1"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </div>
      {/* {part.length > 0 && ( */}
        <PartCard
          // setPart={setPart}
          user={data.data}
          // part={part}
          setSuccess={setSuccess}
          search={search}
        />
      {/* )} */}
    </div>
  );
}

export default Answer;

// export function useComponentRefs(length) {
  // return Array.from({ length }, () => React.useRef(null));
// }
