import React, { useRef, useState, useContext, useEffect } from "react";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import config from "../../config";
import { Link, useNavigate } from "react-router-dom";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import Fab from "@mui/material/Fab";
import EditIcon from "@mui/icons-material/Edit";
import FormUpdatePart from "./FormUpdatePart";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { KeepPartContext, ScrollContext } from "../../Layout";
import { Tooltip } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import DeletePartModal from "../../Components/DeletePartModal";
import { APIServices } from "../../helpers/APIServices";
import SimpleBackdrop from "../../Components/Backdrop";
import { parseStatus, scrollToRef } from "../../helpers/common";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import NormalDialog from "../../Components/NormalDialog";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';

export default function PartCard({ part, setSuccess, search, user }) {
  const navigation = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [keepPart, setKeepPart] = useContext(KeepPartContext);
  const [openDelete, setOpenDelete] = useState({
    open: false,
    message: "",
  });
  const [dataForDelete, setDataForDelete] = useState();
  const [dataForResend, setDataForResend] = useState();
  const [editPart, setEditPart] = useState({
    open: false,
    part: null,
  });
  const [loading, setLoading] = useState(false);
  const [openResend, setOpenResend] = useState({
    open: false,
    message: "",
  });
  const refLayout = useContext(ScrollContext)

  const handleOnClickDelete = (data) => {
    setDataForDelete(data);
    let message = "ลบข้อมูลใช่หรือไม่";
    setOpenDelete({ open: true, message: message });
  };

  const daletePart = async (result, row) => {
    if (result) {
      setLoading(true);
      await APIServices.updateDeletePart(false, false, row.id);
      setSuccess(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const checkSearch = (emptyCheck) => {
    if (emptyCheck.part_name !== null) {
      if (
        search === "" ||
        emptyCheck.part_name.toLowerCase().includes(search.toLowerCase()) ||
        emptyCheck.part_code.toLowerCase().includes(search.toLowerCase())
      ) {
        return true;
      }
    }

    return false;
  };

  const checkRole = () => {
    if (
      user.qc_role === "super_admin" ||
      user.qc_role === "admin" ||
      user.qc_role === "release"
    ) {
      return true;
    }
    return false;
  };

  const onclickResendReviseStatus = (part) => {
    setDataForResend(part);
    setOpenResend({
      open: true,
      message: "คุณต้องการตรวจสอบข้อมูล Part อีกครั้งใช่หรือไม่",
    });
  };

  const resendData = async (result, data) => {
    const reviseID = data.revise.data && data.revise.data.id;
    const release_signature = user.signature
      ? user.signature[user.signature.length - 1].url
      : null;
    const releaseID = user.id;

    if (result) {
      setLoading(true);
      await APIServices.updateReviseForResendPart(
        reviseID,
        release_signature,
        releaseID
      );
      setSuccess(true);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  };

  const refs = useRef([])
  const idKeepIdPart = "scroll-to-part-id-partpage"
  const keepPartId = (id) => {
    sessionStorage.setItem(idKeepIdPart, id)
  }
  const [partMakerId] = useState(() => {
    const presistedId = sessionStorage.getItem(idKeepIdPart)
    return presistedId ? presistedId : null
  })
  
  useEffect(() => {
    if(partMakerId){
      const partIndex = part.findIndex(part => part.id.toString() === partMakerId.toString())
      scrollToRef(refs.current[partIndex])
      sessionStorage.removeItem(idKeepIdPart)
    }
  }, [part, partMakerId])

  return (
    <div className="h-(screen-18)">
      <div
        className={`bg-blue-400 p-4 fixed bottom-0 right-0 mx-8 my-4 rounded-full flex cursor-pointer hover:bg-blur-500 text-white`}
        onClick={() => {
          refLayout.current.scrollTo({top: 0, behavior: 'smooth'})
        }}
      >
        <ArrowUpwardIcon />
      </div>
      <SimpleBackdrop open={loading} setOpen={setLoading} />
      <div className="flex flex-wrap ">
        {part
          .filter((emptyCheck) => {
            return checkSearch(emptyCheck);
          })
          .map((data, indexQuestion) => (
            <div
              key={data.id}
              style={{ flex: "0 1 19%" }}
              className="border-2 mr-2 rounded-lg shadow-md mb-4 flex"
              ref={(element) => refs.current[indexQuestion] = element}
            >
              <Card
                sx={{
                  width: 345,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  // background: "red",
                }}
              >
                <div>
                  {data.image.data ? (
                    <CardMedia
                      sx={{ height: 250 }}
                      image={`${config.server}${data.image.data.attributes.url}`}
                      title="green iguana"
                      // onClick={() => {
                      //   setShowImage({
                      //     open: true,
                      //     part: data,
                      //   });
                      // }}
                    />
                  ) : (
                    <div
                      style={{ height: 250 }}
                      className="bg-gray-100 flex items-center justify-center"
                    >
                      <NoPhotographyIcon sx={{ fontSize: 80 }} />
                    </div>
                  )}

                  {/* <ShowImagePart state={[showImage, setShowImage]} /> */}

                  <CardContent>
                    <Typography gutterBottom variant="h5">
                      {data.part_name}
                    </Typography>
                    <Typography variant="body2">{data.part_code}</Typography>
                    {/* <Typography variant="body2">REV. {data.revise_time}</Typography> */}
                    <div className="mt-2">
                      {parseStatus(
                        data.revise.data && data.revise.data.attributes.revise_status
                      )}
                    </div>
                    {/* <Typography variant="body2">{data.status}</Typography> */}
                  </CardContent>
                </div>
                <div>
                  <CardActions
                    sx={{
                      display: 'flex',
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    {checkRole() ? (
                      <Link
                        key={"add_categories"}
                        to={`add_categories/${data.id}`}
                        onClick={() => {
                          setKeepPart(data);
                          keepPartId(data.id)
                        }}
                      >
                        <Button
                          // disabled={data.status === false ? true : false}
                          size="medium"
                        >
                          เพิ่มคำถาม
                        </Button>
                      </Link>
                    ) : (
                      <Link
                        key={"add_categories"}
                        to={`add_categories/${data.id}`}
                      >
                        <Button
                          // disabled={data.status === false ? true : false}
                          size="medium"
                        >
                          ข้อมูลคำถาม
                        </Button>
                      </Link>
                    )}
                    {checkRole() ? (
                      <div className={`flex`}>
                        <Tooltip title={`Revise`} TransitionProps={{className: 'text-sm'}}>
                          <div className={`mr-4`}>
                            <Fab
                              disabled={data.revise.data.attributes.revise_status !== "4"}
                              aria-label="revise"
                              size="medium"
                              sx={{
                                background: '#FB8F23',
                                ":hover": {
                                  background: '#F07A05'
                                }
                              }}
                              onClick={() => {
                                navigation(`revise/${data.id}`)
                              }}
                            >
                              <RotateRightIcon size="medium" />
                            </Fab>
                          </div>
                        </Tooltip>
                        <Tooltip title={`Delete part`} TransitionProps={{className: 'text-sm'}}>
                          <Fab
                            aria-label="add"
                            size="medium"
                            color="error"
                            sx={{ marginRight: 2 }}
                            onClick={() => handleOnClickDelete(data)}
                          >
                            <DeleteIcon size="medium" />
                          </Fab>
                        </Tooltip>
                        {data.revise.data &&
                          (data.revise.data.attributes.revise_status === "3" ||
                            data.revise.data.attributes.revise_status === "5")
                             && (
                            <Fab
                              aria-label="add"
                              size="medium"
                              color="primary"
                              sx={{ marginRight: 2 }}
                              onClick={
                                () => {
                                  onclickResendReviseStatus(data);
                                }
                                // handleClickOpen(data.id)
                              }
                            >
                              <RotateRightIcon size="medium" />
                            </Fab>
                        )}
                        <Fab
                          aria-label="add"
                          size="medium"
                          color="primary"
                          onClick={
                            () => {
                              setEditPart({
                                open: true,
                                part: data,
                              });
                            }
                            // handleClickOpen(data.id)
                          }
                        >
                          <EditIcon size="medium" />
                        </Fab>
                      </div>
                    ) : (
                      <Fab
                        aria-label="add"
                        size="medium"
                        color="primary"
                        onClick={
                          () => {
                            setEditPart({
                              open: true,
                              part: data,
                            });
                          }
                          // handleClickOpen(data.id)
                        }
                      >
                        <VisibilityIcon size="medium" />
                      </Fab>
                    )}
                  </CardActions>
                </div>
              </Card>
            </div>
          ))}
        {editPart.open &&
          <FormUpdatePart
            state={[editPart, setEditPart]}
            setSuccess={setSuccess}
          />
        }
        <DeletePartModal
          open={openDelete.open}
          setOpen={setOpenDelete}
          action={daletePart}
          header={"ยืนยันการลบข้อมูล"}
          message={
            <span>
              คุณต้องการ
              <span>{openDelete.message}</span>
            </span>
          }
          confirmText={"ใช่"}
          cancelText={"ยกเลิก"}
          data={dataForDelete}
        />
        <NormalDialog
          setOpen={setOpenResend}
          open={openResend.open}
          message={openResend.message}
          header={"ยืนยันข้อมูล"}
          confirmText={"ใช่"}
          cancelText={"ยกเลิก"}
          action={resendData}
          data={dataForResend}
        />
      </div>
      {/* <div className={`items-end`}>
        <Pagination count={10} variant="outlined" shape="rounded" />
      </div> */}
    </div>
  );
}
