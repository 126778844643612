import * as React from "react";

import { PhotoCamera, Search } from "@mui/icons-material";
import { Controller, useForm } from "react-hook-form";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";

import { APIServices } from "../../helpers/APIServices";
import Button from "@mui/material/Button";
import MuiAlert from "@mui/material/Alert";
import { useSnackbarStore } from "../../helpers/state";

export default function AddUser({ state, handleUpdate }) {
  const [addUser, setAddUser] = [...state];
  const [signatureState, setSignatureState] = React.useState(null);
  const snackBarState = useSnackbarStore();

  const {
    register,
    handleSubmit,
    setValue,
    // onChange,
    getValues,
    // watch,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      lastname: "",
      role: null,
    },
  });

  const onSubmit = async (data) => {
    const { email, lastname, name, role, username } = { ...data };
    if (
      email === "" ||
      lastname === "" ||
      name === "" ||
      username === "" ||
      role === null ||
      role === undefined
    ) {
      snackBarState.onOpen({
        msg: "กรุณากรอกข้อมูลให้ครบถ้วน",
        type: "error",
      });
      return;
    }

    const response = await APIServices.addUser(data);
    if (response.status !== 201) {
      snackBarState.onOpen({
        msg: response.data.error.message,
        type: "error",
      });
      return;
    }
    if (signatureState !== null) {
      const formData = new FormData();
      formData.append("ref", "plugin::users-permissions.user");
      formData.append("refId", response.data.id);
      formData.append("field", "signature");
      formData.append("files", signatureState.file[0]);
      await APIServices.addUserImage(formData);
    }
    snackBarState.onOpen({
      msg: "เพิ่มข้อมูลสำเร็จ",
      type: "success",
    });

    handleUpdate(response.data);
    handleClose();
  };
  const handleClose = (e, reason) => {
    if (reason && reason === "backdropClick") return;
    reset();
    setSignatureState(null);
    setAddUser({ open: false });
  };

  const handleChangeFile = async (e) => {
    const files = Array.from(e.target.files);
    const base64Img = await getBase64(files[0]);
    setSignatureState({
      base: base64Img,
      file: files,
    });
  };

  const handleSearchEssUser = async () => {
    let username = getValues("username").toUpperCase();
    const resGetEssUsername = await APIServices.getEssUserByUser(username);
    if (resGetEssUsername.data.length < 1) {
      snackBarState.onOpen({
        msg: "ไม่มีข้อมูลพนักงานในระบบ ESS",
        type: "error",
      });
      return;
    }
    const resGetEssEmployee = await APIServices.getEssEmployeeByUser(username);
    if (resGetEssEmployee.data.length < 1) {
      snackBarState.onOpen({
        msg: "ค้นหาล้มเหลว ลองใหม่อีกครั้งภายหลัง",
        type: "error",
      });
      return;
    }
    let essUser = resGetEssUsername.data[resGetEssUsername.data.length - 1];
    let essEmp = resGetEssEmployee.data[resGetEssEmployee.data.length - 1];

    setValue("name", essEmp.emp_name);
    setValue("email", essUser.email);
    setValue("lastname", essEmp.sure_name);
    setValue("password", essUser.birthday.replaceAll("-", ""));
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  return (
    <div>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackBarState.open}
        autoHideDuration={6000}
        // onClose={handleClose}
        onClose={snackBarState.onClose}
      >
        <Alert severity={snackBarState.type} sx={{ width: "100%" }}>
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <Dialog open={addUser.open || false} onClose={handleClose}>
        <DialogTitle>เพิ่มข้อมูลพนักงาน</DialogTitle>
        <DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-wrap justify-between"
          >
            <div className=" basis-full flex justify-between">
              <TextField
                className=" basis-3/4"
                {...register("username")}
                name="username"
                margin="dense"
                label="Username (ESS User : AH1000xxxx)"
                type="text"
                // fullWidth
                variant="standard"
              />
              <Button
                sx={{
                  mx: "8px",
                  height: "48px",
                }}
                startIcon={<Search />}
                variant="outlined"
                size="small"
                onClick={() => {
                  handleSearchEssUser();
                }}
              >
                ค้นหา
              </Button>
            </div>
            <Controller
              control={control}
              defaultValue=""
              name="email"
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <TextField
                  //  InputLabelProps={{ shrink: true }}
                  // {...register("lastname")}
                  {...field}
                  fullWidth
                  margin="dense"
                  label="Email"
                  type="text"
                  variant="standard"
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              name="name"
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <TextField
                  {...field}
                  // {...register("name")}
                  margin="dense"
                  name="name"
                  label="Name"
                  type="text"
                  variant="standard"
                />
              )}
            />
            <Controller
              control={control}
              defaultValue=""
              name="lastname"
              render={({
                field,
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <TextField
                  //  InputLabelProps={{ shrink: true }}
                  // {...register("lastname")}
                  {...field}
                  margin="dense"
                  label="Lastname"
                  type="text"
                  variant="standard"
                />
              )}
            />
            <Controller
              control={control}
              name="role"
              render={({ field, fieldState }) => {
                return (
                  <TextField
                    {...field}
                    select
                    margin="dense"
                    label="Role"
                    fullWidth
                    variant="standard"
                  >
                    <MenuItem key={"inspector"} value={"inspector"}>
                      Inspector
                    </MenuItem>
                    <MenuItem key={"checker"} value={"checker"}>
                      Checker
                    </MenuItem>
                    <MenuItem key={"approver"} value={"approver"}>
                      Approver
                    </MenuItem>
                    <MenuItem key={"admin"} value={"admin"}>
                      Admin
                    </MenuItem>
                    <MenuItem key={"release"} value={"release"}>
                      Release for Revise
                    </MenuItem>
                    <MenuItem key={"checker_revise"} value={"checker_revise"}>
                      Checker for Revise
                    </MenuItem>
                    <MenuItem key={"approver_revise"} value={"approver_revise"}>
                      Approver for Revise
                    </MenuItem>
                  </TextField>
                );
              }}
            />
            <div className="mx-auto mt-2 flex-auto flex items-center justify-between">
              <Typography variant="span" color="initial">
                Signature
              </Typography>

              <IconButton
                color="primary"
                aria-label="upload picture"
                component="label"
              >
                <input
                  {...register("signature", {
                    onChange: (e) => handleChangeFile(e),
                  })}
                  hidden
                  accept="image/*"
                  type="file"
                />

                <PhotoCamera />
              </IconButton>
            </div>
            <div className="basis-full w-0"></div>

            {signatureState !== null && (
              <div className=" w-full  ">
                <img
                  draggable="false"
                  className="w-48 h-48 mx-auto"
                  alt={"Signature"}
                  src={signatureState !== null && signatureState.base}
                />
              </div>
            )}

            {errors.exampleRequired && <span>This field is required</span>}
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>ยกเลิก</Button>
          <Button onClick={handleSubmit(onSubmit)}>ยืนยัน</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
