import axios from "axios";
import config from "../config";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";

const axiosToken = () => {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("QCAPPjwt"),
    },
  };
};
const EssToken = () => {
  return {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("ESSjwt"),
    },
  };
};
// import qs from "qs";
const essUser = async (identifier, password) => {
  const res = await axios.post(
    `${config.ess}/auth/local`,
    {
      identifier,
      password,
    },
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  return res;
};
const getEssEmployeeByUser = async (username) => {
  const res = await axios.get(
    `${config.ess}/employees?emp_id=${username}`,
    EssToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  return res;
};
const getEssUserByUser = async (username) => {
  const res = await axios.get(
    `${config.ess}/users?username=${username}`,
    EssToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
  return res;
};

const authQCUser = async (identifier, password) => {
  return await axios.post(
    `${config.server}/api/auth/local`,
    {
      identifier,
      password,
    },
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const getQCUser = async (username) => {
  return await axios.get(
    `${config.server}/api/users?filters[username][$eq]=${username}&populate=*`,

    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const getPartSlim = async () => {
  return await axios.get(
    `${config.server}/api/qc-parts?sort[0]=id&filters[usePartForAdmin][$eq]=true&populate[image][populate]=*&populate[revise][populate]=*&populate=old_parts`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken(),
  )
}

const getPartByID = async (partId) => {
  return await axios.get(
    `${config.server}/api/qc-parts/${partId}?populate[sub_categories][populate]=questions&populate[old_parts][fields]=id&populate[sub_categories][populate]=category&populate[image]=*&populate[revise]=*`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
}

const getPart = async () => {
  return await axios.get(
    `${config.server}/api/qc-parts?sort[0]=id&filters[usePartForAdmin][$eq]=true&populate[image][populate]=*&populate[old_parts][populate]=*&populate[sub_categories][populate]=*&populate[revise][populate]=*`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
  // return await axios.get(
  //   `${config.server}/api/qc-parts?sort[0]=id&filters[usePartForAdmin][$eq]=true&populate[sub_categories][populate][questions][populate]&populate[image][populate]=*&populate[sub_categories][populate][category][populate]=*&populate[papers][populate]=*&populate[revise][populate]=*&populate[old_parts][populate]=*&populate=*`,
  //   {
  //     validateStatus: function (status) {
  //       return status < 500;
  //     },
  //   },
  //   axiosToken()
  // );
};
const getPartWithRevise = async () => {
  // return await axios.get(
  //   `${config.server}/api/qc-parts?sort[0]=id&filters[usePartForAdmin][$eq]=true&populate[image][populate]=*`,
  //   {
  //     validateStatus: function (status) {
  //       return status < 500;
  //     },
  //   },
  //   axiosToken()
  // );
  return await axios.get(
       `${config.server}/api/qc-parts?sort[0]=id&filters[status][$eq]=true&populate[image][populate]=*&populate[old_parts][populate]=*&populate[revise][populate]=*&populate=*`,
      //  `${config.server}/api/qc-parts?sort[0]=id&filters[usePartForAdmin][$eq]=true&populate[sub_categories][populate][questions][populate]&populate[image][populate]=*&populate[sub_categories][populate][category][populate]=*&populate[papers][populate]=*&populate[revise][populate]=*&populate[old_parts][populate]=*&populate=*`,

    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const getSubCategoriesAddCategoriesPage = async (id) => {
  return await axios.get(
    `${config.server}/api/sub-categories?sort[0]=id&filters[part][id][$eq]=${id}&populate[category][populate]=*`,

    axiosToken(),

    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
}

const getSubCategories = async (id) => {
  return await axios.get(
    // `${config.server}/api/sub-categories?filters[part][id][$eq]=${id}&populate=*`,
    // `${config.server}/api/sub-categories?filters[part][id][$eq]=${id}&populate[questions][populate]=*`,
    `${config.server}/api/sub-categories?sort[0]=id&filters[part][id][$eq]=${id}&populate[questions][populate]=*&populate[part][populate]=*&populate[category][populate]=*`,

    axiosToken(),

    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

const getSubCategoriesByPartIdSlim = async (id) => {
  return await axios.get(
    `${config.server}/api/sub-categories?sort[0]=id&filters[part][id][$eq]=${id}&populate[questions][populate]=*&populate[part][populate]=*&populate[category][populate][fields]=category`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  )
}

const getSubCategoriesForViewPaperByPartId = async (id) => {
  return await axios.get(
    // `${config.server}/api/sub-categories?sort[0]=id&filters[part][id][$eq]=${id}&populate[questions][populate]=*&populate[part][populate]=*&populate[category][populate]=*`,
    `${config.server}/api/sub-categories?sort[0]=id&filters[part][id][$eq]=${id}&populate[0]=part&populate[1]=category&populate[2]=questions`,
    axiosToken(),

    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
}

const getSubCategoriesForDashboard = async (id) => {
  return await axios.get(
    `${config.server}/api/sub-categories?sort[0]=id&filters[part][id][$eq]=${id}&populate[category][populate][fields]=category`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

const getSubCategoriesSlim = async () => {
  return await axios.get(
    `${config.server}/api/sub-categories?populate[category][fields]=category`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
}

const getCategories = async () => {
  return await axios.get(
    `${config.server}/api/categories`,
    axiosToken(),

    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

const createSubCategories = async (
  category,
  subCategories,
  partId,
  status,
  status_revise,
  revise_time,
  old_sub_categories
) => {
  return await axios.post(
    `${config.server}/api/sub-categories`,
    {
      data: {
        category: category,
        sub_categories: subCategories,
        part: partId,
        status: status,
        status_revise: status_revise,
        revise_time: revise_time,
        old_sub_categories: old_sub_categories,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const getQuestion = async (id) => {
  return await axios.get(
    `${config.server}/api/questions?filters[sub_category][id][$eq]=${id}&populate[sub_category][populate][category]=*&populate=*`,
    // `${config.server}/api/questions?filters[sub_category][id][$eq]=${id}&populate=*&sort[question]=asc`,

    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};
const getAllQuestion = async (id) => {
  return await axios.get(
    `${config.server}/api/questions?populate=*`,

    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const createQuestion = async (
  // cp_id,
  specMin,
  specMax,
  sc_status,
  question,
  UCL_xBar,
  CL_xBar,
  LCL_xBar,
  UCL_rChart,
  CL_rChart,
  LCL_rChart,
  subCategory,
  status,
  formula,
  status_revise,
  revise_time,
  old_question
) => {
  return await axios.post(
    `${config.server}/api/questions`,
    {
      data: {
        // cq_id: cp_id,
        spec_min: parseFloat(specMin),
        spec_max: parseFloat(specMax),
        sc_status: sc_status,
        question: question,
        UCL_Xbar: parseFloat(UCL_xBar),
        CL_Xbar: parseFloat(CL_xBar),
        LCL_Xbar: parseFloat(LCL_xBar),
        UCL_Rchart: parseFloat(UCL_rChart),
        CL_Rchart: parseFloat(CL_rChart),
        LCL_Rchart: parseFloat(LCL_rChart),
        sub_category: subCategory,
        status: status,
        formula: {
          data: formula,
        },
        status_revise: status_revise,
        revise_time: revise_time,
        old_question: old_question,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const createPart = async (partName, partCode, status, usePartForAdmin) => {
  return await axios.post(
    `${config.server}/api/qc-parts?populate[old_parts][populate]=*`,
    {
      data: {
        part_name: partName,
        part_code: partCode,
        status: status,
        revise_time: "0",
        usePartForAdmin: usePartForAdmin,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const createPartForRevise = async (
  partName,
  partCode,
  revise_time,
  old_part,
  status,
  usePartForAdmin
) => {
  return await axios.post(
    `${config.server}/api/qc-parts`,
    {
      data: {
        part_name: partName,
        part_code: partCode,
        revise_time: revise_time + 1,
        old_parts: old_part,
        status: status,
        usePartForAdmin: usePartForAdmin,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const revise = async (
  revisionRecord,
  date,
  release_signature,
  release_id,
  revise_time,
  part_id
) => {
  return await axios.post(
    `${config.server}/api/revises`,
    {
      data: {
        revisionRecord: revisionRecord,
        date: date,
        release_signature: release_signature,
        release_id: release_id,
        revise: parseInt(revise_time),
        part: part_id,
        revise_status: "1",
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const reviseForUpdate = async (
  revisionRecord,
  date,
  release_signature,
  release_id,
  revise_time,
  part_id
) => {
  return await axios.post(
    `${config.server}/api/revises`,
    {
      data: {
        revisionRecord: revisionRecord,
        date: date,
        release_signature: release_signature,
        release_id: release_id,
        revise: parseInt(revise_time),
        part: part_id,
        revise_status: "1",
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updatePart = async (partName, partCode, id, status, usePartForAdmin) => {
  return await axios.put(
    `${config.server}/api/qc-parts/${id}`,
    {
      data: {
        part_name: partName,
        part_code: partCode,
        status: status,
        usePartForAdmin: usePartForAdmin,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updateRevisionRecord = async ( id, status) => {
  return await axios.put(
    `${config.server}/api/revises/${id}`,
    {
      data: {
        revisionRecord: status,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updateReviseForResendPart = async ( id ,release_signature, releaseID) => {
  return await axios.put(
    `${config.server}/api/revises/${id}`,
    {
      data: {
        approver_id: null,
        checker_id: null,
        checker_signature: null,
        approver_signature: null,
        release_id: releaseID,
        release_signature: release_signature,
        revise_status: "1"
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};


const updatePartStatus = async (id, status) => {
  return await axios.put(
    `${config.server}/api/qc-parts/${id}`,
    {
      data: {
        status: status,
        // revise_time: revise_Time
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const deletePart = async (id) => {
  return await axios.delete(
    `${config.server}/api/upload/files/${id}`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const findOnePart = async (id) => {
  return await axios.get(
    `${config.server}/api/qc-parts/${id}?[populate][sub_categories][populate]=*&[populate][sub_categories][populate][questions][populate]=*&[populate][sub_categories][populate][questions][populate][old_question][populate]=*&[populate][sub_categories][populate][old_sub_categories][populate]=*&[populate][sub_categories][populate][category][fields]=category&[populate][revise][populate]=*&[populate][papers][populate]=*&[populate][image][populate]=*&populate[old_parts][populate]=*&populate=*`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

const findOnePartForFromAddQuestion = async (id) => {
  return await axios.get(
    `${config.server}/api/qc-parts/${id}?populate[revise][fields]=revise_status&populate[image][fields]=url`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
}

const findSubAndQuestionByPartId = async (partId) => {
  return await axios.get(
    `${config.server}/api/qc-parts/${partId}?populate[sub_categories][populate]=questions&populate[sub_categories][populate]=category`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
}

const getPartName = async (partId) => {
  return await axios.get(
    `${config.server}/api/qc-parts/${partId}?[populate][sub_categories][populate]=*&[populate][image][populate]=*`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
  )
}

const findOnePartForUseName = async (id) => {
  return await axios.get(
    // `${config.server}/api/qc-parts/${id}?populate=*`,
    `${config.server}/api/qc-parts/${id}?populate[revise][populate]=*&populate[old_parts][fields]=part_name&populate[papers][fields]=shift`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

const findOnePartPopulateAll = async (id) => {
  return await axios.get(
    `${config.server}/api/qc-parts/${id}?populate[sub_categories][populate][questions]=*&populate=*`,
    // `${config.server}/api/qc-parts/${id}?populate=*`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
}

//populate[sub_categories][populate][questions][populate][old_question][populate]

const findOneRevise = async (id) => {
  return await axios.get(
    `${config.server}/api/revises/${id}?populate[release_id][populate]=*&populate[checker_id][populate]=*&populate[approver_id][populate]=*&populate[part][populate][image][populate]=*&populate[part][populate][sub_categories][populate]=*&populate[part][populate][old_parts][populate]=*&populate=*`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

const updateCheckerForRevise = async (
  reviseID,
  checkerSignatureURL,
  status,
  checkerID,
  remark
) => {
  return await axios.put(`${config.server}/api/revises/${reviseID}`, {
    data: {
      checker_id: checkerID,
      checker_signature: checkerSignatureURL,
      revise_status: status,
      remark: remark
    },
  });
};

const updateApproverForRevise = async (
  reviseID,
  approverSignatureURL,
  status,
  approverID,
  remark,
  // revise_time
) => {
  return await axios.put(`${config.server}/api/revises/${reviseID}`, {
    data: {
      approver_id: approverID,
      approver_signature: approverSignatureURL,
      revise_status: status,
      remark: remark,
      // revise: revise_time
    },
  });
};

const findOneQuestion = async (questionID) => {
  return await axios.get(
    `${config.server}/api/questions/${questionID}`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

const findOneSubCat = async (subCatID) => {
  return await axios.get(
    // `${config.server}/api/sub-categories?filters[part][id][$eq]=${id}&populate[questions][populate]=*&populate[part][populate]=*&populate[category][populate]=*`,
    `${config.server}/api/sub-categories/${subCatID}?&populate=*`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

const updateSubCategory = async (
  category,
  subCategory,
  id,
  status,
  status_revise
) => {
  return await axios.put(
    `${config.server}/api/sub-categories/${id}`,
    {
      data: {
        category: category,
        sub_categories: subCategory,
        status: status,
        status_revise: status_revise,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updateDeleteSubCategory = async (id, status) => {
  return await axios.put(
    `${config.server}/api/sub-categories/${id}`,
    {
      data: {
        status: status,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updateQuestion = async (
  question_data,
  spec_min,
  spec_max,
  sc_status,
  id,
  status,
  UCL_xBar,
  CL_xBar,
  LCL_xBar,
  UCL_rChart,
  CL_rChart,
  LCL_rChart,
  formula,
  status_revise
) => {
  return await axios.put(
    `${config.server}/api/questions/${id}`,
    {
      data: {
        question: question_data,
        spec_min: parseFloat(spec_min),
        spec_max: parseFloat(spec_max),
        sc_status: sc_status,
        status: status,
        UCL_Xbar: parseFloat(UCL_xBar),
        CL_Xbar: parseFloat(CL_xBar),
        LCL_Xbar: parseFloat(LCL_xBar),
        UCL_Rchart: parseFloat(UCL_rChart),
        CL_Rchart: parseFloat(CL_rChart),
        LCL_Rchart: parseFloat(LCL_rChart),
        formula: {
          data: formula,
        },
        status_revise: status_revise,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updateFormulaInQuestion = async (id, formula) => {
  return await axios.put(
    `${config.server}/api/questions/${id}`,
    {
      data: {
        formula: {
          data: formula,
        },
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const createAnswer = async (
  paper_id,
  question_id,
  actual,
  comment,
  answer
  // user_id,
) => {
  return await axios.post(
    `${config.server}/api/answers`,
    {
      data: {
        paper_id: paper_id,
        question_id: question_id,
        actual: parseFloat(actual),
        comment: comment,
        answer: answer,
        // user_id: user_id,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const createPaper = async (
  answer,
  partID,
  inspector_id,
  shift,
  create_datetime,
  date,
  time,
  shiftAB,
  inspectorSignature,
  process_status,
  cavity,
) => {
  return await axios.post(
    `${config.server}/api/papers`,
    {
      data: {
        answer: answer,
        partName: partID,
        inspector_id: inspector_id,
        // time: time,
        shift: shift,
        create_datetime: create_datetime,
        date: date,
        time: time,
        shiftAB: shiftAB,
        status: "1",
        signature_inspector: inspectorSignature,
        process_status: process_status,
        cavity: cavity,
        // approver_id: approver_id,
        // checker_id: checker_id,
      },
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const getPaper = async (beforeDate, afterDate, id, user) => {
  let url = `/api/papers?sort=create_datetime:ASC&filters[create_datetime][$gte]=${beforeDate}&filters[create_datetime][$lt]=${afterDate}&filters[partName][id][$eq]=${id}&filters[delete][$eq]=false&populate=*`;
  if (user.qc_role === "inspector") {
    url = `/api/papers?sort=create_datetime:ASC&filters[create_datetime][$gte]=${beforeDate}&filters[create_datetime][$lt]=${afterDate}&filters[partName][id][$eq]=${id}&filters[delete][$eq]=false&filters[inspector_id][id][$eq]=${user.id}&populate=*`;
  }

  return await axios.get(
    `${config.server}${url}`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const getPaperForReport = async (beforeDate, afterDate, id, user) => {
  let url = `/api/papers?sort=create_datetime:ASC&filters[create_datetime][$gte]=${beforeDate}&filters[create_datetime][$lt]=${afterDate}&filters[partName][id][$eq]=${id}&filters[delete][$eq]=false&populate=*`;
  if (user.qc_role === "inspector") {
    url = `/api/papers?sort=create_datetime:ASC&filters[create_datetime][$gte]=${beforeDate}&filters[create_datetime][$lt]=${afterDate}&filters[partName][id][$eq]=${id}&filters[delete][$eq]=false&filters[inspector_id][id][$eq]=${user.id}&populate=*`;
  }

  return await axios.get(
    `${config.server}${url}`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const getAllPaper = async () => {
  let url = `/api/papers?populate=*`;
  // if (user.qc_role === "inspector") {
  //   url = `/api/papers?sort=create_datetime:ASC&filters[create_datetime][$gte]=${beforeDate}&filters[create_datetime][$lt]=${afterDate}&filters[partName][id][$eq]=${id}&filters[delete][$eq]=false&filters[inspector_id][id][$eq]=${user.id}&populate=*`;
  // }

  return await axios.get(
    `${config.server}${url}`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const findOnePaper = async (id) => {
  return await axios.get(
    `${config.server}/api/papers/${id}?populate[approver_id][populate]=*&populate[answer][populate]=*&populate[checker_id][populate]=*&populate[inspector_id][populate]=*&populate[partName][populate][sub_categories][populate]=*&populate[partName][populate][revise][populate]=*&populate[partName][populate][image][populate]=*&populate[partName][populate][old_parts][populate]=*&populate[partName][populate][old_parts][populate][revise][populate]=*&populate[partName][populate][old_parts][populate][revise][populate][checker_id][populate]=*&populate[partName][populate][old_parts][populate][revise][populate][release_id][populate]=*&populate[partName][populate][old_parts][populate][revise][populate][approver_id][populate]=*&populate[partName][populate][old_parts][populate][sub_categories][populate]=*&populate=*`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const findOnePaperForViewPaper = async (id) => {
  return await axios.get(
    `${config.server}/api/papers/${id}?populate[answer][populate]=*&populate[approver_id][populate][profile_image][fields]=url&populate[approver_id][populate][signature][fields]=url&populate[checker_id][populate][profile_image][fields]=url&populate[checker_id][populate][signature][fields]=url&populate[inspector_id][populate][profile_image][fields]=url&populate[inspector_id][populate][signature][fields]=url&populate[partName][populate][sub_categories][populate]=*&populate[partName][populate][revise][populate]=*&populate[partName][populate][image][populate]=*&populate[partName][populate][old_parts][populate]=*&populate=*`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
}

const findOnePaperForEditPaper = async (id) => {
  return await axios.get(
    // `${config.server}/api/papers/${id}?populate[approver_id][populate]=*&populate[answer][populate]=*&populate[checker_id][populate]=*&populate[inspector_id][populate]=*&populate[partName][populate][sub_categories][populate]=*&populate[partName][populate][revise][populate]=*&populate[partName][populate][image][populate]=*&populate[partName][populate][old_parts][populate]=*&populate[partName][populate][old_parts][populate][revise][populate]=*&populate[partName][populate][old_parts][populate][revise][populate][checker_id][fields]=name&populate[partName][populate][old_parts][populate][revise][populate][release_id][fields]=name&populate[partName][populate][old_parts][populate][revise][populate][approver_id][fields]=name&populate=*`,
    // `${config.server}/api/papers/${id}?populate[approver_id][populate][signature][fields]=url&populate[approver_id][populate][profile_image][fields]=url&populate[checker_id][populate][signature][fields]=url&populate[checker_id][populate][profile_image][fields]=url&populate[inspector_id][populate][signature][fields]=url&populate[inspector_id][populate][profile_image][fields]=url&populate=partName`,
    `${config.server}/api/papers/${id}?populate[approver_id][populate][signature][fields]=url&populate[approver_id][populate][profile_image][fields]=url&populate[checker_id][populate][signature][fields]=url&populate[checker_id][populate][profile_image][fields]=url&populate[inspector_id][populate][signature][fields]=url&populate[inspector_id][populate][profile_image][fields]=url&populate[partName][populate][sub_categories][populate]=*`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken(),
  );
}

const updateDeletePaper = async (deleteAnswer, id) => {
  return await axios.put(
    `${config.server}/api/papers/${id}`,
    {
      data: {
        delete: deleteAnswer,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updateDeletePart = async (usePartForAdmin,status, id) => {
  return await axios.put(
    `${config.server}/api/qc-parts/${id}`,
    {
      data: {
        usePartForAdmin: usePartForAdmin,
        status:status
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updatePaper = async (answer, shiftAB, id, process_status) => {
  return await axios.put(
    `${config.server}/api/papers/${id}`,
    {
      data: {
        answer: answer,
        shiftAB: shiftAB,
        process_status: process_status, 
        checker_id: null, 
        approver_id: null,
        signature_checker: null, 
        signature_approver: null,
        status: "1"
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updateCheckerInPaper = async (
  paperID,
  checkerSignatureURL,
  status,
  checkerID,
  remark
) => {
  return await axios.put(`${config.server}/api/papers/${paperID}`, {
    data: {
      checker_id: checkerID,
      signature_checker: checkerSignatureURL,
      status: status,
      remark: remark
    },
  });
};

const updateApproverInPaper = async (
  paperID,
  approverSignatureURL,
  status,
  approverID,
  remark
) => {
  return await axios.put(`${config.server}/api/papers/${paperID}`, {
    data: {
      approver_id: approverID,
      signature_approver: approverSignatureURL,
      status: status,
      remark: remark
    },
  });
};

const addUserImage = async (formData) => {
  const headers = {
    headers: {
      Authorization: "Bearer " + localStorage.getItem("QCAPPjwt"),
      "Content-Type": "multipart/form-data",
    },
  };
  return axios.post(`${config.server}/api/upload`, formData, headers);
};

const removeUserOldImage = async (id) => {
  return axios.delete(`${config.server}/api/upload/files/${id}`, axiosToken());
};

const getEssImage = async (imagePath) => {
  const resType = { responseType: "blob" };

  return await axios.get(config.ess + imagePath, resType);
};

const fetcher = (url) =>
  axios
    .get(`${config.server}${url}`, axiosToken(), {
      validateStatus: function (status) {
        return status < 500;
      },
    })
    .then((res) => res);

function multiFetcher(urls) {
  return Promise.all(
    urls.map((url) => {
      return fetcher(url);
    })
  );
}

const useAllFetch = (url) => {
  return useSWR(url, multiFetcher, {
    refreshInterval: 600000,
  });
};

const useUser = () => {
  const { data, error, isLoading, mutate } = useSWRImmutable(
    // `/api/users?populate=*`,
    // `/api/users?sort[0]=id&populate=*`,
    `/api/users?sort[0]=id&populate[profile_image]=*&populate[signature]=*&populate=*`,
    fetcher,
    {
      refreshInterval: 600000,
    }
  );
  return { data, error, isLoading, mutate };
};

const useOneUser = (id) => {
  const { data, error, isLoading, mutate } = useSWRImmutable(
    `/api/users/${id}?populate[signature]=*&populate[profile_image]=*&populate=*`,
    fetcher,
    {
      refreshInterval: 600000,
    }
  );
  if (data) {
    if (data.data.signature) {
      data.data.signature.sort((a, b) => a.id - b.id);
    }
  }
  return { data, error, isLoading, mutate };
};

const getUrlPaper = (beforeDate, afterDate, id) => {
  return `/api/papers?filters[create_datetime][$gte]=${beforeDate}&filters[create_datetime][$lt]=${afterDate}&filters[partName][id][$eq]=${id}&filters[delete][$eq]=false`;
};

const usePartWithImage = () => {
  const { data, error, isLoading, mutate } = useSWR(
    `/api/qc-parts?sort[0]=id&filters[status][$eq]=true&filters[revise][revise_status][$eq]=4&populate[image]=*&populate[revise][populate]=*&populate[old_parts][populate]=*`,
    fetcher,
    {
      refreshInterval: 30000,
    }
  )
  return { data, error, isLoading, mutate };
}

const usePart = () => {
  const { data, error, isLoading, mutate } = useSWR(
    // `/api/qc-parts?populate=*&sort[0]=id&filters[status][$eq]=true`,
    // `/api/qc-parts?sort[0]=id&filters[status][$eq]=true&populate[sub_categories][populate][questions][populate]=*&populate=*`,
    `/api/qc-parts?sort[0]=id&filters[status][$eq]=true&filters[revise][revise_status][$eq]=4&populate[sub_categories][populate][questions][populate]=*&populate[image]=*&populate[revise][populate]=*&populate[old_parts][populate]=*&populate=*`,
    // `/api/qc-parts?sort[0]=id&filters[status][$eq]=true&populate[sub_categories][populate][questions][populate]=*&populate[image]=*&populate[revise][populate]=*&populate=*`,

    fetcher,
    {
      refreshInterval: 30000,
    }
  );
  return { data, error, isLoading, mutate };
};

const getPartForPaper = async () => {
  return await axios.get(
    `${config.server}/api/qc-parts?sort[0]=id&filters[status][$eq]=true&filters[revise][revise_status][$eq]=4&populate[sub_categories][populate][questions][populate]=*&populate[image]=*&populate[revise][populate]=*&populate=*`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );

  // const { data, error, isLoading, mutate } = useSWRImmutable(
  //   // `/api/qc-parts?populate=*&sort[0]=id&filters[status][$eq]=true`,
  //   // `/api/qc-parts?sort[0]=id&filters[status][$eq]=true&populate[sub_categories][populate][questions][populate]=*&populate=*`,
  //   `/api/qc-parts?sort[0]=id&filters[status][$eq]=true&filters[revise][revise_status][$eq]=4&populate[sub_categories][populate][questions][populate]=*&populate[image]=*&populate[revise][populate]=*&populate=*`,
  //   // `/api/qc-parts?sort[0]=id&filters[status][$eq]=true&populate[sub_categories][populate][questions][populate]=*&populate[image]=*&populate[revise][populate]=*&populate=*`,

  //   fetcher,
  //   {
  //     refreshInterval: 60000,
  //   }
  // );
  // return { data, error, isLoading, mutate };
};

const getPartForDashboard = async () => {
  return await axios.get(
    // `${config.server}/api/qc-parts?sort[0]=id&filters[status][$eq]=true&filters[revise][revise_status][$eq]=4&populate[sub_categories][populate][questions][populate]=*&populate[image]=*&populate[revise][populate]=*&populate[old_parts][populate]=*&populate=*`,
    `${config.server}/api/qc-parts?sort[0]=id&filters[status][$eq]=true&filters[revise][revise_status][$eq]=4&populate[image]=*&populate[revise][populate]=*&populate[old_parts][populate]=*&populate=*`,
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const usePaper = (beforeDate, afterDate, id, user) => {
  let url = `/api/papers?sort=create_datetime:ASC&filters[create_datetime][$gte]=${beforeDate}&filters[create_datetime][$lt]=${afterDate}&filters[partName][id][$eq]=${id}&filters[delete][$eq]=false&populate=*`;
  if (user.qc_role === "inspector") {
    url = `/api/papers?sort=create_datetime:ASC&filters[create_datetime][$gte]=${beforeDate}&filters[create_datetime][$lt]=${afterDate}&filters[partName][id][$eq]=${id}&filters[delete][$eq]=false&filters[inspector_id][id][$eq]=${user.id}&populate=*`;
  }
  const { data, error, isLoading, mutate } = useSWR(url, fetcher, {
    refreshInterval: 60000,
  });
  return { data, error, isLoading, mutate };
};

const updateUser = async ({ name, lastname, role, id, email, blocked }) => {
  return await axios.put(
    `${config.server}/api/users/${id}`,
    {
      email: email,
      name: name,
      lastname: lastname,
      qc_role: role,
      blocked: !blocked,
    },
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};
const addUser = async ({ name, lastname, role, email, username, password }) => {
  return await axios.post(
    `${config.server}/api/users`,
    {
      email,
      name: name,
      lastname: lastname,
      qc_role: role,
      password,
      username: username.toUpperCase(),
      role: 1,
    },
    {
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const createSetting = async (time) => {
  return await axios.post(
    `${config.server}/api/settings`,
    {
      data: {
        timeToSendEmail: time,
      },

      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const updateSetting = async (setting, id) => {
  return await axios.put(
    `${config.server}/api/settings/${id}`,
    {
      data: setting,
      validateStatus: function (status) {
        return status < 500;
      },
    },
    axiosToken()
  );
};

const getSetting = async () => {
  return await axios.get(`${config.server}/api/settings`, axiosToken(), {
    validateStatus: function (status) {
      return status < 500;
    },
  });
};

const findRevise = async () => {
  return await axios.get(
    // `${config.server}/api/revises?sort[id]=desc&populate=*`,
    `${config.server}/api/revises?sort[id]=desc&populate=*&filters[part][usePartForAdmin][$eq]=true`,
    axiosToken(),
    {
      validateStatus: function (status) {
        return status < 500;
      },
    }
  );
};

const sendEmailNG = async (ng) => {
  if (!Array.isArray(ng)) {
    throw Error("ng should be array type").stack;
  }
  return await axios({
    method: "POST",
    url: `${config.orgServer}/api/sendemailbytemplate`,
    data: {
      to: "sawanon.w@aapico.com",
      templateReferenceId: "11",
      data: {
        ng: ng,
      },
    },
  });
};

const getManyPaper = async (papersId) => {
  if(!Array.isArray(papersId)){
    throw Error("papersId should be array type")
  }
  return await axios({
    method: "POST",
    url: `${config.server}/api/papers/getManyPaper`,
    data: {
      papersId,
    }
  })
}

const getDetailPart = async (partId) => {
  if(!partId){
    throw Error("required partId")
  }
  return await axios({
    method: "GET",
    url: `${config.server}/api/qc-parts/getDetailPart/${partId}`
  })
}

const createQuestionRevise = async (question) => {
  return await axios({
    url: `${config.server}/api/questions`,
    method: "POST",
    data: {
      data: {
        ...question
      }
    },
    ...axiosToken(),
  })
}

const createSubcategoryRevise = async (subcategory) => {
  return await axios({
    url: `${config.server}/api/sub-categories`,
    method: "POST",
    data: {
      data: {
        ...subcategory,
      }
    },
    ...axiosToken(),
  })
}

const createPartRevise = async (part) => {
  return await axios({
    url: `${config.server}/api/qc-parts`,
    method: "POST",
    data: {
      data: {
        ...part,
      }
    },
    ...axiosToken(),
  })
}

const createRevise = async (reviseData) => {
  return await axios({
    url: `${config.server}/api/revises`,
    method: "POST",
    data: {
      data: {
        ...reviseData,
      }
    },
    ...axiosToken(),
  })
}

const updateStatusPart = async  (partId, status, usePartForAdmin) => {
  return await axios({
    url: `${config.server}/api/qc-parts/${partId}`,
    method: "PUT",
    data: {
      data: {
        status,
        usePartForAdmin,
      }
    },
    ...axiosToken(),
  })
}

export { useUser, useOneUser };

export const APIServices = {
  essUser,
  authQCUser,
  getQCUser,
  getPart,
  getPartSlim,
  getPartByID,
  getSubCategoriesAddCategoriesPage,
  getSubCategories,
  getSubCategoriesByPartIdSlim,
  getSubCategoriesForViewPaperByPartId,
  getSubCategoriesForDashboard,
  getSubCategoriesSlim,
  getCategories,
  createSubCategories,
  getQuestion,
  createQuestion,
  addUserImage,
  getEssImage,
  removeUserOldImage,
  useUser,
  updateUser,
  updateDeleteSubCategory,
  getEssUserByUser,
  getEssEmployeeByUser,
  addUser,
  updatePart,
  deletePart,
  findOnePart,
  findOnePartForFromAddQuestion,
  findSubAndQuestionByPartId,
  getPartName,
  createPart,
  updateSubCategory,
  findOneSubCat,
  updateQuestion,
  createAnswer,
  createPaper,
  getPaper,
  updateDeletePaper,
  useAllFetch,
  usePartWithImage,
  usePart,
  getUrlPaper,
  usePaper,
  findOnePaper,
  findOnePaperForViewPaper,
  findOnePaperForEditPaper,
  updatePaper,
  findOneQuestion,
  updateCheckerInPaper,
  updateApproverInPaper,
  createSetting,
  updateSetting,
  getSetting,
  revise,
  createPartForRevise,
  reviseForUpdate,
  findRevise,
  getAllPaper,
  getAllQuestion,
  findOneRevise,
  updateApproverForRevise,
  updateCheckerForRevise,
  updatePartStatus,
  updateFormulaInQuestion,
  getPartForDashboard,
  sendEmailNG,
  getPartForPaper,
  getPartWithRevise,
  updateDeletePart,
  findOnePartForUseName,
  findOnePartPopulateAll,
  updateRevisionRecord,
  getPaperForReport,
  updateReviseForResendPart,
  getManyPaper,
  getDetailPart,
  createQuestionRevise,
  createSubcategoryRevise,
  createPartRevise,
  createRevise,
  updateStatusPart,
};
