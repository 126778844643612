import React, { useRef, useState } from 'react'
import NoPhotographyIcon from '@mui/icons-material/NoPhotography'
import config from '../config'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import { useEffect } from 'react'
import Edit from '@mui/icons-material/Edit'
import Delete from '@mui/icons-material/Delete'
import { getBase64 } from '../helpers/common'

const EditPart = ({
    imageBroken,
    part,
    onConfirm,
    image,
    setImage,
}) => {
    const [edit, setEdit] = useState(false)
    const [partName, setPartName] = useState('')
    const [partCode, setPartCode] = useState('')
    const [error, setError] = useState({
        partName: false,
        partCode: false,
    })
    // const [image, setImage] = useState({
    //     base: null,
    //     file: null,
    // })
    const inputRef = useRef()

    const clearError = () => {
        setError({
            partName: false,
            partCode: false,
            reviseNo: false,
        })
    }

    const handleOnChangePartName = (e) => {
        const value = e.target.value
        console.log(value, typeof value);
        setError(prev => ({
            ...prev,
            partName: false,
        }))
        setPartName(value)
    }

    const handleOnChangePartCode = (e) => {
        const value = e.target.value
        console.log(value, typeof value);
        setError(prev => ({
            ...prev,
            partCode: false,
        }))
        setPartCode(value)
    }

    const handleOnCancelEdit = () => {
        clearImage()
        handleOnClose()
    }

    const clearImage = () => {
        setImage({
            base: null,
            file: null,
        })
    }

    const handleOnClose = () => {
        setEdit(false)
        clearError()
    }

    const handleOnConfirm = () => {
        try {
            validPart()
            if(onConfirm){
                onConfirm({
                    partName,
                    partCode,
                    image,
                })
                handleOnClose()
            }
        } catch (error) {
            console.error("handleOnConfirm", error)
        }
    }

    const validPart = () => {
        if(partName === ""){
            setError(prev => ({
                ...prev,
                partName: true,
            }))
            throw Error("required Part name")
        }
        if(partCode === ""){
            setError(prev => ({
                ...prev,
                partCode: true,
            }))
            throw Error("required Part code")
        }
    }

    const handleOnChangeImage = async (e) => {
        const file = e.target.files[0]
        console.log("file 93:", file);
        const base = await getBase64(file)
        setImage({
            base,
            file,
        })
    }

    const renderImage = () => {
        // image
        const src = image.base !== null ? `${image.base}` : `${config.server}${part.image.url}`
        return src
    }

    useEffect(() => {
        if(edit){
            return
        }
        setPartName(part?.part_name)
        setPartCode(part?.part_code)

    }, [part, edit])
  return (
    <div className={``}>
        <div className={`flex justify-end`}>
            {edit
            ?
            <div className={`flex gap-2`}>
                <Button
                    variant='outlined'
                    onClick={handleOnCancelEdit}
                >
                    Cancel
                </Button>
                <Button
                    variant='contained'
                    onClick={handleOnConfirm}
                >
                    Confirm
                </Button>
            </div>
            :
            <div>
                <Button
                    variant='outlined'
                    onClick={() => setEdit(true)}
                    endIcon={<Edit />}
                >
                    Edit part
                </Button>
            </div>
            }
        </div>
        <div className={`w-80 drop-shadow rounded-lg overflow-hidden mt-4 relative`}>
            {edit&&
                <div className={`flex gap-2 absolute right-0 top-0 m-1`}>
                    {image.base !== null&&
                        <div
                            className={`text-red-400 hover:text-white hover:bg-red-400 hover:bg-opacity-80 cursor-pointer rounded-full p-2`}
                            onClick={clearImage}
                        >
                            <Delete />
                        </div>
                    }
                    <div
                        className={`hover:bg-gray-400 hover:bg-opacity-30 rounded-full p-2 cursor-pointer`}
                        onClick={() => {
                            inputRef.current.click()
                        }}
                    >
                        <Edit />
                        <input
                            ref={inputRef}
                            type="file"
                            hidden
                            onChange={handleOnChangeImage}
                        />
                    </div>
                </div>
            }
            {imageBroken
            ?
            <NoPhotographyIcon
                sx={{
                    width: '14rem',
                    height: '14rem',
                    color: '#c9c9c9',
                }}
            />
            :
            <img
                src={`${renderImage()}`}
                alt=''
            />
            }
        </div>
        {edit
        ?
        <div>
            <TextField
                label={`Part name`}
                defaultValue={part?.part_name}
                fullWidth
                className={`mt-4`}
                size='small'
                onChange={handleOnChangePartName}
                error={error.partName}
            />
        </div>
        :
        <div className={`flex gap-2 items-center mt-4 justify-between`}>
            <div className={`text-gray-500 font-light`}>
                Part name
            </div>
            <div className={`text-black text-opacity-80`}>
                {`${part?.part_name || ''}`}
            </div>
        </div>
        }
        {edit
        ?
        <div>
            <TextField
                label={`Part code`}
                defaultValue={part?.part_code}
                className={`mt-4`}
                fullWidth
                size='small'
                onChange={handleOnChangePartCode}
                error={error.partCode}
            />
        </div>
        :
        <div className={`flex gap-2 items-center mt-2 justify-between`}>
            <div className={`text-gray-500 font-light`}>
                Part code
            </div>
            <div className={`text-black text-opacity-80`}>
                {`${part?.part_code || ''}`}
            </div>
        </div>
        }
        {edit
        ?
        <div className={`mt-4  pointer-events-none`}>
            <TextField
                label={`Revise time`}
                defaultValue={part?.revise_time}
                size='small'
                disabled
                fullWidth
                className={``}
                sx={{
                    '.Mui-disabled': {
                        'WebkitTextFillColor': 'rgba(0, 0, 0, 0.8)',
                    }
                }}
                inputProps={{
                    className: `text-black`,
                    style: {
                        color: 'black',
                        ':dsiabled': {
                            color: 'black'
                        }
                    }
                }}
            />
        </div>
        :
        <div className={`flex gap-2 items-center mt-2 justify-between`}>
            <div className={`text-gray-500 font-light`}>
                Revise time
            </div>
            <div className={`text-black text-opacity-80`}>
                {`${part?.revise_time}`}
            </div>
        </div>
        }
    </div>
  )
}

export default EditPart