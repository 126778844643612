import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ListItemIcon from "@mui/material/ListItemIcon";
import LogoutIcon from "@mui/icons-material/Logout";
import Storages from "../helpers/localStorage";
import PersonIcon from "@mui/icons-material/Person";
import RefreshIcon from "@mui/icons-material/Refresh";
import config from "../config";
import { useContext } from "react";
import { UserContext } from ".";

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

export default function PersistentDrawerLeft({ setOpen, open }) {
  const theme = useTheme();
  let navigate = useNavigate();
  const location = useLocation();
  // let user = JSON.parse(localStorage.getItem("QCAPPuser"));
  const user = useContext(UserContext);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const onLogout = () => {
    Storages.deleteStorage("QCAPPuser");
    Storages.deleteStorage("QCAPPjwt");
    Storages.deleteStorage("ESSjwt");
    navigate("/login");
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const openProfile = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box sx={{ display: "flex" }} className='max-w-screen'>
      <CssBaseline />
      <AppBar position='relative' open={open}>
        <Toolbar className='flex flex-row justify-between'>
          {/* <div> */}
          <div className='flex flex-row justify-between'>
            <div className='flex items-center '>
              <IconButton
                color='inherit'
                aria-label='open drawer'
                onClick={handleDrawerOpen}
                edge='start'
                sx={{ mr: 2, ...(open && { display: "none" }) }}>
                <MenuIcon />
              </IconButton>
            </div>
            <div className='flex items-center '>
              <Typography variant='h6' noWrap component='div'>
                QC APP
              </Typography>
            </div>
          </div>
          <div>
            <IconButton
              className='text-white mr-2'
              onClick={() => window.location.reload()}>
              <RefreshIcon />
            </IconButton>
            <Button
              variant='contained'
              onClick={handleClick}
              endIcon={<KeyboardArrowDownIcon />}>
              {user.profile_image !== null ? (
                <Avatar src={user.profile_image.url} />
              ) : (
                <div className='flex justify-center items-center'>
                  <PersonIcon />
                </div>
              )}
              <span className='ml-2'>{`${user.qc_role}`}</span>
            </Button>
          </div>

          <Menu
            // id="fade-menu"
            // MenuListProps={{
            //   "aria-labelledby": "fade-button",
            // }}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            anchorEl={anchorEl}
            open={openProfile}
            onClose={handleClose}
            // TransitionComponent={Fade}
          >
            <Link key={"profile"} to={`/layout/profile/${user.id}`}>
              <MenuItem>
                <ListItemIcon>
                  <AccountCircleIcon />
                </ListItemIcon>
                Profile
              </MenuItem>
            </Link>
            {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
            <MenuItem onClick={() => onLogout()} key={"Logout"}>
              <ListItemIcon>
                <LogoutIcon />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Menu>
          {/* {location.pathname !== "/home" && (
              <div>
                <Fab
                  color="#fff"
                  variant="extended"
                  size="medium"
                  aria-label="back"
                  onClick={() => navigate(-1)}
                >
                  <ArrowBackIosIcon color="primary" sx={{ mr: 1 }} />
                  ย้อนกลับ
                </Fab>
              </div>
            )} */}

          {/* </div> */}
        </Toolbar>
      </AppBar>
    </Box>
  );
}
