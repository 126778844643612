import React, { useContext, useState, useEffect, createContext } from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Daily from "./DailyHistory";
import Weekly from "./WeeklyHistory";
import Monthly from "./MonthlyHistory";
import { useLocation, useParams } from "react-router-dom";
import { APIServices } from "./../../helpers/APIServices";
import { getToday, getTomorrow, getYesterday } from "./../../helpers/common";
import { UserContext } from "../../Layout";
import dayjs from "dayjs";
import axios from "axios";
import { DateTimePicker } from "@mui/x-date-pickers";
import DateTime from "../../Components/DateTime";
import CustomDateHistory from "./CustomDateHistory";
import SimpleBackdrop from "../../Components/Backdrop";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PartContext = createContext();

export default function History() {
  const [value, setValue] = React.useState(0);
  const [data, setData] = React.useState();
  const location = useLocation();
  const { id } = useParams();
  const [success, setSuccess] = useState(true);
  const user = useContext(UserContext);
  const selectPartID = id;
  const [startDate, setStartDate] = useState({
    value: `${dayjs().subtract(1, "d").format("YYYY-MM-DD")} 00:00:00`,
    error: false,
  });
  const [loading, setLoading] = useState(true);

  const [endDate, setEndDate] = useState();

  const getData = async (partID) => {
    const now = dayjs().toISOString();
    const yesterday = dayjs().subtract(1, "day").toISOString();

    let test = await APIServices.getPaper(yesterday, now, partID, user);
    setData(test.data);
  };
  // const paperData = APIServices.usePaper();

  const getPart = async () => {
    // const response = await APIServices.findOnePart(id);
    // const part = response.data.data;
    // let revise = [];
    // if (part.attributes.old_parts.data.length > 0) {
    //   revise = part.attributes.old_parts.data.map((old_part) => old_part);
    // }
    // const reviseList = [...revise, part];
    getData(selectPartID);
    setLoading(false)
  };

  useEffect(() => {
    if (success) {
      // getData();
      setSuccess(false);
      getPart();
    }
  }, [success]);
  // if (paperData.isLoading) {
  //   return <>Loading</>;
  // }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <SimpleBackdrop open={loading} setOpen={setLoading} />

      <Box sx={{ width: "100%" }}>
        {/* <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            {/* <Tab label="24 ชั่วโมง" {...a11yProps(0)} />
            <Tab label="7 วัน" {...a11yProps(1)} />
            <Tab label="1 เดือน" {...a11yProps(2)} /> 
            <Tab label="กำหนดเอง" {...a11yProps(3)} />
          </Tabs>
        </Box> */}
        {data && (
          <CustomDateHistory partData={data.data} setSuccess={setSuccess} />
        )}
        {/*<>
             <TabPanel value={value} index={0}>
              <Daily partData={data.data} setSuccess={setSuccess} />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Weekly partData={data.data} setSuccess={setSuccess} />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Monthly partData={data.data} setSuccess={setSuccess} />
            </TabPanel> */}
        {/*<TabPanel value={value} index={0}>
               <Monthly partData={data.data} /> 
              
            </TabPanel>
          </>*/}
      </Box>
    </div>
  );
}
