import { Close, Add, Delete } from "@mui/icons-material";
import { DialogContent, DialogTitle, Fab, IconButton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { BootstrapDialog, getBase64 } from "./FormAddImageAnswer";

function FormAddImageAnswerV2({ paper, setPaper, open, onClose, data }) {
  const clonePaper = { ...paper };
  const subcategory = clonePaper.sub_categories.find(
    (subcategory) => subcategory.subId === data.subcategoryID
  );
  const question = subcategory.questions.find(
    (question) => question.questionId === data.questionID
  );
  const [showImages, setShowImages] = useState([]);

  const setupImages = async () => {
    const images = Array.from(question.imageURL[data.indexAnswer]);
    const arrImageShow = [];
    for (let i = 0; i < images.length; i++) {
      const imagePerLoop = images[i];
      const parsedImage = await getBase64(imagePerLoop);
      arrImageShow.push(parsedImage);
    }
    setShowImages(arrImageShow);
  };

  const handleOnChangeImage = (e) => {
    try {
      const files = Array.from(e.target.files);

      files.forEach((file) => {
        question.imageURL[data.indexAnswer].push(file);
      });
      setPaper(clonePaper);
      // question.imageURl[data.indexAnswer].push(files)

      // setPaper(prev => ({
      //     ...prev,
      //     sub
      // }))
      // question
    } catch (error) {
      console.error(Error(error).stack);
    }
  };

  const handleDeleteImage = (indexShowImage) => {
    question.imageURL[data.indexAnswer].splice(indexShowImage, 1);
    setPaper(clonePaper);
  };

  useEffect(() => {
    setupImages();
  }, [paper]);

  return (
    <div>
      <BootstrapDialog open={open} onClose={onClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          เพิ่มรูปภาพ
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          <div className={`flex flex-wrap gap-4`}>
            {paper &&
              showImages.length > 0 &&
              showImages.map((showImage, indexShowImage) => (
                <div className={`relative`} key={`image${indexShowImage}`}>
                  <div
                    className={`absolute -top-4 -right-4`}
                    onClick={() => handleDeleteImage(indexShowImage)}
                  >
                    <Fab
                      color="error"
                      variant="contained"
                      component="label"
                      size="small"
                    >
                      <Delete />
                    </Fab>
                  </div>
                  <div
                    className={`overflow-hidden w-[200px] h-[200px] border-2 border-blue-500 rounded-md`}
                  >
                    <img src={showImage} />
                  </div>
                </div>
              ))}
            <AddPhotoButton onChange={(e) => handleOnChangeImage(e)} />
          </div>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}

export default FormAddImageAnswerV2;

const AddPhotoButton = ({ onChange }) => {
  const inputFileRef = useRef();

  const handleOnClick = () => {
    inputFileRef.current.click();
  };
  return (
    <div
      onClick={handleOnClick}
      className={`cursor-pointer rounded-md border-dashed border-2 border-blue-500 bg-blue-100 hover:bg-blue-200 w-[200px] h-[200px] flex items-center justify-center`}
    >
      <input
        type="file"
        hidden
        multiple
        onChange={onChange}
        ref={inputFileRef}
        // onChange={(e) =>
        //     handleChangeFile(e, index.question_index, index.sub_index)
        // }
      />
      <Add sx={{ fontSize: 50, color: "rgb(59 130 246)" }} />
    </div>
  );
};
