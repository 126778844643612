import React, { useEffect, useState, useContext } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { APIServices } from '../helpers/APIServices'
import config from '../config'
import NoPhotographyIcon from  '@mui/icons-material/NoPhotography'
import axios from 'axios'
import { motion } from 'framer-motion'
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import MoreVert from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Edit from '@mui/icons-material/Edit';
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import EditSubcategory from './EditSubcategory'
import _ from 'lodash'
import EditQuestion from './EditQuestion'
import Button from '@mui/material/Button'
import Add from '@mui/icons-material/Add'
import Delete from '@mui/icons-material/Delete';
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import EditPart from './EditPart'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import RotateRight from '@mui/icons-material/RotateRight'
import Refresh from '@mui/icons-material/Refresh'
import TextField from '@mui/material/TextField'
import { UserContext } from '../Layout'
import { sortSignature } from '../helpers/common'
import dayjs from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import CheckCircle from '@mui/icons-material/CheckCircle';

const RevisePart = () => {
    const user = useContext(UserContext);
    const params = useParams()
    const [originPart, setOriginPart] = useState()
    const [part, setPart] = useState()
    const [categories, setCategories] = useState([])
    const [imageBroken, setImageBroken] = useState(true)
    const [isOpen, setIsOpen] = useState(true)
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const [isHover, setIsHover] = useState(false)
    const [dialogSubCategory, setDialogSubCategory] = useState({
        open: false
    })
    const [dialogQuestion, setDialogQuestion] = useState({
        open: false
    })
    const [subcategoryIndex, setSubcategoryIndex] = useState()
    const [questionoIndex, setQuestionoIndex] = useState()
    const [mode, setMode] = useState("edit")
    const [dialogConfirmRevision, setDialogConfirmRevision] = useState({
        open: false,
    })
    const [newQuestionId, setNewQuestionId] = useState([])
    const [dialogError, setDialogError] = useState({
        open: false,
        message: '',
    })
    const [image, setImage] = useState({
        base: null,
        file: null,
    })
    const [revisionRecode, setRevisionRecode] = useState('')
    const [reviseNo, setReviseNo] = useState('')
    const [signature, setSignature] = useState()
    const [dateRevise, setDateRevise] = useState(dayjs())
    const [timeRevise, setTimeRevise] = useState(dayjs())
    const [dialogSuccess, setDialogSuccess] = useState({
        open: false,
    })
    const [disableConfirmButton, setDisableConfirmButton] = useState(false)

    const navigate = useNavigate()

    const variants = {
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: "-100%" },
    }
    
    const variantsQuestion = {
        open: { opacity: 1, x: 0 },
        closed: { opacity: 0, x: "100%" },
    }

    const colorCategory = (category) => {
        let background = "#000"
        let color = "#fff"
        const outlineStyle = 'solid'
        const outlineWidth = '1px'
        let outlineColor = ''
        if(category === "Appearance"){
            background = "#044389"
            color = "#fff"
        }else if(category === "Dimension"){
            background = "#FCFF4B"
            color = "#000"
            outlineColor = '#a3a3a3'
        }
        return {
            background,
            color,
            outlineStyle,
            outlineWidth,
            outlineColor,
        }
    }

    const handleClickMenu = (event, subcategory, index) => {
        setAnchorEl(event.currentTarget);
        setSubcategoryIndex(index)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null);
    }

    const checkStatusRevise = (status_revise) => {
        if(status_revise?.toString() === "1"){
            return 'outline outline-2 outline-green-600'
        }else if(status_revise?.toString() === "2"){
            return 'outline outline-2 outline-yellow-500'
        }else if(status_revise?.toString() === "3"){
            return 'outline outline-2 outline-red-500'
        }
    }

    const handleOnChangePart = (part) => {
        console.log("part 101", part);
        setPart(prev => ({
            ...prev,
            part_name: part.partName,
            part_code: part.partCode,
        }))
    }

    const handleChangeSubCategory = (subcategory, mode) => {
        if(mode === "edit"){
            setPart(prev => {
                const clonePart = _.cloneDeep(prev)
                clonePart.sub_categories[subcategoryIndex] = subcategory
                return clonePart
            })
        }else if(mode === "add"){
            setPart(prev => {
                const clonePart = _.cloneDeep(prev)
                clonePart.sub_categories.push(subcategory)
                return clonePart
            })
        }
    }

    const handleChangeQuestion = (question, mode) => {
        if(mode === "edit"){
            setPart(prev => {
                const clonePart = _.cloneDeep(prev)
                clonePart.sub_categories[subcategoryIndex].questions[questionoIndex] = question
                return clonePart
            })
        }else if(mode === "add"){
            const fakeId = `n${newQuestionId.length}`
            setNewQuestionId(prev => [...prev, fakeId])
            setPart(prev => {
                const clonePart = _.cloneDeep(prev)
                clonePart.sub_categories[subcategoryIndex].questions.push({
                    ...question,
                    id: fakeId,
                })
                return clonePart
            })
        }
    }

    const handleOnClickQuestion = (question, index) => {
        setDialogQuestion(prev => ({...prev,  open: true}))
        setQuestionoIndex(index)
    }

    const handleOnClickAddSubcategory = () => {
        setDialogSubCategory({
            open: true,
        })
        setSubcategoryIndex(undefined)
        setMode("add")
    }

    const handleOnClickAddQuestion = () => {
        setDialogQuestion(prev => ({
            ...prev,
            open: true,
        }))
        setQuestionoIndex(undefined)
        setMode("add")
    }

    const validatePart = (part) => {
        if(!part?.part_name){
            throw Error("กรุณากรอกข้อมูลให้ครบถ้วน (Part name)")
        }
        if(!part?.part_code){
            throw Error("กรุณากรอกข้อมูลให้ครบถ้วน (Part code)")
        }
        if(revisionRecode === ""){
            throw Error("กรุณากรอก Revision record")
        }
        if(reviseNo === ""){
            throw Error("กรุณากรอก Revision No.")
        }
        if(!dateRevise || !timeRevise){
            throw Error("กรุณาเลือกวันเวลาที่จะทำการ Revision")
        }
    }

    const revisePart = async () => {
        setDisableConfirmButton(true)
        try {
            validatePart(part)
            const cloneSubcategoriesStatus = await cloneSubcategories(part.sub_categories)
            console.log("cloneSubcategoriesStatus", cloneSubcategoriesStatus);
            const clonePart = _.cloneDeep(part)
            delete clonePart.id
            delete clonePart.revise
            const oldParts = clonePart.old_parts ? [ ...clonePart.old_parts.map(old => old.id), originPart.id] : [originPart.id]
            const partRevise = {
                ...clonePart,
                sub_categories: cloneSubcategoriesStatus.id,
                old_parts: oldParts,
                status: false,
                revise_time: reviseNo,
                usePartForAdmin: true,
            }
            const responseClonePart = await APIServices.createPartRevise(partRevise)
            const newPartId = responseClonePart.data.data.id
            console.log("newPartId 188:", newPartId);
            if(image.file !== null){
                const formData = new FormData();
                formData.append("ref", "api::qc-part.qc-part");
                formData.append("refId", newPartId);
                formData.append("field", "image");
                formData.append("files", image.file);
                await APIServices.addUserImage(formData);
            }
            const parseDateRevise = dayjs(dateRevise).format('YYYY-MM-DD')
            const parseTimeRevise = dayjs(timeRevise).format('HH:mm:ss')
            const timestamp = dayjs(`${parseDateRevise} ${parseTimeRevise}`).toISOString()
            const responseCreateRevise = await APIServices.createRevise({
                release_signature: signature.url,
                date: timestamp,
                revise: reviseNo,
                release_id: user.id,
                revisionRecord: revisionRecode,
                revise_status: '1',
                part: newPartId,
            })
            console.log("responseCreateRevise", responseCreateRevise.data);
            const responseUpdatePartStatus = await APIServices.updateStatusPart(originPart.id, false, false)
            console.log("responseUpdatePartStatus", responseUpdatePartStatus.data);
            setDialogConfirmRevision(prev => ({
                ...prev,
                open: false,
            }))
            setDialogSuccess(prev => ({
                ...prev,
                open: true,
            }))
            setTimeout(() => {
                setDialogSuccess(prev => ({
                    ...prev,
                    open: false,
                }))
                navigate(-1)
            }, 3000);
        } catch (error) {
            console.warn("revisePart", error)
            setDialogError(prev => ({
                ...prev,
                open: true,
                message: `${error}`
            }))
        } finally {
            setDisableConfirmButton(false)
        }
    }

    const cloneQuestions = async (question) => {
        try {
            delete question.id
            console.log("question 163:", question);
            const cloneQuestion = _.cloneDeep(question)
            // if(cloneQuestion.formula && cloneQuestion.formula !== ""){
            if(!_.isEmpty(cloneQuestion.formula)){
                cloneQuestion.formula = JSON.stringify({
                    data: cloneQuestion.formula,
                })
            }else{
                cloneQuestion.formula = {}
            }
            console.log("cloneQuestion", cloneQuestion);
            if(cloneQuestion.status_revise !== "0"){
                cloneQuestion.revise_time = reviseNo
            }
            // revise_time: part.revise_time + 1,
            const responseQuestion = await APIServices.createQuestionRevise({
                ...cloneQuestion,
            })
            console.log("responseQuestion 164:", responseQuestion.data.data);
            return {
                status: true,
                id: responseQuestion.data.data.id,
            }
        } catch (error) {
            console.error('cloneQuestions', error)
            return {
                status: false,
                message: error
            }
        }
    }

    const cloneSubcategories = async (subcategories) => {
        try {
            console.log("subcategories", subcategories);
            const subCategoryId = []
            const keepQuestionId = []
            const questionHasFormula = []
            for (let i = 0; i < subcategories.length; i++) {
                const subcategory = subcategories[i];
                // when found status 3 (delete) continue to other subcategory - sawanon:20231117
                delete subcategory.id
                console.log("subcategory", subcategory);
                const newQuestionId = []
                let statusSubcategory = true
                for(let j = 0; j < subcategory.questions.length; j++){
                    if(subcategory.status_revise === "3"){
                        statusSubcategory = false
                        break
                    }
                    const question = subcategory.questions[j]
                    console.log("question.formula 336", question, question.formula, !_.isEmpty(question.formula))
                    if(!_.isEmpty(question.formula)){
                        const cloneQuestion = _.cloneDeep(question)
                        questionHasFormula.push(cloneQuestion)
                    }
                    const oldQuestionId = question.id
                    const cloneQuestionsStatus = await cloneQuestions(question)
                    if(cloneQuestionsStatus.status === false){
                        throw Error('create qiestion failed')
                    }
                    keepQuestionId.push({
                        newId: cloneQuestionsStatus.id,
                        oldId: oldQuestionId,
                    })
                    console.log("question id", cloneQuestionsStatus.id);
                    newQuestionId.push(cloneQuestionsStatus.id)
                }
                if(subcategory.status_revise !== "0"){
                    subcategory.revise_time = reviseNo
                }
                const cloneSubcategoryStatus = await APIServices.createSubcategoryRevise({
                    ...subcategory,
                    questions: newQuestionId,
                    // revise_time: part.revise_time + 1,
                    status: statusSubcategory,
                })
                console.log("cloneSubcategoryStatus 198:", cloneSubcategoryStatus);
                subCategoryId.push(cloneSubcategoryStatus.data.data.id)
            }
            console.log("keepQuestionId", keepQuestionId);
            await updateFormulaId(keepQuestionId, questionHasFormula)
            return {
                id: subCategoryId,
            }
        } catch (error) {
            console.error(error)
            return false
        }
    }

    const updateFormulaId = async (keepQuestionId, questionHasFormula) => {
        console.log("questionHasFormula", questionHasFormula);
        for (let i = 0; i < questionHasFormula.length; i++) {
            const question = questionHasFormula[i];
            const questionId = keepQuestionId.find(keepQuestionId => keepQuestionId.oldId.toString() === question.id.toString())
            let formula = question.formula.data ? question.formula.data.toString() : question.formula.toString()
            const arrId = formula.match(/{.*?}/g);
            console.log("arrFormula", arrId);
            arrId.forEach(idBackket => {
                const idRemoveBackket = idBackket.substring(1, idBackket.length - 1)
                console.log("idRemoveBackket", idRemoveBackket);
                const foundQuestionId = keepQuestionId.find(keepQuestionId => keepQuestionId.oldId.toString() === idRemoveBackket.toString())
                console.log("foundQuestionId", foundQuestionId);
                if(!foundQuestionId){
                    throw Error(`not found oldId ${idRemoveBackket} in keepQuestionId`)
                }
                formula = formula.replace(idBackket, `{${foundQuestionId.newId}}`)
            })
            console.log("formula", formula);
            const responseUpdateFormulaQuestion = await APIServices.updateFormulaInQuestion(questionId.newId, formula)
            console.log("responseUpdateFormulaQuestion 253:", responseUpdateFormulaQuestion.data);
        }
    }

    const handleOnClickDeleteSubcategory = () => {
        console.log("subcategoryIndex 312:", subcategoryIndex);
        setSubcategoryIndex(undefined)
        setPart(prev => {
            const clonePart = _.cloneDeep(prev)
            clonePart.sub_categories[subcategoryIndex].status_revise = '3'
            return clonePart
        })
    }

    const clearImage = () => {
        setImage({
            base: null,
            file: null,
        })
    }

    const getData = async () => {
        const signature = sortSignature(user.signature)
        setSignature(signature[0])
        clearImage()
        const response = await APIServices.getDetailPart(params.id)
        console.log("data", response.data);
        const part = {
            ...response.data,
            sub_categories: response.data.sub_categories.filter(subcategories => subcategories.status)
            .map(subcategory => ({
                ...subcategory,
                status_revise: '0',
                questions: subcategory.questions.filter(question => question.status)
                .map(question => ({
                    ...question,
                    status_revise: '0',
                }))
            })),
        }
        setPart(part)
        setOriginPart(part)
        setSubcategoryIndex(undefined)
        setQuestionoIndex(undefined)
        const responseImage = await axios({
            url: `${config.server}${response.data.image?.url}`
        })
        if(responseImage.status === 200){
            setImageBroken(false)
        }
        
    }

    const handleOnChangeRevisionRecord = (e) => {
        const value = e.target.value
        setRevisionRecode(value)
    }
    
    const handleOnChangeReviseNo = (e) => {
        const value = e.target.value
        setReviseNo(value)
    }

    const handleOnChangeDateRevise = (date) => {
        const value = dayjs(date)
        setDateRevise(value)
    }

    const handleOnChangeTimeRevise = (date) => {
        const value = dayjs(date)
        setTimeRevise(value)
    }

    const handleOnCloseSnackbar = () => {
        setDialogError(prev => ({
            ...prev,
            open: false,
        }))
    }
    
    useEffect(() => {
        getData()
        const getCategories = async () => {
            const response = await APIServices.getCategories()
            // console.log("response category", response.data)
            setCategories(response.data.data.map(d => ({
                id: d.id,
                category: d.attributes.category,
            })))
        }
        getCategories()
    }, [params.id])
    
  return (
    <div className={`absolute inset-0 flex flex-col justify-between`}>
        <Dialog
            open={dialogSuccess.open}
            fullWidth
            maxWidth={`sm`}
        >
            <div className={`flex flex-col items-center p-4`}>
                <CheckCircle
                    sx={{
                        fontSize: '10rem',
                        color: '#216d2e',
                    }}
                />
                <div className={`mt-6 text-2xl`}>
                    การ Revision สำเร็จ
                </div>
            </div>
        </Dialog>
        <Snackbar
            open={dialogError.open}
            autoHideDuration={5000}
            onClose={handleOnCloseSnackbar}
            anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        >
            <Alert
                severity='error'
            >
                {`${dialogError.message}`}
            </Alert>
        </Snackbar>
        {dialogConfirmRevision.open&&
            <Dialog
                open={dialogConfirmRevision.open}
                fullWidth
                maxWidth={`sm`}
            >
                <DialogTitle>
                    <div>
                        ยืนยันการ <span className={`underline text-orange-400`}>Revision</span>
                    </div>
                </DialogTitle>
                <DialogContent>
                    <div>
                        <div className={`flex mt-4`}>
                            <div className={`text-gray-400 min-w-[150px]`}>Part:</div>
                            <div>{`${part?.part_name}`}</div>
                        </div>
                        <div className={`mt-4 flex`}>
                            <div className={`text-gray-400 min-w-[150px]`}>Part code:</div>
                            <div>{`${part?.part_code}`}</div>
                        </div>
                        <div className={`mt-4 flex items-center`}>
                            <div className={`text-gray-400 min-w-[150px]`}>Revision record:</div>
                            <TextField
                                placeholder={`intial part`}
                                size='small'
                                value={revisionRecode}
                                onChange={handleOnChangeRevisionRecord}
                            />
                        </div>
                        <div className={`mt-4 flex items-center`}>
                            <div className={`text-gray-400 min-w-[150px]`}>Revise No.:</div>
                            <TextField
                                placeholder={`${parseInt(part?.revise_time)+1}`}
                                size='small'
                                type='number'
                                value={reviseNo}
                                onChange={handleOnChangeReviseNo}
                            />
                        </div>
                        <div className={`mt-4 flex items-center`}>
                            <div className={`text-gray-400 min-w-[150px]`}>Revise Date:</div>
                            <DatePicker
                                slotProps={{
                                    textField: {
                                        size: 'small'
                                    }
                                }}
                                value={dateRevise}
                                onChange={handleOnChangeDateRevise}
                            />
                        </div>
                        <div className={`mt-4 flex items-center`}>
                            <div className={`text-gray-400 min-w-[150px]`}>Revise time:</div>
                            <TimePicker
                                ampm={false}
                                slotProps={{
                                    textField: {
                                        size: 'small'
                                    }
                                }}
                                value={timeRevise}
                                onChange={handleOnChangeTimeRevise}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='outlined'
                        onClick={() => {
                            setDialogConfirmRevision(prev => ({
                                ...prev,
                                open: false,
                            }))
                        }}
                    >
                        ยกเลิก
                    </Button>
                    <Button
                        variant='contained'
                        disabled={disableConfirmButton}
                        onClick={revisePart}
                    >
                        ยืนยัน
                    </Button>
                </DialogActions>
            </Dialog>
        }
        {dialogSubCategory.open&&
            <EditSubcategory
                open={dialogSubCategory.open}
                onClose={() => {
                    setDialogSubCategory(prev => ({...prev, open: false}))
                    setMode("edit")
                }}
                categories={categories}
                // subcategory={selectedSubcategory}
                subcategory={part.sub_categories[subcategoryIndex]}
                onConfirm={handleChangeSubCategory}
                mode={mode}
            />
        }
        {dialogQuestion.open &&
            <EditQuestion
                open={dialogQuestion.open}
                onClose={() => {
                    setDialogQuestion(prev => ({...prev, open: false}))
                    setMode("edit")
                }}
                question={part.sub_categories[subcategoryIndex].questions[questionoIndex]}
                partID={params.id}
                onConfirm={handleChangeQuestion}
                mode={mode}
                subcategories={part.sub_categories}
            />
        }
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleCloseMenu}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem
                onClick={() => {
                    setDialogSubCategory({
                        open: true,
                    })
                    handleCloseMenu()
                }}
            >
                <ListItemIcon>
                    <Edit fontSize="small" />
                </ListItemIcon>
                <ListItemText>แก้ไข</ListItemText>
            </MenuItem>
            <MenuItem
                onClick={() => {
                    handleOnClickDeleteSubcategory()
                    handleCloseMenu()
                }}
            >
                <ListItemIcon>
                    <Delete fontSize="small" />
                </ListItemIcon>
                <ListItemText>ลบ</ListItemText>
            </MenuItem>
        </Menu>
        <div className={`flex flex-grow`}>
            <EditPart
                part={part}
                imageBroken={imageBroken}
                onConfirm={handleOnChangePart}
                image={image}
                setImage={setImage}
            />
            {part&&
                <div className={`px-2 pb-2 flex-grow overflow-x-hidden overflow-y-auto relative`}>
                    {/* Subcategory start */}
                    <motion.nav
                        // variants={variants}
                        variants={variants}
                        animate={isOpen ? `open` : 'closed'}
                    >
                        <div
                            className={`absolute w-full`}
                        >
                            <div className={`flex justify-between`}>
                                <span className={`text-3xl font-bold`}>
                                    Categories
                                </span>
                                <div className={`flex gap-4`}>
                                    <Button
                                        variant='contained'
                                        onClick={getData}
                                    >
                                        <Refresh />
                                    </Button>
                                    <Button
                                        variant='contained'
                                        onClick={handleOnClickAddSubcategory}
                                    >
                                        <Add />
                                    </Button>
                                </div>
                            </div>
                            <div className={`space-y-2 mt-4`}>
                                {part.sub_categories.filter(d => d.status).map((subcategory, index) => (
                                    <div
                                        className={`flex items-center justify-between drop-shadow bg-white hover:bg-gray-50 cursor-pointer py-2 px-4 rounded-lg ${checkStatusRevise(subcategory.status_revise)}`} key={`part${index}`}
                                        onClick={() => {
                                            if(!anchorEl && !isHover){
                                                setIsOpen(false)
                                                setSubcategoryIndex(index)
                                            }
                                        }}
                                    >
                                        <div className={`text-lg`}>{subcategory.sub_categories}</div>
                                        <div className={`flex gap-2 items-center`}>
                                            {subcategory.questions.some(question => question.sc_status)&&
                                                <div className={`bg-blue-600 text-white w-8 h-8 rounded-full flex justify-center items-center`}>SC</div>
                                            }
                                            <div
                                                className={`w-max rounded-lg h-max py-1 px-2 flex items-center text-sm`}
                                                style={{
                                                    ...colorCategory(subcategory.category.category),
                                                }}
                                            >
                                                {`${subcategory.category.category}`}
                                            </div>
                                            <IconButton
                                                id="basic-button"
                                                aria-controls={openMenu ? 'basic-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openMenu ? 'true' : undefined}
                                                onClick={(e) => handleClickMenu(e, subcategory, index)}
                                                onMouseEnter={() => setIsHover(true)}
                                                onMouseLeave={() => setIsHover(false)}
                                            >
                                                <MoreVert />
                                            </IconButton>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </motion.nav>
                    {/* Subcategory end */}
                    {/* Question start */}
                    <motion.nav
                        animate={isOpen ? `closed` : `open`}
                        variants={variantsQuestion}
                    >
                        <div
                            className={``}
                        >
                            <div className={`flex items-center justify-between`}>
                                <div className={`flex gap-2 items-center`}>
                                    <div
                                        className={`flex justify-center items-center bg-blue-200 w-9 h-9 rounded-full cursor-pointer hover:bg-blue-400`}
                                        onClick={() => setIsOpen(true)}
                                    >
                                        <ArrowBackIosNew fontSize='14' />
                                    </div>
                                    <div>
                                        <span className={`text-3xl font-bold`}>Questions</span>
                                    </div>
                                </div>
                                <div>
                                    <Button
                                        variant='contained'
                                        onClick={handleOnClickAddQuestion}
                                    >
                                        <Add />
                                    </Button>
                                </div>
                            </div>
                            {/* {selectedSubcategory && selectedSubcategory.questions.map((question, index) => ( */}
                            <div className={`mt-4 space-y-2`}>
                                {subcategoryIndex !== undefined && part.sub_categories[subcategoryIndex].questions.map((question, index) => (
                                    <div
                                        className={`flex items-center justify-between bg-white hover:bg-gray-50 cursor-pointer drop-shadow py-2 px-4 rounded-lg text-lg text-black text-opacity-80 ${checkStatusRevise(question.status_revise)}`} key={`question${index}`}
                                        onClick={() => handleOnClickQuestion(question, index)}
                                    >
                                        <div>
                                            {`${question.question}`}
                                        </div>
                                        <div>
                                            {question.sc_status&&
                                                <div className={`bg-blue-600 text-white w-8 h-8 rounded-full flex justify-center items-center`}>SC</div>
                                            }
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </motion.nav>
                    {/* Question end */}
                </div>
            }
        </div>
        <div className={`flex justify-end pt-2 px-2 pb-4`}>
            <Button
                variant='contained'
                onClick={() => {
                    setDialogConfirmRevision(prev => ({
                        ...prev,
                        open: true,
                    }))
                }}
                endIcon={<RotateRight />}
                sx={{
                    background: '#FB8F23',
                    color: 'rgba(0, 0, 0, 0.8)',
                    ":hover": {
                        background: '#F07A05'
                    }
                }}
            >
                revision
            </Button>
        </div>
    </div>
  )
}

export default RevisePart