import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

function createData(name, xBar, rChart) {
  return { name, xBar, rChart };
}
export default function TableControlLimit({ data, partData }) {
  const rows = [
    createData("UCL", `${partData.UCL_Xbar}`, `${partData.UCL_Rchart}`),
    createData("CL", `${partData.CL_Xbar}`, `${partData.CL_Rchart}`),
    createData("LCL", `${partData.LCL_Xbar}`, `${partData.LCL_Rchart}`),
  ];

  return (
    <TableContainer component={Paper}>
      <Table aria-label="caption table">
        <TableHead>
          <TableRow>
            <TableCell></TableCell>
            <TableCell align="center">X bar</TableCell>
            <TableCell align="center">R chart</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="center">{row.xBar}</TableCell>
              <TableCell align="center">{row.rChart}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
