import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";
import { APIServices } from "../../helpers/APIServices";
import Chip from "@mui/material/Chip";
import { useParams, useLocation } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Button from "@mui/material/Button";
import { BootstrapDialog, getBase64 } from "./FormAddImageAnswer";
import Stack from "@mui/material/Stack";
import config from "../../config";
import MuiAlert from "@mui/material/Alert";
import dayjs from "dayjs";
import { DialogContent } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import DialogActions from "@mui/material/DialogActions";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";

export const checkMinMaxOK = (min, max, value) => {
  if (value === "") {
    return false;
  }
  const floatValue = parseFloat(value);
  if (floatValue >= min && floatValue <= max) {
    return true;
  } else {
    return false;
  }
};
export const checkMinMaxNG = (min, max, value) => {
  if (value === "") {
    return false;
  }
  const floatValue = parseFloat(value);
  if (floatValue >= min && floatValue <= max) {
    return false;
  } else {
    return true;
  }
};

export const searchSubcategoriesName = (subCategories, subID) => {
  const result = subCategories.find((subData) => subData.id === subID);
  const subCategoriesName = result.attributes.sub_categories || "";
  const categoryName =
    result.attributes.category.data.attributes.category || "";
  const questions = result.attributes.questions.data || [];
  return {
    subCategoriesName,
    categoryName,
    questions,
  };
};

export function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

function EditPaper() {
  const param = useParams();
  const dataFromLocation = useLocation();
  const [paper, setPaper] = useState();
  // const [answer, setAnswer] = useState([]); //for edit answer data
  const [openEditPhoto, setOpenEditPhoto] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();

  const getPaperByID = async () => {
    const res = await APIServices.findOnePaper(param.idPaper);
    const result = res.data;
    if (result.data) {
      const answer = result.data.attributes.answer;
      // setAnswer(answer);
      setPaper(result.data.attributes);
    }
  };

  const updatePaper = async (answer) => {
    const response = await APIServices.updatePaper(answer, param.idPaper);
  };

  useEffect(() => {
    getPaperByID();
    // getSubCategories();
  }, []);

  const SubCategories = ({ subCategories, subCatPerLoop, subCatIndex }) => {
    const subData = searchSubcategoriesName(subCategories, subCatPerLoop.subId);
    return (
      <div
        key={`paper${subCatPerLoop.subId}`}
        className={`bg-white p-4 mb-4 flex w-full flex-col rounded-md shadow-md`}>
        <div className='flex flex-row pb-4'>
          <div className='basis-1/6  flex items-center rounded-full text-xl'>
            <Chip label={subData.categoryName} variant='outlined' />
          </div>
          <div className='basis-1/2  flex items-center text-xl font-semibold'>
            {subData.subCategoriesName}
          </div>
        </div>
        <hr />
        <div>
          {subCatPerLoop.questions.map((question, index) => (
            <QuestionContent
              key={`question${index}`}
              question={question}
              questionsPerSub={subData.questions}
              questionIndex={index}
              subCatIndex={subCatIndex}
            />
          ))}
        </div>
      </div>
    );
  };

  const QuestionContent = ({
    question,
    questionsPerSub,
    questionIndex,
    subCatIndex,
  }) => {
    const resultQuestion = questionsPerSub.find(
      (questionPerLoop) => questionPerLoop.id === question.questionId
    );
    const questionName = resultQuestion.attributes.question || "";
    const min = resultQuestion.attributes.spec_min || null;
    const max = resultQuestion.attributes.spec_max || null;
    const sc_status = resultQuestion.attributes.sc_status;
    return (
      <div>
        <div className='text-lg mt-4 flex flex-row items-center'>
          {questionName}
          {min !== null && max !== null && (
            <div className='ml-2'>
              {`${parseFloat(min).toFixed(2)} - ${parseFloat(max).toFixed(2)}`}
            </div>
          )}
          {sc_status && (
            <div className='ml-2'>
              <Chip label='SC' color='primary' variant='outlined' />
            </div>
          )}
        </div>
        <div className='mt-2 mb-2  '>
          {
            // (sc_status || Array.isArray(question.answer)) ? (
            new Array(3).fill(0).map((d, index) => {
              return (
                <div key={`form${index}`} className='flex flex-col mt-4'>
                  <FormQuestion
                    questionMaster={resultQuestion.attributes}
                    questionData={question}
                    indexAnswer={index}
                    questionIndex={questionIndex}
                    subCatIndex={subCatIndex}
                  />
                </div>
              );
            })
            // ) : (
            //   <div className="flex flex-col mt-4">
            //     <FormQuestion
            //       questionMaster={resultQuestion.attributes}
            //       questionData={question}
            //       questionIndex={questionIndex}
            //       subCatIndex={subCatIndex}
            //     />
            //   </div>
            // )
          }
        </div>
      </div>
    );
  };

  const FormQuestion = ({
    questionMaster,
    questionData,
    indexAnswer,
    questionIndex,
    subCatIndex,
  }) => {
    return (
      <div className='flex flex-row items-center'>
        <div>
          {/* <div>{`questionIndex ${questionIndex}`}</div>
          <div>{`subCatIndex ${subCatIndex}`}</div> */}
          {/* <pre>{JSON.stringify(questionData)}</pre> */}
        </div>
        <FormControl>
          {questionMaster.spec_min !== null &&
          questionMaster.spec_max !== null ? (
            <RadioGroup row>
              <FormControlLabel
                value={`OK`}
                control={
                  <Radio
                    disabled
                    checked={checkMinMaxOK(
                      questionMaster.spec_min,
                      questionMaster.spec_max,
                      // questionMaster.sc_status
                      questionData.actual[indexAnswer]
                      // : questionData.actual
                    )}
                  />
                }
                label='OK'
              />
              <FormControlLabel
                value={`NG`}
                control={
                  <Radio
                    disabled
                    checked={checkMinMaxNG(
                      questionMaster.spec_min,
                      questionMaster.spec_max,
                      // questionMaster.sc_status
                      questionData.actual[indexAnswer]
                      // : questionData.actual
                    )}
                  />
                }
                label='NG'
              />
            </RadioGroup>
          ) : (
            <RadioGroup row>
              <FormControlLabel
                value={`OK`}
                checked={questionData.answer[indexAnswer] === "OK"}
                control={<Radio />}
                label='OK'
              />
              <FormControlLabel
                value={`NG`}
                checked={questionData.answer[indexAnswer] === "NG"}
                control={<Radio />}
                label='NG'
              />
            </RadioGroup>
          )}
        </FormControl>
        <Box
          component={"form"}
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete='off'>
          {questionMaster.spec_min !== null &&
            questionMaster.spec_max !== null && (
              <TextField
                id='outlined-number'
                label='Actual'
                type='number'
                size='small'
                defaultValue={
                  // questionMaster.sc_status
                  isNaN(parseFloat(questionData.actual[indexAnswer]))
                    ? ""
                    : parseFloat(questionData.actual[indexAnswer])
                  // : parseFloat(questionData.actual)
                }
                // value={
                //   questionMaster.sc_status
                //     ? parseFloat(questionData.actual[indexSC])
                //     : parseFloat(questionData.actual)
                // }
                onBlur={
                  (e) =>
                    // questionMaster.sc_status
                    handleOnChange(
                      "actual",
                      e,
                      questionIndex,
                      subCatIndex,
                      questionMaster.sc_status,
                      indexAnswer
                    )
                  // : handleOnChange(
                  //     "actual",
                  //     e,
                  //     questionIndex,
                  //     subCatIndex,
                  //     questionMaster.sc_status
                  //   )
                }
                // value={text}
                // onChange={(e) => setText(e.target.value)}
              />
            )}
          <TextField
            id='outlined-number'
            label='Comment'
            type='text'
            size='small'
            defaultValue={
              // questionMaster.sc_status
              // ? questionData.comment
              questionData.comment[indexAnswer]
              //   : ""
              // : questionData.comment
            }
            onBlur={
              (e) =>
                // questionMaster.sc_status
                handleOnChange(
                  "comment",
                  e,
                  questionIndex,
                  subCatIndex,
                  questionMaster.sc_status,
                  indexAnswer
                )
              // : handleOnChange(
              //     "comment",
              //     e,
              //     questionIndex,
              //     subCatIndex,
              //     questionMaster.sc_status
              //   )
            }
          />
        </Box>
        <div className='flex flex-col max-h-fit justify-end items-center'>
          <Fab
            onClick={() =>
              handleOnClickEditPhoto(
                questionData,
                subCatIndex,
                questionIndex,
                questionMaster,
                indexAnswer
              )
            }
            color='primary'
            variant='contained'
            component='label'>
            {questionData.imageURL[indexAnswer].length > 0 ? (
              `${questionData.imageURL[indexAnswer].length} รูป`
            ) : (
              <AddAPhotoIcon />
            )}
          </Fab>

          {/* {indexAnswer
            ? questionData.imageURL &&
              questionData.imageURL[indexAnswer].length !== 0 && (
                <div className="bg-orange-400 shadow-sm rounded-full p-1 pr-2 pl-2 text-white mt-1 ">
                  {questionData.imageURL[indexAnswer].length + " รูป"}
                </div>
              )
            : questionData.imageURL && (
                <div className="bg-orange-400 shadow-sm rounded-full p-1 pr-2 pl-2 text-white mt-1">
                  {questionData.imageURL.length + " รูป"}
                </div>
              )} */}
        </div>
      </div>
    );
  };

  const handleOnClickEditPhoto = (
    questionData,
    subCatIndex,
    questionIndex,
    questionMaster,
    indexAnswer
  ) => {
    setOpenEditPhoto(true);
    setSelectedQuestion({
      questionData,
      subCatIndex,
      questionIndex,
      questionMaster,
      indexAnswer,
    });
  };

  const EditPhoto = ({ open, setOpen, selectedQuestion, setPaper, paper }) => {
    const handleAddFile = async (e, selectedQuestion) => {
      const files = Array.from(e.target.files);
      const subCatIndex = selectedQuestion.subCatIndex;
      const questionIndex = selectedQuestion.questionIndex;
      const clonePaper = { ...paper };

      const cloneQuestion = {
        ...clonePaper.answer.sub_categories[subCatIndex].questions[
          questionIndex
        ],
      };
      if (selectedQuestion.questionMaster.sc_status) {
        console.error("SC ! please dev");
      } else {
        if (cloneQuestion.imageURL) {
        } else {
          const images = [];
          for (let i = 0; i < files.length; i++) {
            const base64 = await getBase64(files[i]);
            images.push({
              base: base64,
              file: files[i],
            });
          }
          cloneQuestion.imageURL = images;
        }
      }
      clonePaper.answer.sub_categories[subCatIndex].questions[questionIndex] =
        cloneQuestion;
      // setPaper()
    };

    return (
      <div>
        <BootstrapDialog onClose={() => setOpen(false)} open={open}>
          <BootstrapDialogTitle onClose={() => setOpen(false)}>
            เพิ่มรูปภาพ
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Box
              component='form'
              sx={{
                "& .MuiTextField-root": {
                  mt: 2,
                },
              }}
              noValidate
              autoComplete='off'>
              <div className='flex flex-col'>
                <div className='mb-4'>
                  {/* <Fab color="primary" variant="contained" component="label">
                    <input
                      type="file"
                      hidden
                      multiple
                      // TODO: dev
                      // onChange={(e) => handleAddFile(e, selectedQuestion)}
                    />
                    <AddIcon />
                  </Fab> */}
                </div>
                <div className='mt-4 flex gap-4 flex-wrap'>
                  {selectedQuestion.questionData.imageURL && (
                    <ShowImages
                      imageURL={
                        selectedQuestion.questionData.imageURL[
                          selectedQuestion.indexAnswer
                        ]
                      }
                    />
                  )}
                </div>
              </div>
            </Box>
          </DialogContent>
          {/* <DialogActions>
            <Button variant="outlined" autoFocus>
              ล้างข้อมูล
            </Button>
            <Button variant="contained" autoFocus>
              ยืนยัน
            </Button>
          </DialogActions> */}
        </BootstrapDialog>
      </div>
    );
  };

  const ShowImages = ({ imageURL }) => {
    return (
      <>
        {imageURL.map(
          (imageURLLoop, index) =>
            typeof imageURLLoop === "string" && (
              <div
                key={index}
                // className={`flex justify-evenly items-center ${
                //   index === 0 ? "mx-auto" : index % 2 !== 0 && "mx-auto"
                // } relative basis-1/3 `}
              >
                <div className='relative '>
                  {/* TODO: function delete image */}
                  {/* <div
                    className="absolute -top-4 -right-4 "
                    // onClick={() => handleDeleteImage(index)}
                  >
                    <Fab
                      color="error"
                      variant="contained"
                      component="label"
                      size="small"
                    >
                      <DeleteIcon />
                    </Fab>
                  </div> */}
                  <div className='overflow-hidden w-[200px] h-[200px] border-2 border-blue-500 rounded-md'>
                    <img src={`${config.server}${imageURLLoop}`} />
                  </div>
                </div>
              </div>
            )
        )}
      </>
    );
  };

  const handleOnChange = (
    propertyName,
    e,
    questionIndex,
    subCatIndex,
    sc_status,
    indexSC
  ) => {
    setPaper((prev) => {
      const cloneArrSubCat = [...prev.answer.sub_categories];
      const cloneQuestion = [...cloneArrSubCat[subCatIndex].questions];
      // if (sc_status) {
      if (cloneQuestion[questionIndex][propertyName]) {
        if (propertyName === "comment") {
          cloneQuestion[questionIndex][propertyName][indexSC] =
            e.target.value || "";
        } else {
          cloneQuestion[questionIndex][propertyName][indexSC] =
            e.target.value || 0;
        }
      } else {
        if (propertyName === "comment") {
          cloneQuestion[questionIndex][propertyName] = ["", "", ""];
          cloneQuestion[questionIndex][propertyName][indexSC] =
            e.target.value || "";
        }
      }
      // }
      // else {
      //   if (propertyName === "comment") {
      //     cloneQuestion[questionIndex][propertyName] = e.target.value || "";
      //   } else {
      //     cloneQuestion[questionIndex][propertyName] = e.target.value || 0;
      //   }
      // }
      return {
        ...prev,
        answer: {
          ...prev.answer,
          sub_categories: cloneArrSubCat,
        },
      };
    });
  };

  return (
    <>
      <div className='flex flex-col '>
        {/* <SimpleBackdrop open={loading} setOpen={setLoading} /> */}
        <div className='mb-4 flex flex-row items-center'>
          <div className=''>
            <Stack direction='row' spacing={1}>
              {/* <Chip label={`ครั้งที่: ${dataFromLocation.state.index}`} /> */}
              <Chip
                label={`วันที่: ${
                  paper && dayjs(paper.create_datetime).format("DD-MM-YYYY")
                }`}
              />
              <Chip label={`ช่วงเวลา: ${paper && paper.shift}`} />
              {/* {paper && paper.shiftAB && ( */}
              <Chip label={`กลุ่ม: ${paper && paper.shiftAB}`} />
              {/* )} */}
            </Stack>
          </div>
        </div>
        {/* <div className="bg-pink-500">
                min = 5 max = 10
                OK <input checked={answers.ok} type={'radio'} />
                NG <input checked={answers.ng} type={'radio'} />
                value <input onChange={handleOnChange} type={`number`} />
        </div> */}

        <div className=' flex justify-center items-center '>
          {paper && paper.answer.partImage !== "" ? (
            <div className=' flex justify-center items-center '>
              <img
                src={paper.answer.partImage}
                // config.server +
                className=' object-contain h-[300px]'
              />
            </div>
          ) : (
            <div
              style={{ width: "12rem", height: "12rem" }}
              className='bg-gray-100 flex items-center justify-center rounded-lg'>
              <NoPhotographyIcon sx={{ fontSize: 80 }} />
            </div>
          )}
        </div>

        <div className={``}>
          {paper ? (
            paper.answer.sub_categories.map((subCatData, subCatIndex) => (
              <SubCategories
                key={`subCat${subCatIndex}`}
                subCategories={
                  paper.partName.data.attributes.sub_categories.data
                }
                subCatPerLoop={subCatData}
                subCatIndex={subCatIndex}
              />
            ))
          ) : (
            <div>No data</div>
          )}
        </div>
        <div className={`flex justify-end`}>
          <Button
            variant='contained'
            size='large'
            onClick={() => updatePaper(paper.answer)}>
            บันทึกข้อมูล
          </Button>
        </div>
      </div>
      {openEditPhoto && (
        <EditPhoto
          open={openEditPhoto}
          setOpen={setOpenEditPhoto}
          selectedQuestion={selectedQuestion}
          setPaper={setPaper}
          paper={paper}
        />
      )}
    </>
  );
}

export default EditPaper;
