import React, { createContext, useState } from "react";
import Navbar from "./navbar";
import { Outlet } from "react-router-dom";
import { Sidebar } from "./sidebar";
import { makeStyles, styled } from "@mui/material";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { useOneUser } from "../helpers/APIServices";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Swal from "sweetalert2";
import { useRef } from "react";
import Storages from "../helpers/localStorage";

function handleClick(event) {
  event.preventDefault();
  console.info("You clicked a breadcrumb.");
}

const drawerWidth = 240;
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export const UserContext = createContext();
export const KeepPaperContext = createContext();
export const KeepPartContext = createContext();
export const ScrollContext = createContext()

function Layout() {
  const [open, setOpen] = React.useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState({
    open: false,
    type: "info",
    massage: "",
  });
  const [keepPaper, setKeepPaper] = useState();
  const [keepPart, setKeepPart] = useState();

  const user = JSON.parse(localStorage.getItem("QCAPPuser"));
  const { data, error, isLoading, mutate } = useOneUser(user.id);
  const refLayout = useRef(null)
  if (isLoading) {
    return <div>Loading...</div>;
  }
  if(error){
    console.log("error", error.response);
    return (
      <div
        className={`bg-[#FCFBF6] flex justify-center items-center`}
        style={{
          height: `100dvh`
        }}
      >
        <div>
          <img
            src="/error.jpg"
            alt=""
            className={`h-[400px]`}
          />
          <div className={`-m-6`}>
            <div className={`font-bold text-3xl text-[#3C3230] text-center`}>
              Something went wrong
            </div>
            <div
              className={`cursor-pointer hover:bg-[#E8E1D1] mt-4 uppercase font-bold text-lg border-4 border-[#3C3230] rounded-2xl flex justify-center items-center py-2 px-4`}
              onClick={() => {
                Storages.deleteStorage("QCAPPuser");
                Storages.deleteStorage("QCAPPjwt");
                Storages.deleteStorage("ESSjwt");
                navigate("/login");
              }}
            >
              Signin again
            </div>
          </div>
        </div>
      </div>
    )
  }
  console.log("data layout.jsx 63", data);
  const userData = data.data;

  const splitLocation = location.pathname.split("/");
  let path = "/layout";

  const upperCaseFirstCharacter = (data) => {
    const splitData = data.split("_").join(" ");
    const firstCharacter = splitData.substring(0, 1).toUpperCase();
    const leftCharacter = splitData.substring(1);
    return firstCharacter + leftCharacter;
  };

  return (
    <>
      <UserContext.Provider value={userData}>
        <KeepPartContext.Provider value={[keepPart, setKeepPart]}>
        <ScrollContext.Provider value={refLayout}>
          <Navbar setOpen={setOpen} open={open} />
          <div
            style={{ display: "flex", height: "calc(100vh - 64px)" }}
            className="max-w-screen overflow-x-auto"
            ref={refLayout}
          >
            <Sidebar setOpen={setOpen} open={open} />
            <Main open={open} className={`flex flex-col p-0 px-4 relative`}>
              <div role="presentation" className="my-4 flex-row">
                <Breadcrumbs aria-label="breadcrumb">
                  {splitLocation.map((d, index) => {
                    if (isNaN(parseInt(d)) && index > 1) {
                      if (isNaN(parseInt(splitLocation[index + 1]))) {
                        path += "/" + d;
                      } else {
                        path += "/" + d + "/" + splitLocation[index + 1];
                      }
                      return (
                        <Link
                          key={index}
                          underline="hover"
                          color="inherit"
                          to={path}
                        >
                          {upperCaseFirstCharacter(d)}
                        </Link>
                      );
                    }
                    // return <span key={index}></span>
                  })}
                </Breadcrumbs>
              </div>
              <div className="flex-grow relative">
                <Outlet />
              </div>
            </Main>
          </div>
        </ScrollContext.Provider>
        </KeepPartContext.Provider>
      </UserContext.Provider>
    </>
  );
}

export default Layout;
