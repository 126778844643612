import { create } from "zustand";

const useSnackbarStore = create((set) => ({
  open: false,
  autoHideDuration: 6000,
  message: "Note archived",
  type: "success",
  onClose: async () => {
    set({
      open: false,
      message: null,
    });
  },
  onOpen: async ({ msg, type }) => {
    set({
      open: true,
      message: msg,
      type: type,
    });
  },
}));

const useSuccessStore = create((set) => ({
  open: false,
  onClose: async () => {
    set({
      open: false,
    });
  },
  onOpen: async () => {
    set({
      open: true,
    });
  },
}));

export { useSnackbarStore, useSuccessStore };
