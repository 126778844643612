import React from "react";
import { useState } from "react";
import { APIServices } from "../helpers/APIServices";
import { useEffect } from "react";
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import Chip from '@mui/material/Chip'
import _ from "lodash";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import dayjs from "dayjs";
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import Button from '@mui/material/Button'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'

const Setting = () => {
  const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  
  const [setting, setSetting] = useState()
  const [dialog, setDialog] = useState({
    open: false,
    message: "",
    type: "info",
  })
  const [loading, setLoading] = useState(false)

  const getSetting = async () => {
    try {
      setLoading(true)
      const responseSetting = await APIServices.getSetting()
      const setting = {
        id: responseSetting.data.data[0].id,
        ...responseSetting.data.data[0].attributes,
      }
      console.log("setting 30:", setting);
      setSetting(setting)
      setLoading(false)
    } catch (error) {
      console.error("getSetting", error)
    }
  }

  const handleOnChangeEmail = (e, value) => {
    if(!regex.test(e.target.value)){
      setDialog(prev => ({
        ...prev,
        open: true,
        message: "กรุณากรอก email",
        type: "error",
      }))
      return
    }
    const emailOptions = value.length === 0 ? null : value
    console.log("e", e.target.value, regex.test(e.target.value));
    console.log("value", value);
    console.log("emailOptions", emailOptions);
    setSetting(prev => ({
      ...prev,
      emailDailyNG: emailOptions
    }))
  }

  const handleOnChangeTime = (value) => {
    const isMoreThan30 = dayjs(value).get('minute') > 0
    setSetting(prev => ({
      ...prev,
      timeToSendEmail: dayjs(value).set("minute", isMoreThan30 ? 30 : 0).format("HH:mm:ss"),
    }))
  }

  const handleOnDeleteEmail = (index) => {
    console.log("index", index);
    setSetting(prev => {
      const cloneSetting = _.cloneDeep(prev)
      cloneSetting.emailDailyNG.splice(index, 1)
      return cloneSetting
    })
  }

  const handleOnCloseSnackbar = () => {
    setDialog(prev => ({
      ...prev,
      open: false,
    }))
  }

  const renderTime = (time) => {
    return dayjs(`${dayjs().format("YYYY-MM-DD")} ${time}`)
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)
      const responseSubmit = await APIServices.updateSetting(setting, setting.id)
      console.log("responseSubmit", responseSubmit.data)
      setLoading(false)
      setDialog(prev => ({
        ...prev,
        open: true,
        message: "บันทึกการตั้งค่าเรียบร้อย",
        type: "success",
      }))
    } catch (error) {
      setLoading(false)
      setDialog(prev => ({
        ...prev,
        open: true,
        message: `${error}`
      }))
      console.error("handleSubmit", error)
    }
  }

  useEffect(() => {
    getSetting()
  }, [])

  return (
    <div className={`absolute inset-0`}>
      <Backdrop
        open={loading}
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          color: '#fff',
        }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Snackbar
        open={dialog.open}
        autoHideDuration={5000}
        onClose={handleOnCloseSnackbar}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
      >
        {/* error / info / success / warning */}
        <Alert
          severity={`${dialog.type}`}
        >
          {`${dialog.message}`}
        </Alert>
      </Snackbar>
      <div className={`text-2xl  font-semibold mx-auto max-w-2xl`}>
        การส่ง email report NG รายวัน
      </div>
      <div className={`mt-2 max-w-2xl border border-gray-300 p-4 mx-auto rounded-lg text-black text-opacity-80`}>
        <div className={``}>
          <Autocomplete
            fullWidth
            multiple
            options={setting?.emailDailyNG ? setting?.emailDailyNG : []}
            value={setting?.emailDailyNG ? setting?.emailDailyNG : []}
            onChange={handleOnChangeEmail}
            renderInput={params => (
              <TextField
                {...params}
                label="email"
                placeholder="example@aapico.com"
              />
            )}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  variant="outlined"
                  label={option}
                  {...getTagProps({ index })}
                  onDelete={() => handleOnDeleteEmail(index)}
                />
              ))
            }
            freeSolo
          />
        </div>
        <div className={`mt-4 flex gap-4 text-sm`}>
          <TimePicker
            label="ตั้งค่าเวลาการส่งอีเมล์"
            ampm={false}
            ampmInCloc={true}
            value={renderTime(setting?.timeToSendEmail)}
            onChange={handleOnChangeTime}
          />
          <div className={`text-gray-400`}>
            <span className={`text-red-500`}>*</span> นาทีที่ส่ง email จะต้องเป็น 00 หรือ 30 นาที
          </div>
        </div>
      </div>
      {/* <div className={`bg-red-200`}>
        {`${JSON.stringify(setting)}`}
      </div> */}
      <div className={`bg-blue-400 absolute bottom-0 right-0 mb-4`}>
        <Button
          variant="contained"
          onClick={handleSubmit}
        >
          ยืนยัน
        </Button>
      </div>
    </div>
  )
};

export default Setting;
