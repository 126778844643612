import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

// const labels = ['January', 'February'];

// export const data = {
//   labels,
//   datasets: [
//     {
//       label: 'Dataset 1',
//       data: labels.map(() => 2),
//       borderColor: 'rgb(255, 99, 132)',
//       backgroundColor: 'rgba(255, 99, 132, 0.5)',
//     },
//   ],
// };

export default function LineChart({
  arrData,
  chartName,
  arrLabel,
  dataSetName,
  controlLimit,
}) {
  const options = {
    // responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: chartName ? chartName : "Chart.js Line Chart",
      },
    },
  };

  const data = {
    labels: arrData.map((data, index) => index + 1),
    datasets: [
      {
        label: dataSetName || "dataset 1",
        data: arrData.map((data) => data),
        borderColor: "#1976D2",
        backgroundColor: "#1976D2",
      },
      {
        label: dataSetName || "UCL",
        data: arrData.map((data) => controlLimit.UCL),
        borderColor: "#d0d0d0",
        backgroundColor: "#d0d0d0",
        borderDash: [5, 5],
        pointStyle: false,
      },
      {
        label: dataSetName || "CL",
        data: arrData.map((data) => controlLimit.CL),
        borderColor: "#d0d0d0",
        backgroundColor: "#d0d0d0",
        borderDash: [5, 5],
        pointStyle: false,
      },
      {
        label: dataSetName || "LCL",
        data: arrData.map((data) => controlLimit.LCL),
        borderColor: "#d0d0d0",
        borderDash: [5, 5],
        pointStyle: false,
      },
    ],
  };
  return (
    <div className="h-80 lg:h-48 xl:h-60 lg:w-80 xl:w-full">
      <Line options={options} data={data} />
    </div>
  );
}
