import {
  Button,
  Chip,
  DialogContent,
  Fab,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import dayjs from "dayjs";
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { APIServices } from "../../helpers/APIServices";
// import {
//   BootstrapDialogTitle,
//   checkMinMaxNG,
//   checkMinMaxOK,
//   searchSubcategoriesName,
// } from "./EditPaper";
import { IconCheckNG } from "./PaperV2";
import PhotoIcon from "@mui/icons-material/Photo";
import { BootstrapDialog, getBase64 } from "./FormAddImageAnswer";
import config from "../../config";
import ConfirmModal from "../../Components/ConfirmModal";
import { UserContext } from "../../Layout";
import SignatureBox from "../../Components/SignatureBox";
import { useSnackbarStore, useSuccessStore } from "../../helpers/state";
import Snackbar from "@mui/material/Snackbar";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import MuiAlert from "@mui/material/Alert";

export const checkMinMaxOK = (min, max, value) => {
  if (value === "") {
    return false;
  }
  const floatValue = parseFloat(value);
  if (floatValue >= min && floatValue <= max) {
    return true;
  } else {
    return false;
  }
};

export const checkMinMaxNG = (min, max, value) => {
  if (value === "") {
    return false;
  }
  const floatValue = parseFloat(value);
  if (floatValue >= min && floatValue <= max) {
    return false;
  } else {
    return true;
  }
};

export const searchSubcategoriesName = (subCategories, subID) => {
  const result = subCategories.find((subData) => subData.id === subID);
  const subCategoriesName = result.attributes.sub_categories || "";
  const categoryName =
    result.attributes.category.data.attributes.category || "";
  const questions = result.attributes.questions.data || [];
  return {
    subCategoriesName,
    categoryName,
    questions,
  };
};

export function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

const EditPaperV2 = () => {
  const param = useParams();
  const [isOpenPartPhoto, setIsOpenPartPhoto] = useState(false);
  const dataFromLocation = useLocation();
  const [paper, setPaper] = useState();
  const [openEditPhoto, setOpenEditPhoto] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const user = useContext(UserContext);
  const snackBarState = useSnackbarStore();
  const navigation = useNavigate();
  const [alertPayload, setAlertPayload] = useState({
    open: false,
    type: "info",
    message: "",
    duration: 3000,
  });

  const getPaperByID = async () => {
    const response = await APIServices.findOnePaper(param.idPaper);
    const result = response.data;
    if (result.data) {
      const paper = result.data.attributes;
      setPaper(paper);
    }
  };

  const updatePaper = async (answer) => {
    const response = await APIServices.updatePaper(answer, param.idPaper);
  };

  useEffect(() => {
    getPaperByID();
  }, []);

  const ShowImages = ({ imageURL, indexSC }) => {
    return (
      <>
        {indexSC !== undefined
          ? imageURL[indexSC].map(
              (imageURLLoop, index) =>
                typeof imageURLLoop === "string" && (
                  <div
                    key={index}
                    className={`flex justify-evenly items-center ${
                      index === 0 ? "mx-auto" : index % 2 !== 0 && "mx-auto"
                    } relative basis-1/3 `}>
                    <div className='relative '>
                      <div className='w-[200px] h-[200px] overflow-hidden '>
                        <img src={`${config.server}${imageURLLoop}`} />
                      </div>
                    </div>
                  </div>
                )
            )
          : imageURL.map(
              (imageURLLoop, index) =>
                typeof imageURLLoop === "string" && (
                  <div
                    key={index}
                    className={`flex justify-evenly items-center ${
                      index === 0 ? "mx-auto" : index % 2 !== 0 && "mx-auto"
                    } relative basis-1/3 `}>
                    <div className='relative '>
                      <div className='w-[200px] h-[200px] overflow-hidden '>
                        <img src={`${config.server}${imageURLLoop}`} />
                      </div>
                    </div>
                  </div>
                )
            )}
      </>
    );
  };

  // const ViewPhoto = ({ open, setOpen, selectedQuestion, setPaper, paper }) => {
  //   return (
  //     <div>
  //       <BootstrapDialog onClose={() => setOpen(false)} open={open}>
  //         <BootstrapDialogTitle onClose={() => setOpen(false)}>
  //           รูปภาพ
  //         </BootstrapDialogTitle>
  //         <DialogContent dividers>
  //           <Box
  //             component="form"
  //             sx={{
  //               "& .MuiTextField-root": {
  //                 mt: 2,
  //               },
  //             }}
  //             noValidate
  //             autoComplete="off"
  //           >
  //             <div className="flex flex-col">
  //               <div className="mt-4 flex gap-12 flex-wrap">
  //                 {selectedQuestion.questionData.imageURL ? (
  //                   <ShowImages
  //                     imageURL={selectedQuestion.questionData.imageURL}
  //                     indexSC={selectedQuestion.indexSC}
  //                   />
  //                 ) : (
  //                   <div>ไม่มีข้อมูลรูปภาพ</div>
  //                 )}
  //               </div>
  //             </div>
  //           </Box>
  //         </DialogContent>
  //       </BootstrapDialog>
  //     </div>
  //   );
  // };

  const SubCategories = ({
    paper,
    subCategories,
    subCatPerLoop,
    subCatIndex,
    setOpenEditPhoto,
    setSelectedQuestion,
    setPaper,
  }) => {
    const subData = searchSubcategoriesName(subCategories, subCatPerLoop.subId);
    return (
      <div key={subCatIndex} className={`bg-white drop-shadow p-4 rounded-md`}>
        <div className='flex flex-row pb-4'>
          <div className='flex items-center rounded-full text-xl'>
            <Chip label={subData.categoryName} variant='outlined' />
          </div>
          <div className='flex items-center text-xl font-semibold ml-4'>
            {subData.subCategoriesName}
          </div>
        </div>
        <hr />
        {subCatPerLoop.questions.map((question, index) => (
          <div key={index}>
            <QuestionContent
              key={`question${index}`}
              question={question}
              questionsPerSub={subData.questions}
              questionIndex={index}
              subCatIndex={subCatIndex}
              setOpenEditPhoto={setOpenEditPhoto}
              setSelectedQuestion={setSelectedQuestion}
              paper={paper}
              subCat={subCatPerLoop}
              setPaper={setPaper}
            />
          </div>
        ))}
      </div>
    );
  };

  const QuestionContent = ({
    question,
    questionsPerSub,
    questionIndex,
    subCatIndex,
    setOpenEditPhoto,
    setSelectedQuestion,
    paper,
    subCat,
    setPaper,
  }) => {
    const resultQuestion = questionsPerSub.find(
      (questionPerLoop) => questionPerLoop.id === question.questionId
    );
    const questionName = resultQuestion.attributes.question || "";
    const min = resultQuestion.attributes.spec_min || null;
    const max = resultQuestion.attributes.spec_max || null;
    const sc_status = resultQuestion.attributes.sc_status;

    return (
      <div key={questionIndex}>
        <div className='text-lg mt-4 flex flex-row items-center'>
          {questionName}
          {min !== null && max !== null && (
            <div className={`flex gap-2 ml-4`}>
              <span>{parseFloat(min).toFixed(2)}</span>
              <span>~</span>
              <span>{parseFloat(max).toFixed(2)}</span>
            </div>
          )}
          {sc_status && (
            <div className='ml-2'>
              <Chip label='SC' color='primary' variant='outlined' />
            </div>
          )}
        </div>
        <div className={`mt-4`}>
          {Array(3)
            .fill(0)
            .map((d, index) => {
              return (
                <div key={`form${index}`} className='flex mt-4'>
                  <FormQuestion
                    questionMaster={resultQuestion.attributes}
                    questionData={question}
                    indexSC={index}
                    questionIndex={questionIndex}
                    subCatIndex={subCatIndex}
                    setOpenEditPhoto={setOpenEditPhoto}
                    setSelectedQuestion={setSelectedQuestion}
                    paper={paper}
                    subCat={subCat}
                    setPaper={setPaper}
                  />
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  // const checkDisableInput = (image) => {
  //   if (image === "") {
  //     return true;
  //   }
  // };

  const checkNG = (paper, subCategoryIndex, questionIndex, index) => {
    if (paper) {
      const currentAnswer = paper.answer[index];
      if (currentAnswer === "NG") {
        return true;
      }
    }
  };

  const checkChecker = (paper) => {
    if (paper.checker_id.data !== null) {
      return true;
    }
  };

  const checkDisableInput = (
    questionData,
    indexSC,
    questionMaster,
    subCatIndex,
    questionIndex,
    isActualCommentAndPicture
  ) => {
    if (questionMaster.spec_min && questionMaster.spec_max) {
      if (isActualCommentAndPicture) {
        if (questionData) {
          if (indexSC !== 0) {
            const currentValue = questionData.answer[indexSC - 1];
            if (!currentValue || currentValue === "") {
              return true;
            }
          }
        }
        return false;
      }
      return true;
    } else {
      if (questionData) {
        if (indexSC !== 0) {
          const currentValue = questionData.answer[indexSC - 1];
          if (!currentValue || currentValue === "") {
            return true;
          }
        }
      }
      return false;
    }
  };

  const handleOnChangeAnswer = (e, questionId, indexSC, subID) => {
    try {
      const clonePaper = { ...paper };
      const cloneSubcategory = clonePaper.answer.sub_categories.find(
        (sub_category) => sub_category.subId === subID
      );
      const cloneQuestion = cloneSubcategory.questions.find(
        (question) => question.questionId === questionId
      );
      cloneQuestion.answer[indexSC] = e.target.value;
      setPaper(clonePaper);
    } catch (error) {
      console.error("error 396", error);
      setAlertPayload((prev) => ({
        open: true,
        duration: 5000,
        message: JSON.stringify(error),
        type: "error",
      }));
    }
  };

  const handleOnBlurActual = (e, subcategoryID, questionID, index, subCat) => {
    const actual = parseFloat(e.target.value);
    const clonePaper = { ...paper };
    const cloneSubcategory = clonePaper.answer.sub_categories.find(
      (subcategory) => subcategory.subId === subcategoryID
    );
    const cloneQuestion = cloneSubcategory.questions.find(
      (question) => question.questionId === questionID
    );

    if (!isNaN(actual)) {
      cloneQuestion.answer[index] =
        cloneQuestion.spec_min <= actual && actual <= cloneQuestion.spec_max
          ? "OK"
          : "NG";
    } else {
      cloneQuestion.answer[index] = "";
    }
    cloneQuestion.actual[index] = actual;
    setPaper(clonePaper);
  };

  const handleOnChangeComment = (e, subcategoryID, questionID, index) => {
    const comment = e.target.value;
    const clonePaper = { ...paper };
    const cloneSubcategory = clonePaper.answer.sub_categories.find(
      (subcategory) => subcategory.subId === subcategoryID
    );
    const cloneQuestion = cloneSubcategory.questions.find(
      (question) => question.questionId === questionID
    );
    cloneQuestion.comment[index] = comment;
    setPaper(clonePaper);
  };

  const FormQuestion = ({
    questionMaster,
    // subID,
    questionData,
    indexSC,
    questionIndex,
    subCatIndex,
    setOpenEditPhoto,
    setSelectedQuestion,
    paper,
    subCat,
    setPaper,
  }) => {
    //question = questionMaster
    return (
      <div key={indexSC} className='flex flex-row items-center'>
        <FormControl>
          {questionMaster.spec_min !== null &&
          questionMaster.spec_max !== null ? (
            <RadioGroup row>
              <FormControlLabel
                value={`OK`}
                label='OK'
                disabled={questionMaster.spec_min && questionMaster.spec_max}
                control={
                  <Radio
                    checked={checkMinMaxOK(
                      questionMaster.spec_min,
                      questionMaster.spec_max,
                      questionData.actual[indexSC]
                        ? questionData.actual[indexSC]
                        : ""
                    )}
                    disabled
                  />
                }
              />
              <FormControlLabel
                value={`NG`}
                control={
                  <Radio
                    checked={checkMinMaxNG(
                      questionMaster.spec_min,
                      questionMaster.spec_max,
                      questionData.actual[indexSC]
                        ? questionData.actual[indexSC]
                        : ""
                    )}
                    disabled
                  />
                }
                label='NG'
              />
            </RadioGroup>
          ) : (
            <RadioGroup
              row
              onChange={(e) =>
                handleOnChangeAnswer(
                  e,
                  questionData.questionId,
                  indexSC,
                  subCat.subId
                )
              }>
              <FormControlLabel
                value={`OK`}
                label='OK'
                control={
                  <Radio checked={questionData.answer[indexSC] === "OK"} />
                }
              />
              <FormControlLabel
                value={`NG`}
                label='NG'
                control={
                  <Radio checked={questionData.answer[indexSC] === "NG"} />
                }
              />
            </RadioGroup>
          )}
        </FormControl>
        <Box
          component={"form"}
          sx={{
            "& .MuiTextField-root": {
              m: 1,
              width: "25ch",
            },
          }}
          noValidate
          autoComplete='off'>
          {questionMaster.spec_min !== null &&
            questionMaster.spec_max !== null && (
              <TextField
                label='Actual'
                type='number'
                size='small'
                onBlur={(e) =>
                  handleOnBlurActual(
                    e,
                    subCat.subId,
                    questionData.questionId,
                    indexSC,
                    subCat
                  )
                }
                onWheel={(event) => event.target.blur()}
                defaultValue={
                  questionData.actual && questionData.actual[indexSC]
                    ? parseFloat(questionData.actual[indexSC])
                    : ""
                }
                disabled={checkDisableInput(
                  questionData,
                  indexSC,
                  questionMaster,
                  subCatIndex,
                  questionIndex,
                  true
                )}
                // disabled={checkChecker(paper)}
              />
            )}
          <TextField
            label='Comment'
            type='text'
            size='small'
            defaultValue={
              questionData.comment ? questionData.comment[indexSC] : ""
            }
            onBlur={(e) =>
              handleOnChangeComment(
                e,
                subCat.subId,
                questionData.questionId,
                indexSC
              )
            }
            disabled={checkDisableInput(
              questionData,
              indexSC,
              questionMaster,
              subCatIndex,
              questionIndex,
              true
            )}
            // disabled={checkChecker(paper)}
          />
        </Box>
        <div className='flex gap-4 items-center'>
          <Fab
            onClick={() =>
              handleOnClickEditPhoto(
                questionData,
                subCatIndex,
                questionIndex,
                questionMaster,
                indexSC
              )
            }
            // onClick={() => {
            //   setOpenEditPhoto(true);
            //   setSelectedQuestion({
            //     questionData,
            //     subCatIndex,
            //     questionIndex,
            //     questionMaster,
            //     indexSC,
            //   });
            // }}
            // disabled={checkDisableInput(
            //   indexSC !== undefined &&
            //     questionData.imageURL &&
            //     questionData.imageURL[indexSC].length > 0
            //     ? `${questionData.imageURL[indexSC].length}`
            //     : ""
            // )}
            // disabled={checkChecker(paper)}
            disabled={checkDisableInput(
              questionData,
              indexSC,
              questionMaster,
              subCatIndex,
              questionIndex,
              true
            )}
            color='primary'
            variant='contained'
            component='label'>
            {indexSC !== undefined &&
            questionData.imageURL &&
            questionData.imageURL[indexSC].length > 0 ? (
              `${questionData.imageURL[indexSC].length + " รูป"}`
            ) : (
              <PhotoIcon />
            )}
          </Fab>
          <IconCheckNG
            show={questionData ? questionData.answer[indexSC] !== "" : false}
            isNG={checkNG(questionData, subCatIndex, questionIndex, indexSC)}
          />
        </div>
      </div>
    );
  };

  const handleOnClickEditPhoto = (
    questionData,
    subCatIndex,
    questionIndex,
    questionMaster,
    indexSC
  ) => {
    setOpenEditPhoto(true);
    setSelectedQuestion({
      questionData,
      subCatIndex,
      questionIndex,
      questionMaster,
      indexSC,
    });
  };

  const EditPhoto = ({ open, setOpen, selectedQuestion, setPaper, paper }) => {
    const handleAddFile = async (e, selectedQuestion) => {
      const files = Array.from(e.target.files);
      const subCatIndex = selectedQuestion.subCatIndex;
      const questionIndex = selectedQuestion.questionIndex;
      const clonePaper = { ...paper };

      const cloneQuestion = {
        ...clonePaper.answer.sub_categories[subCatIndex].questions[
          questionIndex
        ],
      };
      if (selectedQuestion.questionMaster.sc_status) {
        console.error("SC ! please dev");
      } else {
        if (cloneQuestion.imageURL) {
        } else {
          const images = [];
          for (let i = 0; i < files.length; i++) {
            const base64 = await getBase64(files[i]);
            images.push({
              base: base64,
              file: files[i],
            });
          }
          cloneQuestion.imageURL = images;
        }
      }
      clonePaper.answer.sub_categories[subCatIndex].questions[questionIndex] =
        cloneQuestion;
      // setPaper()
    };
    const indexSC = selectedQuestion.indexSC;

    return (
      <div>
        <BootstrapDialog onClose={() => setOpen(false)} open={open}>
          <BootstrapDialogTitle onClose={() => setOpen(false)}>
            เพิ่มรูปภาพ
          </BootstrapDialogTitle>
          <DialogContent dividers>
            <Box
              component='form'
              sx={{
                "& .MuiTextField-root": {
                  mt: 2,
                },
              }}
              noValidate
              autoComplete='off'>
              <div className='flex flex-col'>
                <div className='mb-4'>
                  {/* <Fab color="primary" variant="contained" component="label">
                    <input
                      type="file"
                      hidden
                      multiple
                      // TODO: dev
                      // onChange={(e) => handleAddFile(e, selectedQuestion)}
                    />
                    <AddIcon />
                  </Fab> */}
                </div>
                <div className='mt-4 flex gap-4 flex-wrap'>
                  {selectedQuestion.questionData.imageURL && (
                    <ShowImages
                      imageURL={selectedQuestion.questionData.imageURL[indexSC]}
                    />
                  )}
                </div>
              </div>
            </Box>
          </DialogContent>
          {/* <DialogActions>
            <Button variant="outlined" autoFocus>
              ล้างข้อมูล
            </Button>
            <Button variant="contained" autoFocus>
              ยืนยัน
            </Button>
          </DialogActions> */}
        </BootstrapDialog>
      </div>
    );
  };

  return (
    <>
      {isOpenPartPhoto && (
        <BootstrapDialog
          open={isOpenPartPhoto}
          onClose={() => setIsOpenPartPhoto(false)}>
          <img src={`${config.server}${paper.answer.partImage}`} />
        </BootstrapDialog>
      )}
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={snackBarState.open}
        autoHideDuration={6000}
        onClose={snackBarState.onClose}>
        <Alert severity={snackBarState.type}>{snackBarState.message}</Alert>
      </Snackbar>
      <div className='flex flex-row justify-between'>
        <div className='mb-4 flex flex-row items-center'>
          <Stack direction='row' spacing={1}>
            {/* <Chip label={`ครั้งที่: ${dataFromLocation.state.index}`} /> */}
            <Chip
              label={`วันที่: ${
                paper && dayjs(paper.create_datetime).format("DD-MM-YYYY")
              }`}
            />
            <Chip label={`ช่วงเวลา: ${paper && paper.shift}`} />
            {paper && paper.shiftAB && (
              <Chip label={`กลุ่ม: ${paper && paper.shiftAB}`} />
            )}
          </Stack>
        </div>
        {/* <div className="">
          <Button variant="contained">Export Paper</Button>
        </div> */}
      </div>
      <div className={`flex flex-col lg:flex-row relative gap-4 mt-4`}>
        <div className={` lg:relative`}>
          <div
            className={`flex justify-center items-center relative lg:sticky top-0 rounded-md drop-shadow-md bg-white`}>
            {paper && paper.answer.partImage !== "" ? (
              <div
                className={`mt-4 w-full flex justify-center cursor-pointer`}
                onClick={() => setIsOpenPartPhoto(true)}>
                <img
                  src={paper.answer.partImage}
                  //config.server +
                  className=' object-contain h-[300px]'
                />
              </div>
            ) : (
              <div
                style={{ width: "12rem", height: "12rem" }}
                className='bg-gray-100 flex items-center justify-center rounded-lg m-2'>
                <NoPhotographyIcon sx={{ fontSize: 80 }} />
              </div>
            )}
          </div>
        </div>
        <div className={`lg:flex lg:flex-col `}>
          {/* <div
            className={`h-96 lg:h-full overflow-auto lg:overflow-visible lg:relative rounded-md shadow-md`}
          > */}
          {paper ? (
            paper.answer.sub_categories.map((subCatData, subCatIndex) => (
              <SubCategories
                key={`subCat${subCatIndex}`}
                subCategories={
                  paper.partName.data.attributes.sub_categories.data
                }
                subCatPerLoop={subCatData}
                paper={paper}
                setPaper={setPaper}
                subCatIndex={subCatIndex}
                setOpenEditPhoto={setOpenEditPhoto}
                setSelectedQuestion={setSelectedQuestion}
              />
            ))
          ) : (
            <div>ไม่มีข้อมูล</div>
          )}
          {paper && paper.checker_id.data === null && (
            <div className={`flex justify-end mt-4`}>
              <Button
                variant='contained'
                size='large'
                onClick={() => updatePaper(paper.answer)}>
                บันทึกข้อมูล
              </Button>
            </div>
          )}
        </div>
      </div>
      {openEditPhoto && (
        <EditPhoto
          open={openEditPhoto}
          setOpen={setOpenEditPhoto}
          selectedQuestion={selectedQuestion}
          setPaper={setPaper}
          paper={paper}
        />
      )}
    </>
  );
};

export default EditPaperV2;
