import React, { useState } from "react";
import config from "../config";
import Button from "@mui/material/Button";
import EditSignature from "./EditSignature";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { useOneUser } from "../helpers/APIServices";

const Profile = () => {
  const [openModalSignature, setOpenModalSignature] = useState(false);
  let userLocal = JSON.parse(localStorage.getItem("QCAPPuser"));
  const { data, error, isLoading, mutate } = useOneUser(userLocal.id);
  if (!isLoading) {
    console.log("loading", data.data);
  }

  const [userData, setUserData] = useState({
    open: false,
    user: null,
  });

  const handleUpdateSignature = (updateUser) => {
    mutate();
  };

  const visibleButton = () => {
    if (data.data.qc_role === "inspector" || data.data.qc_role === "admin") {
      return false;
    } else {
      return true;
    }
  };

  const uppercaseRole = (data) => {
    const splitData = data.split("_").join(" ");
    const firstCharacter = splitData.substring(0, 1).toUpperCase();
    const leftCharacter = splitData.substring(1);
    return firstCharacter + leftCharacter;
  };

  if (error) return <div>failed to load</div>;
  if (isLoading) return <div>loading...</div>;

  return (
    <>
      <div className='flex flex-col border-2 border-slate-100 shadow-lg rounded-lg p-4'>
        <div className='mb-4'>
          <div className='text-xl font-bold mb-4'>
            ข้อมูลส่วนตัวผู้ใช้งานระบบ
          </div>
          <hr />
        </div>
        <div className=' md:grid md:grid-cols-2 '>
          <div className=' flex  justify-center items-center md:flex md:flex-col md:justify-center md:mt-4'>
            {data.data.profile_image ? (
              <img
                src={data.data.profile_image.url}
                // config.server +
                className='rounded-full h-40 w-40'
              />
            ) : (
              <span>----- ไม่มีรูปภาพ -----</span>
            )}
          </div>
          <div className=' md:flex md:flex-col md:justify-center'>
            <div className=' flex flex-row items-center'>
              <Chip label='ชื่อ - นามสกุล :' />
              <div className='flex flex-row ml-2'>
                <div className='font-semibold'>{data.data.name}</div>
                <div className='ml-2 font-semibold'>{data.data.lastname}</div>
              </div>
            </div>
            <div className='flex flex-row mt-2 items-center'>
              <Chip label='รหัสพนักงาน :' />
              <div className='ml-2 font-semibold'>{data.data.username}</div>
            </div>
            <div className='flex flex-row mt-2 items-center'>
              <Chip label='สถานะ :' />
              <div className='ml-2 font-semibold'>
                {uppercaseRole(data.data.qc_role)}
              </div>
            </div>

            {/* {visibleButton() && ( */}
            <div>
              <div className='mt-2 mb-2 items-center'>
                <Chip label='ลายเซ็น :' />
                <div className='mt-4 mb-4 flex items-center justify-center'>
                  {data.data.signature ? (
                    <img
                      src={
                        data.data.signature[data.data.signature.length - 1].url
                      }
                      //config.server +
                      className='rounded-full h-40 w-70 '
                    />
                  ) : (
                    <span>----- ไม่มีลายเซ็น -----</span>
                  )}
                </div>
              </div>

              <div className='flex justify-end mr-10'>
                <Button
                  variant='contained'
                  onClick={() => {
                    setUserData({
                      open: true,
                      user: data.data,
                    });
                  }}>
                  แก้ไขลายเซ็น
                </Button>
              </div>
            </div>
            {/* )} */}
          </div>
        </div>
      </div>
      <EditSignature
        state={[userData, setUserData]}
        handleUpdateSignature={handleUpdateSignature}
      />
    </>
  );
};

export default Profile;
