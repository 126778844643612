import {
    AssignmentInd,
    VerifiedUser,
    Visibility,
} from "@mui/icons-material";

const checkRule = (user) => {
    if (user.qc_role === "inspector") {
        return <Visibility />;
    } else if (user.qc_role === "checker") {
        return <VerifiedUser />;
    }
    return <AssignmentInd />;
};


export { checkRule }