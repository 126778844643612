import { Alert, Snackbar, TextField } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { useState } from "react";

export default function DeleteSubCatModal({
  open,
  setOpen,
  openDelete,
  setRevisionRecord,
  revisionRecord,
  action,
  header,
  message,
  confirmText,
  cancelText,
  data,
}) {
  const [alert, setAlert] = useState({
    open: false,
    type: "info",
    massage: "",
  });
  const handleClose = () => {
    setOpen((prev) => ({ ...prev, open: false }));
  };
  const handleCloseAlert = () => {
    setAlert((data) => ({ ...data, open: false }));
  };

  const handleOnClick = (result) => {
    setOpen((prev) => ({ ...prev, open: false }));
    action(result, data);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Snackbar
        open={alert.open}
        autoHideDuration={3000}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        onClose={handleCloseAlert}
      >
        <Alert
          onClose={handleCloseAlert}
          severity={alert.type}
          sx={{ width: "100%" }}
        >
          {alert.massage}
        </Alert>
      </Snackbar>
      <DialogTitle id="alert-dialog-title">{header || "Header"}</DialogTitle>
      <DialogContent>
        {/* <DialogContentText id="alert-dialog-description"> */}
        {message || "Content"}
        {/* </DialogContentText> */}
        {openDelete.status_revise && (
          // <div className="mt-2">
          <TextField
            id="outlined-password-input"
            label="Revision Record"
            value={revisionRecord}
            margin="dense"
            onChange={(e) => setRevisionRecord(e.target.value)}
          />
          // </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleOnClick(false)}>
          {cancelText || "Disagree"}
        </Button>
        <Button
          variant="contained"
          onClick={() => handleOnClick(true)}
          autoFocus
        >
          {confirmText || "Agree"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
