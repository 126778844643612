import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import PropTypes from "prop-types";

export default function TableReport({ headers, data }) {
  return (
    <TableContainer
      component={Paper}
      sx={{
        width: {
          md: "38rem",
          lg: "100%",
        },
      }}
    >
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            {headers.map((header, index) =>
              header.col ? (
                <TableCell
                  key={`cell${index}`}
                  align="center"
                  colSpan={header.col.length}
                >
                  <div className="">{header.name}</div>
                  <div className="flex justify-between mt-4">
                    {header.col.map((col, index) => (
                      <div key={`col${index}`} className="">
                        {col.name}
                      </div>
                    ))}
                  </div>
                </TableCell>
              ) : (
                <TableCell key={`cell${index}`} align="center" colSpan={0}>
                  {header.name}
                </TableCell>
              )
            )}
            {/* <TableCell>Dessert (100g serving)</TableCell>
            <TableCell align="right">Calories</TableCell>
            <TableCell align="right">Fat&nbsp;(g)</TableCell>
            <TableCell align="right">Carbs&nbsp;(g)</TableCell>
            <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((dataLoop, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {/* <TableCell component="th" align="center" scope="row">
                {dataLoop.id}
              </TableCell> */}
              <TableCell component="th" align="center" scope="row">
                {index + 1}
              </TableCell>
              <TableCell align="center">{dataLoop.createAt}</TableCell>
              <TableCell align="center">{dataLoop.shift}</TableCell>
              {dataLoop.sc.actual ? (
                dataLoop.sc.actual.map((actual, index) => (
                  <TableCell key={`cell${index}`} align="center">
                    {actual.toString() || "-"}
                  </TableCell>
                ))
              ) : (
                <>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                  <TableCell align="center">-</TableCell>
                </>
              )}
              <TableCell align="center">{dataLoop.average}</TableCell>
              <TableCell align="center">{dataLoop.range}</TableCell>
              <TableCell align="center">{dataLoop.inspector}</TableCell>
              <TableCell align="center">{dataLoop.checker}</TableCell>
            </TableRow>
          ))}
          {/* {rows.map((row) => (
            <TableRow
              key={row.name}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              <TableCell align="right">{row.calories}</TableCell>
              <TableCell align="right">{row.fat}</TableCell>
              <TableCell align="right">{row.carbs}</TableCell>
              <TableCell align="right">{row.protein}</TableCell>
            </TableRow>
          ))} */}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

TableReport.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
};
