import React, { useState, useRef, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { APIServices } from "./../../helpers/APIServices";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Switch from "@mui/material/Switch";
import SimpleBackdrop from "../../Components/Backdrop";
import Formula from "../../Components/Formula";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../Layout";
import config from "../../config";
import Checkbox from "@mui/material/Checkbox";
import { useEffect } from "react";
import { changeIDFormula } from "./FormUpdateQuestion";
import { Chip } from "@mui/material";

const labelCheckbox = { inputProps: { "aria-label": "Checkbox demo" } };

const label = { inputProps: { "aria-label": "Switch demo" } };

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export const searchPartID = (url) => {
  if (typeof url !== "string") {
    throw Error("url should be type string").stack;
  }
  const arr = url.split("/");
  return arr[4];
};

function FromAddQuestion({
  subCat,
  setOpen,
  open,
  setSuccess,
  subCategory,
  category,
  partName,
  partCode,
}) {
  const location = useLocation();
  const partId = searchPartID(location.pathname);
  const [revise, setRevise] = useState(false);
  const [revisionRecord, setRevisionRecord] = useState("");
  const [part, setPart] = useState("");
  const user = useContext(UserContext);
  const navigate = useNavigate();
  // const [subCategory, setSubCategory]

  const [imageQuestion, setImageQuestion] = useState("");
  const [alert, setAlert] = React.useState({
    open: false,
    type: "info",
    massage: "",
  });
  const [isSC, setIsSC] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorMinMax, setErrorMinMax] = useState(false);
  const switchRef = useRef();
  const questionRef = useRef();
  const specMinRef = useRef();
  const specMaxRef = useRef();
  const UCL_xBarRef = useRef();
  const CL_xBarRef = useRef();
  const LCL_xBarRef = useRef();
  const UCL_rChartRef = useRef();
  const CL_rChartRef = useRef();
  const LCL_rChartRef = useRef();

  const [formula, setFormula] = useState();

  const checkError = () => {
    if (specMinRef.current) {
      setErrorMinMax(
        parseFloat(specMinRef.current.value) >
          parseFloat(specMaxRef.current.value) ||
          parseFloat(specMinRef.current.value) ===
            parseFloat(specMaxRef.current.value)
      );
    }
  };

  const handleClose = () => {
    setErrorMinMax(false);
    setRevisionRecord("");
    specMinRef.current.value = "";
    specMaxRef.current.value = "";
    switchRef.current.checked = "";
    questionRef.current.value = "";
    UCL_xBarRef.current.value = "";
    CL_xBarRef.current.value = "";
    LCL_xBarRef.current.value = "";
    UCL_rChartRef.current.value = "";
    CL_rChartRef.current.value = "";
    LCL_rChartRef.current.value = "";
    setOpen(false);
    setImageQuestion({
      base: undefined,
      file: undefined,
    });
  };

  const handleCloseAlert = () => {
    setAlert((data) => ({ ...data, open: false }));
  };

  const findOnePart = async () => {
    console.log("use api");
    const findPart = await APIServices.findOnePartForFromAddQuestion(partId);
    const partData = findPart.data.data;
    setPart({
      id: partData.id,
      ...partData.attributes,
    });
  };

  // const findOnwQuestion = async () => {
  //   const findPart = await APIServices.findOnePart(partId);
  //   const partData = findPart.data.data;
  //   setPart({
  //     id: partData.id,
  //     ...partData.attributes,
  //   });
  // }

  const createQuestion = async (
    image,
    specMin,
    specMax,
    sc_status,
    question,
    UCL_xBar,
    CL_xBar,
    LCL_xBar,
    UCL_rChart,
    CL_rChart,
    LCL_rChart,
    subCategory,
    formula
  ) => {
    const signature =
      user.signature !== null
        ? user.signature[user.signature.length - 1].url
        : "";
    if (question === "") {
      setAlert({
        open: true,
        type: "error",
        massage: "ไม่มีข้อมูลคำถาม",
      });
      return;
    }
    // if (part.papers.data.length > 0 && revise === false) {
    //   setAlert({
    //     open: true,
    //     type: "error",
    //     massage: "พาร์ทนี้มีการเพิ่ม paper กรุณาทำการกด revise",
    //   });
    //   return;
    // }
    // if (checkFormulaError(formula)) {
    //   setAlert({
    //     open: true,
    //     type: "error",
    //     massage: "รูปแบบสูตรของคุณผิด !!!",
    //   });
    //   return;
    // }
    if (revise === false) {
      if (question === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "ยังไม่ได้ทำการเพิ่มคำถาม",
        });
      } else {
        setLoading(true);
        const response = await APIServices.createQuestion(
          specMin,
          specMax,
          sc_status,
          question,
          UCL_xBar,
          CL_xBar,
          LCL_xBar,
          UCL_rChart,
          CL_rChart,
          LCL_rChart,
          subCategory,
          true,
          formula,
          "1",
          part.revise_time
        );

        if (response.data.data !== null) {
          if (image !== undefined) {
            const formData = new FormData();
            formData.append("ref", "api::question.question");
            formData.append("refId", response.data.data.id);
            formData.append("field", "image");
            formData.append("files", image[0]);
            await APIServices.addUserImage(formData);
          }
        }

        specMinRef.current.value = "";
        specMaxRef.current.value = "";
        switchRef.current.checked = "";
        questionRef.current.value = "";

        UCL_xBarRef.current.value = "";
        CL_xBarRef.current.value = "";
        LCL_xBarRef.current.value = "";
        UCL_rChartRef.current.value = "";
        CL_rChartRef.current.value = "";
        LCL_rChartRef.current.value = "";
        setTimeout(() => {
          setSuccess(true);
          setLoading(false);
          setOpen(false);
          setImageQuestion({
            base: undefined,
            file: undefined,
          });
        }, 1500);
      }
    } else {
      if (revisionRecord === "" || question === "") {
        setAlert({
          open: true,
          type: "error",
          massage: "ยังไม่ได้ทำการเพิ่มคำถาม หรือเพิ่ม revision record",
        });
      } else {
        setLoading(true);
        const findPart = await APIServices.findOnePart(partId);
        const partData = findPart.data.data;
        const part = {
          id: partData.id,
          ...partData.attributes,
        };
        let partRevise;
        let keepNewData = [];
        let dataQuestionFormula = [];
        const reviseTime = part.revise_time ? part.revise_time : null;
        if (part.old_parts.data.length === 0) {
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            part.id,
            false,
            true
          );
          partRevise = responseReviseForPart;
        } else {
          let oldPartID = [];
          part.old_parts.data.forEach((oldPart) => {
            oldPartID.push(oldPart.id);
          });
          oldPartID.push(part.id);
          const responseReviseForPart = await APIServices.createPartForRevise(
            part.part_name,
            part.part_code,
            reviseTime,
            oldPartID,
            false,
            true
          );
          partRevise = responseReviseForPart;
        }
        await APIServices.updatePart(
          part.part_name,
          part.part_code,
          part.id,
          false,
          false
        );

        const part_data = partRevise.data.data.attributes;
        const part_id = partRevise.data.data.id;

        if (part.sub_categories.data.length !== 0) {
          for (
            let indexSubCat = 0;
            indexSubCat < part.sub_categories.data.length;
            indexSubCat++
          ) {
            const subCatData = part.sub_categories.data[indexSubCat];
            const categoryID = subCatData.attributes.category.data.id;
            const subCatName = subCatData.attributes.sub_categories;
            const reviseTimeSubCat = subCatData.attributes.revise_time;
            let responseSubCat;
            if (subCatData.attributes.old_sub_categories.data.length === 0) {
              const response = await APIServices.createSubCategories(
                categoryID,
                subCatName,
                part_id,
                true,
                "0",
                reviseTimeSubCat,
                subCatData.id
              );
              responseSubCat = response;
            } else {
              let oldSubCatID = [];
              subCatData.attributes.old_sub_categories.data.forEach(
                (oldSub) => {
                  oldSubCatID.push(oldSub.id);
                }
              );
              oldSubCatID.push(subCatData.id);
              const response = await APIServices.createSubCategories(
                categoryID,
                subCatName,
                part_id,
                true,
                "0",
                reviseTimeSubCat,
                oldSubCatID
              );
              responseSubCat = response;
            }

            const realSubCatID = responseSubCat.data.data.id;

            const attributesQT = {
              spec_min: specMin,
              spec_max: specMax,
              sc_status: sc_status,
              question: question,
              UCL_Xbar: UCL_xBar,
              CL_Xbar: CL_xBar,
              LCL_Xbar: LCL_xBar,
              UCL_Rchart: UCL_rChart,
              CL_Rchart: CL_rChart,
              LCL_Rchart: LCL_rChart,
              sub_category: realSubCatID,
              status: true,
              formula: {
                data: formula,
              },
            };

            let newQuestion = subCatData.attributes.questions.data;
            if (
              subCatData.attributes.questions.data &&
              subCatData.id === subCat
            ) {
              newQuestion.push({
                attributes: attributesQT,
              });
            }

            if (newQuestion.length !== 0) {
              for (
                let indexQuestion = 0;
                indexQuestion < newQuestion.length;
                indexQuestion++
              ) {
                const realQuestion = newQuestion[indexQuestion];
                const revise_time = realQuestion.attributes.revise_time;
                const specMin = realQuestion.attributes.spec_min;
                const specMax = realQuestion.attributes.spec_max;
                const sc_status = realQuestion.attributes.sc_status;
                const question = realQuestion.attributes.question;
                const UCL_xBar = realQuestion.attributes.UCL_Xbar;
                const CL_xBar = realQuestion.attributes.CL_Xbar;
                const LCL_xBar = realQuestion.attributes.LCL_Xbar;
                const UCL_rChart = realQuestion.attributes.UCL_Rchart;
                const CL_rChart = realQuestion.attributes.CL_Rchart;
                const LCL_rChart = realQuestion.attributes.LCL_Rchart;
                const subCategory = realSubCatID;
                const status = realQuestion.attributes.status;
                const formula = realQuestion.attributes.formula
                  ? realQuestion.attributes.formula.data
                  : null;

                if (realQuestion.id) {
                  if (realQuestion.attributes.old_question.data.length === 0) {
                    const responseQuestion = await APIServices.createQuestion(
                      specMin,
                      specMax,
                      sc_status,
                      question,
                      UCL_xBar,
                      CL_xBar,
                      LCL_xBar,
                      UCL_rChart,
                      CL_rChart,
                      LCL_rChart,
                      subCategory,
                      status,
                      formula,
                      "0",
                      revise_time,
                      realQuestion.id
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  } else {
                    let oldQuestionID = [];
                    realQuestion.attributes.old_question.data.forEach(
                      (oldQuestion) => {
                        oldQuestionID.push(oldQuestion.id);
                      }
                    );
                    oldQuestionID.push(realQuestion.id);
                    const responseQuestion = await APIServices.createQuestion(
                      specMin,
                      specMax,
                      sc_status,
                      question,
                      UCL_xBar,
                      CL_xBar,
                      LCL_xBar,
                      UCL_rChart,
                      CL_rChart,
                      LCL_rChart,
                      subCategory,
                      status,
                      formula,
                      "0",
                      revise_time,
                      oldQuestionID
                    );
                    if (responseQuestion.data.data.attributes.formula.data) {
                      keepNewData.push({
                        id: responseQuestion.data.data.id,
                        formula: responseQuestion.data.data.attributes.formula,
                      });
                    }
                    dataQuestionFormula.push({
                      old_id: realQuestion.id,
                      new_id: responseQuestion.data.data.id,
                    });
                  }
                } else {
                  const responseQuestion = await APIServices.createQuestion(
                    specMin,
                    specMax,
                    sc_status,
                    question,
                    UCL_xBar,
                    CL_xBar,
                    LCL_xBar,
                    UCL_rChart,
                    CL_rChart,
                    LCL_rChart,
                    subCategory,
                    status,
                    formula,
                    "1",
                    part_data.revise_time
                  );
                  if (responseQuestion.data.data.attributes.formula.data) {
                    keepNewData.push({
                      id: responseQuestion.data.data.id,
                      formula: responseQuestion.data.data.attributes.formula,
                    });
                  }
                  dataQuestionFormula.push({
                    old_id: realQuestion.id,
                    new_id: responseQuestion.data.data.id,
                  });
                }
              }
            }
          }
        }
        if (partRevise.data.data !== null) {
          if (part.image.data !== null) {
            const files = await fetch(
              // "http://agrappl03.aapico.com:1339/uploads/9_FC_8_B6_E7_F948_451_A_8_CB_6_4_A821_B407339_a1915890e4.jpeg"
              `${part.image.data.attributes.url}`
              // config.server +
            )
              .then((response) => response.blob())
              .then((blob) => {
                const myFile = new File(
                  [blob],
                  `${part.image.data.attributes.name}`,
                  {
                    type: blob.type,
                  }
                );
                return myFile;
              });

            const formData = new FormData();
            formData.append("ref", "api::qc-part.qc-part");
            formData.append("refId", part_id);
            formData.append("field", "image");
            formData.append("files", files);
            await APIServices.addUserImage(formData);
          }
        }

        if (partRevise.data.data.id !== null) {
          await APIServices.reviseForUpdate(
            revisionRecord,
            new Date(),
            signature,
            user.id,
            part_data.revise_time,
            part_id
          );
        }
        changeIDFormula(keepNewData, dataQuestionFormula);

        specMinRef.current.value = "";
        specMaxRef.current.value = "";
        switchRef.current.checked = "";
        questionRef.current.value = "";

        UCL_xBarRef.current.value = "";
        CL_xBarRef.current.value = "";
        LCL_xBarRef.current.value = "";
        UCL_rChartRef.current.value = "";
        CL_rChartRef.current.value = "";
        LCL_rChartRef.current.value = "";
        setTimeout(() => {
          setOpen(false);
          setLoading(false);
          setImageQuestion({
            base: undefined,
            file: undefined,
          });
          navigate("/layout/revise");
        }, 1500);
      }
    }
  };

  useEffect(() => {
    findOnePart();
  }, []);

  return (
    <BootstrapDialog
      // onClose={handleClose}
      aria-labelledby='customized-dialog-title'
      open={open}
      maxWidth={"sm"}
      fullWidth>
      <SimpleBackdrop open={loading} setOpen={setLoading} />
      <BootstrapDialogTitle id='customized-dialog-title' onClose={handleClose}>
        แบบฟอร์มเพิ่มข้อมูลคำถาม
      </BootstrapDialogTitle>
      <div>
        <Snackbar
          open={alert.open}
          autoHideDuration={3000}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleCloseAlert}>
          <Alert
            onClose={handleCloseAlert}
            severity={alert.type}
            sx={{ width: "100%" }}>
            {alert.massage}
          </Alert>
        </Snackbar>
      </div>
      <DialogContent dividers>
        <Box
          component='form'
          sx={{
            "& .MuiTextField-root": {
              // mr: 2,
              // width: 500,
              width: "100%",
              mt: 2,
            },
          }}
          noValidate
          autoComplete='off'>
          <div className='flex flex-col '>
            <div className={`flex flex-col justify-center items-start mb-2`}>
              <div className={`flex flex-col items-baseline mb-2`}>
                <div className={`ml-2 font-bold text-lg`}>{partName}</div>
                <div className={`ml-2 `}>({partCode})</div>
              </div>
              <div className={`flex mb-2 flex-row items-center`}>
                <Chip
                  label={"Category: "}
                  color='primary'
                  size='small'
                  variant='outlined'
                />
                <div className={`ml-2 font-medium`}>{category}</div>
              </div>
              <div className={`flex mb-2 flex-row items-center`}>
                <Chip
                  label={"Sub category: "}
                  color='primary'
                  size='small'
                  variant='outlined'
                />
                <div className={`ml-2 font-medium`}>{subCategory}</div>
              </div>
            </div>
            {/* <div className="flex flex-row">
              {part &&
                part.revise &&
                part.revise.data.attributes.revise_status === "4" && (
                  <div className="flex flex-row items-center mr-6">
                    <div>Revise :</div>
                    <Checkbox
                      {...labelCheckbox}
                      // disabled={
                      //   keepPart.revise.data.attributes.revise_status !== "4"
                      // }
                      onChange={(e) => setRevise(e.target.checked)}
                    />
                  </div>
                )}
              {revise === true && (
                <div className="flex flex-row items-center ">
                  <TextField
                    label="Revision Record"
                    onChange={(e) => {
                      setRevisionRecord(e.target.value);
                    }}
                  />
                </div>
              )}
            </div> */}
            <div className='flex flex-row'>
              <div className='basis-1/4 flex flex-row justify-center items-center m-1'>
                <span>SC</span>
                <Switch
                  inputRef={switchRef}
                  {...label}
                  onChange={(e) => setIsSC(e.target.checked)}
                />
              </div>
              <div className='m-1 basis-3/4 flex items-center '>
                <TextField inputRef={questionRef} label='คำถาม' />
              </div>
            </div>

            <div className='flex gap-4'>
              <div className='basis-1/2'>
                <TextField
                  onWheel={(event) => event.target.blur()}
                  fullWidth
                  type={"number"}
                  onBlur={() => checkError()}
                  inputRef={specMinRef}
                  label='min'
                  error={errorMinMax}
                  helperText={errorMinMax && "มีค่ามากกว่าหรือเท่ากับ Spec Max"}
                />
              </div>
              <div className='basis-1/2'>
                <TextField
                  onWheel={(event) => event.target.blur()}
                  type={"number"}
                  onBlur={() => checkError()}
                  inputRef={specMaxRef}
                  label='max'
                  error={errorMinMax}
                  helperText={
                    errorMinMax && "มีค่าน้อยกว่าหรือเท่ากับ Spec Min "
                  }
                  // disabled={!specMinRef.current.value && true}
                />
                {/* {console.log("specMinRef",specMinRef.current)} */}
              </div>
            </div>
            {/* {isSC && (
              <> */}
            <div className='flex flex-col mt-2'>
              <span className='mt-2  font-semibold'>X-bar</span>
              <div className='flex flex-row'>
                <div className='m-1'>
                  <TextField
                    disabled={!isSC}
                    inputRef={UCL_xBarRef}
                    label='UCL'
                    type='number'
                    onWheel={(event) => event.target.blur()}
                  />
                </div>
                <div className='m-1'>
                  <TextField
                    disabled={!isSC}
                    inputRef={CL_xBarRef}
                    label='CL'
                    type='number'
                    onWheel={(event) => event.target.blur()}
                  />
                </div>
                <div className='m-1'>
                  <TextField
                    disabled={!isSC}
                    inputRef={LCL_xBarRef}
                    label='LCL'
                    type='number'
                    onWheel={(event) => event.target.blur()}
                  />
                </div>
              </div>
            </div>

            <div className='flex flex-col mt-2'>
              <span className='mt-2 font-semibold'>R-chart</span>
              <div className='flex flex-row'>
                <div className='m-1'>
                  <TextField
                    disabled={!isSC}
                    inputRef={UCL_rChartRef}
                    label='UCL'
                    type='number'
                    onWheel={(event) => event.target.blur()}
                  />
                </div>
                <div className='m-1'>
                  <TextField
                    disabled={!isSC}
                    inputRef={CL_rChartRef}
                    label='CL'
                    type='number'
                    onWheel={(event) => event.target.blur()}
                  />
                </div>
                <div className='m-1'>
                  <TextField
                    inputRef={LCL_rChartRef}
                    label='LCL'
                    type='number'
                    disabled={!isSC}
                    onWheel={(event) => event.target.blur()}
                  />
                </div>
              </div>
            </div>
            {/* </>
            )} */}

            {/* <div className="col-span-4 mt-4 flex flex-col items-center ">
              <Button variant="contained" component="label">
                Upload image
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleChangeFile(e)}
                />
              </Button>
              <div className="mt-4">
                {imageQuestion.base && (
                  <img
                    src={imageQuestion.base}
                    className="max-w-96 max-h-96 mx-auto"
                  />
                )}
              </div>
            </div> */}
          </div>
        </Box>
        <Formula
          partID={partId}
          formula={formula}
          setFormula={setFormula}
          setChangeData={() => {}}
        />
      </DialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => {
            // setImageQuestion({
            //   base: undefined,
            //   file: undefined,
            // });
            specMinRef.current.value = "";
            specMaxRef.current.value = "";
            switchRef.current.checked = "";
            questionRef.current.value = "";
            setFormula("");
            UCL_xBarRef.current.value = "";
            CL_xBarRef.current.value = "";
            LCL_xBarRef.current.value = "";
            UCL_rChartRef.current.value = "";
            CL_rChartRef.current.value = "";
            LCL_rChartRef.current.value = "";
          }}>
          ล้างข้อมูล
        </Button>
        <Button
          disabled={errorMinMax}
          autoFocus
          variant='contained'
          onClick={() => {
            createQuestion(
              imageQuestion.file,
              specMinRef.current.value,
              specMaxRef.current.value,
              switchRef.current.checked,
              questionRef.current.value,
              UCL_xBarRef.current.value,
              CL_xBarRef.current.value,
              LCL_xBarRef.current.value,
              UCL_rChartRef.current.value,
              CL_rChartRef.current.value,
              LCL_rChartRef.current.value,
              subCat,
              formula
            );
          }}>
          บันทึก
        </Button>
      </DialogActions>
    </BootstrapDialog>
  );
}

export default FromAddQuestion;
