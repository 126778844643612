import * as React from "react";

import {
  useNavigate,
} from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { APIServices } from "./../helpers/APIServices";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Link from "@mui/material/Link";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import MuiAlert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Storages from "./../helpers/localStorage";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import SimpleBackdrop from "../Components/Backdrop";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://www.aapico.com/">
        AAPICO
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
const theme = createTheme();

export default function SignIn() {
  const navigate = useNavigate();
  const [alert, setAlert] = React.useState({
    open: false,
    type: "info",
    massage: "",
  });
  const [isLoad, setIsLoad] = React.useState(false)

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlert((data) => ({ ...data, open: false }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoad(true)
    const data = new FormData(event.currentTarget);
    let identifier = data.get("username").toUpperCase();
    let password = data.get("password");

    const resAuth = await APIServices.essUser(identifier, password);
    if (resAuth.status !== 200) {
      setAlert({
        open: true,
        type: "error",
        massage: "Invalid username or password",
      });
      setIsLoad(false)
      return;
    }

    let qcPassword = resAuth.data.user.birthday.replaceAll("-", "");
    const resQC = await APIServices.authQCUser(identifier, qcPassword);
    if (resQC.status !== 200) {
      setAlert({
        open: true,
        type: "error",
        massage: "Username is not active in QC APP",
      });
      setIsLoad(false)
      return;
    }
    const resUserQc = await APIServices.getQCUser(identifier);
    let userData = resUserQc.data[0];
    let localUser = {
      id: userData.id,
      user: userData.username,
      name: userData.name,
      lastname: userData.lastname,
      role: userData.qc_role,
      email: userData.email,
      signature: userData.signature !== null ? userData.signature.url : "",
      signatureID: userData.signature !== null ? userData.signature.id : "",
      profile_image:
        userData.profile_image !== null ? userData.profile_image.url : "",
    };
    // set before use - sawanon:modify 20230307
    Storages.setStorage("QCAPPuser", JSON.stringify(localUser));
    Storages.setStorage("QCAPPjwt", resQC.data.jwt);
    Storages.setStorage("ESSjwt", resAuth.data.jwt);
    try {
      if (userData.profile_image !== null) {
        //remove old image
        await APIServices.removeUserOldImage(userData.profile_image.id);
      }
    } catch (error) {
      setAlert({
        open: true,
        type: "info",
        massage: JSON.stringify(error.response.data),
      });
      setIsLoad(false)
      return;
    }
    if (resAuth.data.user.avatar !== null) {
      await APIServices.getEssImage(resAuth.data.user.avatar.url).then(
        async (response) => {
          let imgFile = new File(
            [response.data],
            resAuth.data.user.username + ".png",
            {
              type: "image/png, image/jpeg",
            }
          );
          const formData = new FormData();
          formData.append("ref", "plugin::users-permissions.user");
          formData.append("refId", resQC.data.user.id);
          formData.append("field", "profile_image");
          formData.append("files", imgFile);
          const res = await APIServices.addUserImage(formData);
        }
      );
    }
    let defaultPath = "";
    if (
      userData.qc_role === "inspector" ||
      userData.qc_role === "approver" ||
      userData.qc_role === "checker"
    ) {
      defaultPath = "paper";
    } else {
      defaultPath = "part";
    }
    setAlert({
      open: true,
      type: "success",
      massage: "Login Success",
    });
    setIsLoad(false)
    navigate(`/layout/${defaultPath}`);
    // TODO:
  };

  return (
    <ThemeProvider theme={theme}>
      <div className=" bg-gray-100 absolute inset-0">
        <div className={`bg-[#dda15e] fixed inset-0 z-0`}>
          <div className={`bg-[#344E41] h-10 rotate-45 w-[120%]`}></div>
          <div className={`bg-[#3A5A40] h-10 rotate-45 w-[120%]`}></div>
          <div className={`bg-[#588157] h-10 rotate-45 w-[120%]`}></div>
          <div className={`bg-[#A3B18A] h-10 rotate-45 w-[120%]`}></div>
          <div className={`bg-[#DAD7CD] h-10 rotate-45 w-[120%]`}></div>
          <div className={`bg-white h-10 -rotate-45 w-[120%]`}></div>
        </div>
        <SimpleBackdrop open={isLoad} />
        <Container
          component="main"
          maxWidth="xs"
          sx={{
            position: 'relative',
            zIndex: 10,
            background: '#fff',
            marginTop: 8,
            padding: '1rem',
            borderRadius: '1rem',
          }}
          className={`shadow-lg`}
        >
          <CssBaseline />
          {/* alert */}
          <Snackbar
            open={alert.open}
            // autoHideDuration={3000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity={alert.type}
              sx={{ width: "100%" }}
            >
              {alert.massage}
            </Alert>
          </Snackbar>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              zIndex: 1,
              background: `#fff`
            }}
          >
            <Avatar sx={{ m: 1, bgcolor: "secondary.main" }}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              QC APP
            </Typography>
            <Box
              component="form"
              onSubmit={handleSubmit}
              // noValidate
              sx={{
                mt: 1,
              }}
            >
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username"
                name="username"
                autoComplete="username"
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
              />
              {/* <FormControlLabel
                control={
                  <Checkbox value={true} name="remember" color="primary" />
                }
                label="Remember me"
              /> */}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
              >
                Login
              </Button>
            </Box>
          </Box>

          {/* <Copyright sx={{ mt: 8, mb: 4 }} /> */}
          <Copyright/>
        </Container>
      </div>
    </ThemeProvider>
  );
}
