import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Fab from "@mui/material/Fab";
import { APIServices } from "./../../helpers/APIServices";
import Chip from "@mui/material/Chip";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import Button from "@mui/material/Button";
import FormAddImageAnswer from "./FormAddImageAnswer";
import SimpleBackdrop from "../../Components/Backdrop";
import ConfirmAnswerDialog from "../../Components/Dialog";
import Stack from "@mui/material/Stack";
import config from "../../config";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";
import ConfirmCreatePaper from "./../../Components/ConfirmCreatePaperModal";
import { useOneUser } from "./../../helpers/APIServices";
const dayjs = require("dayjs");

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
});

function Paper() {
  const { state } = useLocation();
  const [subCategories, setSubCategories] = useState([]);
  const paramPart = useParams();
  const [success, setSuccess] = useState(true);
  const [paper, setPaper] = useState();
  const [open, setOpen] = useState(false);
  const [index, setIndex] = useState({
    sub_index: null,
    question_index: null,
    subID: null,
    qtID: null,
  });
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    type: "info",
    massage: "",
  });
  const [partImage, setPartImage] = useState("");
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem("QCAPPuser"));
  const date = dayjs().format("DD/MM/YYYY");
  const time = dayjs().format("HH:mm:ss");
  const date_time = new Date();
  const today = dayjs().minute(0);
  const shift = today.hour() >= 8 && today.hour() < 20 ? "Day" : "Night";
  const [shiftAB, setShiftAB] = useState("");
  const [openSave, setOpenSave] = useState({
    open: false,
    message: "",
  });

  const { data, error, isLoading, mutate } = useOneUser(user.id);

  const handleChangeShiftAB = (event) => {
    const eventUpperCase = event.target.value.toUpperCase();
    setShiftAB(eventUpperCase);
  };

  const handleCloseAlert = () => {
    setAlert((data) => ({ ...data, open: false }));
  };
  const handleClickOpen = (
    sub_index,
    question_index,
    subID,
    qtID,
    sc_index,
    isSC
  ) => {
    setOpen(true);
    setIndex({
      sub_index: sub_index,
      question_index: question_index,
      subID: subID,
      qtID: qtID,
      sc_index: sc_index,
      isSC: isSC,
    });
  };

  const getSubCategories = async () => {
    const response = await APIServices.getSubCategories(paramPart.id);
    const subCat = response.data.data;
    const newSubCat = subCat.map((data) => {
      return {
        id: data.id,
        ...data.attributes,
        questions: {
          data: data.attributes.questions.data.map((qt) => {
            return {
              id: qt.id,
              ...qt.attributes,
            };
          }),
        },
      };
    });
    let paperObj = {
      partId: paramPart.id,
      partImage: "",
      partName: "",
      sub_categories: [],
    };
    newSubCat.map((subCat, index) => {
      let question_id = [];
      subCat.questions.data.map((d) => {
        question_id.push({ questionId: d.id });
      });
      paperObj.partName = subCat.part.data.attributes.part_name;
      paperObj.partImage =
        subCat.part.data.attributes.image.data.attributes.url !== ""
          ? subCat.part.data.attributes.image.data.attributes.url
          : "";
      paperObj.sub_categories[index] = {
        subId: subCat.id,
        questions: question_id,
      };
    });

    setSubCategories(newSubCat);
    setPartImage(paperObj.partImage);
    setPaper(paperObj);
  };

  const createAnswer = async (result) => {
    if (result) {
      let ngArr = [];

      for (const [
        sub_index,
        sub_categories,
      ] of paper.sub_categories.entries()) {
        for (const [
          answer_index,
          answerData,
        ] of sub_categories.questions.entries()) {
          if (answerData.answer) {
            if (Array.isArray(answerData.answer)) {
              for (const [index, arrayAnswer] of answerData.answer.entries()) {
                let check =
                  answerData.comment === undefined ||
                  answerData.image === undefined;
                if (arrayAnswer === "NG") {
                  if (check) {
                    ngArr.push({
                      question_id: answerData.questionId,
                      // answer: answerData.answer,
                      subCat_index: sub_index,
                      answer_index: answer_index,
                    });
                  } else if (
                    answerData.image[index].length === 0 ||
                    answerData.comment[index] === undefined ||
                    answerData.comment[index] === ""
                  ) {
                    ngArr.push({
                      question_id: answerData.questionId,
                      // answer: answerData.answer,
                      subCat_index: sub_index,
                      answer_index: answer_index,
                    });
                  }

                  break;
                }
              }
            } else {
              if (
                answerData.image === undefined &&
                (answerData.comment === undefined ||
                  answerData.comment === "") &&
                answerData.answer === "NG"
              ) {
                ngArr.push({
                  question_id: answerData.questionId,
                  answer: answerData.answer,
                  subCat_index: sub_index,
                  answer_index: answer_index,
                });
              }
            }
          }
        }
      }
      let checkNotCompleteAnswer = false;
      for (const ngData of ngArr) {
        for (const question_ng of subCategories[ngData.subCat_index].questions
          .data) {
          if (question_ng.id === ngData.question_id) {
            checkNotCompleteAnswer = true;
            enqueueSnackbar(
              `คำถาม ${question_ng.question} ยังไม่มีรูปภาพ หรือ comment`,
              {
                variant: "error",
                anchorOrigin: { vertical: "top", horizontal: "right" },
              }
            );
            // }
          }
        }
      }

      if (checkNotCompleteAnswer) {
        return;
      }

      const imageData = [];
      paper.sub_categories.map((paperLoop, subIndex) => {
        paperLoop.questions.map((qt, qtIndex) => {
          let isSC = false;
          if (qt.image) {
            const images = qt.image.map((img) => {
              if (Array.isArray(img)) {
                // sc
                isSC = true;
                // 3 array of sc image - 3 round
                return img.map((imgLoop, imgLoopIndex) => {
                  // what is index of sc
                  return imgLoop.file;
                });
              } else {
                return img.file;
              }
            });

            imageData.push({
              subIndex: subIndex,
              qtIndex: qtIndex,
              images: images,
              isSC: isSC,
            });
          }
        });
      });

      let newPayload = { ...paper };

      for (let j = 0; j < imageData.length; j++) {
        // check this
        for (let i = 0; i < imageData[j].images.length; i++) {
          let myResponse;
          if (imageData[j].isSC) {
            const arrayResponse = [];
            for (let k = 0; k < imageData[j].images[i].length; k++) {
              const formData = new FormData();
              formData.append("files", imageData[j].images[i][k]);
              const response = await APIServices.addUserImage(formData);
              arrayResponse.push(response.data[0].url);
            }
            myResponse = arrayResponse;
          } else {
            const formData = new FormData();
            formData.append("files", imageData[j].images[i]);
            const response = await APIServices.addUserImage(formData);
            myResponse = response.data[0].url;
          }

          delete newPayload.sub_categories[imageData[j].subIndex].questions[
            imageData[j].qtIndex
          ].image;
          let newSub_categories = [...newPayload.sub_categories];
          let newQuestion = newSub_categories[imageData[j].subIndex].questions;

          // check imageURL is exist ? first loop in else
          if (newQuestion[imageData[j].qtIndex].imageURL) {
            newQuestion[imageData[j].qtIndex].imageURL.push(myResponse);
          } else {
            newQuestion[imageData[j].qtIndex].imageURL = [myResponse];
          }

          newPayload = {
            ...newPayload,
            sub_categories: newSub_categories,
          };
        }
      }
      let newAnswerForCheckIsEmpty = [];
      newPayload.sub_categories.map((t) => {
        t.questions.map((tt) => {
          if (Array.isArray(tt.answer)) {
            newAnswerForCheckIsEmpty.push({
              answer: tt.answer,
              image: tt.imageURL,
            });
          } else {
            newAnswerForCheckIsEmpty.push({
              answer: tt.answer,
              image: tt.imageURL,
            });
          }
        });
      });
      const signature = data.data.signature
        ? data.data.signature[data.data.signature.length - 1].url
        : "";

      let check;
      for (let l = 0; l < newAnswerForCheckIsEmpty.length; l++) {
        if (newAnswerForCheckIsEmpty[l].answer === undefined) {
          check = true;
        }
      }
      if (check === true) {
        setAlert({
          open: true,
          type: "error",
          massage: "Paper is not success",
        });
      } else {
        setLoading(true);
        const responseFromCreatePaper = await APIServices.createPaper(
          newPayload,
          newPayload.partId,
          user.id,
          // count + 1,
          shift,
          date_time,
          date,
          time,
          shiftAB,
          signature
        );
        setTimeout(() => {
          setAlert({
            open: true,
            type: "success",
            massage: "Paper is success",
          });
          setLoading(false);
          navigate("/layout/paper");
        }, 1500);
      }
    }
  };

  const checkAnswerOK = (actual, specMin, specMax, isSC) => {
    if (isSC) {
      if (actual !== undefined) {
        actual.map((t) => {
          if (parseFloat(t) >= specMin && parseFloat(t) <= specMax) {
            return true;
          } else {
            return false;
          }
        });
      } else {
      }
    } else {
      if (parseFloat(actual) >= specMin && parseFloat(actual) <= specMax) {
        return true;
      } else {
        return false;
      }
    }
  };

  const checkAnswerNG = (actual, specMin, specMax, isSC, index) => {
    if (isSC) {
      if (actual[index] !== undefined && actual[index] !== "") {
        if (
          parseFloat(actual[index]) < specMin ||
          parseFloat(actual[index]) > specMax
        ) {
          return "NG";
        } else {
          return "OK";
        }
      } else {
        return null;
      }
    } else {
      if (actual === undefined && actual[index] === "") {
        return null;
      }
      if (parseFloat(actual) < specMin || parseFloat(actual) > specMax) {
        return "NG";
      } else {
        return "OK";
      }
    }
  };

  const handleOnClickSave = () => {
    let message = "บันทึกข้อมูลใช่หรือไม่";
    setOpenSave({ open: true, message: message });
  };

  //date and shift realtime
  useEffect(() => {
    getSubCategories();
    // showCountImage();
  }, []);

  const renderForm = ({ data, sub_index, question_index, qt, isSC, index }) => {
    let answer_data = paper.sub_categories[sub_index].questions[question_index];

    return (
      <>
        <FormControl>
          <RadioGroup
            // className="bg-slate-600"
            error={answer_data.answer === null}
            row
            aria-labelledby='demo-row-radio-buttons-group-label'
            name='row-radio-buttons-group'
            value={
              answer_data.answer === undefined
                ? null
                : index === undefined
                ? answer_data.answer
                : answer_data.answer[index] === undefined
                ? null
                : answer_data.answer[index]
            }
            // value={checkNg ? "NG" : checkNg === false ? "OK" : null}
            onChange={(e) => {
              setPaper((prev) => {
                let newSub_categories = [...prev.sub_categories];
                let newQuestion = newSub_categories[sub_index].questions;
                if (isSC) {
                  if (newQuestion[question_index].answer === undefined) {
                    newQuestion[question_index].answer = new Array(3).fill(
                      undefined
                    );
                  }
                  newQuestion[question_index].answer[index] = e.target.value;
                } else {
                  newQuestion[question_index] = {
                    ...newQuestion[question_index],
                    answer: e.target.value,
                  };
                }

                return {
                  ...prev,
                  sub_categories: newSub_categories,
                };
              });
            }}>
            <FormControlLabel
              value='OK'
              control={
                <Radio disabled={qt.spec_min && qt.spec_max ? true : false} />
              }
              label='OK'
              // checked={checkAnswer.ok === true}
            />
            <FormControlLabel
              value='NG'
              control={
                <Radio disabled={qt.spec_min && qt.spec_max ? true : false} />
              }
              label='NG'
              // checked={checkAnswer.ng === true}
            />
            {/* <FormControlLabel
              sx={{ display: "none" }}
              value={null}
              control={<Radio />}
              label="NG"
            /> */}
          </RadioGroup>
        </FormControl>

        <Box
          component='form'
          // className="bg-gray-700"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete='off'>
          {qt.spec_max !== null && qt.spec_min !== null && (
            <TextField
              // error={checkError()}
              id='outlined-number'
              label='Actual'
              type='number'
              size='small'
              // value={paper.sub_categories.questions.data.actual}
              onChange={(e) => {
                setPaper((prev) => {
                  let newSub_categories = [...prev.sub_categories];
                  let newQuestion = newSub_categories[sub_index].questions;
                  if (isSC) {
                    if (newQuestion[question_index].actual === undefined) {
                      newQuestion[question_index].actual = new Array(3).fill(
                        undefined
                      );
                    }
                    if (newQuestion[question_index].answer === undefined) {
                      newQuestion[question_index].answer = new Array(3).fill(
                        undefined
                      );
                    }
                    newQuestion[question_index].actual[index] = e.target.value;
                    newQuestion[question_index].answer[index] =
                      e.target.value === undefined
                        ? null
                        : checkAnswerNG(
                            newQuestion[question_index].actual,
                            qt.spec_min,
                            qt.spec_max,
                            isSC,
                            index
                          );
                  } else {
                    newQuestion[question_index].actual = e.target.value;
                    newQuestion[question_index].answer =
                      e.target.value === undefined
                        ? null
                        : checkAnswerNG(
                            newQuestion[question_index].actual,
                            qt.spec_min,
                            qt.spec_max,
                            isSC,
                            index
                          );
                  }
                  return {
                    ...prev,
                    sub_categories: newSub_categories,
                  };
                });
              }}
            />
          )}
          <TextField
            id='outlined-number'
            label='Comment'
            type='text'
            size='small'
            onChange={(e) => {
              setPaper((prev) => {
                let newSub_categories = [...prev.sub_categories];
                let newQuestion = newSub_categories[sub_index].questions;
                if (isSC) {
                  if (newQuestion[question_index].comment === undefined) {
                    newQuestion[question_index].comment = new Array(3).fill("");
                  }
                  newQuestion[question_index].comment[index] = e.target.value;
                } else {
                  newQuestion[question_index] = {
                    ...newQuestion[question_index],
                    comment: e.target.value,
                  };
                }
                return {
                  ...prev,
                  sub_categories: newSub_categories,
                };
              });
            }}
          />
        </Box>
        <div className='flex flex-col max-h-fit justify-end items-center '>
          <Fab
            // disabled
            color='primary'
            variant='contained'
            component='label'
            onClick={() =>
              handleClickOpen(
                sub_index,
                question_index,
                data.id,
                qt.id,
                index,
                isSC
              )
            }>
            <AddAPhotoIcon />
          </Fab>
          {isSC
            ? answer_data.image &&
              answer_data.image[index].length !== undefined &&
              answer_data.image[index].length !== 0 && (
                <div className='bg-orange-400 shadow-sm rounded-full p-1 pr-2 pl-2 text-white mt-1 '>
                  {answer_data.image[index].length + " รูป"}
                </div>
              )
            : answer_data.image && (
                <div className='bg-orange-400 shadow-sm rounded-full p- pr-2 pl-2 text-white mt-1'>
                  {answer_data.image.length + " รูป"}
                </div>
              )}
        </div>
      </>
    );
  };
  // const imagePart = config.server + paper.partImage;

  return (
    <>
      <div className='flex flex-col '>
        <SimpleBackdrop open={loading} setOpen={setLoading} />
        <div className='mb-4 flex flex-row items-center'>
          <div className=''>
            <Stack direction='row' spacing={1}>
              {/* <Chip label={`ครั้งที่: ${count + 1}`} /> */}
              <Chip label={"วันที่: " + date} />
              <Chip label={"ช่วงเวลา: " + shift} />
            </Stack>
          </div>
          <div className='ml-4'>
            <FormControl sx={{ minWidth: 100 }} size='small'>
              <TextField
                label='Shift'
                size='small'
                value={shiftAB}
                onChange={(e) => handleChangeShiftAB(e)}
                inputProps={{ maxLength: 1 }}
              />
              {/* <InputLabel id="demo-simple-select-label">Shift</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={shiftAB}
                label="Shift"
                onChange={(e) => handleChangeShiftAB(e)}
              >
                <MenuItem value="A">A</MenuItem>
                <MenuItem value="B">B</MenuItem>
              </Select> */}
            </FormControl>
          </div>
        </div>
        <div>
          <Snackbar
            open={alert.open}
            autoHideDuration={3000}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handleCloseAlert}>
            <Alert
              onClose={handleCloseAlert}
              severity={alert.type}
              sx={{ width: "100%" }}>
              {alert.massage}
            </Alert>
          </Snackbar>
        </div>
        <div className=' flex justify-center items-center '>
          {partImage !== "" ? (
            <div className=' flex justify-center items-center '>
              <img
                src={partImage}
                //config.server +
                className=' object-contain h-[300px]'
              />
            </div>
          ) : (
            <div
              style={{ width: "12rem", height: "12rem" }}
              className='bg-gray-100 flex items-center justify-center rounded-lg'>
              <NoPhotographyIcon sx={{ fontSize: 80 }} />
            </div>
          )}
        </div>
        {subCategories.length === 0 && (
          <div className='bg-white p-4 mb-4 mt-4 flex justify-center w-full rounded-md shadow-md'>
            <span> ไม่มีข้อมูล</span>
          </div>
        )}

        {/* <div className="overflow-scroll h-96"> */}
        {subCategories.map((data, sub_index) => (
          <div
            key={data.id}
            className='bg-white p-4 mb-4 mt-4 flex w-full flex-col rounded-md shadow-md'>
            {/* {data.questions.data !== "" && ( */}
            <div className='flex flex-row pb-4'>
              <div className='basis-1/6  flex items-center rounded-full text-xl'>
                <Chip
                  label={data.category.data.attributes.category}
                  variant='outlined'
                />
              </div>
              <div className='basis-1/2  flex items-center text-xl font-semibold'>
                {data.sub_categories}
              </div>
            </div>
            <hr />
            {/* )} */}
            <div className=''>
              {data.questions.data.map((qt, question_index) => (
                <div key={qt.id}>
                  <div className='text-lg mt-4 flex flex-row items-center'>
                    {qt.question}
                    {qt.spec_min !== null && qt.spec_max !== null && (
                      <div className='ml-2'>
                        {parseFloat(qt.spec_min).toFixed(2)} -{" "}
                        {parseFloat(qt.spec_max).toFixed(2)}
                      </div>
                    )}
                    {qt.sc_status === true && (
                      <div className='ml-2'>
                        {/* <b className="bg-slate-600">()</b> */}
                        <Chip label='SC' color='primary' variant='outlined' />
                      </div>
                    )}
                  </div>

                  {/* for (let index = 0; index < array.length; index++) {
                    const element = array[index];
                    
                  } */}
                  {/* {qt.sc_status=== true } */}
                  <div className='mt-2 mb-2  '>
                    {qt.sc_status === true ? (
                      new Array(3).fill(0).map((d, index) => {
                        return (
                          <div className='flex flex-col mt-4'>
                            <div>
                              <div className='flex flex-row items-center '>
                                {renderForm({
                                  data,
                                  sub_index,
                                  question_index,
                                  qt,
                                  isSC: qt.sc_status === true,
                                  index,
                                })}
                              </div>
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className='flex flex-row items-center'>
                        {renderForm({
                          data,
                          sub_index,
                          question_index,
                          qt,
                          isSC: qt.sc_status === true,
                        })}
                      </div>
                    )}
                    <hr className='mt-4' />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
        {/* </div> */}
        <div className='flex justify-end mb-2'>
          {subCategories.length !== 0 && (
            <Button
              variant='contained'
              size='large'
              onClick={() => handleOnClickSave()}>
              บันทึกข้อมูล
            </Button>
          )}
        </div>
        {/* <ConfirmAnswerDialog onClick={handleClickOpenDialog}/> */}
        <FormAddImageAnswer
          state={[paper, setPaper]}
          setOpen={setOpen}
          open={open}
          setSuccess={setSuccess}
          stateIndex={[index, setIndex]}
        />
        <ConfirmCreatePaper
          open={openSave.open}
          setOpen={setOpenSave}
          action={createAnswer}
          confirmText={"ใช่"}
          cancelText={"ยกเลิก"}
          header={"ยืนยันการบันทึกข้อมูล"}
          message={
            <span>
              คุณต้องการ
              <span>{openSave.message}</span>
            </span>
          }
        />
      </div>
    </>
  );
}

export default Paper;
