import * as React from "react";

import { Navigate, Outlet, useLocation } from "react-router-dom";

//import { Cookie } from "@mui/icons-material";
import menuItem from "./Layout/menuItem.jsx";
function ProtectedPage() {
  return <h3>Protected</h3>;
}

const AuthRoute = ({ children }) => {
  let location = useLocation();
  let user = JSON.parse(localStorage.getItem("QCAPPuser"));
  let locationArr = location.pathname.split("/").reverse();
  let lastPath = "";
  if (!user) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  for (const path of locationArr) {
    if (isNaN(parseInt(path)) && menuItem.some((group) => group.menu.some(menu => menu.path === path))) {
      lastPath = path;
      break;
    }
  }
  if(locationArr[0] === "" && user !== null){
    return <Navigate to={`/layout/part`} />
  }
  let checkRole = menuItem.some((group) => {
    const result = group.menu.some(menu => {
      console.log("in group", menu);
      let pathName = menu.path.split("/").reverse()[0];
      let checkSome = menu.role.some((role) => {
        return role === user.role;
      });
  
      let checkPath = pathName === lastPath;
      // console.log(checkSome, checkPath, pathName, lastPath);
      return checkSome && checkPath;
    })
    console.log(`${group.groupName}`, result);
    return result
  });
  console.log("checkRole", checkRole);
  return lastPath === "profile" || (user && checkRole) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} />
  );
};
export { AuthRoute, ProtectedPage };
