import { Button, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { APIServices } from "../helpers/APIServices";

export const searchQuestionByID = (questionID, subcategories) => {
  if (!Array.isArray(subcategories)) {
    throw Error("subcategorise should be array type").stack;
  }
  if (typeof questionID !== "string") {
    throw Error("questionID should be string type").stack;
  }
  let foundQuestion;
  for (let i = 0; i < subcategories.length; i++) {
    const subcategory = subcategories[i];
    let breakloopi = false;
    for (let j = 0; j < subcategory.questions.length; j++) {
      const question = subcategory.questions[j];
      if (question.id.toString() === questionID.toString()) {
        foundQuestion = question;
        breakloopi = true;
        break;
      }
    }
    if (breakloopi) {
      break;
    }
  }
  return foundQuestion;
};

const operands = [
  {
    type: "plus",
    operand: "+",
  },
  {
    type: "minus",
    operand: "-",
  },
  {
    type: "multiply",
    operand: "*",
  },
  {
    type: "divide",
    operand: "/",
  },
];

const parseAverageFn = (formula) => {
  if (typeof formula !== "string") {
    throw Error("formula should be string type").stack;
  }
  const regex = /AVERAGE\(.*?\)/g;
  // let test = formula.match(/{.*?}/g)
  const arrFound = formula.match(regex);
  if (arrFound !== null) {
    // console.log("parseAverageFn", arrFound);
    arrFound.forEach((averageFound) => {
      // console.log("averageFound", averageFound);
      const arrValue = `[${averageFound.substring(
        averageFound.indexOf("(") + 1,
        averageFound.indexOf(")")
      )}]`;
      const result = `(${arrValue}.reduce((prev, current) => prev + current) / ${arrValue}.length)`;
      // console.log("arrValue", result);
      // console.log("raw result", eval(result));
      formula = formula.replace(averageFound, result);
    });
  }
  return formula;
};

const parseSumFn = (formula) => {
  if (typeof formula !== "string") {
    throw Error("formula should be string type").stack;
  }
  const regex = /SUM\(.*?\)/g;
  const arrFound = formula.match(regex);
  if (arrFound !== null) {
    arrFound.forEach((sumFound) => {
      const arrValue = `[${sumFound.substring(
        sumFound.indexOf("(") + 1,
        sumFound.indexOf(")")
      )}]`;
      const result = `${arrValue}.reduce((prev, current) => prev + current)`;
      formula = formula.replace(sumFound, result);
    });
  }
  return formula;
};

export const checkFormulaError = (formula) => {
  try {
    // const formula = "(1+2)-MIN(2,4,1)+MAX(2,7,1)+AVERAGE(5,10,5)-AVERAGE(5,1,1)"
    if (typeof formula !== "string") {
      throw Error("formula should be string type").stack;
    }
    const firstJoin = formula.split("{").join("");
    const secondJoin = firstJoin.split("}").join("");
    const clearMAX = secondJoin.replaceAll("MAX", "Math.max");
    const clearMIN = clearMAX.replaceAll("MIN", "Math.min");
    const clearAVERAGE = parseAverageFn(clearMIN);
    const clearSUM = parseSumFn(clearAVERAGE);
    // console.log("checkFormulaError",eval(clearSUM));
    return false;
  } catch (error) {
    console.error(error);
    return true;
  }
};

export const calculateFormula = (formula) => {
  if (typeof formula !== "string") {
    throw Error("formula should be string type").stack;
  }
  const clearMAX = formula.replaceAll("MAX", "Math.max");
  const clearMIN = clearMAX.replaceAll("MIN", "Math.min");
  const clearAVERAGE = parseAverageFn(clearMIN);
  const clearSUM = parseSumFn(clearAVERAGE);
  const result = clearSUM;
  // console.log("calculateFormula 117", result);
  return result;
};

const Formula = ({ partID, formula, setFormula, setChangeData, rawSubcategory }) => {
  // console.log("formula 122", formula);
  const [subcategoriseData, setSubcategoriseData] = useState([]);
  // const [formula, setFormula] = useState()
  const [openSelectQuestion, setOpenSelectQuestion] = useState(false);

  useEffect(() => {
    const getSubcategorise = async () => {
      const response = await APIServices.findSubAndQuestionByPartId(partID);
      const result = response.data;
      if (result.data) {
        const partData = result.data;
        // console.log("subcategoriseData", partData);
        const part = {
          id: partData.id,
          ...partData.attributes,
          sub_categories: partData.attributes.sub_categories.data.map(
            (sub_categories) => ({
              id: sub_categories.id,
              ...sub_categories.attributes,
              questions: sub_categories.attributes.questions.data.map(
                (question) => ({
                  id: question.id,
                  ...question.attributes,
                })
              ),
            })
          ),
        };
        // console.log("part", part);
        setSubcategoriseData(part.sub_categories);
      }
    };
    if(rawSubcategory){
      setSubcategoriseData(rawSubcategory)
      return
    }
    getSubcategorise();
  }, [partID, rawSubcategory]);

  if (!partID) {
    return (
      <div className={"text-2xl font-bold drop-shadow bg-white rounded p-4"}>
        Component {`<Formula>`} required Part ID.
      </div>
    );
  }

  const handleOnChangeFormula = (e) => {
    // console.log("all event", e.target.value);
    const value = e.target.value;
    if (typeof value !== "string") {
      throw Error("e.target.value should be string type");
    }
    const lastText = value.substring(value.length - 1, value.length);
    if (lastText === "{") {
      setOpenSelectQuestion(true);
    } else {
      setOpenSelectQuestion(false);
    }
    setFormula(value.toUpperCase());
  };

  const handleOnKeyFormula = (e) => {
    // console.log("handleOnKeyFormula", e.code);
    // console.log("current value", e.target.value);
    const value = e.target.value;
    if (e.code === "Backspace") {
      const lastText = value.substring(value.length - 1, value.length);
      if (lastText === "}") {
        // console.log("delete !!!");
        const startIndex = formula.lastIndexOf("{");
        // console.log("startIndex", startIndex);
        setFormula((prev) => `${prev.substring(0, startIndex + 1)}`);
      }
    }
  };

  const handleOnSaveQuestion = (question) => {
    // console.log("question", question);
    // console.log(formula);
    setFormula((prev) => `${prev}${question.id}}`);
    setOpenSelectQuestion(false);
  };

  const parseFormulaToShow = (formula) => {
    try {
      if (subcategoriseData.length > 0 && formula) {
        if (typeof formula !== "string") {
          throw Error("formula should be string type");
        }
        // console.log("search", formula.indexOf("{"));
        let cloneFormula = `${formula}`;
        let arrFormula = formula.match(/{.*?}/g);
        if (arrFormula === null) return "";
        // console.log("arrFormula", arrFormula);
        arrFormula.forEach((questionID) => {
          // const rawQuestionID = parseInt(
          //   questionID.substring(1, questionID.length - 1)
          // );
          const rawQuestionID = questionID.substring(1, questionID.length - 1)
          console.log("rawQuestionID, subcategoriseData 223", rawQuestionID, subcategoriseData);
          const question = searchQuestionByID(rawQuestionID, subcategoriseData);
          console.log("question 224", question);
          cloneFormula = cloneFormula.replace(
            questionID,
            ` [${question.question}] `
          );
          // console.log("questionID", questionID);
        });
        return cloneFormula;
      }
      return "";
    } catch (error) {
      return formula
    }
  };

  // const createFormulaJSON = (formula, isFirst = true) => {
  //     try {
  //         console.log("fomula per round", formula);
  //         if(typeof formula !== "string"){
  //             throw Error("formula should be string type").stack
  //         }
  //         const firstCharacter = formula.charAt(0)
  //         // if(isFirst){

  //         // }
  //         if(firstCharacter === "("){
  //             const inBacket = formula.substring(1, formula.indexOf(")"))
  //             console.log("inBacket",inBacket);

  //             const setValue = createFormulaJSON(inBacket)
  //             console.log("setValue", setValue);
  //             const typeValue = createFormulaJSON(setValue.leftFormula)
  //             console.log('typeValue', typeValue);
  //             const withValue = createFormulaJSON(typeValue.leftFormula)
  //             console.log("withValue", withValue);
  //             return {
  //                 set: setValue.result,
  //                 type: typeValue.result,
  //                 with: withValue.result
  //             }
  //             const leftFormula = formula.substring(formula.indexOf(")")+1)
  //             console.log("leftFormula 172", leftFormula);
  //         }
  //         if(firstCharacter === "{"){
  //             const questionID = formula.substring(1, formula.indexOf("}"))
  //             console.log("questionID", questionID);
  //             return {
  //                 result :{
  //                     formula: {
  //                         questionID,
  //                         value: null
  //                     },
  //                 },
  //                 leftFormula: formula.substring(formula.indexOf("}") + 1)
  //             }
  //         }
  //         if(firstCharacter === "+" || firstCharacter === "-" || firstCharacter === "*" || firstCharacter === "/"){
  //             const type = operands.find(operandOG => operandOG.operand === firstCharacter)
  //             if(!type){
  //                 throw Error(`not found this oprand ${firstCharacter}`)
  //             }
  //             return {
  //                 result: type.type,
  //                 leftFormula: formula.substring(1)
  //             }
  //         }
  //     } catch (error) {
  //         console.error(Error(error).stack)
  //     }
  // }

  // const calculate = () => {
  //     let formula = '({143} + 100) / (5 * ( 10 - 15) )'
  //     let test = formula.match(/{.*?}/g)
  //     let id = "143"
  //     let dumpValue = 5
  //     let newTT = formula.replace(test[0],dumpValue)
  //     console.log(test)
  //     console.log(eval(newTT))
  // }

  // testMinMax()

  // if(subcategoriseData.length > 0){
  //     const myformula = createFormulaJSON("({234}+{236})/({237}*({236}-{234}))")
  //     console.log("myformula",myformula)
  // }

  return (
    <div className={`py-4`}>
      <div className={`bg-yellow-100`}>
        <TextField
          label="Preview formula :"
          multiline
          fullWidth
          value={`${parseFormulaToShow(formula)}`}
          disabled
        />
      </div>
      <div className={`mt-4`}>
        <TextField
          label="Formula"
          multiline
          fullWidth
          value={formula}
          disabled={openSelectQuestion}
          onChange={(e) => {
            setChangeData(true) 
            handleOnChangeFormula(e)
          }}
          onKeyDown={(e) => handleOnKeyFormula(e)}
        />
      </div>
      <div className={`mt-4`}>
        {openSelectQuestion && (
          <Question
            subcategoriseData={subcategoriseData}
            onSave={(question) => handleOnSaveQuestion(question)}
            onCancel={(e) => {
              setFormula((prev) => `${prev.substring(0, prev.length - 1)}`);
              setOpenSelectQuestion(false);
            }}
          />
        )}
      </div>
    </div>
  );
};

export default Formula;

const Question = ({ subcategoriseData, onCancel, onSave }) => {
  const [questions, setQuestions] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState();

  const handleOnClickCancel = () => {
    onCancel();
  };

  const handleOnSave = (selectedQuestion) => {
    if (selectedQuestion) {
      onSave(selectedQuestion);
    }
  };

  const filterCategory = (subcategoriseData) => {
    if(subcategoriseData.length > 0){
      if(subcategoriseData[0].category.data){
        return subcategoriseData.filter((sub) => sub.status === true && sub.category.data.attributes.category === "Dimension")
      }else{
        return subcategoriseData.filter((sub) => sub.status === true && sub.category.category === "Dimension")
      }
    }
    return []
  }

  // console.log("subcategoriseData 371", subcategoriseData);

  return (
    <div className={`bg-blue-300 p-4 rounded text-white`}>
      Please select question
      <div className={`flex justify-between`}>
        <div>Subcategories : </div>
        <Select
          onChange={(e) => setQuestions(e.target.value)}
          sx={{
            minWidth: "200px",
          }}
        >
          {filterCategory(subcategoriseData)
            // .filter((sub) => sub.status === true && sub.category.data.attributes.category === "Dimension")
            .map((subcategory) => (
              <MenuItem value={subcategory.questions}>
                {subcategory.sub_categories}
              </MenuItem>
            ))}
        </Select>
      </div>
      <div className={`flex justify-between mt-2`}>
        <div>Question : </div>
        <Select
          disabled={!questions}
          onChange={(e) => setSelectedQuestion(e.target.value)}
          sx={{
            minWidth: "200px",
          }}
        >
          {questions
            // .filter(
            //   (question) =>
            //     question.spec_min && question.spec_max && question.status
            // )
            .filter(
              (question) =>
                question.status
            )
            .map((question) => (
              <MenuItem value={question}>{question.question}</MenuItem>
            ))}
          {questions.filter(
            (question) => question.spec_min && question.spec_max
          ).length === 0 && (
            <MenuItem disabled value={false}>
              ไม่มีคำถามที่มี Actual
            </MenuItem>
          )}
        </Select>
      </div>
      <div className={`flex gap-4 justify-end mt-4`}>
        <Button onClick={() => handleOnClickCancel()} variant="contained">
          Cancel
        </Button>
        <Button
          disabled={selectedQuestion ? false : true}
          onClick={() => handleOnSave(selectedQuestion)}
          variant="contained"
        >
          Insert
        </Button>
      </div>
    </div>
  );
};
