/* eslint-disable no-eval */
import React, { useState, useEffect, useContext } from "react";
import { APIServices } from "../../helpers/APIServices";
import { useNavigate, useParams } from "react-router-dom";
import config from "../../config";
import {
  AlertTitle,
  Button,
  Chip,
  Fab,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Alert from "@mui/material/Alert";
import { useSnackbarStore } from "../../helpers/state";
import dayjs from "dayjs";
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import PhotoIcon from "@mui/icons-material/Photo";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { BootstrapDialog } from "./FormAddImageAnswer";
import FormEditImageAnswerV3 from "./FormEditImageAnswerV3";
import { UserContext } from "../../Layout";
import SimpleBackdrop from "../../Components/Backdrop";
import { calculateFormula, searchQuestionByID } from "../../Components/Formula";
import { processStatus } from "./PaperV2";
import { Add, Remove } from "@mui/icons-material";
import _ from "lodash";
import SignatureBox from "../../Components/SignatureBox";
import { parseStatus, sortData } from "../../helpers/common";
import NextPlanIcon from "@mui/icons-material/NextPlan";
// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

const EditPaperV3 = () => {
  const param = useParams();
  const paperID = param.idPaper;
  const [paper, setPaper] = useState();
  const [isOpenPartPhoto, setIsOpenPartPhoto] = useState(false);
  const [openEditPhoto, setOpenEditPhoto] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState();
  const [loading, setLoading] = useState(false);
  const [subCategories, setSubCategories] = useState([]);
  const navigate = useNavigate();
  const [alertPayload, setAlertPayload] = useState({
    open: false,
    type: "info",
    message: "",
    duration: 3000,
  });
  const userContext = useContext(UserContext);
  const [inputPerQuestion, setInputPerQuestion] = useState([]);
  const snackBarState = useSnackbarStore();

  const searchSubcategoriesName = (subCategories, subID) => {
    const result = subCategories.find((subData) => subData.id === subID);
    const subCategoriesName = result.attributes.sub_categories || "";
    const revise_time = result.attributes.revise_time;
    const categoryName =
      result.attributes.category.data.attributes.category || "";
    const questions = result.attributes.questions.data || [];
    return {
      subCategoriesName,
      categoryName,
      questions,
      revise_time,
    };
  };

  const checkNG = (subCategoryIndex, questionIndex, index) => {
    if (paper) {
      const currentAnswer =
        paper.dataSub[subCategoryIndex].questionForAnswer[questionIndex].answer[
          index
        ];

      if (currentAnswer === "NG") {
        return true;
      }
    }
  };

  const handleDeleteQuestion = (sub_index, ques_index) => {
    const clonePaper = _.cloneDeep(paper);

    let index = inputPerQuestion[sub_index][ques_index] - 1;
    clonePaper.dataSub[sub_index].questionForAnswer[ques_index].actual[index] =
      "";
    clonePaper.dataSub[sub_index].questionForAnswer[ques_index].answer[index] =
      "";
    clonePaper.dataSub[sub_index].questionForAnswer[ques_index].comment[index] =
      "";
    clonePaper.dataSub[sub_index].questionForAnswer[ques_index].imageURL[
      index
    ] = [];
    setPaper((prev) => {
      return clonePaper;
    });
    // setKeepPaper(clonePaper, sub_index, ques_index);
    setInputPerQuestion((prev) => {
      const cloneInputPerQuestion = [...prev];
      cloneInputPerQuestion[sub_index][ques_index] = index;
      return cloneInputPerQuestion;
    });
  };

  const handleChangeAnswer = (e, question, questionID, subID, index) => {
    try {
      if (paper) {
        const clonePaper = { ...paper };
        const cloneSub = clonePaper.dataSub.find((sub) => sub.subID === subID);
        const cloneQuestion = cloneSub.questionForAnswer.find(
          (question) => question.questionID === questionID
        );
        cloneQuestion.answer[index] = e.target.value;
        setPaper(clonePaper);
      }
    } catch (error) {
      console.log("error");
    }
  };

  // const handleOnBlurActual = (e, subcategoryID, questionID, index, subCat) => {
  const handleOnBlurActual = (e, subIndex, questionIndex, index, subCat) => {
    const actual = isNaN(parseFloat(e.target.value))
      ? ""
      : parseFloat(e.target.value);
    const clonePaper = _.cloneDeep(paper);
    // console.log("clonePaper 242", clonePaper);
    // const cloneSubcategory = clonePaper.dataSub.find(
    //   (subcategory) => subcategory.subID === subcategoryID
    // );
    // const cloneQuestion = cloneSubcategory.questionForAnswer.find(
    //   (question) => question.questionID === questionID
    // );
    // if (!isNaN(actual)) {
    //   cloneQuestion.answer[index] =
    //     cloneQuestion.spec_min <= actual && actual <= cloneQuestion.spec_max
    //       ? "OK"
    //       : "NG";
    // } else {
    //   cloneQuestion.answer[index] = "";
    // }
    // cloneQuestion.actual[index] = actual;
    // setPaper(clonePaper);
    const cloneSubCategories = subCategories;
    const subCategoryMaster = cloneSubCategories[subIndex];
    const questionMaster = subCategoryMaster.questions[questionIndex];
    const { spec_min, spec_max } = questionMaster;
    // console.log("254 ", subCategoryMaster, questionMaster);
    // console.log("254 2", spec_min, spec_max);
    console.log(
      "254",
      clonePaper.dataSub[subIndex].questionForAnswer[questionIndex],
      clonePaper
    );
    if (!isNaN(actual)) {
      if (spec_min !== null && spec_max !== null) {
        clonePaper.dataSub[subIndex].questionForAnswer[questionIndex].answer[
          index
        ] = spec_min <= actual && actual <= spec_max ? "OK" : "NG";
      } else {
        clonePaper.dataSub[subIndex].questionForAnswer[questionIndex].answer[
          index
        ] = "";
      }
    } else {
      clonePaper.dataSub[subIndex].questionForAnswer[questionIndex].answer[
        index
      ] = "";
    }
    clonePaper.dataSub[subIndex].questionForAnswer[questionIndex].actual[
      index
    ] = actual;
    setPaper(clonePaper);
  };

  const handleOnChangeComment = (e, subcategoryID, questionID, index) => {
    const comment = e.target.value;
    const clonePaper = { ...paper };
    const cloneSubcategory = clonePaper.dataSub.find(
      (subcategory) => subcategory.subID === subcategoryID
    );
    const cloneQuestion = cloneSubcategory.questionForAnswer.find(
      (question) => question.questionID === questionID
    );
    cloneQuestion.comment[index] = comment;
    setPaper(clonePaper);
  };

  const checkDisableInput = (question, index, isActualCommentAndPicture) => {
    if (question.spec_min && question.spec_max) {
      if (isActualCommentAndPicture) {
        if (question) {
          if (index !== 0) {
            const currentValue = question.answer[index - 1];
            if (!currentValue || currentValue === "") {
              return true;
            }
          }
        }
        return false;
      }
      return true;
    } else {
      if (question) {
        if (index !== 0) {
          const currentValue = question.answer[index - 1];
          if (!currentValue || currentValue === "") {
            return true;
          }
        }
      }
      return false;
    }
  };

  const handleOnClickEditPhoto = (
    question,
    subCatIndex,
    questionIndex,
    index,
    subID,
    questionID
  ) => {
    setOpenEditPhoto(true);
    setSelectedQuestion({
      question,
      subCatIndex,
      questionIndex,
      index,
      subID,
      questionID,
    });
  };

  const IconCheckNG = ({ show, isNG }) => {
    if (show) {
      if (isNG) {
        return <ErrorIcon color='error' fontSize='large' />;
      } else {
        return <CheckCircleIcon color='success' fontSize='large' />;
      }
    }
    return <div></div>;
  };

  const findOgQuestion = (subcategoryID, questionID) => {
    const subcategory = subCategories.find(
      (subcategory) => subcategory.id === subcategoryID
    );
    const question = subcategory.questions.find(
      (question) => question.id === questionID
    );
    return question;
  };

  const findSubcategoryByID = (subcategoryID) => {
    return subCategories.find(
      (subcategory) => subcategory.id === subcategoryID
    );
  };

  const validatePaper = (paper) => {
    const allSubcategoriesError = [];
    const NGQuestions = [];
    paper.dataSub.forEach((subcategory, subcategoryIndex) => {
      const arrQuestionError = [];
      subcategory.questionForAnswer.forEach((question, questionIndex) => {
        const isSkip = question.skipQuestion;
        if (isSkip) {
          return;
        }
        const questionData = findOgQuestion(
          subcategory.subID,
          question.questionID
        );
        if (
          subcategory.category === "Dimension" &&
          question.spec_min === null &&
          question.spec_max === null
        ) {
          const isNotEmpty = question.actual.some((actual) => actual !== "");
          // console.log("question.actual", isNotEmpty);
          if (!isNotEmpty) {
            // console.log("question 727", questionData.question);
            arrQuestionError.push(questionData.question);
            return;
          } else {
            return;
          }
        }
        const answerError = [];
        if (questionData.status === true) {
          question.answer.forEach((answer, answerIndex) => {
            if (!answer) {
              answerError.push(answerIndex);
            } else {
              if (answer === "NG") {
                // TODO: check comment and photo
                let NGrequired = false;
                if (question.comment[answerIndex] === "") {
                  NGrequired = true;
                }
                if (question.imageURL[answerIndex].length === 0) {
                  NGrequired = true;
                }
                if (NGrequired) {
                  arrQuestionError.push(questionData.question);
                }
                NGQuestions.push({
                  questionOG: questionData,
                  questionValue: question,
                });
              }
            }
          });

          if (answerError.length === question.answer.length) {
            arrQuestionError.push(questionData.question);
          }
          if (
            question.answer.length - answerError.length !==
            inputPerQuestion[subcategoryIndex][questionIndex]
          ) {
            arrQuestionError.push(questionData.question);
          }
        }

        // if (question.sc_status === true) {
        //   if (answerError.length > 0) {
        //     arrQuestionError.push(question.question);
        //   }
        // } else {
        //   if (answerError.length === question.answer.length) {
        //     arrQuestionError.push(question.question);
        //   }
        // }
      });
      if (arrQuestionError.length > 0) {
        const subcategoryData = findSubcategoryByID(subcategory.subID);
        allSubcategoriesError.push({
          subcategoriesError: subcategoryData.sub_categories,
          questionsError: arrQuestionError,
        });
      }
    });
    if (allSubcategoriesError.length > 0) {
      setAlertPayload({
        duration: 5000,
        type: "error",
        message: (
          <div>
            <div className={`text-lg`}>
              กรุณากรอกข้อมูลให้ครบ หาก NG กรุณา Comment และเพิ่มรูปภาพ
            </div>
            {allSubcategoriesError.map(
              (subcategoryError, subcategoryErrorIndex) => (
                <div key={`errorsubcontainer${subcategoryErrorIndex}`}>
                  <div
                    className={`text-lg font-semibold`}>{`${subcategoryError.subcategoriesError}`}</div>
                  {subcategoryError.questionsError.map(
                    (question, questionErrorIndex) => (
                      <div key={`alert${questionErrorIndex}`}>
                        Check point: {question}
                      </div>
                    )
                  )}
                </div>
              )
            )}
          </div>
        ),
        open: true,
      });
      return {
        isValid: false,
        checkData: undefined,
      };
    } else {
      return {
        isValid: true,
        checkData: {
          NGQuestions,
        },
      };
    }
  };
  const updatePaper = async () => {
    try {
      if (!userContext) {
        throw Error("userContext is empty").stack;
      }
      if (!paper.process_status) {
        setAlertPayload({
          duration: 3000,
          type: "error",
          message: "กรุณาเลือก Process",
          open: true,
        });
        return;
      }
      if (paper.shiftAB === "none") {
        setAlertPayload({
          duration: 3000,
          type: "error",
          message: "กรุณากรอกข้อมูล Shift",
          open: true,
        });
        return;
      }
      const validate = validatePaper(paper);
      if (validate.isValid) {
        let dataSub = [];
        const subCatFromDataSub = paper.dataSub;
        for (
          let indexSubFromDataSub = 0;
          indexSubFromDataSub < subCatFromDataSub.length;
          indexSubFromDataSub++
        ) {
          let dataQuestion = [];
          const resSubCatFromDataSub = subCatFromDataSub[indexSubFromDataSub];
          for (
            let indexQuestionFromDataSub = 0;
            indexQuestionFromDataSub <
            resSubCatFromDataSub.questionForAnswer.length;
            indexQuestionFromDataSub++
          ) {
            const resQuestionFromDataSub =
              resSubCatFromDataSub.questionForAnswer[indexQuestionFromDataSub];
            dataQuestion.push({
              isSc: resQuestionFromDataSub.sc_status,
              actual: resQuestionFromDataSub.actual,
              answer: resQuestionFromDataSub.answer,
              comment: resQuestionFromDataSub.comment,
              imageURL: resQuestionFromDataSub.imageURL,
              questionId: resQuestionFromDataSub.questionID,
              questionName: resQuestionFromDataSub.question,
              spec_min: resQuestionFromDataSub.spec_min,
              spec_max: resQuestionFromDataSub.spec_max,
              revise_time: resQuestionFromDataSub.revise_time,
              skipQuestion: resQuestionFromDataSub.skipQuestion,
            });
          }
          dataSub.push({
            subId: resSubCatFromDataSub.subID,
            questions: dataQuestion,
            revise_time_subCat: resSubCatFromDataSub.revise_time_subCat,
            category: resSubCatFromDataSub.category,
            sub_category: resSubCatFromDataSub.subCategoriesName,
          });
        }
        paper.answer.sub_categories = dataSub;

        if (paper.answer.sub_categories) {
          setLoading(true);
          let newPayload = { ...paper };
          for (
            let subcategoryIndex = 0;
            subcategoryIndex < paper.answer.sub_categories.length;
            subcategoryIndex++
          ) {
            const subcategory = paper.answer.sub_categories[subcategoryIndex];
            for (
              let questionIndex = 0;
              questionIndex < subcategory.questions.length;
              questionIndex++
            ) {
              const question = subcategory.questions[questionIndex];
              for (
                let indexImage = 0;
                indexImage < question.imageURL.length;
                indexImage++
              ) {
                const arrImage = question.imageURL[indexImage];
                for (
                  let indexPerImage = 0;
                  indexPerImage < arrImage.length;
                  indexPerImage++
                ) {
                  const image = arrImage[indexPerImage];
                  if (typeof image === "string") {
                    newPayload.answer.sub_categories[
                      subcategoryIndex
                    ].questions[questionIndex].imageURL[indexImage][
                      indexPerImage
                    ] = image;
                  } else {
                    const formData = new FormData();
                    formData.append("files", image);
                    const responseFromAddImage = await APIServices.addUserImage(
                      formData
                    );
                    newPayload.answer.sub_categories[
                      subcategoryIndex
                    ].questions[questionIndex].imageURL[indexImage][
                      indexPerImage
                    ] = responseFromAddImage.data[0].url;
                  }
                }
              }
            }
          }
          const responseFromEditPaper = await APIServices.updatePaper(
            newPayload.answer,
            paper.shiftAB,
            paper.id,
            paper.process_status
          );
          if (responseFromEditPaper.status === 200) {
            setTimeout(() => {
              setLoading(false);
              setAlertPayload({
                duration: 3000,
                type: "success",
                message: "แก้ไขข้อมูล paper สำเร็จ",
                open: true,
              });

              navigate(-1);
            }, 1500);
          } else {
            throw Error(responseFromEditPaper.data).stack;
          }
        }
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const handleCalculate = (
    question,
    subcategoryIndex,
    questionIndex,
    index,
    formula,
    min,
    max
  ) => {
    try {
      const clonePaper = { ...paper };
      const formulaData = formula.data;

      if (typeof formulaData !== "string") {
        throw Error("formula should be string type").stack;
      }
      if (typeof min !== "number" || typeof max !== "number") {
        throw Error("min or max should be number type").stack;
      }

      let parsedFormula = formulaData;
      const arrQuestionID = formulaData.match(/{.*?}/g);
      if (arrQuestionID !== null) {
        arrQuestionID.forEach((questionIDandBacket) => {
          const questionIDInFormula = parseInt(
            questionIDandBacket.substring(1, questionIDandBacket.length - 1)
          );
          const questionValue = findValueInPaperByQuestionID(
            questionIDInFormula,
            index
          );
          if (questionValue.actual === "") {
            const requiredQuestion = searchQuestionByID(
              questionIDInFormula,
              subCategories
            );
            throw Error(
              `กรุณตอบคำถาม ${requiredQuestion.question} ช่องที่ ${index + 1}`
            );
          }
          parsedFormula = parsedFormula.replace(
            questionIDandBacket,
            questionValue.actual
          );
        });
      }
      parsedFormula = calculateFormula(parsedFormula);
      const actual = parseFloat(parseFloat(eval(parsedFormula)).toFixed(2));
      clonePaper.dataSub[subcategoryIndex].questionForAnswer[
        questionIndex
      ].answer[index] = min <= actual && actual <= max ? "OK" : "NG";
      clonePaper.dataSub[subcategoryIndex].questionForAnswer[
        questionIndex
      ].actual[index] = actual;
      setPaper(clonePaper);
    } catch (error) {
      console.error(error);
      setAlertPayload({
        duration: 5000,
        type: "error",
        message: `${error}`,
        open: true,
      });
    }
  };

  const findValueInPaperByQuestionID = (questionID, index) => {
    if (typeof questionID !== "number") {
      throw Error(
        `questionID should be string type. typeof questionID is '${typeof questionID}'`
      ).stack;
    }
    const clonePaper = { ...paper };
    let questionValue;
    for (let i = 0; i < clonePaper.dataSub.length; i++) {
      const subcategory = clonePaper.dataSub[i];
      const foundQuestionValue = subcategory.questionForAnswer.find(
        (question) => question.questionID === questionID
      );
      if (foundQuestionValue) {
        questionValue = foundQuestionValue;
        break;
      }
    }
    return {
      actual: questionValue.actual[index],
    };
  };

  const handleChangeShiftAB = (event) => {
    const eventUpperCase = event.target.value.toUpperCase();
    setPaper((prev) => {
      return {
        ...prev,
        shiftAB: eventUpperCase,
      };
    });
    // setShiftTeam(eventUpperCase);
  };

  const handleChangeProcess = (event) => {
    setPaper((prev) => {
      return {
        ...prev,
        process_status: event.target.value,
      };
    });
    // setProcess(event.target.value); //id
  };

  const checkUpdateButton = () => {
    if (paper.checker_id.data === null) {
      return true;
    }
    if (paper.checker_id.data) {
      if (paper.status === "3") {
        return true;
      }
    }
    if (paper.approver_id.data) {
      if (paper.status === "5") {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    const findPaper = async () => {
      setLoading(true);
      const findPaper = await APIServices.findOnePaperForEditPaper(paperID);
      const res = findPaper.data.data;
      const paperData = {
        id: res.id,
        ...res.attributes,
      };
      if (paperData) {
        let dataSub = [];
        const subCat = paperData.partName.data.attributes.sub_categories.data;
        const subCatFromAnswer = paperData.answer.sub_categories;

        const subCategories = subCat
          .map((subCategory) => ({
            id: subCategory.id,
            ...subCategory.attributes,
            questions: subCategory.attributes.questions.data
              .map((question) => ({
                id: question.id,
                error: {
                  actual: false,
                  answer: false,
                  comment: false,
                  imageURL: false,
                },
                ...question.attributes,
              }))
              .filter((question) => question.status === true),
          }))
          .filter((subcategory) => subcategory.status === true);

        setSubCategories(subCategories);
        const inputPerQuestion = [];
        for (
          let indexSubFromAnswer = 0;
          indexSubFromAnswer < subCatFromAnswer.length;
          indexSubFromAnswer++
        ) {
          let dataQuestion = [];
          const resSubCatFromAnswer = subCatFromAnswer[indexSubFromAnswer];
          const realSubCat = searchSubcategoriesName(
            subCat,
            resSubCatFromAnswer.subId
          );
          inputPerQuestion.push([]);
          for (
            let indexQuestionFromAnswer = 0;
            indexQuestionFromAnswer < resSubCatFromAnswer.questions.length;
            indexQuestionFromAnswer++
          ) {
            const resQuestionFromAnswer =
              resSubCatFromAnswer.questions[indexQuestionFromAnswer];

            const resultQuestion = realSubCat.questions.find(
              (questionPerLoop) =>
                questionPerLoop.id === resQuestionFromAnswer.questionId
            );

            let checkAnswer = resQuestionFromAnswer
              ? resQuestionFromAnswer.answer.filter((d) => d !== "").length
              : 1;
            if (resSubCatFromAnswer.category === "Dimension") {
              checkAnswer = resQuestionFromAnswer
                ? resQuestionFromAnswer.actual.filter((d) => d !== "").length
                : 1;
            }
            inputPerQuestion[indexSubFromAnswer].push(checkAnswer);
            dataQuestion.push({
              paperID: paperData.id,
              questionID: resultQuestion.id,
              question: resultQuestion.attributes.question,
              spec_min: resultQuestion.attributes.spec_min,
              spec_max: resultQuestion.attributes.spec_max,
              sc_status: resultQuestion.attributes.sc_status,
              actual: resQuestionFromAnswer.actual,
              answer: resQuestionFromAnswer.answer,
              comment: resQuestionFromAnswer.comment,
              imageURL: resQuestionFromAnswer.imageURL,
              formula: resultQuestion.attributes.formula,
              revise_time: resQuestionFromAnswer.revise_time,
              question_status: resultQuestion.attributes.status,
              skipQuestion: !!resQuestionFromAnswer.skipQuestion,
            });
          }
          dataSub.push({
            subID: resSubCatFromAnswer.subId,
            category: realSubCat.categoryName,
            subCategoriesName: realSubCat.subCategoriesName,
            questionForAnswer: dataQuestion,
            revise_time_subCat: realSubCat.revise_time,
          });
        }
        console.log("inputPerQuestion 183", inputPerQuestion);
        setInputPerQuestion(inputPerQuestion);
        setPaper({
          id: res.id,
          dataSub: dataSub,
          ...res.attributes,
        });
      }
      setLoading(false);
    };
    findPaper();
  }, [paperID]);

  return (
    <>
      <SimpleBackdrop open={loading} setOpen={setLoading} />
      {/* <ProgressLoading open={loading} setOpen={setLoading} /> */}
      {paper && (
        <div>
          {openEditPhoto && (
            <FormEditImageAnswerV3
              paper={paper}
              setPaper={setPaper}
              select={selectedQuestion}
              setOpen={setOpenEditPhoto}
              open={openEditPhoto}
            />
          )}
          {isOpenPartPhoto && (
            <BootstrapDialog
              open={isOpenPartPhoto}
              onClose={() => setIsOpenPartPhoto(false)}>
              <img src={`${config.server}${paper.answer.partImage}`} alt='' />
            </BootstrapDialog>
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={snackBarState.open}
            autoHideDuration={6000}
            onClose={snackBarState.onClose}>
            <Alert severity={snackBarState.type}>{snackBarState.message}</Alert>
          </Snackbar>
          <Snackbar
            open={alertPayload.open}
            autoHideDuration={alertPayload.duration}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={() =>
              setAlertPayload((prev) => ({ ...prev, open: false }))
            }>
            <Alert
              severity={alertPayload.type}
              onClose={() =>
                setAlertPayload((prev) => ({ ...prev, open: false }))
              }>
              {alertPayload.message}
            </Alert>
          </Snackbar>
          {paper && (
            <div className={`mb-2`}>
              <Typography variant='h4'>
                {paper.partName.data.attributes.part_name}
              </Typography>
              <Typography variant='h6'>
                {paper.partName.data.attributes.part_code}
              </Typography>
            </div>
          )}
          <div className='flex flex-row justify-between'>
            <div className=' flex flex-row items-center'>
              <div>
                <Stack direction='row' spacing={1}>
                  {/* <Chip label={`ครั้งที่: ${dataFromLocation.state.index}`} /> */}
                  <Chip
                    label={`วันที่: ${
                      paper && dayjs(paper.create_datetime).format("DD-MM-YYYY")
                    }`}
                  />
                  <Chip label={`ช่วงเวลา: ${paper && paper.shift}`} />
                </Stack>
              </div>
              <div className={`ml-4`}>
                <FormControl
                  size={`small`}
                  sx={{
                    minWidth: 120,
                    m: 1,
                  }}>
                  <InputLabel id='shiftlabel'>Shift</InputLabel>
                  <Select
                    labelId='shiftlabel'
                    label='Process'
                    id='shiftSelect'
                    defaultValue={paper.shiftAB}
                    renderValue={(e) => {
                      if (e === "none")
                        return (
                          <span className={`text-gray-400`}>{`${e}`}</span>
                        );
                      return e;
                    }}
                    onChange={handleChangeShiftAB}>
                    <MenuItem value={`none`}>none</MenuItem>
                    <MenuItem value={`A`}>A</MenuItem>
                    <MenuItem value={`B`}>B</MenuItem>
                  </Select>
                </FormControl>
                {/* <TextField
                  label="Shift"
                  size="small"
                  value={paper.shiftAB ? paper.shiftAB : ""}
                  onChange={(e) => handleChangeShiftAB(e)}
                  inputProps={{ maxLength: 1 }}
                /> */}
              </div>
              <div className={`ml-4`}>
                <FormControl sx={{ m: 1, minWidth: 120 }} size='small'>
                  <InputLabel>Process</InputLabel>
                  <Select
                    value={paper.process_status ? paper.process_status : ""}
                    label='Process'
                    onChange={(e) => handleChangeProcess(e)}>
                    {processStatus.map((data, index) => (
                      <MenuItem key={index} value={data.name}>
                        {data.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>
          {paper &&
            paper.remark &&
            paper.remark.map((remark, remarkIndex) => {
              // statusRevise
              return (
                <div key={remarkIndex} className='mb-2'>
                  {remark.status &&
                    (remark.status === "2" || remark.status === "4" ? (
                      <Alert severity='success'>
                        <AlertTitle>{parseStatus(remark.status)}</AlertTitle>
                        <AlertTitle>{remark.remark}</AlertTitle>
                        {remark.userFirstName +
                          " " +
                          remark.userLastName} — <strong>{remark.role}</strong>
                      </Alert>
                    ) : (
                      <Alert severity='error'>
                        <AlertTitle>{parseStatus(remark.status)}</AlertTitle>
                        <AlertTitle>{remark.remark}</AlertTitle>
                        {remark.userFirstName +
                          " " +
                          remark.userLastName} — <strong>{remark.role}</strong>
                      </Alert>
                    ))}
                </div>
              );
            })}

          {paper && (
            <div className={`flex flex-col lg:flex-row relative gap-4 mt-4`}>
              <div className={` lg:relative`}>
                <div
                  className={`flex justify-center items-center relative lg:sticky top-0 rounded-md drop-shadow-md bg-white`}>
                  {paper && paper.answer.partImage !== "" ? (
                    <div
                      className={`mt-4 mb-4 w-full flex justify-center cursor-pointer`}
                      onClick={() => setIsOpenPartPhoto(true)}>
                      <img
                        src={paper.answer.partImage}
                        //config.server +
                        className=' object-contain h-[300px]'
                        alt=''
                      />
                    </div>
                  ) : (
                    <div
                      style={{ width: "12rem", height: "12rem" }}
                      className='bg-gray-100 flex items-center justify-center rounded-lg m-2'>
                      <NoPhotographyIcon sx={{ fontSize: 80 }} />
                    </div>
                  )}
                </div>
              </div>

              <div
                className={`h-96 lg:h-full overflow-auto lg:overflow-visible lg:relative rounded-md space-y-4`}>
                {paper ? (
                  paper.dataSub.map(
                    (subCatData, subCatIndex) =>
                      subCatData.questionForAnswer.length !== 0 && (
                        <div
                          key={subCatIndex}
                          className={`bg-white drop-shadow p-4 rounded-md`}>
                          <div className='flex flex-row pb-4'>
                            <div className='flex items-center rounded-full text-xl'>
                              <Chip
                                label={subCatData.category}
                                variant='outlined'
                              />
                            </div>
                            <div className='flex items-center text-xl font-semibold ml-4'>
                              {subCatData.subCategoriesName}
                            </div>
                          </div>
                          <hr />
                          {sortData(subCatData.questionForAnswer, "question")
                            // subCatData.questionForAnswer
                            .filter((qt) => qt.question_status === true)
                            .map((question, indexQuestion) => {
                              return (
                                <div key={indexQuestion}>
                                  <div className='text-lg mt-4 flex flex-row items-center'>
                                    {question.question}
                                    {question.spec_min !== null &&
                                      question.spec_max !== null && (
                                        <div className={`flex gap-2 ml-4`}>
                                          <span>
                                            {parseFloat(
                                              question.spec_min
                                            ).toFixed(2)}
                                          </span>
                                          <span>~</span>
                                          <span>
                                            {parseFloat(
                                              question.spec_max
                                            ).toFixed(2)}
                                          </span>
                                        </div>
                                      )}
                                    {question.sc_status && (
                                      <div className='ml-2'>
                                        <Chip
                                          label='SC'
                                          color='primary'
                                          variant='outlined'
                                        />
                                      </div>
                                    )}
                                  </div>
                                  <div className={`mt-4`}>
                                    {question.skipQuestion && (
                                      <div
                                        className={`py-2 text-lg font-semibold text-blue-500`}>
                                        Skiped question{" "}
                                        <NextPlanIcon color='primary' />
                                      </div>
                                    )}
                                    {Array(
                                      inputPerQuestion[subCatIndex][
                                        indexQuestion
                                      ]
                                        ? inputPerQuestion[subCatIndex][
                                            indexQuestion
                                          ]
                                        : 0
                                    )
                                      .fill(0)
                                      .map((d, index) => {
                                        return (
                                          <div
                                            key={index}
                                            className='flex mt-4'>
                                            {subCatData.category ===
                                              "Dimension" &&
                                            question.spec_min === null &&
                                            question.spec_max === null ? (
                                              <div
                                                className={`w-[141px]`}></div>
                                            ) : (
                                              <FormControl key={index}>
                                                <RadioGroup
                                                  row
                                                  onChange={(e) =>
                                                    handleChangeAnswer(
                                                      e,
                                                      question,
                                                      question.questionID,
                                                      subCatData.subID,
                                                      index
                                                    )
                                                  }>
                                                  <FormControlLabel
                                                    value={`OK`}
                                                    label='OK'
                                                    disabled={checkDisableInput(
                                                      question,
                                                      index,
                                                      true
                                                    )}
                                                    control={
                                                      <Radio
                                                        checked={
                                                          question.answer[
                                                            index
                                                          ] === "OK"
                                                        }
                                                      />
                                                    }
                                                  />
                                                  <FormControlLabel
                                                    value={`NG`}
                                                    label='NG'
                                                    disabled={checkDisableInput(
                                                      question,
                                                      index,
                                                      true
                                                    )}
                                                    control={
                                                      <Radio
                                                        checked={
                                                          question.answer[
                                                            index
                                                          ] === "NG"
                                                        }
                                                      />
                                                    }
                                                  />
                                                </RadioGroup>
                                              </FormControl>
                                            )}

                                            <div
                                              className={`flex gap-4 items-center`}>
                                              {((question.spec_min !== null &&
                                                question.spec_max !== null) ||
                                                subCategories[subCatIndex]
                                                  .category.data.attributes
                                                  .category === "Dimension") &&
                                                (question.formula &&
                                                question.formula.data ? (
                                                  <div className='flex'>
                                                    <div
                                                      className={`cursor-default border border-gray-500 rounded h-10 w-12 px-2 flex items-center justify-center`}>
                                                      {question.actual
                                                        ? question.actual[index]
                                                        : ""}
                                                    </div>
                                                    <div
                                                      className={`cursor-pointer ml-4 bg-blue-500 hover:bg-blue-600 h-10 w-24 rounded flex justify-center items-center text-white`}
                                                      onClick={() =>
                                                        handleCalculate(
                                                          question,
                                                          subCatIndex,
                                                          indexQuestion,
                                                          index,
                                                          question.formula,
                                                          question.spec_min,
                                                          question.spec_max
                                                        )
                                                      }>
                                                      คำนวณ
                                                    </div>
                                                  </div>
                                                ) : (
                                                  <TextField
                                                    label='Actual'
                                                    type='number'
                                                    size='small'
                                                    defaultValue={
                                                      question.actual[index] &&
                                                      question.actual[index]
                                                    }
                                                    onBlur={(e) =>
                                                      handleOnBlurActual(
                                                        e,
                                                        subCatIndex,
                                                        indexQuestion,
                                                        index,
                                                        subCatData
                                                      )
                                                    }
                                                    onWheel={(event) =>
                                                      event.target.blur()
                                                    }
                                                    disabled={checkDisableInput(
                                                      question,
                                                      index,
                                                      true
                                                    )}
                                                  />
                                                ))}
                                              <TextField
                                                label='Comment'
                                                type='text'
                                                size='small'
                                                defaultValue={
                                                  question.comment[index]
                                                }
                                                onBlur={(e) =>
                                                  handleOnChangeComment(
                                                    e,
                                                    subCatData.subID,
                                                    question.questionID,
                                                    index
                                                  )
                                                }
                                                disabled={checkDisableInput(
                                                  question,
                                                  index,
                                                  true
                                                )}
                                              />
                                              <div className='flex gap-4 items-center'>
                                                <Fab
                                                  onClick={() =>
                                                    handleOnClickEditPhoto(
                                                      question,
                                                      subCatIndex,
                                                      indexQuestion,
                                                      index,
                                                      subCatData.subID,
                                                      question.questionID
                                                    )
                                                  }
                                                  disabled={checkDisableInput(
                                                    question,
                                                    index,
                                                    true
                                                  )}
                                                  color='primary'
                                                  variant='contained'
                                                  component='label'>
                                                  {index !== undefined &&
                                                  question.imageURL &&
                                                  question.imageURL[index]
                                                    .length > 0 ? (
                                                    `${
                                                      question.imageURL[index]
                                                        .length + " รูป"
                                                    }`
                                                  ) : (
                                                    <PhotoIcon />
                                                  )}
                                                </Fab>
                                                <IconCheckNG
                                                  show={
                                                    question
                                                      ? question.answer[
                                                          index
                                                        ] !== ""
                                                      : false
                                                  }
                                                  isNG={checkNG(
                                                    subCatIndex,
                                                    indexQuestion,
                                                    index
                                                  )}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                  <div className={`space-x-2`}>
                                    <Fab
                                      size='medium'
                                      color='success'
                                      disabled={
                                        question.skipQuestion ||
                                        inputPerQuestion[subCatIndex][
                                          indexQuestion
                                        ] >= 3
                                      }
                                      onClick={() => {
                                        setInputPerQuestion((prev) => {
                                          const cloneInputPerQuestion = [
                                            ...prev,
                                          ];
                                          inputPerQuestion[subCatIndex][
                                            indexQuestion
                                          ]++;
                                          return cloneInputPerQuestion;
                                        });
                                      }}>
                                      <Add />
                                    </Fab>
                                    <Fab
                                      size='medium'
                                      color='error'
                                      disabled={
                                        question.skipQuestion ||
                                        inputPerQuestion[subCatIndex][
                                          indexQuestion
                                        ] === 1
                                      }
                                      onClick={() => {
                                        handleDeleteQuestion(
                                          subCatIndex,
                                          indexQuestion
                                        );
                                      }}>
                                      <Remove />
                                    </Fab>
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      )
                  )
                ) : (
                  <div>ไม่มีข้อมูล</div>
                )}
              </div>
            </div>
          )}
          {paper && (
            <div className='mb-2 mt-2'>
              {paper.inspector_id && (
                <div className='flex justify-end'>
                  <div className={`text-gray-500`}>Inspector :</div>
                  <SignatureBox
                    className={`ml-4`}
                    user={paper.inspector_id.data.attributes}
                    signatureURL={
                      paper.signature_inspector
                        ? paper.signature_inspector
                        : undefined
                    }
                  />
                </div>
              )}
              {paper.signature_checker && (
                <div className='flex justify-end mt-4'>
                  <div className={`text-gray-500`}>Checker :</div>

                  <SignatureBox
                    className={`ml-4`}
                    user={paper.checker_id.data.attributes}
                    signatureURL={paper.signature_checker}
                  />
                </div>
              )}
              {paper.signature_approver && (
                <div className='flex justify-end mt-4'>
                  <div className={`text-gray-500`}>Approver :</div>
                  <SignatureBox
                    className={`ml-4`}
                    user={paper.approver_id.data.attributes}
                    signatureURL={paper.signature_approver}
                  />
                </div>
              )}
            </div>
          )}
          {checkUpdateButton() && (
            <div className={`flex justify-end mt-4`}>
              <Button
                variant='contained'
                size='large'
                onClick={() => updatePaper()}>
                แก้ไขข้อมูล
              </Button>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EditPaperV3;
