import React, { useEffect, useState } from "react";
import { APIServices } from "./../helpers/APIServices";
import CloseIcon from "@mui/icons-material/Close";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import Search from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Switch from "@mui/material/Switch";
import CheckIcon from "@mui/icons-material/Check";
import {
  Link,
  useParams,
  Navigate,
  Outlet,
  useLocation,
} from "react-router-dom";
import Fab from "@mui/material/Fab";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SimpleBackdrop from "../Components/Backdrop";

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

function Question() {
  const [question, setQuestion] = useState([]);
  const param = useParams();
  const [search, setSearch] = useState("");
  const [searchSwitch, setSearchSwitch] = useState(undefined);
  const [loading, setLoading] = useState(true);

  const handleSearch = (value) => {
    setSearch(value);
  };

  const handleSearchSwitch = (value) => {
    setSearchSwitch(value);
  };

  const getQuestion = async () => {
    setLoading(true)
    const response = await APIServices.getQuestion(param.id);
    const question = response.data.data;
    const newQuestion = question.map((questionPerRound) => {
      return {
        id: questionPerRound.id,
        ...questionPerRound.attributes,
      };
    });
    setQuestion(newQuestion);
    setLoading(false)
  };

  const checkSearch = (emptyCheck) => {
    if (search === "" || emptyCheck.question.toLowerCase().includes(search.toLowerCase())) {
      return true;
    }
    if (searchSwitch === undefined) {
      return true;
    }
    if (searchSwitch === false || emptyCheck.sc_status) {
      if (search === "" || emptyCheck.question.toLowerCase().includes(search.toLowerCase())) {
        return true;
      }
      // else {
      //   if (search === "" || emptyCheck.question.includes(search)) {
      //     return true;
      //   }
      // }

      return false;
    }
  };

  //   const handleToOpenDashboard = (SC) => {
  //     return SC ? Navigate("/data") : Navigate("/normal_data");
  //   };

  useEffect(() => {
    getQuestion();
  }, []);

  return (
    <>
      <SimpleBackdrop open={loading} setOpen={setLoading} />

      <div className="mb-4 flex ">
        <div className="flex flex-row items-center justify-center">
          <span>SC</span>
          <Switch
            onChange={(e) => {
              handleSearchSwitch(e.target.checked);
            }}
          />
        </div>
        <TextField
          onChange={(e) => {
            handleSearch(e.target.value);
          }}
          placeholder="Search by question"
          className="flex-1"
          variant="outlined"
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        ></TextField>
      </div>
      <div className="flex flex-col justify-center items-center ">
        {question.length !== 0 ? (
          <div className="bg-white p-4 mb-4 flex w-full flex-row rounded-md shadow-md">
            {/* <div className="basis-1/4 flex items-center rounded-full ">รูป</div> */}
            <div className="basis-1/2  flex items-center">คำถาม</div>
            <div className="basis-1/4  flex items-center justify-center">
              Spec Min
            </div>
            <div className="basis-1/4  flex items-center justify-center">
              Spec Max
            </div>
            <div className="basis-1/4  flex items-center justify-center">
              สถานะ SC
            </div>
            <div className="basis-1/4  flex items-center justify-center">
              Dashboard
            </div>
          </div>
        ) : (
          <div className="bg-white p-4 mb-4 flex justify-center w-full rounded-md shadow-md">
            <span>ไม่มีข้อมูล</span>
          </div>
        )}

        {question
          .filter((emptyCheck) => emptyCheck !== "" && emptyCheck.status === true && checkSearch(emptyCheck))
          .map((data, index) => (
            <div
              key={data.id}
              className="bg-white p-4 mb-4 flex w-full flex-row rounded-md shadow-md hover:bg-neutral-100"
            >
              <div className="basis-1/2  flex items-center ">
                {data.question}
              </div>
              <div className="basis-1/4  flex items-center justify-center">
                {data.spec_min ? data.spec_min : "-"}
              </div>
              <div className="basis-1/4  flex items-center justify-center">
                {data.spec_max ? data.spec_max : "-"}
              </div>
              <div className="basis-1/4  flex items-center justify-center">
                {data.sc_status ? <CheckIcon /> : "-"}
              </div>
              <div className="basis-1/4  flex items-center justify-center">
                {/* {data.sc_status ? (
                  <Link key={`${index}`} to={`data/${data.id}`}>
                    <Fab color="primary" aria-label="add" size="medium">
                      <ArrowForwardIosIcon />
                    </Fab>
                  </Link>
                ) : (
                  <>
                    {data.spec_min && data.spec_max ? (
                      <Link
                        key={`${index}`}
                        to={`normal_data_with_actual/${data.id}`}
                      >
                        <Fab color="primary" aria-label="add" size="medium">
                          <ArrowForwardIosIcon />
                        </Fab>
                      </Link>
                    ) : (
                      <Link key={`${index}`} to={`normal_data/${data.id}`}>
                        <Fab color="primary" aria-label="add" size="medium">
                          <ArrowForwardIosIcon />
                        </Fab>
                      </Link>
                    )}
                  </>
                )} */}
                <LinkDashboard data={data} key={`LinkDashboard${index}`} />
              </div>
            </div>
          ))}
      </div>
    </>
  );
}

export default Question;

const LinkDashboard = ({data}) => {
  console.log("data 222", data);
  const checkLink = () => {
    if(data.sc_status){
      return 'data'
    }else{
      if(data.spec_min && data.spec_max){
        return 'normal_data_with_actual'
      }
      console.log("230", data.sub_category);
      if(data.sub_category.data.attributes.category.data.attributes.category === "Dimension"){
        return 'normal_data_with_actual'
      }
      return 'normal_data'
    }
  }
  console.log("237", checkLink(), `${checkLink()}/${data.id}`);
  return (
    <Link to={`${checkLink()}/${data.id}`}>
      <Fab color="primary" aria-label="add" size="medium">
        <ArrowForwardIosIcon />
      </Fab>
    </Link>
  )
}